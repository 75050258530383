import { IUser } from './authentication.client';
import { CaseEvents } from './case.client';
import httpClient from './http-client';
import { IPagination, IPaginationQuery } from './notification.client';

export interface ISession {
  id: number;
  createdAt: string;
  updatedAt: string;
  endsAt?: string;
  loggedOutAt?: string;
  events: CaseEvents[];
  ip: string;
  userAgent?: string;
  user: IUser;
}

export class SessionClient {
  static async listUserSessions(
    userId: number,
    params: IPaginationQuery
  ): Promise<IPagination<ISession>> {
    const response = await httpClient.get(`/v1/session/user/${userId}`, {
      params,
    });

    return response.data;
  }

  static async detail(sessionId: number): Promise<ISession> {
    const response = await httpClient.get(`/v1/session/${sessionId}`);

    return response.data;
  }

  static async listSessionEvents(
    sessionId: number,
    params: IPaginationQuery
  ): Promise<IPagination<CaseEvents>> {
    const response = await httpClient.get(`/v1/session/${sessionId}/events`, {
      params,
    });

    return response.data;
  }
}
