import { IHideLabSchema } from 'kach-components/src/schemas/hide-lab.schema';
import httpClient from './http-client';
import {
  IContact,
  IContactPrice,
  IPagination,
  IPaginationQuery,
} from './notification.client';

export interface ICreateContactPrice
  extends Partial<
    Omit<IContactPrice, 'id' | 'sinisterType' | 'investigationType'>
  > {
  sinisterTypeId: number;
  investigationType: string;
}

export interface ICreateContactSchema {
  name: string;
  email: string;
  sinisterTypesSupported: {
    name: string;
    company_id: number;
  }[];
  hidden?: boolean;
}

export class ContactClient {
  static async list(
    params: IPaginationQuery,
    cookie?: string
  ): Promise<IPagination<IContact>> {
    const response = await httpClient.get('/v1/company/contact/lab', {
      params,
      headers: {
        Cookie: cookie,
      },
    });

    return response.data;
  }

  static async fetchPrices(
    contactId: number,
    endpointPrefix: string = ''
  ): Promise<{
    contactPrices: IContactPrice[];
  }> {
    const response = await httpClient.get(
      `/v1${endpointPrefix}/contact/${contactId}/price?isLatestVersion=true`
    );

    return response.data;
  }

  static async detail(id: number, cookie?: string): Promise<IContact> {
    const response = await httpClient.get(`/v1/company/contact/${id}`, {
      headers: {
        Cookie: cookie,
      },
    });

    return response.data;
  }

  private static filterEmptyPriceFields(
    price: ICreateContactPrice
  ): ICreateContactPrice {
    return Object.fromEntries(
      Object.entries(price).filter(([, value]) => {
        if (!!value && typeof value === 'object') {
          return 'amount' in value ? !!value.amount : true;
        }

        return !!value;
      })
    ) as any as ICreateContactPrice;
  }

  static async create(
    data: ICreateContactSchema
  ): Promise<{ contactId: number }> {
    const response = await httpClient.post(`/v1/company/contact/lab`, data);

    return response.data;
  }

  static async update(
    contactId: number,
    data: Partial<ICreateContactSchema | IHideLabSchema>
  ): Promise<{ contactId: number }> {
    const response = await httpClient.put(
      `/v1/company/contact/${contactId}`,
      data
    );

    return response.data;
  }

  static async createPrice(input: {
    contactId: number;
    data: ICreateContactPrice;
    previousContactPriceId?: number;
    endpointPrefix?: string;
  }): Promise<IContact> {
    const response = await httpClient.post(
      `/v1${input.endpointPrefix || ''}/contact/${input.contactId}/price`,
      {
        ...this.filterEmptyPriceFields(input.data),
        previousContactPriceId: input.previousContactPriceId,
      }
    );

    return response.data;
  }
}
