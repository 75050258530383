import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel as FormLabelChakra,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverContentProps,
  PopoverHeader,
  PopoverTrigger,
  Select,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { ChevronDownIcon, UserIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import get from 'lodash.get'
import { CaseClient, ICaseExtended, ICaseUser } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { AsYouType } from 'libphonenumber-js'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { CustomerSchema, ICustomerSchema } from '../schemas/customer.schema'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { CellphoneField } from './CellphoneField'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

const CustomerForm = (props: {
  caseUsers: ICaseUser[]
  customer: ICustomerSchema
  caseId: number
  close: () => void
  readonly?: boolean
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ICustomerSchema>({
    resolver: zodResolver(CustomerSchema),
    defaultValues: props.customer,
  })

  const auth = useAuth()

  const isCurrentUserIncluded = props.caseUsers.some(
    (caseUser) => caseUser.user.id === auth.id,
  )

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onSubmit = useCallback((customer: ICustomerSchema) => {
    setIsLoading(true)

    toast.promise(CaseClient.updateCase(props.caseId, { customer }), {
      loading: {
        title: 'Actualizando asegurado...',
      },
      success: () => {
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
        props.close()
        setIsLoading(false)
        return { title: 'Asegurado actualizado correctamente' }
      },
      error: (err) => {
        setIsLoading(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [])

  const fields = watch()

  return (
    <chakra.form
      display='flex'
      flexDir='column'
      experimental_spaceY={4}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl isInvalid={!!errors['firstName']}>
        <FormLabelChakra>Nombre</FormLabelChakra>
        <Input
          type='text'
          {...register('firstName')}
          size='sm'
          isReadOnly={!isCurrentUserIncluded || !!props.customer?.firstName}
        />
        {!!!errors['firstName'] ? (
          <FormHelperText></FormHelperText>
        ) : (
          <FormErrorMessage>{errors['firstName'].message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={!!errors['lastName']}>
        <FormLabelChakra>Apellido</FormLabelChakra>
        <Input
          type='text'
          {...register('lastName')}
          size='sm'
          isReadOnly={!isCurrentUserIncluded || !!props.customer?.lastName}
        />
        {!!!errors['lastName'] ? (
          <FormHelperText></FormHelperText>
        ) : (
          <FormErrorMessage>{errors['lastName'].message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={!!errors.cellphone}>
        <FormLabelChakra>Celular</FormLabelChakra>
        <Box display='flex'>
          <Select
            {...register('countryISO')}
            minW='25%'
            w='15%'
            size='sm'
            borderRight='none'
            borderBottomRightRadius={0}
            borderTopRightRadius={0}
            isDisabled={!isCurrentUserIncluded}
            isReadOnly={!isCurrentUserIncluded || !!props.customer?.countryISO}
          >
            <option>AR</option>
          </Select>
          <Input
            w='75%'
            size='sm'
            borderBottomLeftRadius={0}
            borderLeft='none'
            borderTopLeftRadius={0}
            type='text'
            isDisabled={!isCurrentUserIncluded}
            isReadOnly={!isCurrentUserIncluded || !!props.customer?.cellphone}
            placeholder='+54 (381) 363-5420'
            {...register('cellphone', {
              setValueAs: (val) =>
                //@ts-ignore
                new AsYouType(get(fields, 'countryISO')).input(val),
            })}
          />
        </Box>
        {!!!errors.cellphone ? (
          <FormHelperText></FormHelperText>
        ) : (
          <FormErrorMessage>{errors.cellphone.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={!!errors['identification']}>
        <FormLabelChakra>Identificación</FormLabelChakra>
        <Input
          type='text'
          {...register('identification')}
          size='sm'
          isReadOnly={
            !isCurrentUserIncluded ||
            (!!props.customer?.identification &&
              props.customer.identification.length >= 8)
          }
        />
        {!!!errors['identification'] ? (
          <FormHelperText></FormHelperText>
        ) : (
          <FormErrorMessage>
            {errors['identification'].message}
          </FormErrorMessage>
        )}
      </FormControl>

      {isCurrentUserIncluded && !props.readonly && (
        <Button
          {...buildTestId('customer-save-btn')}
          colorScheme='brand'
          size='sm'
          isLoading={isLoading}
          type='submit'
        >
          Guardar
        </Button>
      )}
    </chakra.form>
  )
}

export const CaseCustomer: React.FC<{
  caseExtended: ICaseExtended
  readonly?: boolean
  boxStyles?: PopoverContentProps
}> = (props) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
    <Box>
      <Popover isLazy isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button
            {...buildTestId('customer-popover-trigger-btn')}
            size='xs'
            onClick={onToggle}
            rightIcon={<Icon as={ChevronDownIcon} />}
          >
            <Icon as={UserIcon} />
          </Button>
        </PopoverTrigger>
        <PopoverContent {...props.boxStyles}>
          <PopoverHeader fontWeight='semibold'>Asegurado</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton {...buildTestId('customer-popover-close-btn')} />
          <PopoverBody>
            <CustomerForm
              readonly={props.readonly}
              caseUsers={props.caseExtended.users}
              close={onClose}
              caseId={props.caseExtended.id}
              customer={props.caseExtended.customer}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
