import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Select,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { InvestigationClient } from 'kach-clients'
import { buildTestId, reportConclusions } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'
import { useQueryClient } from '@tanstack/react-query'
import { useFileUploadV2 } from '..'
import { useError } from '../hooks/useError'
import { MAX_FILE_SIZE_IN_MB } from '../hooks/useFileUpload'
import {
  AddInvestigationReportSchema,
  IAddInvestigationReportSchema,
} from '../schemas/add-investigation-report.schema'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'
import { translator } from '../utils/translator'
import { FileUploadV2 } from './FileUploadV2'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { remapFile, remapFileList } from '../utils/remap-file-list'

export const AddInvestigationReport = (props: { investigationId: number }) => {
  const { onUploadFiles, fileList, onRemove, onUpdateFile, onCleanUp } =
    useFileUploadV2((files) =>
      InvestigationClient.tokenizeArtifacts(props.investigationId, files),
    )

  const [artifactError, setArtifactError] = useState<{ message?: string }>()

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IAddInvestigationReportSchema>({
    resolver: zodResolver(AddInvestigationReportSchema),
  })

  const error = useError()

  const fields = watch()

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onAddReport = useCallback(
    (onClose: () => void) => {
      return (data: Required<IAddInvestigationReportSchema>) => {
        if (!fileList.length) {
          setArtifactError({ message: 'Mínimo un archivo!' })
          return
        }

        const [artifact] = fileList

        setIsLoading(true)

        toast.promise(
          InvestigationClient.addReport(props.investigationId, {
            artifact: remapFile(artifact),
            ...data,
          }),
          {
            error: (err) => {
              setIsLoading(false)

              return {
                title: error.getErrorMessage(err),
              }
            },
            loading: {
              title: 'Agregando informe',
            },
            success: () => {
              setIsLoading(false)

              queryClient.invalidateQueries([
                buildInvestigationQueryKey(props.investigationId),
              ])

              onCleanUp()
              reset()
              onClose()

              return {
                title: 'Informe agregado correctamente',
              }
            },
          },
        )
      }
    },
    [fileList],
  )

  return (
    <Popover isLazy>
      {({ onClose }) => {
        return (
          <>
            <PopoverTrigger>
              <Button
                {...buildTestId('add-investigation-report')}
                size='sm'
                w={['full', null, 'auto']}
                variant={'primary'}
                leftIcon={<AiOutlinePlus className='mr-2' />}
              >
                Agregar informe
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <chakra.form
                  novalidate
                  novalidate='true'
                  id='add-investigation'
                  onSubmit={handleSubmit(onAddReport(onClose))}
                  display={'flex'}
                  flexDir='column'
                  experimental_spaceY={'4'}
                >
                  <FormControl isRequired>
                    <FormLabel>Conclusion</FormLabel>
                    <Select {...register('conclusion')}>
                      {reportConclusions.map((conclusion, index) => {
                        return (
                          <option key={index} value={conclusion}>
                            {translator(conclusion)}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Informe</FormLabel>
                    <FileUploadV2
                      onUpdateFile={onUpdateFile}
                      inputId='investigation-report'
                      limit={1}
                      fileList={fileList}
                      maxFileSizeMB={MAX_FILE_SIZE_IN_MB}
                      onRemove={onRemove}
                      onUpload={onUploadFiles}
                    />
                  </FormControl>
                </chakra.form>
              </PopoverBody>
              <PopoverFooter
                display='flex'
                justifyContent='end'
                experimental_spaceX={2}
              >
                <Button isDisabled={isLoading} onClick={onClose} size='sm'>
                  Cancelar
                </Button>
                <Button
                  {...buildTestId('add-investigation-report-btn')}
                  type='submit'
                  form='add-investigation'
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onClick={() => onAddReport(onClose)}
                  variant={'primary'}
                  size='sm'
                >
                  Agregar
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </>
        )
      }}
    </Popover>
  )
}
