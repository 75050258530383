import React from 'react'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { buildTestId } from 'kach-commons'

export const CustomBreadCrumb = (props: {
  initialPageLabel?: string
  currentPageLabel: string
  onBack: () => void
}) => {
  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbLink
          {...buildTestId('breadcrumb-link')}
          onClick={props.onBack}
          href='#'
        >
          {props.initialPageLabel || 'Inicio'}
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink href='#'>{props.currentPageLabel}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}
