import { number, z } from 'zod'
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema'
import { PersonSchema } from './person.schema'

export const ssnPersonInvolvedType = [
  'insured',
  'pas',
  'third',
  'driver',
  'witness',
  'lawyer',
  'professional',
  'liquidator',
] as const

export type SsnPersonInvolvedType = (typeof ssnPersonInvolvedType)[number]

export const ssnProfessionType = ['doctor', 'lawyer'] as const

export const ssnPersonInvolvedGroups: {
  name: string
  types: SsnPersonInvolvedType[]
}[] = [
  {
    name: 'insuredCustomer',
    types: ['insured', 'pas', 'third', 'driver'],
  },
  {
    name: 'lawyer',
    types: ['lawyer'],
  },
  {
    name: 'witness',
    types: ['witness'],
  },
  {
    name: 'professional',
    types: ['professional'],
  },
]

export type SsnProfessionType = (typeof ssnProfessionType)[number]

export const ssnLawyerType = [
  'insurer',
  'taker',
  'beneficiary',
  'third_part',
] as const

export type SsnLawyerType = (typeof ssnLawyerType)[number]

export const refineSsnPersonValidationSchema = <T extends z.ZodRawShape>(
  schema: T,
) => {
  return z
    .object({
      person: PersonSchema.optional(),
      type: z.enum(ssnPersonInvolvedType),
      profession: z.string().nullish(),
      lawyerType: z.string().nullish(),
    })
    .extend(schema)
    .refine(
      (v) => {
        if (v.type !== 'lawyer') {
          return true
        }

        if (v?.lawyerType) {
          return true
        }

        return false
      },
      {
        path: ['lawyerType'],
        message: 'Seleccione la Parte del abogado',
      },
    )
    .refine(
      (v) => {
        if (v.type !== 'professional') {
          return true
        }

        if (v?.profession) {
          return true
        }

        return false
      },
      {
        path: ['profession'],
        message: 'Seleccione la profesión del Profesional',
      },
    )
}

export const SsnPersonSchema = refineSsnPersonValidationSchema({})

export interface ISsnPersonSchema extends z.infer<typeof SsnPersonSchema> {}

export const CloseCaseSsnSchema = z.object({
  investigationSummary: z.string().nullish(),
  closeMotive: z
    .string({ required_error: REQUIRED_FIELD_MESSAGE })
    .min(1, REQUIRED_FIELD_MESSAGE)
    .transform((v) => Number(v)),
  result: z
    .string({ required_error: REQUIRED_FIELD_MESSAGE })
    .min(1, REQUIRED_FIELD_MESSAGE)
    .transform((v) => Number(v)),
})

export interface ICloseCaseSsnSchema
  extends z.infer<typeof CloseCaseSsnSchema> {}
