export const caseResults = [
  {
    label: 'Positivo',
    value: 'positive',
  },
  {
    label: 'Sin Resultado',
    value: 'without_result',
  },
  {
    label: 'Negativo',
    value: 'negative',
  },
  {
    label: 'Elementos para el rechazo',
    value: 'elements_for_rejection',
  },
  {
    label: 'En ampliación',
    value: 'in_extension',
  },
  {
    label: 'De baja',
    value: 'canceled',
  },
  {
    label: 'Posible prescripción',
    value: 'possible_prescription',
  },
  {
    label: 'No se deriva',
    value: 'not_derived',
  },
  {
    label: 'Cancelado por la dirección',
    value: 'canceled_by_management',
  },
] as const

type CaseResults = (typeof caseResults)[number]

export type CaseResultsType = CaseResults['value']

export const getLabelByValue = <V extends CaseResultsType>(val: V): string => {
  return caseResults.find((ele) => ele.value === val)?.label || ''
}
