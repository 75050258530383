import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { CaseClient, MailClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { MAX_FILE_SIZE_IN_MB } from '../hooks/useFileUpload'
import { useFileUploadV2 } from '../hooks/useFileUploadV2'
import {
  CreateMailSchema,
  ICreateMailSchema,
} from '../schemas/create-mail.schema'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { FileUploadV2 } from './FileUploadV2'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { remapFile } from '../utils/remap-file-list'

export default function CreateMail(props: {
  caseId: number
  onClose: () => void
}) {
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<ICreateMailSchema>({
    resolver: zodResolver(CreateMailSchema),
  })

  const { fileList, onUploadFiles, onRemove, onUpdateFile } = useFileUploadV2(
    (files) => CaseClient.tokenizeArtifacts(props.caseId, files),
  )

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const isMailFileNotUploaded = useMemo(
    () => !fileList || !Array.isArray(fileList) || !fileList.length,
    [fileList],
  )

  const onSubmit = useCallback(
    (data: ICreateMailSchema) => {
      const [artifact] = fileList

      setIsLoading(true)

      toast.promise(
        MailClient.create(props.caseId, {
          externalId: data.externalId,
          artifact: remapFile(artifact),
        }),
        {
          loading: { title: 'Creando carta...' },
          success: () => {
            queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
            props.onClose()
            setIsLoading(false)
            return { title: 'Carta creada correctamente' }
          },
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
        },
      )
    },
    [fileList, isMailFileNotUploaded],
  )

  useEffect(() => {
    if (!isMailFileNotUploaded) {
      clearErrors('artifact')
    }
  }, [isMailFileNotUploaded])

  console.log('errors', errors)

  return (
    <chakra.form
      onSubmit={handleSubmit(onSubmit)}
      experimental_spaceY={8}
      noValidate
    >
      <Box experimental_spaceY={8}>
        <Box experimental_spaceY={6}>
          <Box>
            <Text fontSize='lg' fontWeight='medium'>
              Carta
            </Text>
            <Text mt={1} fontSize='sm' color='gray.500'>
              Crea una carta para llevar un registro digital de las cartas
              legales relacionadas al caso
            </Text>
          </Box>

          <Box experimental_spaceY={4}>
            <FormControl isInvalid={!!errors['externalId']} isRequired>
              <FormLabel>Identificador</FormLabel>
              <Input type='text' {...register('externalId')} />
              {!!!errors['externalId'] ? (
                <FormHelperText></FormHelperText>
              ) : (
                <FormErrorMessage>
                  {errors['externalId'].message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Carta</FormLabel>
              <FileUploadV2
                onUpdateFile={onUpdateFile}
                fileList={fileList}
                onRemove={onRemove}
                inputId='artifact-mail'
                limit={1}
                maxFileSizeMB={MAX_FILE_SIZE_IN_MB}
                onUpload={onUploadFiles}
              />
              {!!!errors['artifact'] ? (
                <FormHelperText></FormHelperText>
              ) : (
                //@ts-ignore
                <FormErrorMessage>{errors.artifact?.message}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box display='flex' justifyContent='end' experimental_spaceX={2}>
          <Button isDisabled={isLoading} onClick={props.onClose} size='sm'>
            Cancelar
          </Button>
          <Button
            {...buildTestId('create-mail-button')}
            isLoading={isLoading}
            type='submit'
            colorScheme='brand'
            size='sm'
          >
            Crear carta
          </Button>
        </Box>
      </Box>
    </chakra.form>
  )
}
