import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react'
import { CaseEvents, IInvestigation } from 'kach-clients'
import React from 'react'
import { CaseTimeline } from './CaseTimeline'

export const TimelineInvestigation = (props: {
  investigation: IInvestigation
  events: CaseEvents[]
}) => {
  return (
    <section aria-labelledby='timeline-title'>
      <Box
        bg='white'
        borderRadius='md'
        boxShadow='md'
        border='1px'
        borderColor='gray.100'
      >
        <Accordion allowMultiple>
          <AccordionItem border='none' p={4}>
            <h2>
              <AccordionButton p={0} _hover={{ background: 'initial' }}>
                <Box as='span' flex='1' textAlign='left'>
                  <Text id='timeline-title' fontSize='lg' fontWeight='medium'>
                    Progreso
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel p={0}>
              <CaseTimeline
                investigation={props.investigation}
                events={props.events}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </section>
  )
}
