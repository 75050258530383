import {
  Alert,
  AlertIcon,
  Box,
  Button,
  chakra,
  FormControl,
  FormLabel,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Textarea,
  UnorderedList,
  useToast,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useToggle } from 'ahooks'
import {
  CaseClient,
  IContact,
  IInvestigation,
  InvestigationClient,
} from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import {
  CreateInvestigationSchema,
  ICreateInvestigationSchema,
} from '../schemas/create-investigation.schema'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { AddCaseResource } from './AddCaseResource'
import { CaseAccordion } from './CaseAccordion'
import { ContactSelectSearch } from './ContactSelectSearch'
import { IDynamicObject, InvestigationLine } from './InvestigationLine'
import Link from 'next/link'
import { buildTestId } from 'kach-commons'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { WaitForQuery } from './WaitForQuery'

const CreateInvestigationForm = ({
  children,
  ...props
}: {
  onClose: () => void
  caseId: number
  children?: React.ReactNode
}) => {
  const {
    unregister,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<ICreateInvestigationSchema>({
    resolver: zodResolver(CreateInvestigationSchema),
  })

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const fields = watch()

  const toast = useToast()

  const onSubmit = useCallback((data: Required<ICreateInvestigationSchema>) => {
    setIsLoading(true)
    toast.promise(InvestigationClient.create(props.caseId, data), {
      loading: { title: 'Iniciando investigación...' },
      success: () => {
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
        props.onClose()
        setIsLoading(false)
        return { title: 'Investigación iniciada correctamente' }
      },
      error: (err) => {
        console.log('err', err)
        setIsLoading(false)
        return {
          title: error.getErrorMessage(err),
        }
      },
    })
  }, [])

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} experimental_spaceY={8}>
      <Box experimental_spaceY={8}>
        <Box experimental_spaceY={4}>
          <Box>
            <Text fontSize='lg' fontWeight='medium'>
              Investigación
            </Text>
            <Text mt={1} fontSize='sm' color='gray.500'>
              Inicia una investigación con la ayuda de un estudio de
              investigación experto en el campo
            </Text>
          </Box>
          {children}
          <Box display={'flex'} flexDir='column' experimental_spaceY={'5'}>
            <FormControl isRequired isInvalid={!!errors.contactId}>
              <FormLabel>Estudio</FormLabel>
              <ContactSelectSearch
                Navigator={Link}
                onSelect={(contact) => setValue('contactId', contact?.id)}
              />
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.type}>
              <FormLabel>Tipo de investigación</FormLabel>
              <Select {...register('type')}>
                <option value='f2f'>Presencial</option>
                <option value='virtual'>Virtual</option>
              </Select>
            </FormControl>
            <FormControl isInvalid={!!errors.type} isRequired>
              <FormLabel>Indicadores</FormLabel>
              <Textarea {...register('indicators')} />
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box
          display={'flex'}
          justifyContent='end'
          flexDir='row'
          experimental_spaceX={'4'}
        >
          <Button
            size='sm'
            disabled={isLoading}
            onClick={props.onClose}
            type='button'
          >
            Cancelar
          </Button>
          <Button
            {...buildTestId('start-investigation-btn')}
            variant={'primary'}
            isDisabled={isLoading}
            size='sm'
            disabled={isLoading}
            isLoading={isLoading}
            type='submit'
          >
            Iniciar investigación
          </Button>
        </Box>
      </Box>
    </chakra.form>
  )
}

export const ActiveInvestigationsAlert = (props: { caseId: number }) => {
  const query = useQuery({
    queryKey: [`list-case-${props.caseId}-active-investigations`],
    queryFn: () => CaseClient.listActiveInvestigationsByCustomer(props.caseId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  if (query.data && query.data.result.length > 0)
    return (
      <Alert fontSize='sm' status='warning'>
        <Stack direction={'row'}>
          <AlertIcon />
          <Box>
            El socio <strong>{query.data.query}</strong> tiene las siguientes
            investigaciones activas:{' '}
            {query.data.result.map((item, index) => {
              return (
                <ChakraLink
                  key={item.id}
                  textDecor='underline'
                  href={`/case/${item.id}`}
                  target='_blank'
                >
                  {item.caseRequest.sinisterId}
                  {index !== query.data.result.length - 1 ? ', ' : ''}
                </ChakraLink>
              )
            })}
          </Box>
        </Stack>
      </Alert>
    )

  return null
}

const CreateInvestigation = (props: {
  onClose: () => void
  caseId: number
}) => {
  return <CreateInvestigationForm {...props} />
}

export const CaseInvestigations = (props: {
  caseId: number
  investigations: IInvestigation[]
  withoutCreate?: boolean
  query: IDynamicObject
}) => {
  const [open, { toggle }] = useToggle()

  return (
    <>
      <Modal isOpen={open} onClose={toggle}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={6}>
            {open && (
              <CreateInvestigation caseId={props.caseId} onClose={toggle} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <CaseAccordion
        count={props.investigations.length}
        SuffixComponent={
          props.withoutCreate ? null : (
            <Box {...buildTestId('create-investigation-btn-container')}>
              <AddCaseResource label='Crear investigación' onClick={toggle} />
            </Box>
          )
        }
        label={{
          singular: 'investigación',
          plural: 'investigaciones',
        }}
      >
        <Box bg='white' borderRadius='md'>
          <Box bg='white' boxShadow='md' borderRadius='md'>
            <Box bg='white' boxShadow='md'>
              <List px={4}>
                {props.investigations.map((investigation, index) => {
                  return (
                    <InvestigationLine
                      caseId={props.caseId}
                      index={index}
                      withoutCreate={props.withoutCreate}
                      query={props.query}
                      key={index}
                      investigation={investigation}
                    />
                  )
                })}
              </List>
            </Box>
          </Box>
        </Box>
      </CaseAccordion>
    </>
  )
}
