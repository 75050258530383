import { Box, chakra, Text, Badge, Grid } from '@chakra-ui/react'
import { IUser } from 'kach-clients'
import { format } from 'date-fns'
import React from 'react'
import { buildUserName } from '../utils/build-user-name'

const styles = {
  experimental_spaceX: 1,
  display: 'flex',
  fontSize: '2xs',
  color: 'gray.500',
  alignItems: 'center',
}

export const CreatedByDate = (props: {
  date: string
  user: IUser
  destinations: string[] | undefined
}) => {
  const destinations = props.destinations || []

  return (
    <>
      <Box sx={styles}>
        <chakra.span>Creado el</chakra.span>
        <Text fontStyle='italic'>{format(new Date(props.date), 'Pp')}</Text>
        <chakra.span>por</chakra.span>
        <chakra.span>{buildUserName(props.user)}</chakra.span>
      </Box>

      {!!props.destinations && props.destinations.length > 0 && (
        <>
          <Box sx={styles}>
            <Text>Destinatarios Adicionales:</Text>
          </Box>
          <Box sx={styles} style={{ marginTop: 2 }}>
            <Grid templateColumns='repeat(3, 1fr)' gap={2}>
              {destinations.map((destination, index) => (
                <Text
                  key={index}
                  backgroundColor='gray.100'
                  border='1px'
                  pb={0.5}
                  pt={0.5}
                  pl={2}
                  pr={2}
                  borderColor='gray.200'
                  borderRadius='md'
                >
                  <Text fontWeight='light'>{destination.toLowerCase()}</Text>
                </Text>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </>
  )
}
