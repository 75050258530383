import { IArtifactAck } from './case.client';
import httpClient from './http-client';
import { IInvestigationReport } from './notification.client';

export class ReportClient {
  static async create(investigationId: number, artifact: IArtifactAck) {
    const response = await httpClient.post(
      `/v1/investigation/${investigationId}/report`,
      {
        artifact,
      }
    );

    return response.data;
  }

  static async detail(id: number): Promise<IInvestigationReport> {
    const response = await httpClient.get(`/v1/investigation/report/${id}`);

    return response.data;
  }

  static async addMessage(
    reportId: number,
    message: {
      message: string;
      artifacts: IArtifactAck[];
    }
  ): Promise<IInvestigationReport> {
    const response = await httpClient.post(
      `/v1/investigation/report/${reportId}/message`,
      message
    );

    return response.data;
  }
}
