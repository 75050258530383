import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const TunnelRat = ({
  children,
  tunnelId,
}: {
  children:
    | React.ReactNode
    | React.FC<{
        element: HTMLElement
      }>
  tunnelId: string
}) => {
  const [domReady, setDomReady] = useState(false)

  useEffect(() => {
    setDomReady(true)
  }, [])

  return domReady
    ? createPortal(
        typeof children === 'function'
          ? children({
              element: document.getElementById(tunnelId)!,
            })
          : children,
        document.getElementById(tunnelId)!,
      )
    : null
}
