import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { IPagination, IPaginationQuery } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useInfiniteQuery } from '@tanstack/react-query'
import flatten from 'lodash.flatten'
import React, { useContext, useEffect } from 'react'
import { UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form'
import { useAuth } from '../hooks/useAuth'
import { useDebounceSearch } from '../hooks/useDebounceSearch'
import { FiltersControl, useFilters } from '../hooks/useFilters'
import { useTableParams } from '../hooks/useTableParams'
import { KachContext } from '../providers/KachProvider'
import { getLastItem } from '../utils/get-last-item'
import { removeFiltersMetadata } from '../utils/remove-filters-metadata'
import { virtualView2filters } from '../utils/virtual-view'
import { Filters } from './Filters'
import { IDynamicObject } from './InvestigationLine'
import { TableColumns } from './TableColumns'
import { Column } from 'react-table'
import { ITableColumnsController } from '../hooks/useTableColumns'

export interface IFiltersForm {
  search: string
  filters: IFilter[]
}

export interface IFilter extends Omit<ICandidateFilter, 'Icon'> {
  disabledRender?: boolean
  value?: string
  enums?: {
    id?: number
    name: string
    checked: boolean
  }[]
  from?: string
  to?: string
}

export interface EnumOption {
  name?: string
  label?: string
  value: string | number
}

export interface ICandidateFilter {
  column: string
  label: string
  Icon: React.FC<{ className?: string }>
  type: 'text' | 'enum' | 'date' | 'sort' | 'async-enum' | 'range-slider'
  enumData?: EnumOption[]
  search?: (query: string) => Promise<EnumOption[]>
  defaultValue?: any
}

export interface TableAbstractChildrenProps<C> {
  pages: IPagination<C>
  addFilter: UseFieldArrayAppend<IFiltersForm, 'filters'>
  removeFilter: UseFieldArrayRemove
  fields: IFiltersForm
}

export const TABLE_ABSTRACT_SEARCH_TUNNEL_RAT_ID =
  'table-abstract-search-tunnel-rat-id'

export const TableAbstract = <C,>({
  withSearch = true,
  withCaption = true,
  CreateResourceComp,
  withToggleColumns = true,
  ...props
}: {
  withSearch?: boolean
  withCaption?: boolean
  columnsLiteralIds?: string[]
  SearchNeighbour?: React.FC<{
    params: IDynamicObject
    filtersControl: FiltersControl
    columnsController?: ITableColumnsController
  }>
  searchPlaceholder?: string
  initPagination?: Partial<IPagination<C>>
  candidateFilters: ICandidateFilter[]
  queryKey: string
  defaultValues?: Partial<IFiltersForm>
  labels: {
    singular: string
    plural: string
  }
  columnsController?: ITableColumnsController
  fetcher: (input: IPaginationQuery) => Promise<IPagination<C>>
  children: React.FC<TableAbstractChildrenProps<C>>
  CreateResourceComp?: React.FC<{ params: IDynamicObject }>
  withToggleColumns?: boolean
}) => {
  const filtersController = useFilters({
    columnsLiteralIds: props.columnsLiteralIds,
    defaultValues: props.defaultValues,
  })

  const { debounceSearch, search } = useDebounceSearch()

  const { defaultContext } = useContext(KachContext)

  const { params, setParams, virtualView } = useTableParams()

  const auth = useAuth()

  useEffect(() => {
    if (virtualView) {
      const filters = virtualView2filters(
        props.candidateFilters,
        virtualView.filters,
      )

      filtersController.setFilters('filters', filters)

      if (!virtualView?.columns) {
        props.columnsController?.resetColumns()
        return
      }

      props.columnsController?.setColumns(virtualView.columns)
    }
  }, [virtualView?.id])

  const {
    data: dataPagination,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      props.queryKey,
      filtersController.fields,
      search,
      auth.id,
    ] as const,
    queryFn: (context) => {
      const [_, fields, search] = context.queryKey

      const latestFiltersState = fields.filters.map((filter) => {
        if (filter.type === 'range-slider') {
          const latestState = filtersController.fields.filters.find(
            (ele) => ele.column === filter.column,
          )

          return {
            ...filter,
            value: latestState?.value,
          }
        }

        return filter
      })

      const filters = filtersController.serializeFilters(latestFiltersState)

      const nextParams = {
        page: context.pageParam,
        search: search as string,
      }

      setParams({
        ...nextParams,
        ...filters,
      })

      return props.fetcher({
        ...nextParams,
        ...removeFiltersMetadata(filters),
      })
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.hasMore) {
        return undefined
      }

      return (lastPage.page || 0) + 1
    },
    initialData: {
      pages: [props.initPagination],
      pageParams: [props.initPagination?.page || 1],
    },
    context: defaultContext,
    keepPreviousData: true,
  })

  const lastPage = getLastItem(dataPagination?.pages) as IPagination<C>

  return (
    <Box my={4}>
      {withCaption && (
        <Stack
          direction={'row'}
          justifyContent='space-between'
          alignItems={'center'}
          mb='2'
        >
          <Box display='flex' alignItems='center' w='full' mb={2}>
            <Text fontSize='xl' fontWeight='bold'>
              {props.labels.plural}
            </Text>
          </Box>
          {CreateResourceComp && <CreateResourceComp />}
        </Stack>
      )}

      <Box display={['initial', 'flex']} alignItems={['initial', 'center']}>
        <Box
          w='full'
          display='flex'
          flexDir={['column-reverse', null, null, 'row']}
          justifyContent='space-between'
          alignItems={['start', null, null, 'start']}
          experimental_spaceX={[0, null, null, 2]}
          experimental_spaceY={[4, null, null, 0]}
        >
          <Box width={['full', null, null, '60%']}>
            <HStack spacing={2}>
              <Filters
                {...filtersController}
                withToggleColumns={withToggleColumns}
                setFiltersValue={filtersController.setFilters}
                candidatesFilters={props.candidateFilters}
                columnsController={props?.columnsController}
              />
            </HStack>
          </Box>

          {withSearch && (
            <Box
              width={['full', null, null, '35%']}
              display={'flex'}
              flexDir='row'
              alignItems={'center'}
              mt={[2, null, 0]}
            >
              <Box id={TABLE_ABSTRACT_SEARCH_TUNNEL_RAT_ID} />
              <InputGroup size='sm'>
                <InputLeftElement
                  pointerEvents='none'
                  children={<Icon as={MagnifyingGlassIcon} />}
                />
                <Input
                  borderRadius='md'
                  {...buildTestId('search-input-table-abstract')}
                  placeholder={props.searchPlaceholder}
                  onChange={(e) => debounceSearch(e.target.value)}
                  type='text'
                />
              </InputGroup>
              {props.SearchNeighbour
                ? props.SearchNeighbour({
                    params,
                    filtersControl: filtersController,
                    columnsController: props.columnsController,
                  })
                : null}
            </Box>
          )}
        </Box>
      </Box>

      <Box my='5'>
        <props.children
          fields={filtersController.fields}
          addFilter={filtersController.onAddFilter}
          removeFilter={filtersController.onRemoveFilter}
          pages={{
            ...lastPage,
            results: flatten(dataPagination.pages.map((page) => page.results)),
          }}
        />
      </Box>
      <Box w='full' mt={2}>
        <Button
          variant='ghost'
          size='sm'
          w='full'
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage}
          isDisabled={!hasNextPage}
          isLoading={isFetchingNextPage}
        >
          Cargar más
        </Button>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' w='full'>
        <Text fontWeight='bold' textTransform='uppercase'>
          {(() => {
            if (!lastPage?.total) {
              return <>No hay {props.labels.plural}</>
            }

            return (
              <>
                <strong className='font-bold'>{lastPage?.total}</strong>{' '}
                {lastPage?.total === 1
                  ? props.labels.singular
                  : props.labels.plural}
              </>
            )
          })()}
        </Text>
      </Box>
    </Box>
  )
}
