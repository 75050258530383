import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Switch,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { ContactClient, IContact } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { LAB_CONTACTS_QUERY_KEY } from '../constants/query-keys'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { HideLabSchema, IHideLabSchema } from '../schemas/hide-lab.schema'
import { formatDateToLocaleDays } from '../utils/format-date'
import { UpdateContact } from './UpdateContact'

const Visibility = (props: { contact: IContact }) => {
  console.log('props.contact', props.contact)

  const {
    register,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm<IHideLabSchema>({
    resolver: zodResolver(HideLabSchema),
    defaultValues: {
      hidden: props.contact.hidden,
      hiddenReason: props.contact.hiddenReason,
      hiddenFrom: props.contact.hiddenFrom
        ? formatDateToLocaleDays(props.contact.hiddenFrom, 'yyyy-MM-dd')
        : null,
      hiddenTo: props.contact.hiddenTo
        ? formatDateToLocaleDays(props.contact.hiddenTo, 'yyyy-MM-dd')
        : null,
    },
  })
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onClose, onOpen } = useDisclosure()

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const toast = useToast()

  const onSubmit = useCallback(
    (data: IHideLabSchema) => {
      setIsLoading(true)
      toast.promise(
        ContactClient.update(props.contact.id, {
          hidden: data.hidden,
          hiddenFrom: data.hiddenFrom,
          hiddenTo: data.hiddenTo,
          hiddenReason: data.hiddenReason,
          sinisterTypesSupported: props.contact.sinisterTypesSupported,
        }),
        {
          loading: { title: 'Actualizando contacto...' },
          success: () => {
            queryClient.invalidateQueries([LAB_CONTACTS_QUERY_KEY])
            setIsLoading(false)
            onClose()
            return { title: 'Contacto actualizado' }
          },
          error: (err) => {
            setIsLoading(false)
            return error.handleError(err)
          },
        },
      )
    },
    [
      props.contact.id,
      props.contact.hidden,
      props.contact.sinisterTypesSupported,
    ],
  )

  const isHidden = watch('hidden')
  const formId = 'hide-lab-form'

  return (
    <>
      <Box display='flex' alignItems='center'>
        <Tooltip
          label={props.contact.hidden ? 'Mostrar estudio' : 'Ocultar estudio'}
        >
          <IconButton
            mr='2'
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={onOpen}
            size='sm'
            aria-label='Hide contact'
            icon={props.contact.hidden ? <AiFillEyeInvisible /> : <AiFillEye />}
            variant={'ghost'}
          />
        </Tooltip>
      </Box>

      <Modal size='3xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton isDisabled={isLoading} />
          <ModalBody p={5}>
            <chakra.form
              id={formId}
              {...buildTestId('hide-lab-form')}
              onSubmit={handleSubmit(onSubmit)}
              display={'flex'}
              flexDir='column'
              experimental_spaceY={'4'}
            >
              <Box display='flex' alignItems='center'>
                <chakra.label
                  htmlFor='email-address'
                  display={'block'}
                  fontSize={'sm'}
                  fontWeight={'medium'}
                  mr={2}
                >
                  Ocultar
                </chakra.label>
                <Switch
                  defaultChecked={watch('hidden')}
                  onChange={(e) => {
                    setValue('hidden', e.target.checked)
                    setValue('hiddenFrom', '')
                    setValue('hiddenTo', '')
                    setValue('hiddenReason', '')
                  }}
                />
              </Box>
              <FormControl>
                <FormLabel>Motivo</FormLabel>
                <Textarea
                  {...register('hiddenReason')}
                  placeholder='Motivo de ocultar el estudio.'
                  isDisabled={!isHidden}
                />
              </FormControl>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                experimental_spaceX={4}
              >
                <FormControl isInvalid={!!errors.hiddenFrom}>
                  <FormLabel>Inicio</FormLabel>
                  <Input
                    {...register('hiddenFrom')}
                    type='date'
                    isDisabled={!isHidden}
                  />
                  {errors.hiddenFrom && (
                    <FormErrorMessage>
                      {errors.hiddenFrom.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.hiddenTo}>
                  <FormLabel>Fin</FormLabel>
                  <Input
                    {...register('hiddenTo')}
                    type='date'
                    isDisabled={!isHidden}
                  />
                  {errors.hiddenTo && (
                    <FormErrorMessage>
                      {errors.hiddenTo.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            </chakra.form>
          </ModalBody>

          <ModalFooter
            display='flex'
            justifyContent='end'
            experimental_spaceX={2}
          >
            <Button onClick={onClose} isDisabled={isLoading} size='sm'>
              Cancelar
            </Button>
            <Button
              isDisabled={isLoading}
              isLoading={isLoading}
              form={formId}
              type='submit'
              variant='primary'
              size='sm'
            >
              Actualizar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const ContactLabsActions = (props: { contact: IContact }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Visibility contact={props.contact} />
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<Icon as={Bars3Icon} />}
          variant='outline'
          size='xs'
        />
        <MenuList>
          <UpdateContact contact={props.contact} />
        </MenuList>
      </Menu>
    </Box>
  )
}
