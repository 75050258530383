import { RetrieveCaseInspectionTokenResource } from 'kach-constants';
import httpClient from './http-client';

export class CaseInspectionClient {
  static async createInspection(caseId: number, inspectionId: string) {
    const response = await httpClient.post(`/v1/case/${caseId}/inspection`, {
      inspectionId,
    });

    return response.data;
  }

  static async fetchInspectionToken(
    inspectionId: number,
    resource: RetrieveCaseInspectionTokenResource
  ): Promise<{ token: string }> {
    const response = await httpClient.get(
      `/v1/case/inspection/${inspectionId}/token?resource=${resource}`
    );

    return response.data;
  }
}
