import { PriceGroupClient } from 'kach-clients'
import { useQuerySingleton } from './useQuerySingleton'

export const buildCaseSavingDetailQueryKey = (caseId: number) => {
  return `case-saving-${caseId}`
}

export const useCaseSavingDetail = (caseId: number) =>
  useQuerySingleton(
    [buildCaseSavingDetailQueryKey(caseId)],
    () => PriceGroupClient.caseDetail(caseId),
    {
      retry: 1,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )
