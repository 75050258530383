import React from 'react'
import {
  Popover,
  useDisclosure,
  Box,
  PopoverTrigger,
  Button,
  Icon,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Input,
  Stack,
} from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { buildTestId } from 'kach-commons'

import { useState, useEffect, useRef } from 'react'
import { WatchObserver } from 'react-hook-form'
import Fuse from 'fuse.js'
import { ICandidateFilter, IFilter, IFiltersForm } from './TableAbstract'

export const ColumnsSearcher = (props: {
  candidatesFilters: ICandidateFilter[]
  watch: (form: WatchObserver<IFiltersForm>) => {
    unsubscribe: () => void
  }
  onAddColumn: (ele: IFilter) => void
  filtersState: IFilter[]
}) => {
  const [candidateFiltersMatched, setCandidateFiltersMatched] = useState<
    ICandidateFilter[]
  >(props.candidatesFilters)

  const filtersNotUsed = props.candidatesFilters.filter(
    (candidate) =>
      !props.filtersState.find((used) => used.column === candidate.column),
  )

  const fuse = new Fuse(filtersNotUsed, {
    keys: ['label'],
  })

  useEffect(() => {
    const subscribe = props.watch((fields) => {
      setCandidateFiltersMatched(
        props.candidatesFilters.filter(
          (candidate) =>
            !fields.filters?.find((used) => used?.column === candidate.column),
        ),
      )
    })

    return () => subscribe.unsubscribe()
  }, [])

  const { isOpen, onToggle, onClose } = useDisclosure()

  useEffect(() => {
    if (!isOpen && ref.current) {
      ref.current.value = ''
      setCandidateFiltersMatched(filtersNotUsed)
    }
  }, [isOpen])

  const ref = useRef<HTMLInputElement>(null)

  return (
    <Box>
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button
            {...buildTestId('add-filter-btn')}
            size='xs'
            leftIcon={<Icon as={PlusIcon} />}
            onClick={onToggle}
            fontWeight='medium'
          >
            Agregar filtro
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Agregar filtro</PopoverHeader>
          <PopoverBody>
            <Input
              ref={ref}
              onChange={(e) => {
                setCandidateFiltersMatched(
                  e.target.value === ''
                    ? filtersNotUsed
                    : fuse.search(e.target.value).map((ele) => ele.item),
                )
              }}
              placeholder='Filtrar por...'
              type='text'
              size='sm'
            />
            <Stack
              overflowY={'auto'}
              maxHeight='48'
              display='flex'
              flexDir='column'
              mt={2}
              direction='column'
            >
              {candidateFiltersMatched
                .sort((a, b) => a.label.localeCompare(b.label))
                .map(({ Icon, ...rest }, index) => {
                  return (
                    <Box w='full' key={index}>
                      <Button
                        {...buildTestId(`add-filter-btn-${rest.column}`)}
                        onClick={() => {
                          const latestVal = props.candidatesFilters.find(
                            (c) => rest.column === c.column,
                          )

                          props.onAddColumn({
                            ...latestVal,
                            value: latestVal?.defaultValue ?? '',
                          })
                          onClose()
                        }}
                        key={index}
                        size='sm'
                        variant='ghost'
                        textAlign='left'
                        w='full'
                        justifyContent='start'
                        leftIcon={<Icon />}
                      >
                        {rest.label}
                      </Button>
                    </Box>
                  )
                })}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
