import {
  Avatar,
  Badge,
  Box,
  chakra,
  Divider,
  IconButton,
  List,
  ListItem,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SkeletonCircle,
  SkeletonText,
  Text,
} from '@chakra-ui/react'
import { INotification, NotificationClient } from 'kach-clients'
import { buildNotificationLink } from 'kach-commons'
import React, { useCallback } from 'react'
import { FiMoreVertical } from 'react-icons/fi'
import {
  NOTIFICATIONS_PAGINATION_QUERY_KEY,
  NOTIFICATIONS_QUERY_KEY,
} from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildUserName } from '../utils/build-user-name'
import { timeAgoFormat } from 'kach-commons/src/utils/time-ago-format'
import { translator } from '../utils/translator'

export type NavigatorFC = React.FC<React.PropsWithChildren<{ href?: any }>>

export const NotificationList = (props: {
  Navigator: NavigatorFC
  notifications: INotification[]
  invalidatePagination?: boolean
  listStyles?: ListProps
  isLoading?: boolean
}) => {
  const error = useError()

  const queryClient = useQueryClientSingleton()

  const auth = useAuth()

  const onReadNotification = useCallback((notificationId: number) => {
    NotificationClient.read(notificationId)
      .then(async (res) => {
        queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY])
        queryClient.invalidateQueries([NOTIFICATIONS_PAGINATION_QUERY_KEY])
      })
      .catch(error.handleError)
  }, [])

  if (props.isLoading) {
    return (
      <List {...props.listStyles}>
        <ListItem my='3' fontSize={'xs'}>
          <Box
            padding='2'
            bg='white'
            display='flex'
            alignItems='center'
            experimental_spaceX={2}
          >
            <SkeletonCircle size='10' />
            <Box width='full'>
              <SkeletonText
                mt='4'
                noOfLines={2}
                spacing='4'
                skeletonHeight='2'
              />
            </Box>
          </Box>
        </ListItem>
      </List>
    )
  }

  if (!props.notifications.length) {
    return (
      <List {...props.listStyles}>
        <ListItem my='3' fontSize={'xs'}>
          No hay nuevas notificaciones por el momento
        </ListItem>
      </List>
    )
  }

  return (
    <List spacing={3} {...props.listStyles}>
      {props.notifications.map((notification, index) => {
        return (
          <Box key={index}>
            <Box
              display='flex'
              flexDir={'row'}
              alignItems='start'
              justifyContent={'space-between'}
              _hover={{
                bg: 'gray.50',
              }}
              borderRadius='md'
              key={notification.id}
            >
              <Box p={2}>
                <props.Navigator
                  //@ts-ignore
                  href={buildNotificationLink(notification, auth)}
                >
                  <ListItem
                    my='2'
                    pos={'relative'}
                    w='full'
                    display={'flex'}
                    flexDir='row'
                    alignItems={'start'}
                    key={index}
                    justifyContent={'space-between'}
                  >
                    <Box display={'flex'} flexDir='row' alignItems={'start'}>
                      <Avatar
                        referrerPolicy='no-referrer'
                        mr='2'
                        bg='primary'
                        color='white'
                        size='xs'
                        src={notification?.actor?.profilePhoto}
                      />
                      <div className='flex flex-col'>
                        <Text fontSize={'xs'}>
                          {(() => {
                            if (notification.actor) {
                              return (
                                <>
                                  <chakra.span fontWeight={'bold'}>
                                    {buildUserName(notification.actor)}
                                  </chakra.span>{' '}
                                  de la compañía{' '}
                                  <chakra.span fontWeight={'bold'}>
                                    {notification.actor.company.name}{' '}
                                  </chakra.span>
                                </>
                              )
                            }

                            switch (notification.entityType) {
                              case 'thread':
                                return (
                                  <chakra.span fontWeight={'bold'}>
                                    {notification.context.destination}
                                  </chakra.span>
                                )

                              default:
                                return null
                            }
                          })()}

                          <chakra.span fontWeight={'normal'}>
                            {notification.activityType}
                          </chakra.span>
                          {notification.tags.map((tag, index) => {
                            return (
                              <Badge key={index} fontSize='2xs' ml={1}>
                                {tag.name}
                              </Badge>
                            )
                          })}
                        </Text>
                        <Text
                          fontWeight={'light'}
                          color={'gray.500'}
                          fontSize={'xs'}
                        >
                          {timeAgoFormat(notification.createdAt)}
                        </Text>
                      </div>
                    </Box>
                  </ListItem>
                </props.Navigator>
              </Box>

              <Menu size={'xs'}>
                <MenuButton
                  ml='4'
                  mr={2}
                  mt={2}
                  size='xs'
                  as={IconButton}
                  icon={<FiMoreVertical />}
                  aria-label='Options'
                  variant='ghost'
                />
                <MenuList>
                  <MenuItem
                    onClick={() => onReadNotification(notification.id)}
                    fontSize={'xs'}
                    isDisabled={notification.receivers.some(
                      (receiver) =>
                        receiver.user_id === auth.id && receiver.read,
                    )}
                  >
                    Marcar como leído
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            {index + 1 !== props.notifications.length && <Divider />}
          </Box>
        )
      })}
    </List>
  )
}
