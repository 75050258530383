import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { CaseClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useToggle } from 'ahooks'
import React, { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { MAX_FILE_SIZE_IN_MB } from '../hooks/useFileUpload'
import { useFileUploadV2 } from '../hooks/useFileUploadV2'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { AddCaseResource } from './AddCaseResource'
import { FileUploadV2 } from './FileUploadV2'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { remapFileList } from '../utils/remap-file-list'

const UploadFiles = (props: { caseId: number; toggle: () => void }) => {
  const error = useError()

  const queryClient = useQueryClientSingleton()

  const [isLoading, setIsLoading] = useState(false)

  const { fileList, onUploadFiles, onRemove, onUpdateFile } = useFileUploadV2(
    (files) => CaseClient.tokenizeArtifacts(props.caseId, files),
  )

  const toast = useToast()

  const onSaveArtifacts = useCallback(() => {
    setIsLoading(true)

    toast.promise(
      CaseClient.addArtifacts(props.caseId, remapFileList(fileList)),
      {
        error: (err) => {
          setIsLoading(false)

          return { title: error.getErrorMessage(err) }
        },
        loading: {
          title: 'Agregando archivos',
        },
        success: (res) => {
          setIsLoading(false)
          queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])

          props.toggle()
          return {
            title: 'Archivos agregados correctamente',
          }
        },
      },
    )
  }, [fileList, props.caseId])

  return (
    <Box experimental_spaceY={8}>
      <Box>
        <Text fontSize='lg' fontWeight='medium'>
          Archivos
        </Text>
        <Text mt={1} fontSize='sm' color='gray.500'>
          Agrega archivos al caso
        </Text>
      </Box>
      <FileUploadV2
        onUpdateFile={onUpdateFile}
        fileList={fileList}
        limit={10}
        inputId='case-artifacts'
        maxFileSizeMB={MAX_FILE_SIZE_IN_MB}
        onRemove={onRemove}
        onUpload={onUploadFiles}
      />

      <Box
        display='flex'
        justifyContent='flex-end'
        mt={4}
        experimental_spaceX={2}
      >
        <Button size='sm' onClick={props.toggle} isDisabled={isLoading}>
          Cancelar
        </Button>
        <Button
          {...buildTestId('confirm-add-case-artifact-btn')}
          isDisabled={!fileList.length}
          onClick={onSaveArtifacts}
          isLoading={isLoading}
          size='sm'
          colorScheme='brand'
        >
          Agregar archivos
        </Button>
      </Box>
    </Box>
  )
}

export const AddCaseArtifacts = (props: { caseId: number }) => {
  const [open, { toggle }] = useToggle()

  return (
    <div>
      <Box {...buildTestId('add-case-artifact-btn')}>
        <AddCaseResource label='Agregar archivo' onClick={toggle} />
      </Box>

      <Modal isOpen={open} onClose={toggle}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={6}>
            <UploadFiles caseId={props.caseId} toggle={toggle} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}
