import { Button, useToast } from '@chakra-ui/react'
import { CaseClient, ICaseExtended } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { CASES_QUERY_KEY } from '../constants/query-keys'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildCaseQueryKey } from '../utils/build-case-query-key'

export const ReopenCaseBtn = (props: { case: ICaseExtended }) => {
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const toast = useToast()

  const reopenCase = useCallback(() => {
    setIsLoading(true)

    toast.promise(CaseClient.reopen(props.case.id), {
      loading: {
        title: 'Reabriendo caso...',
      },
      success: () => {
        queryClient.invalidateQueries([buildCaseQueryKey(props.case.id)])
        queryClient.invalidateQueries([CASES_QUERY_KEY])
        setIsLoading(false)
        return {
          title: 'Caso reabierto correctamente',
        }
      },
      error: (err) => {
        setIsLoading(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [props.case.id])

  return (
    <Button
      {...buildTestId('reopen-case-btn')}
      onClick={reopenCase}
      variant='primary'
      isLoading={isLoading}
      size={'sm'}
    >
      Reabrir caso
    </Button>
  )
}
