import React from 'react';
import { BiSelectMultiple } from 'react-icons/bi';
import { TableHeaderWrapper } from './TableHeaderWrapper';

export const MultiselectTableHeader = () => {
  return (
    <TableHeaderWrapper>
      <BiSelectMultiple />
      <span>Pronunciamiento</span>
    </TableHeaderWrapper>
  );
};
