import {
  CaseResultsType,
  IntervalType,
  InvestigationTypes,
} from 'kach-constants';
import { CompanyTypes, IUser } from './authentication.client';
import { IEnumOption } from './case.client';
import httpClient from './http-client';
import { IContact, IPagination, IPaginationQuery } from './notification.client';
import { CaseStatusType } from '../../../kach-commons/dist';
import { CreateLimitSchema } from '../schemas/limit.schema';

export interface ILimit {
  id: number;
  name: string;
  createdAt: Date;
  createdBy: IUser;
  created_by_id: number;
  type: CompanyTypes;
  case_reasons?: IEnumOption[];
  case_agency?: IEnumOption[];
  case_sinister_type?: IEnumOption[];
  case_results?: CaseResultsType[];
  case_status?: CaseStatusType[];
  investigation_type?: InvestigationTypes[];
  contacts?: IContact[];
  users?: IUser[];
  limit: number;
  conditions: CreateLimitSchema['conditions'][number][];
  interval: IntervalType;
  intervalValue: number;
}

export const buildLimitsQueryKey = (companyId: number) =>
  `company-${companyId}-limits`;

export class LimitClient {
  static async paginate({
    query,
    cookie,
  }: {
    cookie?: string;
    query: IPaginationQuery;
  }): Promise<IPagination<ILimit>> {
    const response = await httpClient.get('/v1/limit/list', {
      params: query,
      headers: {
        Cookie: cookie,
      },
    });

    return response.data;
  }

  static async create({
    cookie,
    limit,
  }: {
    cookie?: string;
    limit: CreateLimitSchema;
  }): Promise<ILimit> {
    const response = await httpClient.post('/v1/limit', limit, {
      headers: {
        Cookie: cookie,
      },
    });

    return response.data;
  }

  static async update({
    cookie,
    limit,
    id,
  }: {
    cookie?: string;
    id: number;
    limit: CreateLimitSchema;
  }): Promise<ILimit> {
    const response = await httpClient.put(`/v1/limit/${id}`, limit, {
      headers: {
        Cookie: cookie,
      },
    });

    return response.data;
  }

  static async delete({ id }: { id: number }): Promise<{
    success: boolean;
  }> {
    const response = await httpClient.delete(`/v1/limit/${id}`);

    return response.data;
  }
}
