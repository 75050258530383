import {
  Box,
  Button,
  chakra,
  Icon,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {
  ExclamationTriangleIcon,
  FolderPlusIcon,
  InboxArrowDownIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/24/solid'
import { IMail, IMailAttempt, MailClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { format } from 'date-fns'
import React from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { useAuth } from '../hooks/useAuth'
import { buildMailQueryKey } from '../utils/build-mail-query-key'
import { timeAgoFormat } from 'kach-commons/src/utils/time-ago-format'
import { AckMailAttempt } from './AckMailAttempt'
import { ArtifactDisplayer } from './ArtifactDisplayer'
import { CreateMailAttemptForm } from './CreateMailAttemptForm'
import { ExpireMailAttempt } from './ExpireMailAttempt'
import { RemoveMailAttempt } from './RemoveMailAttempt'
import { UserDisplayer } from './UserDisplayer'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import AddMailArtifactForm from './AddMailArtifactForm'

const MailAttemptLine = (props: {
  withoutCreate?: boolean
  isCaseClosed: boolean
  mailId: number
  caseId: number
  attempt: IMailAttempt
  index: number
}) => {
  const auth = useAuth()

  return (
    <Box>
      <Box
        border='2px'
        borderColor='gray.100'
        borderRadius='md'
        p={2}
        display='flex'
        flexDir='column'
        experimental_spaceY={4}
        position='relative'
      >
        <Box
          position='absolute'
          fontSize='sm'
          top='-1.75rem'
          right={0}
          p={2}
          borderRadius='md'
        >
          <Box display='inline-flex' borderRadius='md' boxShadow='md'>
            <Box
              position='relative'
              display='inline-flex'
              alignItems='center'
              borderLeftRadius='md'
              border='1px'
              borderColor='gray.100'
              borderRight='none'
              bg='white'
              px='4'
              py='2'
              fontSize='sm'
              fontWeight='medium'
              color='gray.700'
            >
              {props.index + 1} intento
            </Box>
            {!props.withoutCreate && auth.company.type === 'insurer' && (
              <Menu>
                <MenuButton
                  {...buildTestId(`mail-attempt-${props.index}-menu-btn`)}
                  border='1px'
                  borderColor={'gray.100'}
                  as={IconButton}
                  variant='outline'
                  borderLeft='none'
                  borderLeftRadius='none'
                  bg='white'
                  icon={<Icon as={ChevronDownIcon} />}
                />
                <MenuList>
                  <AckMailAttempt
                    attempt={props.attempt}
                    mailId={props.mailId}
                    isCaseClosed={props.isCaseClosed}
                  />

                  <ExpireMailAttempt
                    index={props.index}
                    attempt={props.attempt}
                    mailId={props.mailId}
                    isCaseClosed={props.isCaseClosed}
                  />

                  <RemoveMailAttempt
                    attempt={props.attempt}
                    mailId={props.mailId}
                    caseId={props.caseId}
                    isCaseClosed={props.isCaseClosed}
                  />
                </MenuList>
              </Menu>
            )}
          </Box>
        </Box>
        <List
          display={['block', 'flex']}
          alignItems='center'
          w='full'
          justifyContent='space-between'
          borderRadius='md'
        >
          <ListItem position='relative' mb={[4, 0]} w='full'>
            <Box display='flex' alignItems='center'>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                w='6'
                h='6'
                bg='primary'
                borderRadius='full'
                color='white'
              >
                <Icon as={AiOutlineMail} />
              </Box>
              <Box
                display={['none', 'flex']}
                w='full'
                bg='gray.200'
                h={0.5}
              ></Box>
            </Box>
            <Box mt={2} pr={[0, 8]}>
              <Text fontWeight='semibold'>Carta enviada</Text>
              <chakra.time
                display='block'
                mb={2}
                fontSize='xs'
                fontWeight='normal'
                color='gray.400'
              >
                {format(new Date(props.attempt.sentAt), 'Pp')}
              </chakra.time>
            </Box>
          </ListItem>
          {!props.attempt.expiredAt ? (
            <ListItem position='relative' mb={[4, 0]} w='full'>
              <Box display='flex' alignItems='center'>
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  w='6'
                  h='6'
                  bg='primary'
                  borderRadius='full'
                  color='white'
                >
                  <Icon
                    as={
                      !props.attempt.receivedAt
                        ? QuestionMarkCircleIcon
                        : InboxArrowDownIcon
                    }
                  />
                </Box>
                <Box
                  display={['none', 'flex']}
                  w='full'
                  bg='gray.200'
                  h={0.5}
                ></Box>
              </Box>
              <Box mt={2} pr={[0, 8]}>
                <Text fontWeight='semibold'>
                  {!props.attempt.receivedAt ? (
                    <>Esperando acuse</>
                  ) : (
                    'Carta recibida'
                  )}
                </Text>
                <chakra.time
                  display='block'
                  mb={2}
                  fontSize='xs'
                  fontWeight='normal'
                  color='gray.400'
                >
                  {!props.attempt.receivedAt
                    ? timeAgoFormat(props.attempt.sentAt)
                    : format(new Date(props.attempt.receivedAt), 'Pp')}
                </chakra.time>
              </Box>
            </ListItem>
          ) : (
            <ListItem position='relative' mb={[4, 0]} w='full'>
              <Box display='flex' alignItems='center'>
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  w='6'
                  h='6'
                  bg='primary'
                  borderRadius='full'
                  color='white'
                >
                  <Icon as={ExclamationTriangleIcon} />
                </Box>
                <Box
                  display={['none', 'flex']}
                  w='full'
                  bg='gray.200'
                  h={0.5}
                ></Box>
              </Box>
              <Box mt={2} pr={[0, 8]}>
                <Text
                  {...buildTestId('mail-attempt-expired')}
                  fontWeight='semibold'
                >
                  Intento expirado
                </Text>
                <chakra.time
                  display='block'
                  mb={2}
                  fontSize='xs'
                  fontWeight='normal'
                  color='gray.400'
                >
                  {format(new Date(props.attempt.sentAt), 'Pp')}
                </chakra.time>
              </Box>
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  )
}

export default function MailDetail(props: {
  withoutCreate?: boolean
  isCaseClosed: boolean
  caseId: number
  mailId: number
  mail?: IMail
  onClose: () => void
}) {
  const { isLoading, data } = useQuerySingleton(
    [buildMailQueryKey(props.mailId)],
    () => MailClient.detail(props.mailId),
  )
  const { isOpen, onToggle, onClose } = useDisclosure()
  const openPoppoverArtifact = useDisclosure()

  const auth = useAuth()

  if (isLoading || !data) {
    return <Spinner size='xs' />
  }

  return (
    <Box display='flex' flexDir='column' experimental_spaceY={4}>
      <Box
        w='full'
        display='flex'
        alignItems='start'
        my={4}
        justifyContent='space-between'
      >
        <Box display='flex' flexDir='column' experimental_spaceY={2} w='full'>
          <Text fontSize='2xl'>Carta #{data.externalId}</Text>

          <Box
            display='flex'
            alignItems='flex-start'
            justifyContent='space-between'
            w='full'
          >
            <Box
              display='flex'
              flexDir='column'
              experimental_spaceY={2}
              maxW='15rem'
            >
              <UserDisplayer fullWidth user={data.createdBy} />
              {data.artifact ? (
                <Box>
                  <ArtifactDisplayer mode='light' artifact={data.artifact} />
                </Box>
              ) : (
                auth.company.type === 'insurer' && (
                  <Popover
                    isLazy
                    isOpen={openPoppoverArtifact.isOpen}
                    onClose={openPoppoverArtifact.onClose}
                  >
                    <PopoverTrigger>
                      <Button
                        {...buildTestId('add-mail-artifact-btn')}
                        size='xs'
                        onClick={openPoppoverArtifact.onToggle}
                        leftIcon={<Icon as={PlusIcon} h='3' w='3' />}
                      >
                        Agregar archivo
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <AddMailArtifactForm
                          onClose={openPoppoverArtifact.onClose}
                          mailId={props.mailId}
                          caseId={props.caseId}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )
              )}
            </Box>
            <Box>
              {!props.withoutCreate && auth.company.type === 'insurer' && (
                <Popover isLazy isOpen={isOpen} onClose={onClose}>
                  <PopoverTrigger>
                    <Button
                      {...buildTestId('add-mail-attempt-btn')}
                      size='sm'
                      onClick={onToggle}
                      variant={'primary'}
                      leftIcon={<Icon as={PlusIcon} h='3' w='3' />}
                    >
                      Agregar intento
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <CreateMailAttemptForm
                        onClose={onClose}
                        mailId={props.mailId}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display='flex' flexDir='column' experimental_spaceY={12}>
        {!data.attempts.length ? (
          <Box
            position='relative'
            w='full'
            borderRadius='md'
            border='2px'
            borderStyle='dashed'
            borderColor='gray.300'
            p={8}
            textAlign='center'
          >
            <Icon as={FolderPlusIcon} h='12' w='12' color='gray.400' />

            <Text mt={2} fontSize='sm' fontWeight='medium'>
              No hay intentos
            </Text>
            <Text mt={1} fontSize='sm' color='gray.500'>
              Empieza agregando uno
            </Text>
          </Box>
        ) : (
          data.attempts.map((attempt, index) => (
            <MailAttemptLine
              caseId={props.caseId}
              isCaseClosed={props.isCaseClosed}
              withoutCreate={props.withoutCreate}
              key={attempt.id}
              mailId={props.mailId}
              attempt={attempt}
              index={index}
            />
          ))
        )}
      </Box>
    </Box>
  )
}
