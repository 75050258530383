import React from 'react';
import { PaperClipIcon } from '@heroicons/react/24/solid';
import {
  Box,
  chakra,
  Divider,
  Icon,
  List,
  ListItem,
  Spinner,
} from '@chakra-ui/react';

export const FileListDisplayer = (props: {
  files: { name: string }[];
  isUploading?: boolean;
  orientation: 'vertical' | 'horizontal';
  size?: 'lg' | 'sm';
}) => {
  const defaultSize = props.size || 'lg';

  return (
    <List
      display="flex"
      flexDir={props.orientation === 'vertical' ? 'column' : 'row'}
      border="1px"
      borderColor="gray.100"
      borderRadius="md"
    >
      {props.files.map((file, index) => {
        return (
          <>
            <ListItem
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <Box display="flex" w={0} flex={1} alignItems="center">
                <Icon as={PaperClipIcon} w="5" h="5" color="gray.400" />
                <chakra.span fontSize="sm" ml={2} w={0} flex={1} noOfLines={1}>
                  {file.name}
                </chakra.span>
              </Box>
              <div>{props.isUploading && <Spinner size="xs" />}</div>
            </ListItem>
            {props.files.length !== index + 1 ? <Divider /> : null}
          </>
        );
      })}
    </List>
  );
};
