import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { AuthorizationClient, IUserRole } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { hasPermission } from '../utils/has-permission'
import {
  ILocalPermission,
  IRoleSaved,
  RolePermissions,
} from './RolePermissions'

export const PermissionsActions: React.FC<{
  role: IUserRole
  permissionsToRender: ILocalPermission[]
}> = ({ role, permissionsToRender }) => {
  const auth = useAuth()
  return (
    <Menu>
      <MenuButton size='xs' as={Button} rightIcon={<BiChevronDown />}>
        Acciones
      </MenuButton>
      <MenuList>
        <PermissionsVisualizer
          role={role}
          permissionsToRender={permissionsToRender}
        />

        {hasPermission(auth.role.permissions, {
          action: 'update',
          resource: 'role',
        }) &&
          role.createdBy?.email && (
            <RoleUpdate role={role} permissionsToRender={permissionsToRender} />
          )}

        {hasPermission(auth.role.permissions, {
          action: 'delete',
          resource: 'role',
        }) &&
          role.createdBy?.email && <RoleDelete role={role} />}
      </MenuList>
    </Menu>
  )
}

const RoleUpdate: React.FC<{
  role: IUserRole
  permissionsToRender: ILocalPermission[]
}> = ({ role, permissionsToRender }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const error = useError()

  const onRoleUpdate = useCallback(async (rol: IRoleSaved) => {
    toast.promise(AuthorizationClient.updateRole(rol, role.id), {
      loading: {
        title: 'Actualizando rol...',
      },
      success: () => {
        onClose()
        queryClient.invalidateQueries(['roles'])
        return {
          title: 'Rol actualizado correctamente',
        }
      },
      error: (err) => {
        onClose()
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [])

  return (
    <>
      <MenuItem {...buildTestId('role-update-btn')} onClick={onOpen}>
        Actualizar
      </MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Actualiar rol</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RolePermissions
              permissionsToRender={permissionsToRender}
              defaultValues={role}
              onRoleUpdate={onRoleUpdate}
              buttonLabel='Actualizar rol'
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

const PermissionsVisualizer: React.FC<{
  role: IUserRole
  permissionsToRender: ILocalPermission[]
}> = ({ role, permissionsToRender }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ver detalle</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RolePermissions
              permissionsToRender={permissionsToRender}
              readonly
              defaultValues={role}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <MenuItem {...buildTestId('role-details-btn')} onClick={onOpen}>
        Detalles
      </MenuItem>
    </div>
  )
}

const RoleDelete: React.FC<{
  role: IUserRole
}> = ({ role }) => {
  const cancelRef = React.useRef()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const error = useError()

  const onRoleDelete = useCallback(async () => {
    toast.promise(AuthorizationClient.deleteRole(role.id), {
      loading: {
        title: 'Eliminando rol...',
      },
      success: () => {
        onClose()
        queryClient.invalidateQueries(['roles'])
        return {
          title: 'Rol eliminado correctamente',
        }
      },
      error: (err) => {
        onClose()
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [])

  return (
    <>
      <Tooltip
        label='Rol en uso, no se puede eliminar'
        isDisabled={role.usersCount! < 1}
      >
        <MenuItem
          {...buildTestId('role-delete-btn')}
          onClick={onOpen}
          isDisabled={role.usersCount! > 0}
        >
          Eliminar
        </MenuItem>
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Eliminar rol
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estas seguro? Esta es una <strong>acción irreversible</strong>{' '}
              que eliminar el rol {role.name} de manera permanente.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={onRoleDelete} ml={3}>
                Eliminar rol
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
