import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  chakra,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tag,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useToggle } from 'ahooks'
import {
  CaseClient,
  CasePriceDetailVars,
  ICaseExtended,
  ICasePriceDetail,
} from 'kach-clients'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillQuestionCircle } from 'react-icons/ai'
import {
  buildCaseSavingDetailQueryKey,
  useCaseSavingDetail,
} from '../hooks/useCaseSavingDetail'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { IFormulaWithResult } from '../interfaces/formula'
import {
  IUpdateCaseVarsSchema,
  UpdateCaseVarsSchema,
} from '../schemas/update-case-vars.schema'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { translator } from '../utils/translator'
import { CaseAccordion } from './CaseAccordion'
import { IDynamicObject } from './InvestigationLine'
import React from 'react'
import { AbstractSaving } from './AbstractSavings'

const UpdateCaseVars = (props: {
  withoutSubmit?: boolean
  onClose: () => void
  case: ICaseExtended
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<IUpdateCaseVarsSchema>({
    resolver: zodResolver(UpdateCaseVarsSchema),
    defaultValues: {
      attorneyIncluded: props.case.attorneyIncluded,
      appraisedPropertyDamage: props.case.appraisedPropertyDamage,
      inabilityCoefficientCompany: props.case.inabilityCoefficientCompany,
      inabilityCoefficientLab: props.case.inabilityCoefficientLab,
      injuredCount: props.case.injuredCount?.toString() as any,
      insuredAmount: props.case.insuredAmount,
      labOverwrittenFormulaResult: props.case.labOverwrittenFormulaResult,
      companyOverwrittenFormulaResult:
        props.case.companyOverwrittenFormulaResult,
      savingDescription: props.case.savingDescription,
    },
  })

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const fields = watch()

  const toast = useToast()

  const onSubmit = useCallback(
    (data: Required<IUpdateCaseVarsSchema>) => {
      setIsLoading(true)
      toast.promise(CaseClient.updateCaseSaving(props.case.id, data), {
        loading: { title: 'Actualizando variables de fórmula...' },
        success: () => {
          queryClient.invalidateQueries([
            buildCaseSavingDetailQueryKey(props.case.id),
          ])
          queryClient.invalidateQueries([buildCaseQueryKey(props.case.id)])
          props.onClose()
          setIsLoading(false)
          return { title: 'Variables de fórmula actualizadas' }
        },
        error: (err) => {
          setIsLoading(false)
          return error.handleError(err)
        },
      })
    },
    [props.case.id],
  )

  const caseClosed = props.case.status === 'closed'

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} experimental_spaceY={5}>
      <FormControl isInvalid={!!errors.inabilityCoefficientCompany}>
        <FormLabel fontSize={'sm'}>
          {translator('inabilityCoefficientCompany')}
        </FormLabel>
        <Input
          {...register('inabilityCoefficientCompany')}
          type='number'
          step='.01'
          size={'sm'}
          isReadOnly={caseClosed}
        />
        {errors.inabilityCoefficientCompany && (
          <FormErrorMessage>
            {errors.inabilityCoefficientCompany.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.inabilityCoefficientLab}>
        <FormLabel fontSize={'sm'}>
          {translator('inabilityCoefficientLab')}
        </FormLabel>
        <Input
          {...register('inabilityCoefficientLab')}
          type='number'
          step='.01'
          size={'sm'}
          isReadOnly={caseClosed}
        />
        {errors.inabilityCoefficientLab && (
          <FormErrorMessage>
            {errors.inabilityCoefficientLab.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.appraisedPropertyDamage}>
        <FormLabel
          alignItems={'center'}
          display={'flex'}
          flexDir='row'
          fontSize={'sm'}
        >
          {translator('appraisedPropertyDamage')}
          <Tooltip label='Son los daños materiales peritados a terceros'>
            <chakra.span ml='2'>
              <AiFillQuestionCircle />
            </chakra.span>
          </Tooltip>
        </FormLabel>
        <Input
          {...register('appraisedPropertyDamage')}
          size={'sm'}
          type='number'
          step='.01'
          isReadOnly={caseClosed}
        />
        {errors.appraisedPropertyDamage && (
          <FormErrorMessage>
            {errors.appraisedPropertyDamage.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.insuredAmount}>
        <FormLabel
          alignItems={'center'}
          display={'flex'}
          flexDir='row'
          fontSize={'sm'}
        >
          {translator('insuredAmount')}
          <Tooltip label='Son los daños a cubrir al asegurado que pueden ser peritados menores al monto asegurado o el monto asegurado'>
            <chakra.span ml='2'>
              <AiFillQuestionCircle />
            </chakra.span>
          </Tooltip>
        </FormLabel>

        <Input
          {...register('insuredAmount')}
          type='number'
          step='.01'
          size={'sm'}
          isReadOnly={caseClosed}
        />
        {errors.insuredAmount && (
          <FormErrorMessage>{errors.insuredAmount.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.injuredCount}>
        <FormLabel fontSize={'sm'}>{translator('injuredCount')}</FormLabel>
        <Input
          {...register('injuredCount')}
          type='number'
          size={'sm'}
          isReadOnly={caseClosed}
        />
        {errors.injuredCount && (
          <FormErrorMessage>{errors.injuredCount.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.attorneyIncluded}>
        <Checkbox
          {...register('attorneyIncluded')}
          size={'sm'}
          isReadOnly={caseClosed}
        >
          {translator('attorneyIncluded')}
        </Checkbox>
        {errors.attorneyIncluded && (
          <FormErrorMessage>{errors.attorneyIncluded.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.companyOverwrittenFormulaResult}>
        <FormLabel fontSize={'sm'}>
          {translator('companyOverwrittenFormulaResult')}
        </FormLabel>
        <Input
          {...register('companyOverwrittenFormulaResult')}
          type='number'
          step='.01'
          size={'sm'}
          isReadOnly={props.withoutSubmit}
        />
        {errors?.companyOverwrittenFormulaResult && (
          <FormErrorMessage>
            {errors.companyOverwrittenFormulaResult.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.labOverwrittenFormulaResult}>
        <FormLabel fontSize={'sm'}>
          {translator('labOverwrittenFormulaResult')}
        </FormLabel>
        <Input
          {...register('labOverwrittenFormulaResult')}
          type='number'
          step='.01'
          size={'sm'}
          onWheel={(e) => {
            e.currentTarget.blur()
            e.stopPropagation()
          }}
          isReadOnly={props.withoutSubmit}
        />
        {errors?.labOverwrittenFormulaResult && (
          <FormErrorMessage>
            {errors.labOverwrittenFormulaResult.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.savingDescription}>
        <FormLabel fontSize={'sm'}>{translator('savingDescription')}</FormLabel>
        <Textarea
          isReadOnly={props.withoutSubmit}
          {...register('savingDescription')}
        />
        {errors?.savingDescription && (
          <FormErrorMessage>
            {errors.savingDescription.message}
          </FormErrorMessage>
        )}
      </FormControl>
      {!props.withoutSubmit && (
        <Button
          type='submit'
          size='sm'
          isDisabled={isLoading}
          isLoading={isLoading}
          variant={'primary'}
        >
          Guardar cambios
        </Button>
      )}
    </chakra.form>
  )
}

export const CaseSavings = (props: {
  case: ICaseExtended
  formulas: IFormulaWithResult[]
  withoutCreate?: boolean
  query: IDynamicObject
}) => {
  const { data, isLoading, error } = useCaseSavingDetail(props.case.id)

  const [open, { toggle }] = useToggle()

  const lockedVal = props.case.status === 'closed'

  const display = (result: string, translation: string) => {
    return `${lockedVal ? '🔒 ' : '🔄 '}Total Ahorro ${translator(
      translation,
    )}: $${result}`
  }

  const serializeResult = (
    overrideResult:
      | 'labOverwrittenFormulaResult'
      | 'companyOverwrittenFormulaResult',
    calculatedResult: 'company_saving' | 'lab_saving',
  ) => {
    if (!data) {
      return '0'
    }

    if (data.vars?.case[overrideResult]) {
      const result = data.vars.case[overrideResult]

      if (result) {
        return result.toString()
      }
    }

    if (data[calculatedResult]) {
      const result = data[calculatedResult]

      if (result) {
        return result?.result?.toString()
      }
    }

    return '0'
  }

  const companyResult = serializeResult(
    'companyOverwrittenFormulaResult',
    'company_saving',
  )

  const labResult = serializeResult('labOverwrittenFormulaResult', 'lab_saving')

  return (
    <>
      <CaseAccordion
        count={2}
        withoutCreate
        label={{
          singular: 'ahorro',
          plural: 'ahorros',
        }}
      >
        <Accordion allowToggle>
          {data?.company_saving && (
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Tag w='16rem' key={'company_saving'}>
                      {display(companyResult, 'company')}
                    </Tag>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <AbstractSaving
                  data={data.company_saving}
                  vars={data}
                  totalResult={companyResult}
                  isLoading={isLoading}
                  error={error}
                  sortedVars={[
                    'inability',
                    'medical',
                    'attorney',
                    'insuredAmount',
                    'result',
                  ]}
                  type={'company'}
                />
              </AccordionPanel>
            </AccordionItem>
          )}

          {data?.lab_saving && (
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Tag w='16rem' key={'lab_saving'}>
                      {display(labResult, 'lab')}
                    </Tag>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <AbstractSaving
                  data={data.lab_saving}
                  vars={data}
                  totalResult={labResult}
                  isLoading={isLoading}
                  error={error}
                  sortedVars={[
                    'inability',
                    'insuredAmount',
                    'appraisedPropertyDamage',
                    'caseResult',
                    'prize',
                    'result',
                  ]}
                  type={'lab'}
                />
              </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
        <Alert
          size={'sm'}
          borderRadius={'md'}
          fontSize={'sm'}
          mb='5'
          mt={'5'}
          status='info'
        >
          <AlertIcon fontSize={'sm'} />
          Usar el punto (.) para los decimales.
        </Alert>
        <UpdateCaseVars
          // withoutSubmit={props.withoutCreate}
          case={props.case}
          onClose={toggle}
        />
      </CaseAccordion>
    </>
  )
}
