import { z } from 'zod'
import { investigationTypes } from './create-contact-price.schema'

export const updateInvestigationRequestBodySchema = z.object({
  type: z.enum(investigationTypes),
  reason: z.string(),
})

export type UpdateInvestigationRequestBody = z.infer<
  typeof updateInvestigationRequestBodySchema
>

export const addMoreIndicatorsRequestBodySchema = z.object({
  indicators: z.string().min(1, 'Campo requerido'),
})

export type AddMoreIndicatorsRequestBody = z.infer<
  typeof addMoreIndicatorsRequestBodySchema
>
