import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { INewsRequest } from 'kach-clients'
import React from 'react'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { newsRequestToEvent } from '../utils/news-request-to-event'
import { CaseTimeline } from './CaseTimeline'

interface IProps {
  queryKeyIdentifier: string
  newsRequestsFetcher: () => Promise<INewsRequest[]>
  onClick: (newsRequest: INewsRequest, onDone: () => void) => void
}

const Body = (props: IProps & { onClose: () => void }) => {
  const { isLoading, data, error } = useQuerySingleton(
    [`news-requests-${props.queryKeyIdentifier}`],
    () => props.newsRequestsFetcher(),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  )

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return (
      <Box>
        <Text>No se pudieron obtener los pedidos de novedades</Text>
      </Box>
    )
  }

  return (
    <Box overflowY={'auto'} maxH={'40rem'}>
      <Text>Selecciona un pedido de novedad para vincular</Text>
      {data.length ? (
        <CaseTimeline
          onClick={(event) => {
            if (event.event === 'news_requested') {
              const [newsRequest] = event.details
              props.onClick(newsRequest, () => {
                props.onClose()
              })
            }
          }}
          events={data.map((newsRequest) => newsRequestToEvent(newsRequest))}
        />
      ) : (
        <Text fontStyle={'italic'} mt='5'>
          No hay pedidos de novedades
        </Text>
      )}
    </Box>
  )
}

export const NewsRequestSelect = (props: IProps) => {
  const { onOpen, isOpen, onClose } = useDisclosure()

  return (
    <>
      <Button size='xs' variant='primary' onClick={onOpen}>
        Vincular
      </Button>
      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pedidos de novedades</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Body {...props} onClose={onClose} />
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
