import { ISsnPerson } from './case.client';
import httpClient from './http-client';
import { IPaginationQuery } from './notification.client';

export interface ISsnFilters {
  investigationTypes: string;
}

export type ISsnQuery = IPaginationQuery & Partial<ISsnFilters>;

export class SsnService {
  static async downloadCSV(params?: ISsnQuery) {
    return await httpClient.get('/v1/case/ssn/csv', {
      params,
      responseType: 'blob',
    });
  }

  static async fetchPersons(ssnId: number | undefined): Promise<{
    data: ISsnPerson[];
  }> {
    return await httpClient.get(`/v1/case/ssn/${ssnId}/person`);
  }

  static async deletePerson(personId: number) {
    return await httpClient.delete(`/v1/case/ssn/person/${personId}`);
  }

  static async addPerson(ssnId: number, body: Partial<ISsnPerson>) {
    return await httpClient.post(`/v1/case/ssn/${ssnId}/person`, body);
  }

  static async updatePerson(personId: number) {
    return await httpClient.post(`/v1/case/ssn/person/${personId}`);
  }
}
