import { ChangeEvent, useCallback, useState } from 'react'
import { OnUploadFiles } from '../components/FileUpload'
import { bytesToMB } from '../utils/bytes-to-mb'
import { useToast } from '@chakra-ui/react'

export const MAX_FILE_SIZE_IN_MB = 20

//20mb
export const MAX_FILE_SIZE_IN_BYTES = Math.floor(
  MAX_FILE_SIZE_IN_MB * 1024 * 1024,
)

export const useFileUpload = (props: {
  multiple?: boolean
  maxFiles?: number
}) => {
  const [files, setFiles] = useState<File[]>([])

  const toast = useToast()

  const onValidateFiles = useCallback(
    (files: Pick<File, 'size' | 'type' | 'name'>[]): boolean => {
      if (!files || !Array.isArray(files) || files.length < 1) {
        return false
      }

      if (!props.multiple && files.length > 1) {
        toast({
          title: 'Unicamente se puede subir un archivo en simultaneo',
          status: 'error',
        })
        return false
      }

      if (props.maxFiles && files.length > props.maxFiles) {
        toast({
          title: `La cantidad maxima de archivos que se pueden subir es de ${props.maxFiles}`,
          status: 'error',
        })
        return false
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
          toast({
            title: `El archivo ${file.name} pesa ${bytesToMB(
              file.size,
            )}mb y el tamaño máximo soportado es ${MAX_FILE_SIZE_IN_MB}mb.`,
            status: 'error',
          })
          return false
        }
      }

      return true
    },
    [],
  )

  const onInputFileChanges = useCallback(
    (onValidationPass: OnUploadFiles) => (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) return

      const { length, ...files } = e.target.files

      const filesArray = Object.values(files) as File[]

      const valid = onValidateFiles(filesArray)

      if (!valid) {
        return
      }

      setFiles(filesArray)
      onValidationPass(filesArray)
    },
    [],
  )

  return {
    setFiles,
    files,
    onValidateFiles,
    onInputFileChanges,
  }
}
