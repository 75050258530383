import { z } from 'zod';
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema';

//@ts-ignore
export const CreateMailAttemptSchema = z
  .object({
    //@ts-ignore
    sentAt: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    //@ts-ignore
    receivedAt: z.string().optional(),
  })
  .refine(
    (arg) => {
      if (!arg.receivedAt) {
        return true;
      }

      if (new Date(arg.sentAt) > new Date(arg.receivedAt)) {
        return false;
      }

      return true;
    },
    {
      message: 'Fecha de acuse no puede ser anterior a la fecha de envío',
      path: ['receivedAt'],
    }
  );

export interface ICreateMailAttemptSchema
  extends z.infer<typeof CreateMailAttemptSchema> {}
