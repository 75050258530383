import { Box, Button, chakra, Text } from '@chakra-ui/react'
import { IPagination } from 'kach-clients'
import React from 'react'

export const PaginationFooter: React.FC<{
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  paginationData?: IPagination
}> = (props) => {
  return (
    <Box
      display='flex'
      mt={4}
      alignItems='center'
      justifyContent='space-between'
    >
      <Box display={['none', 'block']}>
        <Text fontSize='sm'>
          Mostrando desde{' '}
          <chakra.span fontWeight='medium'>
            {props.paginationData?.skip}
          </chakra.span>{' '}
          hasta{' '}
          <chakra.span fontWeight='medium'>
            {(props.paginationData?.page || 1) *
              (props.paginationData?.limit || 10)}
          </chakra.span>{' '}
          de{' '}
          <chakra.span fontWeight='medium'>
            {props.paginationData?.total}
          </chakra.span>{' '}
          resultados
        </Text>
      </Box>
      <Box
        display='flex'
        flexGrow={1}
        flexShrink={1}
        justifyContent={['space-between', 'end']}
        experimental_spaceX={[0, 2]}
      >
        <Button
          isDisabled={props.page === 1}
          onClick={() => props.setPage((prev) => prev - 1)}
          size='sm'
        >
          Anterior
        </Button>
        <Button
          isDisabled={!props.paginationData?.hasMore}
          onClick={() => props.setPage((prev) => prev + 1)}
          size='sm'
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  )
}
