import { CompanyClient } from 'kach-clients'
import { COMPANY_ENUMS_QUERY_KEY } from '../components/CaseEnumSelect'
import { useAuth } from './useAuth'
import { useQuerySingleton } from './useQuerySingleton'

const DEFAULT_VALUE = {
  agencies: [],
  ramos: [],
  externalStatus: [],
  instance: [],
  caseCloseMotives: [],
  caseReasons: [],
  sinisterTypes: [],
  validationPatterns: [],
  caseSurrenderActors: [],
}

export const useCompanyEnums = () => {
  const auth = useAuth()

  return useQuerySingleton(
    [COMPANY_ENUMS_QUERY_KEY, auth?.id],
    () => {
      if (auth?.company?.type === 'insurer') {
        return CompanyClient.fetchCompanyEnums()
      }

      return Promise.resolve(DEFAULT_VALUE)
    },
    {
      retry: 1,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      initialData: DEFAULT_VALUE,
    },
  )
}
