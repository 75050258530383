import { IArtifactAck } from './case.client';
import httpClient from './http-client';

export interface ICreateNews {
  title: string;
  description: string;
  artifacts: IArtifactAck;
  destinations?: string[];
  relatedNewsRequestId?: number;
}

export class NewsClient {
  static async createCaseNews(
    caseId: number,
    news: ICreateNews
  ): Promise<void> {
    const response = await httpClient.post(`/v1/news/case/${caseId}`, news);

    return response.data;
  }
}
