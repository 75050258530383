import httpClient from './http-client';
import { ValidColumns } from 'kach-constants';

export interface IVirtualViewRefFilter {
  entityId?: number;
  entityData?: any;
}

export interface IVirtualViewFilter {
  type: 'values' | 'references';
  column: string;
  entityName?: string;
  values?: {
    type: 'literal' | 'date';
    literal?: string;
    date?: {
      from: string;
      to: string;
    };
  }[];
  references?: IVirtualViewRefFilter[];
}

export interface IVirtualView {
  id: number;
  name: string;
  scope: 'company' | 'user';
  resource: 'case' | 'case_request' | 'investigation' | 'statement';
  filters: IVirtualViewFilter[];
  columns: ValidColumns[];
  order: number;
}

export class VirtualViewClient {
  static async fetch(
    input: Partial<{
      cookie: string;
      resource: IVirtualView['resource'];
    }> = {}
  ): Promise<{ virtualViews: IVirtualView[] }> {
    const response = await httpClient.get('/v1/virtual-view', {
      headers: {
        Cookie: input.cookie,
      },
      params: {
        resource: input?.resource,
      },
    });

    return response.data;
  }

  static async create(
    data?: Omit<IVirtualView, 'order'>
  ): Promise<{ virtualViewId: number }> {
    const response = await httpClient.post('/v1/virtual-view', data);

    return response.data;
  }

  static async update(
    id: number,
    data?: Partial<IVirtualView>
  ): Promise<{ virtualViews: IVirtualView[] }> {
    const response = await httpClient.put(`/v1/virtual-view/${id}`, data);

    return response.data;
  }

  static async updateOrder(
    data?: Pick<IVirtualView, 'id' | 'order'>[]
  ): Promise<{ virtualViews: IVirtualView[] }> {
    const dataFormatted = { virtualViews: data };
    const response = await httpClient.put(
      `/v1/virtual-view/order`,
      dataFormatted
    );

    return response.data;
  }

  static async remove(id: number): Promise<{ virtualViews: IVirtualView[] }> {
    const response = await httpClient.delete(`/v1/virtual-view/${id}`);

    return response.data;
  }
}
