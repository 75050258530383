import React from 'react'
import { ICaseExtended } from 'kach-clients'
import { useMemo } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  Box,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { buildUserName } from '../utils/build-user-name'
import Link from 'next/link'
import { translator } from 'kach-commons'

export const CaseSuspectsAlert = ({ data }: { data: ICaseExtended }) => {
  console.log('data.suspects', data.suspects)

  const policyholderSuspects = useMemo(
    () =>
      data.suspects?.filter(
        (suspect) => suspect.documentNumber === data.customer.identification,
      ),
    [data.suspects],
  )

  if (!policyholderSuspects?.length) return null

  console.log('policyholderSuspects', policyholderSuspects)

  return (
    <Alert fontSize='sm' status='warning'>
      <Stack direction={'row'}>
        <AlertIcon />
        <Box>
          El socio{' '}
          <strong>
            {data.customer.firstName || data.customer.lastName
              ? `${buildUserName(data.customer)} - `
              : ''}
            {data.customer.identification}
          </strong>{' '}
          posee antecedentes por la vinculación con los siniestros:{' '}
          {policyholderSuspects.map((suspect, index) => {
            return (
              <ChakraLink
                key={suspect.id}
                textDecor='underline'
                href={`/case/request/${suspect.case.caseRequest.id}`}
                target='_blank'
              >
                {suspect.case.caseRequest.sinisterId} (
                {translator(suspect.type)})
                {index !== policyholderSuspects.length - 1 ? ', ' : ''}
              </ChakraLink>
            )
          })}
        </Box>
      </Stack>
    </Alert>
  )
}
