import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { IInvestigation, InvestigationClient } from 'kach-clients'
import { useRouter } from 'next/router'
import React from 'react'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { INVESTIGATIONS_QUERY_KEY } from '../constants/query-keys'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { IRejectInvestigationSchema } from '../schemas/reject-investigation.schema'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'

export const RejectInvestigationBtn = (props: {
  investigation: IInvestigation
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const router = useRouter()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onSubmit = useCallback(
    (data: IRejectInvestigationSchema) => {
      setIsLoading(true)

      toast.promise(
        InvestigationClient.reject(props.investigation.id, data.reason),
        {
          success: () => {
            queryClient.invalidateQueries([
              buildInvestigationQueryKey(props.investigation.id),
            ])
            queryClient.invalidateQueries([INVESTIGATIONS_QUERY_KEY])
            setIsLoading(false)
            return {
              title: 'Investigación rechazada correctamente',
            }
          },
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
          loading: { title: 'Rechazando investigación...' },
        },
      )
    },
    [props.investigation.id],
  )

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<IRejectInvestigationSchema>()

  return (
    <Popover>
      {({ onClose }) => {
        return (
          <>
            <PopoverTrigger>
              <Button>Rechazar</Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton disabled={isLoading} />
              <PopoverBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl isInvalid={!!errors.reason} isRequired>
                    <FormLabel>Motivo del rechazo</FormLabel>
                    <Textarea
                      isInvalid={!!errors.reason}
                      {...register('reason')}
                      isRequired
                    />
                  </FormControl>
                  <ButtonGroup mt='3' size={'xs'}>
                    <Button
                      disabled={isLoading}
                      isLoading={isLoading}
                      type='submit'
                      variant={'primary'}
                    >
                      Confirmar
                    </Button>
                    <Button disabled={isLoading} onClick={onClose}>
                      Cancelar
                    </Button>
                  </ButtonGroup>
                </form>
              </PopoverBody>
            </PopoverContent>
          </>
        )
      }}
    </Popover>
  )
}
