export const countriesAndStateSlim = [
  {
    name: 'Argentina',
    iso2: 'AR',
    emoji: '🇦🇷',
    states: [
      {
        id: 3656,
        name: 'Buenos Aires',
        state_code: 'B',
        latitude: '-37.20172850',
        longitude: '-59.84106970',
        type: 'province',
      },
      {
        id: 3647,
        name: 'Catamarca',
        state_code: 'K',
        latitude: '-28.47158770',
        longitude: '-65.78772090',
        type: 'province',
      },
      {
        id: 3640,
        name: 'Chaco',
        state_code: 'H',
        latitude: '-27.42571750',
        longitude: '-59.02437840',
        type: 'province',
      },
      {
        id: 3651,
        name: 'Chubut',
        state_code: 'U',
        latitude: '-43.29342460',
        longitude: '-65.11148180',
        type: 'province',
      },
      {
        id: 4880,
        name: 'Ciudad Autónoma de Buenos Aires',
        state_code: 'C',
        latitude: '-34.60368440',
        longitude: '-58.38155910',
        type: 'city',
      },
      {
        id: 3642,
        name: 'Córdoba',
        state_code: 'X',
        latitude: '-31.39928760',
        longitude: '-64.26438420',
        type: 'province',
      },
      {
        id: 3638,
        name: 'Corrientes',
        state_code: 'W',
        latitude: '-27.46921310',
        longitude: '-58.83063490',
        type: 'province',
      },
      {
        id: 3654,
        name: 'Entre Ríos',
        state_code: 'E',
        latitude: '-31.77466540',
        longitude: '-60.49564610',
        type: 'province',
      },
      {
        id: 3652,
        name: 'Formosa',
        state_code: 'P',
        latitude: '-26.18948040',
        longitude: '-58.22428060',
        type: 'province',
      },
      {
        id: 3645,
        name: 'Jujuy',
        state_code: 'Y',
        latitude: '-24.18433970',
        longitude: '-65.30217700',
        type: 'province',
      },
      {
        id: 3655,
        name: 'La Pampa',
        state_code: 'L',
        latitude: '-36.61475730',
        longitude: '-64.28392090',
        type: 'province',
      },
      {
        id: 3653,
        name: 'La Rioja',
        state_code: 'F',
        latitude: '-29.41937930',
        longitude: '-66.85599320',
        type: 'province',
      },
      {
        id: 3646,
        name: 'Mendoza',
        state_code: 'M',
        latitude: '-32.88945870',
        longitude: '-68.84583860',
        type: 'province',
      },
      {
        id: 3644,
        name: 'Misiones',
        state_code: 'N',
        latitude: '-27.42692550',
        longitude: '-55.94670760',
        type: 'province',
      },
      {
        id: 3648,
        name: 'Neuquén',
        state_code: 'Q',
        latitude: '-38.94587000',
        longitude: '-68.07309250',
        type: 'province',
      },
      {
        id: 3639,
        name: 'Río Negro',
        state_code: 'R',
        latitude: '-40.82614340',
        longitude: '-63.02663390',
        type: 'province',
      },
      {
        id: 3643,
        name: 'Salta',
        state_code: 'A',
        latitude: '-24.79976880',
        longitude: '-65.41503670',
        type: 'province',
      },
      {
        id: 3634,
        name: 'San Juan',
        state_code: 'J',
        latitude: '-31.53169760',
        longitude: '-68.56769620',
        type: 'province',
      },
      {
        id: 3636,
        name: 'San Luis',
        state_code: 'D',
        latitude: '-33.29620420',
        longitude: '-66.32949480',
        type: 'province',
      },
      {
        id: 3649,
        name: 'Santa Cruz',
        state_code: 'Z',
        latitude: '-51.63528210',
        longitude: '-69.24743530',
        type: 'province',
      },
      {
        id: 3641,
        name: 'Santa Fe',
        state_code: 'S',
        latitude: '-31.58551090',
        longitude: '-60.72380160',
        type: 'province',
      },
      {
        id: 3635,
        name: 'Santiago del Estero',
        state_code: 'G',
        latitude: '-27.78335740',
        longitude: '-64.26416700',
        type: 'province',
      },
      {
        id: 3650,
        name: 'Tierra del Fuego',
        state_code: 'V',
        latitude: '-54.80539980',
        longitude: '-68.32420610',
        type: 'province',
      },
      {
        id: 3637,
        name: 'Tucumán',
        state_code: 'T',
        latitude: '-26.82211270',
        longitude: '-65.21929030',
        type: 'province',
      },
    ],
  },
  {
    name: 'Bolivia',
    iso2: 'BO',
    emoji: '🇧🇴',
    states: [
      {
        id: 3375,
        name: 'Beni',
        state_code: 'B',
        latitude: '-14.37827470',
        longitude: '-65.09577920',
        type: null,
      },
      {
        id: 3382,
        name: 'Chuquisaca',
        state_code: 'H',
        latitude: '-20.02491440',
        longitude: '-64.14782360',
        type: null,
      },
      {
        id: 3381,
        name: 'Cochabamba',
        state_code: 'C',
        latitude: '-17.56816750',
        longitude: '-65.47573600',
        type: null,
      },
      {
        id: 3380,
        name: 'La Paz',
        state_code: 'L',
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3376,
        name: 'Oruro',
        state_code: 'O',
        latitude: '-18.57115790',
        longitude: '-67.76159830',
        type: null,
      },
      {
        id: 3379,
        name: 'Pando',
        state_code: 'N',
        latitude: '-10.79889010',
        longitude: '-66.99880110',
        type: null,
      },
      {
        id: 3383,
        name: 'Potosí',
        state_code: 'P',
        latitude: '-20.62471300',
        longitude: '-66.99880110',
        type: null,
      },
      {
        id: 3377,
        name: 'Santa Cruz',
        state_code: 'S',
        latitude: '-16.74760370',
        longitude: '-62.07509980',
        type: null,
      },
      {
        id: 3378,
        name: 'Tarija',
        state_code: 'T',
        latitude: '-21.58315950',
        longitude: '-63.95861110',
        type: null,
      },
    ],
  },
  {
    name: 'Brazil',
    iso2: 'BR',
    emoji: '🇧🇷',
    states: [
      {
        id: 2012,
        name: 'Acre',
        state_code: 'AC',
        latitude: '-9.02379600',
        longitude: '-70.81199500',
        type: null,
      },
      {
        id: 2007,
        name: 'Alagoas',
        state_code: 'AL',
        latitude: '-9.57130580',
        longitude: '-36.78195050',
        type: null,
      },
      {
        id: 1999,
        name: 'Amapá',
        state_code: 'AP',
        latitude: '0.90199250',
        longitude: '-52.00295650',
        type: null,
      },
      {
        id: 2004,
        name: 'Amazonas',
        state_code: 'AM',
        latitude: '-3.07000000',
        longitude: '-61.66000000',
        type: null,
      },
      {
        id: 2002,
        name: 'Bahia',
        state_code: 'BA',
        latitude: '-11.40987400',
        longitude: '-41.28085700',
        type: null,
      },
      {
        id: 2016,
        name: 'Ceará',
        state_code: 'CE',
        latitude: '-5.49839770',
        longitude: '-39.32062410',
        type: null,
      },
      {
        id: 2017,
        name: 'Distrito Federal',
        state_code: 'DF',
        latitude: '-15.79976540',
        longitude: '-47.86447150',
        type: null,
      },
      {
        id: 2018,
        name: 'Espírito Santo',
        state_code: 'ES',
        latitude: '-19.18342290',
        longitude: '-40.30886260',
        type: null,
      },
      {
        id: 2000,
        name: 'Goiás',
        state_code: 'GO',
        latitude: '-15.82703690',
        longitude: '-49.83622370',
        type: null,
      },
      {
        id: 2015,
        name: 'Maranhão',
        state_code: 'MA',
        latitude: '-4.96094980',
        longitude: '-45.27441590',
        type: null,
      },
      {
        id: 2011,
        name: 'Mato Grosso',
        state_code: 'MT',
        latitude: '-12.68187120',
        longitude: '-56.92109900',
        type: null,
      },
      {
        id: 2010,
        name: 'Mato Grosso do Sul',
        state_code: 'MS',
        latitude: '-20.77222950',
        longitude: '-54.78515310',
        type: null,
      },
      {
        id: 1998,
        name: 'Minas Gerais',
        state_code: 'MG',
        latitude: '-18.51217800',
        longitude: '-44.55503080',
        type: null,
      },
      {
        id: 2009,
        name: 'Pará',
        state_code: 'PA',
        latitude: '-1.99812710',
        longitude: '-54.93061520',
        type: null,
      },
      {
        id: 2005,
        name: 'Paraíba',
        state_code: 'PB',
        latitude: '-7.23996090',
        longitude: '-36.78195050',
        type: null,
      },
      {
        id: 2022,
        name: 'Paraná',
        state_code: 'PR',
        latitude: '-25.25208880',
        longitude: '-52.02154150',
        type: null,
      },
      {
        id: 2006,
        name: 'Pernambuco',
        state_code: 'PE',
        latitude: '-8.81371730',
        longitude: '-36.95410700',
        type: null,
      },
      {
        id: 2008,
        name: 'Piauí',
        state_code: 'PI',
        latitude: '-7.71834010',
        longitude: '-42.72892360',
        type: null,
      },
      {
        id: 1997,
        name: 'Rio de Janeiro',
        state_code: 'RJ',
        latitude: '-22.90684670',
        longitude: '-43.17289650',
        type: null,
      },
      {
        id: 2019,
        name: 'Rio Grande do Norte',
        state_code: 'RN',
        latitude: '-5.40258030',
        longitude: '-36.95410700',
        type: null,
      },
      {
        id: 2001,
        name: 'Rio Grande do Sul',
        state_code: 'RS',
        latitude: '-30.03463160',
        longitude: '-51.21769860',
        type: null,
      },
      {
        id: 2013,
        name: 'Rondônia',
        state_code: 'RO',
        latitude: '-11.50573410',
        longitude: '-63.58061100',
        type: null,
      },
      {
        id: 4858,
        name: 'Roraima',
        state_code: 'RR',
        latitude: '2.73759710',
        longitude: '-62.07509980',
        type: null,
      },
      {
        id: 2014,
        name: 'Santa Catarina',
        state_code: 'SC',
        latitude: '-27.33000000',
        longitude: '-49.44000000',
        type: null,
      },
      {
        id: 2021,
        name: 'São Paulo',
        state_code: 'SP',
        latitude: '-23.55051990',
        longitude: '-46.63330940',
        type: null,
      },
      {
        id: 2003,
        name: 'Sergipe',
        state_code: 'SE',
        latitude: '-10.57409340',
        longitude: '-37.38565810',
        type: null,
      },
      {
        id: 2020,
        name: 'Tocantins',
        state_code: 'TO',
        latitude: '-10.17528000',
        longitude: '-48.29824740',
        type: null,
      },
    ],
  },
  {
    name: 'Chile',
    iso2: 'CL',
    emoji: '🇨🇱',
    states: [
      {
        id: 2828,
        name: 'Aisén del General Carlos Ibañez del Campo',
        state_code: 'AI',
        latitude: '-46.37834500',
        longitude: '-72.30076230',
        type: null,
      },
      {
        id: 2832,
        name: 'Antofagasta',
        state_code: 'AN',
        latitude: '-23.83691040',
        longitude: '-69.28775350',
        type: null,
      },
      {
        id: 2829,
        name: 'Arica y Parinacota',
        state_code: 'AP',
        latitude: '-18.59404850',
        longitude: '-69.47845410',
        type: null,
      },
      {
        id: 2823,
        name: 'Atacama',
        state_code: 'AT',
        latitude: '-27.56605580',
        longitude: '-70.05031400',
        type: null,
      },
      {
        id: 2827,
        name: 'Biobío',
        state_code: 'BI',
        latitude: '-37.44644280',
        longitude: '-72.14161320',
        type: null,
      },
      {
        id: 2825,
        name: 'Coquimbo',
        state_code: 'CO',
        latitude: '-30.54018100',
        longitude: '-70.81199530',
        type: null,
      },
      {
        id: 2826,
        name: 'La Araucanía',
        state_code: 'AR',
        latitude: '-38.94892100',
        longitude: '-72.33111300',
        type: null,
      },
      {
        id: 2838,
        name: "Libertador General Bernardo O'Higgins",
        state_code: 'LI',
        latitude: '-34.57553740',
        longitude: '-71.00223110',
        type: null,
      },
      {
        id: 2835,
        name: 'Los Lagos',
        state_code: 'LL',
        latitude: '-41.91977790',
        longitude: '-72.14161320',
        type: null,
      },
      {
        id: 2834,
        name: 'Los Ríos',
        state_code: 'LR',
        latitude: '-40.23102170',
        longitude: '-72.33111300',
        type: null,
      },
      {
        id: 2836,
        name: 'Magallanes y de la Antártica Chilena',
        state_code: 'MA',
        latitude: '-52.20643160',
        longitude: '-72.16850010',
        type: null,
      },
      {
        id: 2833,
        name: 'Maule',
        state_code: 'ML',
        latitude: '-35.51636030',
        longitude: '-71.57239530',
        type: null,
      },
      {
        id: 2831,
        name: 'Ñuble',
        state_code: 'NB',
        latitude: '-36.72257430',
        longitude: '-71.76224810',
        type: null,
      },
      {
        id: 2824,
        name: 'Región Metropolitana de Santiago',
        state_code: 'RM',
        latitude: '-33.43755450',
        longitude: '-70.65048960',
        type: null,
      },
      {
        id: 2837,
        name: 'Tarapacá',
        state_code: 'TA',
        latitude: '-20.20287990',
        longitude: '-69.28775350',
        type: null,
      },
      {
        id: 2830,
        name: 'Valparaíso',
        state_code: 'VS',
        latitude: '-33.04723800',
        longitude: '-71.61268850',
        type: null,
      },
    ],
  },
  {
    name: 'Colombia',
    iso2: 'CO',
    emoji: '🇨🇴',
    states: [
      {
        id: 2895,
        name: 'Amazonas',
        state_code: 'AMA',
        latitude: '-1.44291230',
        longitude: '-71.57239530',
        type: null,
      },
      {
        id: 2890,
        name: 'Antioquia',
        state_code: 'ANT',
        latitude: '7.19860640',
        longitude: '-75.34121790',
        type: null,
      },
      {
        id: 2881,
        name: 'Arauca',
        state_code: 'ARA',
        latitude: '6.54730600',
        longitude: '-71.00223110',
        type: null,
      },
      {
        id: 2900,
        name: 'Archipiélago de San Andrés, Providencia y Santa Catalina',
        state_code: 'SAP',
        latitude: '12.55673240',
        longitude: '-81.71852530',
        type: null,
      },
      {
        id: 2880,
        name: 'Atlántico',
        state_code: 'ATL',
        latitude: '10.69661590',
        longitude: '-74.87410450',
        type: null,
      },
      {
        id: 4921,
        name: 'Bogotá D.C.',
        state_code: 'DC',
        latitude: '4.28204150',
        longitude: '-74.50270420',
        type: 'capital district',
      },
      {
        id: 2893,
        name: 'Bolívar',
        state_code: 'BOL',
        latitude: '8.67043820',
        longitude: '-74.03001220',
        type: null,
      },
      {
        id: 2903,
        name: 'Boyacá',
        state_code: 'BOY',
        latitude: '5.45451100',
        longitude: '-73.36200300',
        type: null,
      },
      {
        id: 2887,
        name: 'Caldas',
        state_code: 'CAL',
        latitude: '5.29826000',
        longitude: '-75.24790610',
        type: null,
      },
      {
        id: 2891,
        name: 'Caquetá',
        state_code: 'CAQ',
        latitude: '0.86989200',
        longitude: '-73.84190630',
        type: null,
      },
      {
        id: 2892,
        name: 'Casanare',
        state_code: 'CAS',
        latitude: '5.75892690',
        longitude: '-71.57239530',
        type: null,
      },
      {
        id: 2884,
        name: 'Cauca',
        state_code: 'CAU',
        latitude: '2.70498130',
        longitude: '-76.82596520',
        type: null,
      },
      {
        id: 2899,
        name: 'Cesar',
        state_code: 'CES',
        latitude: '9.33729480',
        longitude: '-73.65362090',
        type: null,
      },
      {
        id: 2876,
        name: 'Chocó',
        state_code: 'CHO',
        latitude: '5.25280330',
        longitude: '-76.82596520',
        type: null,
      },
      {
        id: 2898,
        name: 'Córdoba',
        state_code: 'COR',
        latitude: '8.04929300',
        longitude: '-75.57405000',
        type: null,
      },
      {
        id: 2875,
        name: 'Cundinamarca',
        state_code: 'CUN',
        latitude: '5.02600300',
        longitude: '-74.03001220',
        type: null,
      },
      {
        id: 2882,
        name: 'Guainía',
        state_code: 'GUA',
        latitude: '2.58539300',
        longitude: '-68.52471490',
        type: null,
      },
      {
        id: 2888,
        name: 'Guaviare',
        state_code: 'GUV',
        latitude: '2.04392400',
        longitude: '-72.33111300',
        type: null,
      },
      {
        id: 4871,
        name: 'Huila',
        state_code: 'HUI',
        latitude: '2.53593490',
        longitude: '-75.52766990',
        type: 'department',
      },
      {
        id: 2889,
        name: 'La Guajira',
        state_code: 'LAG',
        latitude: '11.35477430',
        longitude: '-72.52048270',
        type: null,
      },
      {
        id: 2886,
        name: 'Magdalena',
        state_code: 'MAG',
        latitude: '10.41130140',
        longitude: '-74.40566120',
        type: null,
      },
      {
        id: 2878,
        name: 'Meta',
        state_code: 'MET',
        latitude: '39.76732580',
        longitude: '-104.97535950',
        type: null,
      },
      {
        id: 2897,
        name: 'Nariño',
        state_code: 'NAR',
        latitude: '1.28915100',
        longitude: '-77.35794000',
        type: null,
      },
      {
        id: 2877,
        name: 'Norte de Santander',
        state_code: 'NSA',
        latitude: '7.94628310',
        longitude: '-72.89880690',
        type: null,
      },
      {
        id: 2896,
        name: 'Putumayo',
        state_code: 'PUT',
        latitude: '0.43595060',
        longitude: '-75.52766990',
        type: null,
      },
      {
        id: 2874,
        name: 'Quindío',
        state_code: 'QUI',
        latitude: '4.46101910',
        longitude: '-75.66735600',
        type: null,
      },
      {
        id: 2879,
        name: 'Risaralda',
        state_code: 'RIS',
        latitude: '5.31584750',
        longitude: '-75.99276520',
        type: null,
      },
      {
        id: 2901,
        name: 'Santander',
        state_code: 'SAN',
        latitude: '6.64370760',
        longitude: '-73.65362090',
        type: null,
      },
      {
        id: 2902,
        name: 'Sucre',
        state_code: 'SUC',
        latitude: '8.81397700',
        longitude: '-74.72328300',
        type: null,
      },
      {
        id: 2883,
        name: 'Tolima',
        state_code: 'TOL',
        latitude: '4.09251680',
        longitude: '-75.15453810',
        type: null,
      },
      {
        id: 2904,
        name: 'Valle del Cauca',
        state_code: 'VAC',
        latitude: '3.80088930',
        longitude: '-76.64127120',
        type: null,
      },
      {
        id: 2885,
        name: 'Vaupés',
        state_code: 'VAU',
        latitude: '0.85535610',
        longitude: '-70.81199530',
        type: null,
      },
      {
        id: 2894,
        name: 'Vichada',
        state_code: 'VID',
        latitude: '4.42344520',
        longitude: '-69.28775350',
        type: null,
      },
    ],
  },
  {
    name: 'Ecuador',
    iso2: 'EC',
    emoji: '🇪🇨',
    states: [
      {
        id: 2923,
        name: 'Azuay',
        state_code: 'A',
        latitude: '-2.89430680',
        longitude: '-78.99683440',
        type: 'province',
      },
      {
        id: 2920,
        name: 'Bolívar',
        state_code: 'B',
        latitude: '-1.70958280',
        longitude: '-79.04504290',
        type: 'province',
      },
      {
        id: 2917,
        name: 'Cañar',
        state_code: 'F',
        latitude: '-2.55893150',
        longitude: '-78.93881910',
        type: 'province',
      },
      {
        id: 2915,
        name: 'Carchi',
        state_code: 'C',
        latitude: '0.50269120',
        longitude: '-77.90425210',
        type: 'province',
      },
      {
        id: 2925,
        name: 'Chimborazo',
        state_code: 'H',
        latitude: '-1.66479950',
        longitude: '-78.65432550',
        type: 'province',
      },
      {
        id: 2921,
        name: 'Cotopaxi',
        state_code: 'X',
        latitude: '-0.83842060',
        longitude: '-78.66626780',
        type: 'province',
      },
      {
        id: 2924,
        name: 'El Oro',
        state_code: 'O',
        latitude: '-3.25924130',
        longitude: '-79.95835410',
        type: 'province',
      },
      {
        id: 2922,
        name: 'Esmeraldas',
        state_code: 'E',
        latitude: '0.96817890',
        longitude: '-79.65172020',
        type: 'province',
      },
      {
        id: 2905,
        name: 'Galápagos',
        state_code: 'W',
        latitude: '-0.95376910',
        longitude: '-90.96560190',
        type: 'province',
      },
      {
        id: 2914,
        name: 'Guayas',
        state_code: 'G',
        latitude: '-1.95748390',
        longitude: '-79.91927020',
        type: 'province',
      },
      {
        id: 2911,
        name: 'Imbabura',
        state_code: 'I',
        latitude: '0.34997680',
        longitude: '-78.12601290',
        type: 'province',
      },
      {
        id: 5068,
        name: 'Loja',
        state_code: 'L',
        latitude: '-3.99313000',
        longitude: '-79.20422000',
        type: 'province',
      },
      {
        id: 2910,
        name: 'Los Ríos',
        state_code: 'R',
        latitude: '-1.02306070',
        longitude: '-79.46088970',
        type: 'province',
      },
      {
        id: 2913,
        name: 'Manabí',
        state_code: 'M',
        latitude: '-1.05434340',
        longitude: '-80.45264400',
        type: 'province',
      },
      {
        id: 2918,
        name: 'Morona-Santiago',
        state_code: 'S',
        latitude: '-2.30510620',
        longitude: '-78.11468660',
        type: 'province',
      },
      {
        id: 2916,
        name: 'Napo',
        state_code: 'N',
        latitude: '-0.99559640',
        longitude: '-77.81296840',
        type: 'province',
      },
      {
        id: 2926,
        name: 'Orellana',
        state_code: 'D',
        latitude: '-0.45451630',
        longitude: '-76.99502860',
        type: 'province',
      },
      {
        id: 2907,
        name: 'Pastaza',
        state_code: 'Y',
        latitude: '-1.48822650',
        longitude: '-78.00310570',
        type: 'province',
      },
      {
        id: 2927,
        name: 'Pichincha',
        state_code: 'P',
        latitude: '-0.14648470',
        longitude: '-78.47519450',
        type: 'province',
      },
      {
        id: 2912,
        name: 'Santa Elena',
        state_code: 'SE',
        latitude: '-2.22671050',
        longitude: '-80.85949900',
        type: 'province',
      },
      {
        id: 2919,
        name: 'Santo Domingo de los Tsáchilas',
        state_code: 'SD',
        latitude: '-0.25218820',
        longitude: '-79.18793830',
        type: 'province',
      },
      {
        id: 2906,
        name: 'Sucumbíos',
        state_code: 'U',
        latitude: '0.08892310',
        longitude: '-76.88975570',
        type: 'province',
      },
      {
        id: 2908,
        name: 'Tungurahua',
        state_code: 'T',
        latitude: '-1.26352840',
        longitude: '-78.56608520',
        type: 'province',
      },
      {
        id: 2909,
        name: 'Zamora Chinchipe',
        state_code: 'Z',
        latitude: '-4.06558920',
        longitude: '-78.95035250',
        type: 'province',
      },
    ],
  },
  {
    name: 'Falkland Islands',
    iso2: 'FK',
    emoji: '🇫🇰',
    states: [],
  },
  {
    name: 'French Guiana',
    iso2: 'GF',
    emoji: '🇬🇫',
    states: [],
  },
  {
    name: 'Guyana',
    iso2: 'GY',
    emoji: '🇬🇾',
    states: [
      {
        id: 2764,
        name: 'Barima-Waini',
        state_code: 'BA',
        latitude: '7.48824190',
        longitude: '-59.65644940',
        type: null,
      },
      {
        id: 2760,
        name: 'Cuyuni-Mazaruni',
        state_code: 'CU',
        latitude: '6.46421410',
        longitude: '-60.21107520',
        type: null,
      },
      {
        id: 2767,
        name: 'Demerara-Mahaica',
        state_code: 'DE',
        latitude: '6.54642600',
        longitude: '-58.09820460',
        type: null,
      },
      {
        id: 2766,
        name: 'East Berbice-Corentyne',
        state_code: 'EB',
        latitude: '2.74779220',
        longitude: '-57.46272590',
        type: null,
      },
      {
        id: 2768,
        name: 'Essequibo Islands-West Demerara',
        state_code: 'ES',
        latitude: '6.57201320',
        longitude: '-58.46299970',
        type: null,
      },
      {
        id: 2762,
        name: 'Mahaica-Berbice',
        state_code: 'MA',
        latitude: '6.23849600',
        longitude: '-57.91625550',
        type: null,
      },
      {
        id: 2765,
        name: 'Pomeroon-Supenaam',
        state_code: 'PM',
        latitude: '7.12941660',
        longitude: '-58.92062950',
        type: null,
      },
      {
        id: 2761,
        name: 'Potaro-Siparuni',
        state_code: 'PT',
        latitude: '4.78558530',
        longitude: '-59.28799770',
        type: null,
      },
      {
        id: 2763,
        name: 'Upper Demerara-Berbice',
        state_code: 'UD',
        latitude: '5.30648790',
        longitude: '-58.18929210',
        type: null,
      },
      {
        id: 2769,
        name: 'Upper Takutu-Upper Essequibo',
        state_code: 'UT',
        latitude: '2.92395950',
        longitude: '-58.73736340',
        type: null,
      },
    ],
  },
  {
    name: 'Paraguay',
    iso2: 'PY',
    emoji: '🇵🇾',
    states: [
      {
        name: 'Asunción',
        state_code: 'ASU',
      },
      {
        id: 2785,
        name: 'Alto Paraguay',
        state_code: '16',
        latitude: '-20.08525080',
        longitude: '-59.47209040',
        type: null,
      },
      {
        id: 2784,
        name: 'Alto Paraná',
        state_code: '10',
        latitude: '-25.60755460',
        longitude: '-54.96118360',
        type: null,
      },
      {
        id: 2782,
        name: 'Amambay',
        state_code: '13',
        latitude: '-22.55902720',
        longitude: '-56.02499820',
        type: null,
      },
      {
        id: 2780,
        name: 'Boquerón',
        state_code: '19',
        latitude: '-21.74492540',
        longitude: '-60.95400730',
        type: null,
      },
      {
        id: 2773,
        name: 'Caaguazú',
        state_code: '5',
        latitude: '-25.46458180',
        longitude: '-56.01385100',
        type: null,
      },
      {
        id: 2775,
        name: 'Caazapá',
        state_code: '6',
        latitude: '-26.18277130',
        longitude: '-56.37123270',
        type: null,
      },
      {
        id: 2771,
        name: 'Canindeyú',
        state_code: '14',
        latitude: '-24.13787350',
        longitude: '-55.66896360',
        type: null,
      },
      {
        id: 2777,
        name: 'Central',
        state_code: '11',
        latitude: '36.15592290',
        longitude: '-95.96620750',
        type: null,
      },
      {
        id: 2779,
        name: 'Concepción',
        state_code: '1',
        latitude: '-23.42142640',
        longitude: '-57.43444510',
        type: null,
      },
      {
        id: 2783,
        name: 'Cordillera',
        state_code: '3',
        latitude: '-25.22894910',
        longitude: '-57.01116810',
        type: null,
      },
      {
        id: 2772,
        name: 'Guairá',
        state_code: '4',
        latitude: '-25.88109320',
        longitude: '-56.29293810',
        type: null,
      },
      {
        id: 2778,
        name: 'Itapúa',
        state_code: '7',
        latitude: '-26.79236230',
        longitude: '-55.66896360',
        type: null,
      },
      {
        id: 2786,
        name: 'Misiones',
        state_code: '8',
        latitude: '-26.84335120',
        longitude: '-57.10131880',
        type: null,
      },
      {
        id: 2781,
        name: 'Ñeembucú',
        state_code: '12',
        latitude: '-27.02991140',
        longitude: '-57.82539500',
        type: null,
      },
      {
        id: 2774,
        name: 'Paraguarí',
        state_code: '9',
        latitude: '-25.62621740',
        longitude: '-57.15206420',
        type: null,
      },
      {
        id: 2770,
        name: 'Presidente Hayes',
        state_code: '15',
        latitude: '-23.35126050',
        longitude: '-58.73736340',
        type: null,
      },
      {
        id: 2776,
        name: 'San Pedro',
        state_code: '2',
        latitude: '-24.19486680',
        longitude: '-56.56164700',
        type: null,
      },
    ],
  },
  {
    name: 'Peru',
    iso2: 'PE',
    emoji: '🇵🇪',
    states: [
      {
        id: 3685,
        name: 'Amazonas',
        state_code: 'AMA',
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3680,
        name: 'Áncash',
        state_code: 'ANC',
        latitude: '-9.32504970',
        longitude: '-77.56194190',
        type: null,
      },
      {
        id: 3699,
        name: 'Apurímac',
        state_code: 'APU',
        latitude: '-14.05045330',
        longitude: '-73.08774900',
        type: null,
      },
      {
        id: 3681,
        name: 'Arequipa',
        state_code: 'ARE',
        latitude: '-16.40904740',
        longitude: '-71.53745100',
        type: null,
      },
      {
        id: 3692,
        name: 'Ayacucho',
        state_code: 'AYA',
        latitude: '-13.16387370',
        longitude: '-74.22356410',
        type: null,
      },
      {
        id: 3688,
        name: 'Cajamarca',
        state_code: 'CAJ',
        latitude: '-7.16174650',
        longitude: '-78.51278550',
        type: null,
      },
      {
        id: 3701,
        name: 'Callao',
        state_code: 'CAL',
        latitude: '-12.05084910',
        longitude: '-77.12598430',
        type: null,
      },
      {
        id: 3691,
        name: 'Cusco',
        state_code: 'CUS',
        latitude: '-13.53195000',
        longitude: '-71.96746260',
        type: null,
      },
      {
        id: 3679,
        name: 'Huancavelica',
        state_code: 'HUV',
        latitude: '-12.78619780',
        longitude: '-74.97640240',
        type: null,
      },
      {
        id: 3687,
        name: 'Huanuco',
        state_code: 'HUC',
        latitude: '-9.92076480',
        longitude: '-76.24108430',
        type: null,
      },
      {
        id: 3700,
        name: 'Ica',
        state_code: 'ICA',
        latitude: '42.35288320',
        longitude: '-71.04300970',
        type: null,
      },
      {
        id: 3693,
        name: 'Junín',
        state_code: 'JUN',
        latitude: '-11.15819250',
        longitude: '-75.99263060',
        type: null,
      },
      {
        id: 3683,
        name: 'La Libertad',
        state_code: 'LAL',
        latitude: '13.49069700',
        longitude: '-89.30846070',
        type: null,
      },
      {
        id: 3702,
        name: 'Lambayeque',
        state_code: 'LAM',
        latitude: '-6.71976660',
        longitude: '-79.90807570',
        type: null,
      },
      {
        id: 3695,
        name: 'Lima',
        state_code: 'LIM',
        latitude: '-12.04637310',
        longitude: '-77.04275400',
        type: null,
      },
      {
        id: 4922,
        name: 'Loreto',
        state_code: 'LOR',
        latitude: '-4.37416430',
        longitude: '-76.13042640',
        type: null,
      },
      {
        id: 3678,
        name: 'Madre de Dios',
        state_code: 'MDD',
        latitude: '-11.76687050',
        longitude: '-70.81199530',
        type: null,
      },
      {
        id: 3698,
        name: 'Moquegua',
        state_code: 'MOQ',
        latitude: '-17.19273610',
        longitude: '-70.93281380',
        type: null,
      },
      {
        id: 3686,
        name: 'Pasco',
        state_code: 'PAS',
        latitude: '46.23050490',
        longitude: '-119.09223160',
        type: null,
      },
      {
        id: 3697,
        name: 'Piura',
        state_code: 'PIU',
        latitude: '-5.17828840',
        longitude: '-80.65488820',
        type: null,
      },
      {
        id: 3682,
        name: 'Puno',
        state_code: 'PUN',
        latitude: '-15.84022180',
        longitude: '-70.02188050',
        type: null,
      },
      {
        id: 3694,
        name: 'San Martín',
        state_code: 'SAM',
        latitude: '37.08494640',
        longitude: '-121.61022160',
        type: null,
      },
      {
        id: 3696,
        name: 'Tacna',
        state_code: 'TAC',
        latitude: '-18.00656790',
        longitude: '-70.24627410',
        type: null,
      },
      {
        id: 3689,
        name: 'Tumbes',
        state_code: 'TUM',
        latitude: '-3.55649210',
        longitude: '-80.42708850',
        type: null,
      },
      {
        id: 3684,
        name: 'Ucayali',
        state_code: 'UCA',
        latitude: '-9.82511830',
        longitude: '-73.08774900',
        type: null,
      },
    ],
  },
  {
    name: 'South Georgia',
    iso2: 'GS',
    emoji: '🇬🇸',
    states: [],
  },
  {
    name: 'Suriname',
    iso2: 'SR',
    emoji: '🇸🇷',
    states: [
      {
        id: 2846,
        name: 'Brokopondo District',
        state_code: 'BR',
        latitude: '4.77102470',
        longitude: '-55.04933750',
        type: null,
      },
      {
        id: 2839,
        name: 'Commewijne District',
        state_code: 'CM',
        latitude: '5.74021100',
        longitude: '-54.87312190',
        type: null,
      },
      {
        id: 2842,
        name: 'Coronie District',
        state_code: 'CR',
        latitude: '5.69432710',
        longitude: '-56.29293810',
        type: null,
      },
      {
        id: 2845,
        name: 'Marowijne District',
        state_code: 'MA',
        latitude: '5.62681280',
        longitude: '-54.25931180',
        type: null,
      },
      {
        id: 2840,
        name: 'Nickerie District',
        state_code: 'NI',
        latitude: '5.58554690',
        longitude: '-56.83111170',
        type: null,
      },
      {
        id: 2841,
        name: 'Para District',
        state_code: 'PR',
        latitude: '5.48173180',
        longitude: '-55.22592070',
        type: null,
      },
      {
        id: 2843,
        name: 'Paramaribo District',
        state_code: 'PM',
        latitude: '5.85203550',
        longitude: '-55.20382780',
        type: null,
      },
      {
        id: 2848,
        name: 'Saramacca District',
        state_code: 'SA',
        latitude: '5.72408130',
        longitude: '-55.66896360',
        type: null,
      },
      {
        id: 2847,
        name: 'Sipaliwini District',
        state_code: 'SI',
        latitude: '3.65673820',
        longitude: '-56.20353870',
        type: null,
      },
      {
        id: 2844,
        name: 'Wanica District',
        state_code: 'WA',
        latitude: '5.73237620',
        longitude: '-55.27012350',
        type: null,
      },
    ],
  },
  {
    name: 'Uruguay',
    iso2: 'UY',
    emoji: '🇺🇾',
    states: [
      {
        id: 3205,
        name: 'Artigas',
        state_code: 'AR',
        latitude: '-30.61751120',
        longitude: '-56.95945590',
        type: null,
      },
      {
        id: 3213,
        name: 'Canelones',
        state_code: 'CA',
        latitude: '-34.54087170',
        longitude: '-55.93076000',
        type: null,
      },
      {
        id: 3211,
        name: 'Cerro Largo',
        state_code: 'CL',
        latitude: '-32.44110320',
        longitude: '-54.35217530',
        type: null,
      },
      {
        id: 3208,
        name: 'Colonia',
        state_code: 'CO',
        latitude: '-34.12946780',
        longitude: '-57.66051840',
        type: null,
      },
      {
        id: 3209,
        name: 'Durazno',
        state_code: 'DU',
        latitude: '-33.02324540',
        longitude: '-56.02846440',
        type: null,
      },
      {
        id: 3203,
        name: 'Flores',
        state_code: 'FS',
        latitude: '-33.57337530',
        longitude: '-56.89450280',
        type: null,
      },
      {
        id: 3217,
        name: 'Florida',
        state_code: 'FD',
        latitude: '28.03594950',
        longitude: '-82.45792890',
        type: null,
      },
      {
        id: 3215,
        name: 'Lavalleja',
        state_code: 'LA',
        latitude: '-33.92261750',
        longitude: '-54.97657940',
        type: null,
      },
      {
        id: 3206,
        name: 'Maldonado',
        state_code: 'MA',
        latitude: '-34.55979320',
        longitude: '-54.86285520',
        type: null,
      },
      {
        id: 3218,
        name: 'Montevideo',
        state_code: 'MO',
        latitude: '-34.81815870',
        longitude: '-56.21382560',
        type: null,
      },
      {
        id: 3212,
        name: 'Paysandú',
        state_code: 'PA',
        latitude: '-32.06673660',
        longitude: '-57.33647890',
        type: null,
      },
      {
        id: 3210,
        name: 'Río Negro',
        state_code: 'RN',
        latitude: '-32.76763560',
        longitude: '-57.42952070',
        type: null,
      },
      {
        id: 3207,
        name: 'Rivera',
        state_code: 'RV',
        latitude: '-31.48174210',
        longitude: '-55.24357590',
        type: null,
      },
      {
        id: 3216,
        name: 'Rocha',
        state_code: 'RO',
        latitude: '-33.96900810',
        longitude: '-54.02148500',
        type: null,
      },
      {
        id: 3220,
        name: 'Salto',
        state_code: 'SA',
        latitude: '-31.38802800',
        longitude: '-57.96124550',
        type: null,
      },
      {
        id: 3204,
        name: 'San José',
        state_code: 'SJ',
        latitude: '37.34929680',
        longitude: '-121.90560490',
        type: null,
      },
      {
        id: 3219,
        name: 'Soriano',
        state_code: 'SO',
        latitude: '-33.51027920',
        longitude: '-57.74981030',
        type: null,
      },
      {
        id: 3221,
        name: 'Tacuarembó',
        state_code: 'TA',
        latitude: '-31.72068370',
        longitude: '-55.98598870',
        type: null,
      },
      {
        id: 3214,
        name: 'Treinta y Tres',
        state_code: 'TT',
        latitude: '-33.06850860',
        longitude: '-54.28586270',
        type: null,
      },
    ],
  },
  {
    name: 'Venezuela',
    iso2: 'VE',
    emoji: '🇻🇪',
    states: [
      {
        id: 2044,
        name: 'Amazonas',
        state_code: 'Z',
        latitude: '-3.41684270',
        longitude: '-65.85606460',
        type: 'state',
      },
      {
        id: 2050,
        name: 'Anzoátegui',
        state_code: 'B',
        latitude: '8.59130730',
        longitude: '-63.95861110',
        type: 'state',
      },
      {
        id: 4856,
        name: 'Apure',
        state_code: 'C',
        latitude: '6.92694830',
        longitude: '-68.52471490',
        type: 'state',
      },
      {
        id: 2047,
        name: 'Aragua',
        state_code: 'D',
        latitude: '10.06357580',
        longitude: '-67.28478750',
        type: 'state',
      },
      {
        id: 2049,
        name: 'Barinas',
        state_code: 'E',
        latitude: '8.62314980',
        longitude: '-70.23710450',
        type: 'state',
      },
      {
        id: 2039,
        name: 'Bolívar',
        state_code: 'F',
        latitude: '37.61448380',
        longitude: '-93.41047490',
        type: 'state',
      },
      {
        id: 2040,
        name: 'Carabobo',
        state_code: 'G',
        latitude: '10.11764330',
        longitude: '-68.04775090',
        type: 'state',
      },
      {
        id: 2034,
        name: 'Cojedes',
        state_code: 'H',
        latitude: '9.38166820',
        longitude: '-68.33392750',
        type: 'state',
      },
      {
        id: 2051,
        name: 'Delta Amacuro',
        state_code: 'Y',
        latitude: '8.84993070',
        longitude: '-61.14031960',
        type: 'state',
      },
      {
        id: 4855,
        name: 'Distrito Capital',
        state_code: 'A',
        latitude: '41.26148460',
        longitude: '-95.93108070',
        type: 'capital district',
      },
      {
        id: 2035,
        name: 'Falcón',
        state_code: 'I',
        latitude: '11.18106740',
        longitude: '-69.85974060',
        type: 'state',
      },
      {
        id: 2046,
        name: 'Federal Dependencies of Venezuela',
        state_code: 'W',
        latitude: '10.93770530',
        longitude: '-65.35695730',
        type: 'federal dependency',
      },
      {
        id: 2045,
        name: 'Guárico',
        state_code: 'J',
        latitude: '8.74893090',
        longitude: '-66.23671720',
        type: 'state',
      },
      {
        id: 2055,
        name: 'La Guaira',
        state_code: 'X',
        latitude: '29.30522680',
        longitude: '-94.79138540',
        type: 'state',
      },
      {
        id: 2038,
        name: 'Lara',
        state_code: 'K',
        latitude: '33.98221650',
        longitude: '-118.13227470',
        type: 'state',
      },
      {
        id: 2053,
        name: 'Mérida',
        state_code: 'L',
        latitude: '20.96737020',
        longitude: '-89.59258570',
        type: 'state',
      },
      {
        id: 2037,
        name: 'Miranda',
        state_code: 'M',
        latitude: '42.35193830',
        longitude: '-71.52907660',
        type: 'state',
      },
      {
        id: 2054,
        name: 'Monagas',
        state_code: 'N',
        latitude: '9.32416520',
        longitude: '-63.01475780',
        type: 'state',
      },
      {
        id: 2052,
        name: 'Nueva Esparta',
        state_code: 'O',
        latitude: '10.99707230',
        longitude: '-63.91132960',
        type: 'state',
      },
      {
        id: 2036,
        name: 'Portuguesa',
        state_code: 'P',
        latitude: '9.09439990',
        longitude: '-69.09702300',
        type: 'state',
      },
      {
        id: 2056,
        name: 'Sucre',
        state_code: 'R',
        latitude: '-19.03534500',
        longitude: '-65.25921280',
        type: 'state',
      },
      {
        id: 2048,
        name: 'Táchira',
        state_code: 'S',
        latitude: '7.91370010',
        longitude: '-72.14161320',
        type: 'state',
      },
      {
        id: 2043,
        name: 'Trujillo',
        state_code: 'T',
        latitude: '36.67343430',
        longitude: '-121.62875880',
        type: 'state',
      },
      {
        id: 2041,
        name: 'Yaracuy',
        state_code: 'U',
        latitude: '10.33938900',
        longitude: '-68.81088490',
        type: 'state',
      },
      {
        id: 2042,
        name: 'Zulia',
        state_code: 'V',
        latitude: '10.29102370',
        longitude: '-72.14161320',
        type: 'state',
      },
    ],
  },
  {
    name: 'Mexico',
    iso2: 'MX',
    emoji: '🇲🇽',
    states: [
      {
        id: 3456,
        name: 'Aguascalientes',
        state_code: 'AGU',
        latitude: '21.88525620',
        longitude: '-102.29156770',
        type: 'state',
      },
      {
        id: 3457,
        name: 'Baja California',
        state_code: 'BCN',
        latitude: '30.84063380',
        longitude: '-115.28375850',
        type: 'state',
      },
      {
        id: 3460,
        name: 'Baja California Sur',
        state_code: 'BCS',
        latitude: '26.04444460',
        longitude: '-111.66607250',
        type: 'state',
      },
      {
        id: 3475,
        name: 'Campeche',
        state_code: 'CAM',
        latitude: '19.83012510',
        longitude: '-90.53490870',
        type: 'state',
      },
      {
        id: 3451,
        name: 'Chiapas',
        state_code: 'CHP',
        latitude: '16.75693180',
        longitude: '-93.12923530',
        type: 'state',
      },
      {
        id: 3447,
        name: 'Chihuahua',
        state_code: 'CHH',
        latitude: '28.63299570',
        longitude: '-106.06910040',
        type: 'state',
      },
      {
        id: 3473,
        name: 'Ciudad de México',
        state_code: 'CDMX',
        latitude: '19.43260770',
        longitude: '-99.13320800',
        type: 'federal district',
      },
      {
        id: 3471,
        name: 'Coahuila de Zaragoza',
        state_code: 'COA',
        latitude: '27.05867600',
        longitude: '-101.70682940',
        type: 'state',
      },
      {
        id: 3472,
        name: 'Colima',
        state_code: 'COL',
        latitude: '19.24523420',
        longitude: '-103.72408680',
        type: 'state',
      },
      {
        id: 3453,
        name: 'Durango',
        state_code: 'DUR',
        latitude: '37.27528000',
        longitude: '-107.88006670',
        type: 'state',
      },
      {
        id: 3450,
        name: 'Estado de México',
        state_code: 'MEX',
        latitude: '23.63450100',
        longitude: '-102.55278400',
        type: 'state',
      },
      {
        id: 3469,
        name: 'Guanajuato',
        state_code: 'GUA',
        latitude: '21.01901450',
        longitude: '-101.25735860',
        type: 'state',
      },
      {
        id: 3459,
        name: 'Guerrero',
        state_code: 'GRO',
        latitude: '17.43919260',
        longitude: '-99.54509740',
        type: 'state',
      },
      {
        id: 3470,
        name: 'Hidalgo',
        state_code: 'HID',
        latitude: '26.10035470',
        longitude: '-98.26306840',
        type: 'state',
      },
      {
        id: 4857,
        name: 'Jalisco',
        state_code: 'JAL',
        latitude: '20.65953820',
        longitude: '-103.34943760',
        type: 'state',
      },
      {
        id: 3474,
        name: 'Michoacán de Ocampo',
        state_code: 'MIC',
        latitude: '19.56651920',
        longitude: '-101.70682940',
        type: 'state',
      },
      {
        id: 3465,
        name: 'Morelos',
        state_code: 'MOR',
        latitude: '18.68130490',
        longitude: '-99.10134980',
        type: 'state',
      },
      {
        id: 3477,
        name: 'Nayarit',
        state_code: 'NAY',
        latitude: '21.75138440',
        longitude: '-104.84546190',
        type: 'state',
      },
      {
        id: 3452,
        name: 'Nuevo León',
        state_code: 'NLE',
        latitude: '25.59217200',
        longitude: '-99.99619470',
        type: 'state',
      },
      {
        id: 3448,
        name: 'Oaxaca',
        state_code: 'OAX',
        latitude: '17.07318420',
        longitude: '-96.72658890',
        type: 'state',
      },
      {
        id: 3476,
        name: 'Puebla',
        state_code: 'PUE',
        latitude: '19.04143980',
        longitude: '-98.20627270',
        type: 'state',
      },
      {
        id: 3455,
        name: 'Querétaro',
        state_code: 'QUE',
        latitude: '20.58879320',
        longitude: '-100.38988810',
        type: 'state',
      },
      {
        id: 3467,
        name: 'Quintana Roo',
        state_code: 'ROO',
        latitude: '19.18173930',
        longitude: '-88.47913760',
        type: 'state',
      },
      {
        id: 3461,
        name: 'San Luis Potosí',
        state_code: 'SLP',
        latitude: '22.15646990',
        longitude: '-100.98554090',
        type: 'state',
      },
      {
        id: 3449,
        name: 'Sinaloa',
        state_code: 'SIN',
        latitude: '25.17210910',
        longitude: '-107.47951730',
        type: 'state',
      },
      {
        id: 3468,
        name: 'Sonora',
        state_code: 'SON',
        latitude: '37.98294960',
        longitude: '-120.38217240',
        type: 'state',
      },
      {
        id: 3454,
        name: 'Tabasco',
        state_code: 'TAB',
        latitude: '17.84091730',
        longitude: '-92.61892730',
        type: 'state',
      },
      {
        id: 3463,
        name: 'Tamaulipas',
        state_code: 'TAM',
        latitude: '24.26694000',
        longitude: '-98.83627550',
        type: 'state',
      },
      {
        id: 3458,
        name: 'Tlaxcala',
        state_code: 'TLA',
        latitude: '19.31815400',
        longitude: '-98.23749540',
        type: 'state',
      },
      {
        id: 3464,
        name: 'Veracruz de Ignacio de la Llave',
        state_code: 'VER',
        latitude: '19.17377300',
        longitude: '-96.13422410',
        type: 'state',
      },
      {
        id: 3466,
        name: 'Yucatán',
        state_code: 'YUC',
        latitude: '20.70987860',
        longitude: '-89.09433770',
        type: 'state',
      },
      {
        id: 3462,
        name: 'Zacatecas',
        state_code: 'ZAC',
        latitude: '22.77085550',
        longitude: '-102.58324260',
        type: 'state',
      },
    ],
  },
];
