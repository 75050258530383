import { Box, chakra, Icon, useToast } from '@chakra-ui/react'
import { FolderPlusIcon } from '@heroicons/react/24/solid'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useMemo, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { IFileUploadV2 } from '../hooks/useFileUploadV2'
import { bytesToMB } from '../utils/bytes-to-mb'
import { MBToBytes } from '../utils/mb-to-bytes'
import { FileListDisplayerV2 } from './FileListDisplayerV2'

const getFirstNElements = <T,>(arr: T[], n: number): T[] => {
  if (arr.length <= n) {
    return arr
  }
  return arr.slice(0, n)
}

interface Props {
  readonly?: boolean
  onUpdateFile: (index: number, update: Partial<IFileUploadV2>) => void
  withoutTags?: boolean
  accept?: Record<string, string[]>
  mimetypes?: string[]
  maxFileSizeMB: number
  limit: number
  inputId: string
  onRemove: (index: number) => void
  onUpload: (files: File[]) => Promise<void>
  fileList: IFileUploadV2[]
}

export const FileUploadInput = (props: Props) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return

    props.onUpload(acceptedFiles)
  }, [])

  const accept = useMemo(() => {
    if (props.accept) return props.accept

    if (!props.mimetypes) return

    return props.mimetypes.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: [...(acc[curr] || []), `.${curr.split('/')[1]}`],
      }
    }, {})
  }, [props.mimetypes])

  const toast = useToast()

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDropRejected: (fileRejections) => {
        if (fileRejections.length) {
          const uniqueErrors = fileRejections
            .flatMap((fileRejection) =>
              fileRejection.errors.map((error) => error.code),
            )
            .filter((item, i, ar) => ar.indexOf(item) === i)

          for (const error of uniqueErrors) {
            const displayPopup = () => {
              switch (error) {
                case 'too-many-files':
                  return toast({
                    status: 'warning',
                    title: 'Demasiados archivos',
                    description: `Solo podes subir hasta ${props.limit} archivos`,
                  })

                case 'file-too-large':
                  return toast({
                    status: 'warning',
                    title: 'Archivos muy grandes',
                    description: `El tamaño máximo permitido es de ${props.maxFileSizeMB}MB`,
                  })
              }
            }

            displayPopup()
          }
        }
      },
      onDrop,
      maxFiles: props.limit,
      maxSize: props.maxFileSizeMB * 1024 * 1024,
      accept,
      disabled: props.fileList.length >= props.limit,
    })

  console.log('props.mimetypes', props.mimetypes)

  const supportedMessage = useMemo(() => {
    const mimetypesFormatted =
      !props.mimetypes || !props.mimetypes.length
        ? ['Cualquier archivo']
        : props.mimetypes.map((mimetype) => {
            const [_, fileExtension] = mimetype.split('/')
            return fileExtension.toUpperCase()
          })

    return `${mimetypesFormatted.join(', ')} sin superar el limite de ${
      props.maxFileSizeMB
    }MB por cada archivo`
  }, [])

  return (
    <Box>
      {!props.readonly && (
        <>
          <chakra.div {...getRootProps()}>
            <chakra.input
              {...getInputProps()}
              id={props.inputId}
              name='file-upload'
              srOnly
              type='file'
            />
            <chakra.label
              cursor={'pointer'}
              _hover={{
                bg: 'gray.100',
              }}
              {...(isDragActive
                ? {
                    bg: 'gray.100',
                  }
                : {})}
              style={{
                transitionProperty: 'all',
                transitionDuration: '150ms',
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
              }}
              mt={1}
              h='10rem'
              display='flex'
              justifyContent='center'
              alignItems={'center'}
              color='gray.300'
              border='2px'
              borderStyle='dashed'
              borderRadius='md'
              px={6}
              pt={5}
              pb={6}
            >
              <Box
                w='full'
                display={'flex'}
                flexDir='column'
                alignItems={'center'}
                experimental_spaceY={'1'}
                textAlign='center'
              >
                <Icon as={FolderPlusIcon} boxSize={6} />
                <Box display={'flex'} fontSize='sm' color='gray.600'>
                  <chakra.label
                    position='relative'
                    cursor='pointer'
                    color='primary'
                  >
                    <chakra.span
                      fontWeight={'bold'}
                      color='primary'
                      textAlign={'center'}
                    >
                      {props.limit > 0 ? 'Subí archivos' : 'Subí un archivo'}
                    </chakra.span>
                  </chakra.label>
                </Box>
                <chakra.p fontSize='xs' color='gray.400'>
                  {supportedMessage}
                </chakra.p>
              </Box>
            </chakra.label>
          </chakra.div>
          {props.limit > 0 && (
            <chakra.p mt={2} fontSize='sm' color='gray.500'>
              Máximo {props.limit} archivo(s)
            </chakra.p>
          )}
        </>
      )}

      <Box mt='4'>
        {props.fileList.length > 0 && (
          <FileListDisplayerV2
            readonly={props.readonly}
            onUpdateFile={props.onUpdateFile}
            withoutTags={props.withoutTags}
            onRemove={props.onRemove}
            files={props.fileList}
            orientation='vertical'
          />
        )}
      </Box>
    </Box>
  )
}

export const FileUploadInputClickable = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const toast = useToast()

  return (
    <chakra.input
      {...buildTestId('file-upload-input')}
      ref={inputRef}
      onChange={(e) => {
        ;(() => {
          if (!e.target.files?.length || !e.target.files) {
            return
          }

          const files = getFirstNElements(
            new Array(e.target.files.length)
              .fill(null)
              .map((_, i) => (e.target.files as FileList)[i]),
            props.limit - props.fileList.length,
          )

          if (!files.length) {
            return
          }

          const whitelistedFiles: File[] = []

          for (let i = 0; i < files.length; i++) {
            const file = files[i]

            const alreadyExists = props.fileList.some(
              (fileListEle) => fileListEle.name === file.name,
            )

            if (alreadyExists) {
              toast({
                title: `El archivo ${file.name} ya se encuentra en la lista`,
                status: 'error',
              })
              continue
            }

            if (
              props.mimetypes &&
              props.mimetypes.length > 0 &&
              !props.mimetypes.includes(file.type)
            ) {
              toast({
                title: `El archivo ${file.name} no es un archivo válido`,
                status: 'error',
              })
              continue
            }

            if (file.size > MBToBytes(props.maxFileSizeMB)) {
              toast({
                title: `El archivo ${
                  file.name
                } supera el tamaño máximo permitido de ${bytesToMB(
                  props.maxFileSizeMB,
                )}MB`,
                status: 'error',
              })
              continue
            }

            whitelistedFiles.push(file)
          }

          if (!whitelistedFiles.length) {
            return
          }

          props.onUpload(whitelistedFiles)
        })()

        if (inputRef.current) {
          inputRef.current.value = ''
        }
      }}
      disabled={props.fileList.length >= props.limit}
      multiple
      id={props.inputId}
      name='file-upload'
      srOnly
      type='file'
    />
  )
}

export const FileUploadV2 = (props: Props) => {
  return <FileUploadInput {...props} />
}
