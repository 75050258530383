import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  Column,
  useTable,
  useRowSelect,
  TableInstance,
  PluginHook,
} from 'react-table'
import { IFilter } from './TableAbstract'

export const CustomTable = <T extends object>(
  props: React.PropsWithChildren<{
    customUseTable?: TableInstance<T>
    sort?: IFilter[]
    keepLastRowClicked?: boolean
    onClickRow?: (row: T) => void
    onHoverRowIn?: (row: T) => void
    onHoverRowOut?: () => void
    columns: Column<T>[]
    data: T[]
    orientation?: 'left' | 'center' | 'right'
    noHover?: boolean
    onClickHeader?: (headerId: string) => void
    clickableHeaders?: string[]
    hooks?: PluginHook<T>[]
    setSelectedFlatRows?: (selectedFlatRows: T[]) => void
    initialSelectedFlatRows?: T[]
  }>,
) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } =
    //@ts-ignore
    props?.customUseTable ||
    useTable(
      {
        columns: props.columns,
        data: props.data,
        initialState: {
          selectedRowIds: (props.initialSelectedFlatRows || []).map(
            (row) => row.rowId,
          ),
        },
      },
      ...(props.hooks || []),
    )

  useEffect(() => {
    if (props.setSelectedFlatRows) {
      props.setSelectedFlatRows(
        (selectedFlatRows || []).map((row) => ({
          id: row.original.id,
          rowId: row.id,
        })),
      )
    }
  }, [selectedFlatRows])

  const [rowClicked, setRowClicked] = useState<number>()

  const findSortFilter = (headerId: string) =>
    props.sort?.find((s) => s.column === headerId && s.type === 'sort')

  return (
    <TableContainer w='full' h='full' borderBottom='1px' borderColor='gray.100'>
      <Table {...getTableProps()} size='md'>
        <Thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const sortFilter = findSortFilter(column.id)

                  return (
                    <Th
                      {...(props.onClickHeader &&
                      (props.clickableHeaders || [])?.includes(column.id)
                        ? {
                            cursor: 'pointer',
                          }
                        : {})}
                      align={props.orientation}
                      scope='col'
                      py={3}
                      onClick={() => {
                        if (
                          props.onClickHeader &&
                          (props.clickableHeaders || [])?.includes(column.id)
                        ) {
                          props.onClickHeader(column.id)
                        }
                      }}
                      {...column.getHeaderProps()}
                    >
                      <span>
                        {sortFilter
                          ? sortFilter.value === 'DESC'
                            ? '▼'
                            : '▲'
                          : ''}
                      </span>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </Th>
                  )
                })}
              </Tr>
            ))
          }
        </Thead>
        {/* Apply the table body props */}
        <Tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <Tr
                  {...row.getRowProps()}
                  onMouseEnter={() => {
                    if (props.onHoverRowIn) {
                      props.onHoverRowIn(row.original)
                    }
                  }}
                  onMouseLeave={() => {
                    if (props.onHoverRowOut) {
                      props.onHoverRowOut()
                    }
                  }}
                  onClick={() => {
                    if (props.onClickRow) {
                      setRowClicked(row.index)
                      props.onClickRow(row.original)
                    }
                  }}
                  px={3}
                  py={4}
                  fontSize='xs'
                  color='gray.500'
                  bg={rowClicked === row.index ? 'gray.50' : 'transparent'}
                  borderLeft='2px'
                  borderColor='transparent'
                  _hover={
                    props.noHover
                      ? {}
                      : {
                          bg: 'gray.50',
                          cursor: 'pointer',
                          color: 'gray.600',
                          borderLeft: '2px',
                          borderColor: 'brand.500',
                        }
                  }
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, index) => {
                      if (index === 0)
                        return (
                          <Td
                            {...cell.getCellProps()}
                            style={cell.column.styles}
                            fontWeight={500}
                            fontSize='xs'
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </Td>
                        )
                      return (
                        <Td
                          fontWeight='400'
                          fontSize='xs'
                          style={cell.column.styles}
                          {...cell.getCellProps()}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </Td>
                      )
                    })
                  }
                </Tr>
              )
            })
          }
        </Tbody>
      </Table>
      {!rows.length && (
        <Box p={4}>
          <Text fontSize='sm' color='gray.600' textAlign='center'>
            Ningún registro hasta el momento
          </Text>
        </Box>
      )}
    </TableContainer>
  )
}
