import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  chakra,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import {
  Path,
  UseControllerReturn,
  UseFormWatch,
  useForm,
} from 'react-hook-form'
import {
  ISsnPersonSchema,
  SsnPersonInvolvedType,
  SsnPersonSchema,
  ssnLawyerType,
  ssnPersonInvolvedGroups,
  ssnPersonInvolvedType,
} from '../schemas/ssn.schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { translator } from 'kach-commons'
import { ICaseExtended, SsnService } from 'kach-clients'
import { useError } from '../hooks/useError'
import { useQueryClient } from '@tanstack/react-query'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import get from 'lodash.get'
import { useCompanyEnums } from '../hooks/useCompanyEnums'
import { CASES_QUERY_KEY } from '../constants/query-keys'
import { z } from 'zod'

type FormData = z.infer<typeof SsnPersonSchema>

export type SsnPersonFormData = FormData

export const SsnPersonForm = <T extends z.Schema<FormData>>({
  groupName,
  onSubmit,
  defaultValues,
  isLoading,
  schema = SsnPersonSchema,
  children,
  requiredFields = [],
  readonly = false,
  withSubmit = true,
}: {
  defaultValues?: Partial<SsnPersonFormData>
  onSubmit: (data: FormData) => void
  groupName: string
  isLoading?: boolean
  schema: T
  children?: React.FC<{
    control: UseControllerReturn<z.infer<T>>
    watch: UseFormWatch<z.infer<T>>
  }>
  requiredFields?: Array<Path<z.infer<T>>>
  readonly?: boolean
  withSubmit?: boolean
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    control,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const { data } = useCompanyEnums()

  const queryClient = useQueryClient()

  const error = useError()

  const fields = watch()

  const toast = useToast()

  const personTypes = ssnPersonInvolvedGroups.find(
    (group) => group.name === groupName,
  )

  const formId = 'ssn-person-form'

  console.log('readonly', readonly, 'errors', errors)

  return (
    <chakra.form
      onSubmit={handleSubmit(onSubmit)}
      id={formId}
      experimental_spaceY={5}
    >
      <HStack>
        <FormControl isInvalid={!!errors.type}>
          <FormLabel fontSize={'sm'}>{translator('ssnType')}</FormLabel>
          <Select
            {...register('type')}
            onChange={(e) => {
              reset(
                {
                  person: {
                    firstName: '',
                    lastName: '',
                    documentNumber: '',
                    ein: '',
                    address: '',
                    state: '',
                    city: '',
                    zipCode: '',
                    enrollment: '',
                  },
                },
                { keepDefaultValues: false },
              )
              setValue('type', e.target.value)
            }}
            isDisabled={readonly}
          >
            {personTypes?.types &&
              personTypes.types.map((t, i) => {
                return (
                  <option key={i} value={t}>
                    {translator(t)}
                  </option>
                )
              })}
            {errors.type && (
              <FormErrorMessage>{errors.type.message}</FormErrorMessage>
            )}
          </Select>
        </FormControl>
        {groupName === 'lawyer' && (
          <FormControl isInvalid={!!errors?.lawyerType} isRequired>
            <FormLabel fontSize={'sm'}>{translator('lawyerType')}</FormLabel>
            <Select
              {...register('lawyerType')}
              isDisabled={readonly}
              placeholder='Seleccionar Abogado de parte'
            >
              {ssnLawyerType.map((type, i) => {
                return (
                  <option key={i} value={type}>
                    {translator(type)}
                  </option>
                )
              })}
              {errors?.person?.documentType && (
                <FormErrorMessage>
                  {errors.person.documentType.message}
                </FormErrorMessage>
              )}
            </Select>
          </FormControl>
        )}

        {groupName === 'professional' && (
          <FormControl isInvalid={!!errors?.profession} isRequired>
            <FormLabel fontSize={'sm'}>{translator('profession')}</FormLabel>
            <Select
              {...register('profession')}
              isDisabled={readonly}
              placeholder='Seleccionar Profesión'
            >
              {data?.ssnProfessionals.map((p) => {
                return (
                  <option key={p.id} value={p.name}>
                    {p.name}
                  </option>
                )
              })}
              {errors?.profession && (
                <FormErrorMessage>{errors.profession.message}</FormErrorMessage>
              )}
            </Select>
          </FormControl>
        )}
      </HStack>

      <HStack>
        <FormControl
          key={fields.type}
          isInvalid={!!errors?.person?.firstName}
          isRequired
        >
          <FormLabel fontSize={'sm'}>{translator('firstName')}</FormLabel>
          <Input
            {...register('person.firstName')}
            type='string'
            isReadOnly={readonly}
          />
          {errors?.person?.firstName && (
            <FormErrorMessage>
              {errors.person.firstName.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors?.person?.lastName} isRequired>
          <FormLabel fontSize={'sm'}>{translator('lastName')}</FormLabel>
          <Input
            {...register('person.lastName')}
            type='string'
            isReadOnly={readonly}
          />
          {errors?.person?.lastName && (
            <FormErrorMessage>
              {errors.person.lastName.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </HStack>

      <Divider />
      <Heading size={'6xl'}>Identificación</Heading>

      <HStack>
        <FormControl
          isRequired={requiredFields.includes('person.documentType')}
          isInvalid={!!errors?.person?.documentType}
        >
          <FormLabel fontSize={'sm'}>{translator('documentType')}</FormLabel>
          <Select
            isRequired={requiredFields.includes('person.documentType')}
            {...register('person.documentType')}
            isReadOnly={readonly}
          >
            {
              <option key={'dni'} value={'dni'}>
                DNI
              </option>
            }
            {errors?.person?.documentType && (
              <FormErrorMessage>
                {errors.person.documentType.message}
              </FormErrorMessage>
            )}
          </Select>
        </FormControl>
        <FormControl
          isRequired={requiredFields.includes('person.documentNumber')}
          isInvalid={!!errors?.person?.documentNumber}
        >
          <FormLabel fontSize={'sm'}>{translator('documentNumber')}</FormLabel>
          <Input
            {...register('person.documentNumber')}
            type='string'
            isReadOnly={readonly}
          />
          {errors?.person?.documentNumber && (
            <FormErrorMessage>
              {errors.person.documentNumber.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors?.person?.ein}>
          <FormLabel fontSize={'sm'}>{translator('ein')}</FormLabel>
          <Input
            {...register('person.ein')}
            type='string'
            isReadOnly={readonly}
          />
          {errors?.person?.ein && (
            <FormErrorMessage>{errors.person.ein.message}</FormErrorMessage>
          )}
        </FormControl>

        {groupName === 'lawyer' && (
          <FormControl isInvalid={!!errors?.person?.enrollment}>
            <FormLabel fontSize={'sm'}>{translator('enrollment')}</FormLabel>
            <Input
              {...register('person.enrollment')}
              type='string'
              isReadOnly={readonly}
            />
            {errors?.person?.enrollment && (
              <FormErrorMessage>
                {errors.person.enrollment.message}
              </FormErrorMessage>
            )}
          </FormControl>
        )}
      </HStack>

      <Divider />
      <Heading size={'6xl'}>Dirección</Heading>

      <HStack>
        <FormControl isInvalid={!!errors?.person?.address}>
          <FormLabel fontSize={'sm'}>{translator('address')}</FormLabel>
          <Input
            {...register('person.address')}
            type='string'
            isReadOnly={readonly}
          />
          {errors?.person?.address && (
            <FormErrorMessage>{errors.person.address.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors?.person?.state}>
          <FormLabel fontSize={'sm'}>{translator('state')}</FormLabel>
          <Input
            {...register('person.state')}
            type='string'
            isReadOnly={readonly}
          />
          {errors?.person?.state && (
            <FormErrorMessage>{errors.person.state.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors?.person?.city}>
          <FormLabel fontSize={'sm'}>{translator('city')}</FormLabel>
          <Input
            {...register('person.city')}
            type='string'
            isReadOnly={readonly}
          />
          {errors?.person?.city && (
            <FormErrorMessage>{errors.person.city.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors?.person?.zipCode}>
          <FormLabel fontSize={'sm'}>{translator('zipCode')}</FormLabel>
          <Input
            {...register('person.zipCode')}
            type='string'
            isReadOnly={readonly}
          />
          {errors?.person?.zipCode && (
            <FormErrorMessage>{errors.person.zipCode.message}</FormErrorMessage>
          )}
        </FormControl>
      </HStack>

      {children && typeof children === 'function' ? (
        <>
          {children({
            control,
            watch,
          })}{' '}
        </>
      ) : undefined}

      {withSubmit && (
        <Box>
          <Button
            form={formId}
            type='submit'
            size='sm'
            isDisabled={isLoading}
            isLoading={isLoading}
            variant={'primary'}
          >
            Guardar
          </Button>
        </Box>
      )}
    </chakra.form>
  )
}

export const CreateSsnPersonForm = (props: {
  ssnId: number
  caseId: number
  onClose: () => void
  groupName: string
  defaultValues?: ISsnPersonSchema
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
  } = useForm<ISsnPersonSchema>({
    resolver: zodResolver(SsnPersonSchema),
    defaultValues: {
      ...props.defaultValues,
    },
  })

  const { data } = useCompanyEnums()

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClient()

  const error = useError()

  const fields = watch()

  const toast = useToast()

  const onSubmit = useCallback(
    (data: ISsnPersonSchema) => {
      setIsLoading(true)

      toast.promise(
        SsnService.addPerson(props.ssnId, {
          type: data.type,
          profession: data?.profession,
          lawyerType: data?.lawyerType,
          ...data.person,
        }),
        {
          loading: {
            title: 'Creando persona...',
          },
          success: () => {
            props.onClose()
            queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
            queryClient.invalidateQueries([CASES_QUERY_KEY])
            setIsLoading(false)
            return { title: 'Persona creada correctamente' }
          },
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
        },
      )
    },
    [props.ssnId, props.caseId],
  )

  console.log('errors', errors)

  const personTypes = ssnPersonInvolvedGroups.find(
    (group) => group.name === props.groupName,
  )

  const formId = 'ssn-person-form'

  return (
    <SsnPersonForm
      isLoading={isLoading}
      groupName={props.groupName}
      onSubmit={onSubmit}
      defaultValues={props.defaultValues}
    />
  )
}

export const CreateSsnPerson = (props: {
  isOpen: boolean
  onClose: () => void
  ssnId: number
  caseId: number
  groupName: string
  case: ICaseExtended
}) => {
  const defaultValues = () => {
    if (props.groupName === 'insuredCustomer') {
      if (!props.case.customer) {
        return
      }

      return {
        type: 'insured',
        person: {
          ...props.case.customer,
          ein: props.case.customer?.cuil,
          documentNumber: props.case.customer?.identification,
        },
      }
    }

    return
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size={'4xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crear Participante</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateSsnPersonForm
            ssnId={props.ssnId}
            caseId={props.caseId}
            onClose={props.onClose}
            groupName={props.groupName}
            defaultValues={defaultValues()}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
