import { Button, useToast } from '@chakra-ui/react'
import { CaseClient } from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { MdOutlineRestore } from 'react-icons/md'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildCaseQueryKey } from '../utils/build-case-query-key'

interface Props {
  caseId: number
  artifactId: number
}

const RestoreArtifactButtonContent = ({ artifactId, caseId }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const { handleError } = useError()

  const handleRestore = useCallback(() => {
    setIsLoading(true)

    CaseClient.restoreCaseArtifactRemoved(caseId, artifactId)
      .then(() => {
        queryClient.invalidateQueries([buildCaseQueryKey(caseId)])

        toast({
          title: 'Archivo restaurado',
          description: 'El archivo fue restaurado con éxito',
          isClosable: true,
          status: 'success',
        })
      })
      .finally(() => setIsLoading(false))
      .catch(handleError)
  }, [caseId, artifactId])

  return (
    <Button
      isLoading={isLoading}
      onClick={handleRestore}
      variant={'outline'}
      size='xs'
      leftIcon={<MdOutlineRestore />}
      mb='1'
    >
      Restaurar
    </Button>
  )
}

export const RestoreArtifactButton = (props: Props) => {
  return <RestoreArtifactButtonContent {...props} />
}
