import * as React from 'react'
import { IntegrationDetailWrapper } from './IntegrationDetailWrapper'
import { AiOutlinePlus } from 'react-icons/ai'
import { useIntegrationConnectionCredentials } from '../hooks/useIntegrationConnectionCredentials'
import { useAuth } from '../hooks/useAuth'
import { Box, Button, chakra, Icon, Text } from '@chakra-ui/react'
import { buildTestId } from 'kach-commons'
import { FolderPlusIcon } from '@heroicons/react/24/outline'
import { hasPermission } from '../utils/has-permission'
import { IIntegration } from 'kach-clients'

const EmptyState = (props: { integration: IIntegration }) => {
  const auth = useAuth()

  const hasPermissionToConnect = () => {
    return hasPermission(auth.role.permissions, {
      resource:
        props.integration.name === 'autoinspector'
          ? 'autoinspector-integration'
          : 'integration',
      action: 'update',
    })
  }

  return (
    <Box textAlign={'center'} mt='10'>
      <Icon as={FolderPlusIcon} w='12' h='12' color='gray.400' />
      <chakra.h3 mt='2' fontSize='sm' fontWeight='medium' color='gray.900'>
        Integración no activada
      </chakra.h3>

      <Text mt='1' fontSize='sm' color='gray.500'>
        Empieza realizando la conexión con esta integración
      </Text>
      <Box mt='6'>
        {hasPermissionToConnect() && (
          <a target={'_self'} href={props.integration.oauthURL}>
            <Button
              {...buildTestId('connect-oauth-btn')}
              size='md'
              variant='primary'
              leftIcon={<AiOutlinePlus fontSize={'10px'} aria-hidden='true' />}
              type='button'
            >
              Conectar mi cuenta de {props.integration.name}
            </Button>
          </a>
        )}
      </Box>
    </Box>
  )
}

export const IntegrationConnectionOAuth = (props: {
  integration: IIntegration
  onBack: () => void
}) => {
  const auth = useAuth()

  const { isLoading, integrationConnection } =
    useIntegrationConnectionCredentials(props.integration.name)

  return (
    <IntegrationDetailWrapper
      integrationConnection={integrationConnection}
      isLoading={isLoading}
      integration={props.integration}
      onBack={props.onBack}
    >
      {!integrationConnection ? (
        <EmptyState integration={props.integration} />
      ) : null}
    </IntegrationDetailWrapper>
  )
}
