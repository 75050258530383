import React from 'react';
import { Box, Divider, Heading, Text } from '@chakra-ui/react';

export const StatLayout = (props: {
  label: string;
  children?: React.ReactNode;
}) => {
  return (
    <Box
      shadow={'lg'}
      rounded={'lg'}
      borderColor={'gray.100'}
      borderWidth={'1px'}
      p={5}
    >
      <Heading fontSize={'lg'}>{props.label}</Heading>
      <Divider mt="2" mb="4" />
      {props.children}
    </Box>
  );
};
