import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { ISession, SessionClient } from 'kach-clients'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CaseTimeline } from './CaseTimeline'
import { PaginationFooter } from './PaginationFooter'
import { useQuerySingleton } from '../hooks/useQuerySingleton'

const Body = (props: { session: ISession }) => {
  const [page, setPage] = useState<number>(1)

  const { isLoading, data } = useQuerySingleton(
    ['session-events', page],
    (ctx) => {
      const [_, page] = ctx.queryKey

      return SessionClient.listSessionEvents(props.session.id, {
        limit: 10,
        page: page as number,
      })
    },
    {
      refetchOnMount: true,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  )

  if (isLoading || !data) {
    return <Spinner />
  }

  return (
    <Box>
      {!data?.results.length ? (
        <Text>No hay eventos</Text>
      ) : (
        <CaseTimeline isSessionActivity events={data?.results} />
      )}

      <PaginationFooter paginationData={data} page={page} setPage={setPage} />
    </Box>
  )
}

export const SessionEventsModal = (props: {
  isOpen: boolean
  onClose: () => void
  session: ISession
}) => {
  return (
    <Modal size='4xl' isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Eventos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Body session={props.session} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
