import {
  Badge,
  Box,
  Button,
  chakra,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
} from '@chakra-ui/react'
import { BellIcon } from '@heroicons/react/24/outline'
import {
  INotification,
  INotificationFilter,
  IPagination,
  NotificationClient,
} from 'kach-clients'
import React, { useCallback, useContext, useState } from 'react'
import { FaBell } from 'react-icons/fa'
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  NOTIFICATIONS_PAGINATION_QUERY_KEY,
  NOTIFICATIONS_QUERY_KEY,
} from '../constants/query-keys'
import { CustomScrollBox } from './CustomScrollBox'
import { NavigatorFC, NotificationList } from './NotificationList'
import { KachContext } from '../providers/KachProvider'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { Select as ChakraReactSelect } from 'chakra-react-select'
import { NotificationEntityType, notificationEntityTypes } from 'kach-commons'
import { translator } from '../utils/translator'
import { useTags } from '../hooks/useTags'
import { NotificationTags } from './NotificationTags'

export interface INotificationsBellPopoverProps {
  Navigator: NavigatorFC
  initialNotifications: IPagination<INotification>
  query: INotificationFilter
}

const NOTIFICATION_LIMITS = 9

export const NotificationsBellPopover = (
  props: INotificationsBellPopoverProps,
) => {
  const { defaultContext } = useContext(KachContext)

  const [tags, setTags] = useState<number[]>([])

  const {
    data,
    error,
    refetch,
    isLoading: isLoadingNotifications,
    isFetching,
  } = useQuerySingleton(
    [NOTIFICATIONS_QUERY_KEY, tags] as const,
    (context) => {
      const [_, tags] = context.queryKey

      return NotificationClient.paginate({
        ...props.query,
        tags: tags as number[],
        filter: 'unread',
      })
    },
    {
      context: defaultContext,
      initialData: props.initialNotifications,
    },
  )

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const onReadNotifications = useCallback(() => {
    setIsLoading(true)
    NotificationClient.batchRead({
      notificationIds: data?.results.map((r) => r.id),
    })
      .then(() => {
        queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY])
        queryClient.invalidateQueries([NOTIFICATIONS_PAGINATION_QUERY_KEY])
      })
      .finally(() => setIsLoading(false))
  }, [data])

  if (!data) {
    return null
  }

  const notificatiosLength = data.results?.length

  const notificationLabel =
    notificatiosLength > NOTIFICATION_LIMITS
      ? `${NOTIFICATION_LIMITS}+`
      : notificatiosLength

  return (
    <Popover placement='bottom-end' isLazy>
      <PopoverTrigger>
        <Button
          fontSize={'lg'}
          aria-label='notification-bell'
          cursor='pointer'
          borderRadius='full'
          variant='ghost'
        >
          <Box position={'relative'}>
            <Icon
              position='absolute'
              top='-12px'
              right='-12px'
              as={BellIcon}
              w='6'
              h='6'
            />
            <Box pos='absolute' left={'-2px'} top={'-18px'}>
              {notificatiosLength > 0 && (
                <Badge
                  fontSize={'2xs'}
                  color='white'
                  bg='primary'
                  fontWeight='medium'
                  borderRadius='80%'
                  py={'0.1rem'}
                >
                  {notificationLabel}
                </Badge>
              )}
            </Box>
          </Box>
        </Button>
      </PopoverTrigger>
      <PopoverContent maxW={'lg'} width='unset'>
        <PopoverArrow />

        <PopoverHeader>
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            flexDir='row'
            alignItems={'center'}
          >
            <Heading fontWeight={'bold'} fontSize={'lg'}>
              Notificaciones
            </Heading>
            {data?.results.length > 0 && (
              <Button
                isDisabled={isLoading}
                disabled={isLoading}
                onClick={onReadNotifications}
                fontWeight={'normal'}
                variant={'unstyled'}
                size='xs'
              >
                Marcar todo como leído
              </Button>
            )}
          </Box>
          <Box mt='3' maxW='2xl'>
            <NotificationTags onChangeTagsFilter={setTags} />
          </Box>
        </PopoverHeader>

        <PopoverBody>
          <CustomScrollBox>
            <NotificationList
              Navigator={props.Navigator}
              notifications={data.results}
              listStyles={{
                width: 'full',
                maxH: '300px',
                minW: '478px',
                pr: 4,
              }}
              isLoading={isLoading}
            />
          </CustomScrollBox>
        </PopoverBody>
        <PopoverFooter textAlign={'center'}>
          <props.Navigator href={'/notifications?filter='}>
            <chakra.span fontSize={'xs'}>
              Ver todas las notificaciones
            </chakra.span>
          </props.Navigator>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}
