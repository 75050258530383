import {
  columnNames,
  conditionTypes,
  conditionOperators,
  referenceTables,
} from 'kach-constants';
import * as z from 'zod';

export const conditionLine = z
  .object({
    columnName: z.enum(columnNames),
    type: z.enum(conditionTypes),
    operator: z.enum(conditionOperators),
    value: z.string().optional(),
    referenceTable: z.enum(referenceTables).optional(),
    referenceId: z
      .string()
      .or(z.number())
      .transform((v) => Number(v))
      .optional(),
  })
  .refine(
    (v) => {
      if (v.type === 'reference' && (!v.referenceId || !v.referenceTable)) {
        return false;
      }

      return true;
    },
    {
      path: ['referenceId'],
      message: 'Debe seleccionar una opción',
    }
  );

export const conditionsSchema = z.object({
  conditions: z
    .array(conditionLine)
    .min(1, 'Debe agregar al menos una condición'),
});

export type ConditionLineSchema = z.infer<typeof conditionsSchema>;
