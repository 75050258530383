import React from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { TableHeaderWrapper } from './TableHeaderWrapper';

export const DateTableHeader: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <TableHeaderWrapper>
      <BiTimeFive />
      <span>{children}</span>
    </TableHeaderWrapper>
  );
};
