import { z } from 'zod'
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema'
import { documentType } from 'kach-constants'

export const ContactSchema = z
  .object({
    responsible: z.object({
      firstName: z.string().nullable().optional(),
      lastName: z.string().nullable().optional(),
      identification: z
        .string()
        .nullable()
        .optional()
        .transform((val) => (!val ? undefined : val)),
      documentType: z.enum(documentType).optional(),
      documentNumber: z.string().optional(),
      enrollment: z.string().nullable().optional(),
    }),
    email: z.string().email('Email inválido').min(1, REQUIRED_FIELD_MESSAGE),
    name: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    alias: z.string().optional().nullable(),
    cellphone: z.string().nullable().optional(),
    address: z.string().nullable().optional(),
    zipCode: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    state: z.string().nullable().optional(),
    priceGroupId: z
      .string()
      .min(1, REQUIRED_FIELD_MESSAGE)
      .transform((v) => {
        if (!v) {
          return v
        }

        return Number(v)
      }),
    providerNumber: z.string().nullable().optional(),
    investigationTypesSupported: z
      .array(z.enum(['f2f', 'virtual']))
      .nullable()
      .nullish(),
    sinisterTypesSupported: z
      .array(
        z.object({
          id: z.number(),
        }),
      )
      .nullable()
      .nullish(),
    cellphones: z.array(
      z.object({
        cellphone: z.string(),
        reference: z.string(),
      }),
    ),
  })
  .refine(
    (data) => {
      if (!data?.responsible) {
        return true
      }

      if (!data.responsible?.documentNumber) {
        return true
      }

      if (data.responsible.documentNumber.length === 8) {
        return true
      }

      return false
    },
    {
      path: ['responsible.documentNumber'],
      message: 'Numero de documento debe ser de 8 caracteres',
    },
  )
  .refine(
    (data) => {
      if (data.state && !data.country) {
        return false
      }

      return true
    },
    {
      path: ['state'],
      message: 'Para definir el país tienes que definir el estado',
    },
  )

export interface IContactSchema extends z.infer<typeof ContactSchema> {}
