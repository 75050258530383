import { Text, Tooltip, Avatar, Box } from '@chakra-ui/react'
import { IUser } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { buildUserName } from '../utils/build-user-name'
import { ManageCaseUsers } from './ManageCaseUsers'

export const UserListDisplayer = (props: {
  users: IUser[]
  emptyStateLabel?: string
}) => {
  return (
    <Box display='flex' alignItems='center' experimental_spaceX={2}>
      {props.users.length > 0 ? (
        props.users.map((user, index) => {
          return (
            <Tooltip key={index} label={buildUserName(user)}>
              <Avatar
                {...buildTestId('case-collaborator-avatar')}
                referrerPolicy='no-referrer'
                name={buildUserName(user)}
                src={user.profilePhoto}
                size='xs'
                bg='primary'
              />
            </Tooltip>
          )
        })
      ) : (
        <Text>{props.emptyStateLabel || 'No hay usuarios'}</Text>
      )}
    </Box>
  )
}
