import {
  Box,
  Button,
  chakra,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  SkeletonText,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  AuthorizationClient,
  IPermission,
  IRolMinimized,
  IUserRole,
} from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Cell, Column } from 'react-table'
import { useError } from '../hooks/useError'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { CreateRoleSchema, ICreateRoleSchema } from '../schemas/role.schema'
import { CustomTable } from './CustomTable'

export interface ILocalPermission {
  label: string
  permissions: Pick<IPermission, 'action' | 'resource'>[]
}

export interface IRoleSaved extends Pick<IUserRole<{ id: number }>, 'name'> {
  permissions: Pick<IPermission, 'id'>[]
}

export const RolePermissions: React.FC<
  Partial<{
    onlyTable: boolean
    defaultValues?: IRolMinimized
    buttonLabel: string
    onRoleSave: (role: IRoleSaved) => Promise<void>
    onRoleUpdate: (role: IRoleSaved) => Promise<void>
    readonly: boolean
    onCancel: () => void
  }> & {
    permissionsToRender: ILocalPermission[]
  }
> = ({
  onlyTable,
  onRoleSave,
  onRoleUpdate,
  buttonLabel,
  readonly,
  defaultValues,
  onCancel,
  permissionsToRender,
}) => {
  const {
    setValue,
    reset,
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<ICreateRoleSchema>({
    resolver: zodResolver(CreateRoleSchema),
  })

  const fields = watch()

  const { isLoading, data } = useQuerySingleton(
    ['permissions'],
    () => AuthorizationClient.listPermissions(),
    {
      refetchOnMount: true,
      retry: 1,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  )

  const encode = useCallback(
    (rol: IRolMinimized): ICreateRoleSchema['permissions'] => {
      const permissionsFiltered: boolean[] = []

      for (let i = 0; i < permissionsToRender.length; i++) {
        const localPermission = permissionsToRender[i]

        const metPermissions = localPermission.permissions.every((want) =>
          rol.permissions.find(
            (has) =>
              has.action === want.action && has.resource === want.resource,
          ),
        )

        permissionsFiltered.push(metPermissions)
      }

      return permissionsFiltered
    },
    [],
  )

  const decode = useCallback(
    (input: ICreateRoleSchema): IRoleSaved => {
      const permissions: IRoleSaved['permissions'] = []

      for (let i = 0; i < input.permissions.length; i++) {
        const selected = input.permissions[i]

        if (!selected) continue

        const localPermission = permissionsToRender[i]

        permissions.push(
          ...localPermission.permissions.map((permission) => {
            const found = data.find(
              (d) =>
                d.action === permission.action &&
                d.resource === permission.resource,
            )

            return {
              id: found.id,
            }
          }),
        )
      }

      const unique: typeof permissions = []

      permissions.forEach((permission) => {
        const alreadyInList = unique.find(
          (stored) => stored.id === permission.id,
        )

        if (!alreadyInList) {
          unique.push(permission)
        }
      })

      return {
        name: input.name,
        permissions: unique,
      }
    },
    [data],
  )

  useEffect(() => {
    if (defaultValues) {
      setValue('permissions', encode(defaultValues))
      setValue('name', defaultValues.name)
    }
  }, [defaultValues])

  const [isSaving, setIsSaving] = useState(false)

  const error = useError()

  const onSubmit = useCallback(
    async (form: ICreateRoleSchema) => {
      const decoded = decode(form)

      if (onRoleSave) {
        setIsSaving(true)

        onRoleSave(decoded)
          .then(() => reset())
          .finally(() => setIsSaving(false))
          .catch(error.handleError)
      }

      if (onRoleUpdate) {
        setIsSaving(true)

        onRoleUpdate(decoded)
          .then(() => reset())
          .finally(() => setIsSaving(false))
          .catch(error.handleError)
      }
    },
    [data],
  )

  const columns = useMemo<Column<ILocalPermission>[]>(
    () => [
      {
        Header: 'Permiso',
        accessor: (row) => row.label,
      },
      {
        id: 'action',
        //@ts-ignore
        Cell: (cell: Cell<ILocalPermission>) => {
          return (
            <Checkbox
              {...register(`permissions.${cell.row.index}`)}
              transform='none'
              transition={'none'}
              readOnly={readonly}
              isDisabled={readonly}
              aria-describedby='comments-description'
              colorScheme='brandFull'
            />
          )
        },
      },
    ],
    [fields.permissions],
  )

  const Table = (
    <Box {...buildTestId('role-permissions-table')}>
      <CustomTable
        orientation='left'
        columns={columns}
        data={permissionsToRender}
      />
    </Box>
  )

  if (isLoading) {
    return (
      <Box padding='6' bg='white'>
        <SkeletonText mt='4' noOfLines={4} spacing='4' />
      </Box>
    )
  }

  if (onlyTable) {
    return Table
  }

  return (
    <chakra.form
      id='create-role-form'
      display='flex'
      flexDir='column'
      experimental_spaceY={4}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <FormControl mb={4}>
          <FormLabel>Nombre del rol</FormLabel>
          <Input
            type='text'
            isReadOnly={readonly}
            isDisabled={readonly}
            {...register('name')}
            isInvalid={!!errors['name']?.message}
          />
        </FormControl>

        <Box mt={4}>{Table}</Box>
      </Box>
      {buttonLabel && (
        <Button
          {...buildTestId('create-role-dialog-btn')}
          form='create-role-form'
          variant='primary'
          isDisabled={readonly || isSaving}
          type='submit'
          size='sm'
        >
          {buttonLabel}
        </Button>
      )}
    </chakra.form>
  )
}
