import { ChakraStylesConfig, Select } from 'chakra-react-select'
import {
  CaseResourceColumns,
  VirtualViewResources,
  caseResourceColumns,
} from 'kach-constants'
import React from 'react'
import { Column } from 'react-table'
import { ITableColumnsController } from '../hooks/useTableColumns'
import {
  Box,
  Button,
  Checkbox,
  Container,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { BsColumns, BsReverseListColumnsReverse } from 'react-icons/bs'
import { BiColumns } from 'react-icons/bi'
import { translator } from 'kach-commons'

export const TableColumns = (props: {
  columnsController: ITableColumnsController
}) => {
  const options = props.columnsController?.resourceColumns().map((c) => {
    return {
      label: translator(c),
      value: c,
    }
  })

  const selectedValues = options
    .map((option) => {
      const isSelected = props.columnsController.columns.some(
        (c) => c.id === option.value,
      )

      if (!isSelected) {
        return
      }

      return option.value
    })
    .filter((values) => values) as string[]

  return (
    <Box display={'flex'} marginLeft={'2'}>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          size='xs'
          leftIcon={<Icon as={BiColumns} />}
          fontWeight='medium'
        >
          Columnas
        </MenuButton>

        <MenuList>
          <MenuOptionGroup
            value={selectedValues}
            onChange={(v) => {
              props.columnsController.setColumns(v)
            }}
            title='Seleccionar Columnas'
            type='checkbox'
          >
            {options.map((option) => {
              return (
                <MenuItemOption value={option.value} key={option.value}>
                  {translator(option.label)}
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  )
}
