import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export const FetchErrorMessage = () => {
  return (
    <Box p={4}>
      <Text fontSize="xs">Algo no ha salido como lo esperado</Text>
    </Box>
  );
};
