import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  chakra,
  Heading,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverContentProps,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  StackDivider,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  CaseClient,
  ICaseExtended,
  ICompanyEnums,
  UpdateCaseType,
} from 'kach-clients'
import { buildTestId, translator } from 'kach-commons'
import { activeInvestigationStatus } from 'kach-constants'
import React, { useCallback, useState } from 'react'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { CASES_QUERY_KEY } from '../constants/query-keys'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { formatAmount } from '../utils/format-amount'

const CloseCaseAlertDialog = (props: {
  isOpen: boolean
  onClose: () => void
  case: ICaseExtended
  onCloseCase: () => void
}) => {
  const cancelRef = React.useRef()

  const activeInvestigations = props.case.investigations?.filter(
    (investigation) => activeInvestigationStatus.includes(investigation.status),
  )

  const requiredProps: {
    key: 'ssn' | 'result' | 'acceptedReport' | 'activeInvestigation'
    header: string
    subHeader?: JSX.Element
    value: () => boolean
    badge: (value: boolean) => {
      colorScheme: 'green' | 'red' | 'purple' | 'yellow'
      children: string
    }
  }[] = [
    {
      key: 'savingDescription',
      header: 'Desglose ahorro',
      value: () => true,
      badge: () => {
        const value = props.case.savingDescription

        if (value)
          return {
            colorScheme: 'green',
            children: value,
          }

        return {
          colorScheme: 'red',
          children: 'Sin completar',
        }
      },
    },
    {
      key: 'labOverwrittenFormulaResult',
      header: 'Pagado a estudio (Gastos)',
      value: () => true,
      badge: () => {
        const value = props.case.labOverwrittenFormulaResult

        if (value)
          return {
            colorScheme: 'green',
            children: formatAmount(value),
          }

        return {
          colorScheme: 'red',
          children: 'Sin completar',
        }
      },
    },
    {
      key: 'companyOverwrittenFormulaResult',
      header: 'Ahorro compañia',
      value: () => true,
      badge: () => {
        const value = props.case.companyOverwrittenFormulaResult

        if (value)
          return {
            colorScheme: 'green',
            children: formatAmount(value),
          }

        return {
          colorScheme: 'red',
          children: 'Sin completar',
        }
      },
    },
    {
      key: 'result',
      header: 'Resultado',
      value: () => {
        if (!props.case.result) {
          return false
        }

        if (props.case.result === 'without_result') {
          return false
        }

        return true
      },
      badge: (value: boolean) => {
        if (value) {
          return {
            colorScheme: 'green',
            children: translator(props.case.result),
          }
        }

        return {
          colorScheme: 'red',
          children: translator(props.case.result) || 'Sin resultado',
        }
      },
    },
    {
      key: 'ssn',
      header: 'Superintendencia',
      subHeader: <div>Solo casos positivos</div>,
      value: () => {
        if (props.case.result === 'positive' && !props.case.ssn) {
          return false
        }

        return true
      },
      badge: (value: boolean) => {
        if (value) {
          const children =
            props.case.result !== 'positive'
              ? 'No necesario'
              : props.case.ssn?.closeMotive?.name!
          return {
            colorScheme: 'green',
            children: children,
          }
        }

        return {
          colorScheme: 'red',
          children: 'Sin completar',
        }
      },
    },
    {
      key: 'activeInvestigation',
      header: 'Investigaciones activas',
      subHeader: (
        <div>
          Investigación en estado:
          <strong>
            {' '}
            {activeInvestigationStatus
              .map((item) => translator(item))
              .join(', ')}
          </strong>
        </div>
      ),
      value: () => {
        return true
      },
      badge: (value: boolean) => {
        if (activeInvestigations.length) {
          return {
            colorScheme: 'green',
            children: `${activeInvestigations.length} activas`,
          }
        }

        return {
          colorScheme: 'purple',
          children: 'Sin investigaciones activas',
        }
      },
    },
    {
      key: 'acceptedReport',
      header: 'Reporte aceptado',
      value: () => {
        if (activeInvestigations.length) {
          const haveReportAccepted = activeInvestigations?.some(
            (investigation) => investigation.reportAccepted,
          )

          if (!haveReportAccepted) {
            return false
          }

          return true
        }

        return true
      },
      badge: (value: boolean) => {
        if (value) {
          const children = activeInvestigations.length
            ? 'Si'
            : 'Sin investigacions activas'

          return {
            colorScheme: 'green',
            children,
          }
        }

        return {
          colorScheme: 'red',
          children: 'Sin report aceptado',
        }
      },
    },
  ]

  const canCloseCase = requiredProps.every((prop) => prop.value())

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <Box>
            <AlertDialogHeader display={'flex'} fontSize='lg' fontWeight='bold'>
              Cerrar caso
              <Tooltip
                label={
                  'Solo los responsables y administradores pueden cerrar casos'
                }
                placement='top-start'
              >
                <chakra.span ml='2'>
                  <AiFillQuestionCircle />
                </chakra.span>
              </Tooltip>
            </AlertDialogHeader>
          </Box>

          <AlertDialogBody>
            <Card>
              <CardHeader>
                <Heading size='md'>Resumen</Heading>
              </CardHeader>

              <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                  {requiredProps.map((prop) => {
                    const badge = prop.badge(prop.value())
                    return (
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {prop.header}
                        </Heading>
                        {prop.subHeader && (
                          <Text fontSize='xs'>{prop.subHeader}</Text>
                        )}
                        <Badge colorScheme={badge.colorScheme}>
                          {badge.children}
                        </Badge>
                      </Box>
                    )
                  })}
                </Stack>
              </CardBody>
            </Card>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack spacing={'2'}>
              <Button
                isDisabled={!canCloseCase}
                colorScheme='red'
                onClick={props.onCloseCase}
                ml={3}
              >
                Continuar
              </Button>
              <Button ref={cancelRef} onClick={props.onClose}>
                Cancelar
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export const CloseCaseBtn = (props: {
  companyEnums: ICompanyEnums
  case: ICaseExtended
  boxStyles?: PopoverContentProps
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const toast = useToast()

  const onUpdate = useCallback(
    (data: Partial<UpdateCaseType>) => {
      toast.promise(CaseClient.updateCase(props.case.id, data), {
        success: () => {
          queryClient.invalidateQueries([buildCaseQueryKey(props.case.id)])

          queryClient.invalidateQueries([CASES_QUERY_KEY])

          return {
            title: 'Se actualizó el caso',
          }
        },
        error: (err) => error.getErrorMessage(err),
        loading: {
          title: 'Actualizando caso',
        },
      })
    },
    [props.case.id],
  )

  const closeCase = useCallback(() => {
    setIsLoading(true)
    toast.promise(CaseClient.close(props.case.id), {
      loading: { title: 'Cerrando caso...' },
      success: () => {
        queryClient.invalidateQueries([buildCaseQueryKey(props.case.id)])
        queryClient.invalidateQueries([CASES_QUERY_KEY])
        onClose()
        setIsLoading(false)
        return { title: 'Caso cerrado correctamente' }
      },
      error: (err) => {
        setIsLoading(false)
        return error.getErrorMessage(err)
      },
    })
  }, [props.case.id])

  return (
    <>
      {props.case.result === 'negative' ? (
        <Popover>
          {({ onClose }) => {
            return (
              <>
                <PopoverTrigger>
                  <Button
                    variant='primary'
                    isLoading={isLoading}
                    size={'sm'}
                    {...buildTestId('close-case-btn')}
                  >
                    Cerrar caso
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton isDisabled={isLoading} />
                  <PopoverHeader>Endoso</PopoverHeader>
                  <PopoverBody>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      py={4}
                    >
                      ¿Aplica endoso a este caso?
                      <Switch
                        ml={4}
                        onChange={(e) =>
                          onUpdate({ appliesEndorsement: e.target.checked })
                        }
                      />
                    </Box>
                  </PopoverBody>
                  <PopoverFooter>
                    <Button
                      isDisabled={isLoading}
                      onClick={onOpen}
                      size='sm'
                      variant='primary'
                      width='full'
                    >
                      Continuar
                    </Button>
                  </PopoverFooter>
                </PopoverContent>
              </>
            )
          }}
        </Popover>
      ) : (
        <Button
          onClick={onOpen}
          variant='primary'
          isLoading={isLoading}
          size={'sm'}
          {...buildTestId('close-case-btn')}
        >
          Cerrar caso
        </Button>
      )}

      <CloseCaseAlertDialog
        case={props.case}
        isOpen={isOpen}
        onClose={onClose}
        onCloseCase={closeCase}
      />
    </>
  )
}
