import { MenuItem, useToast } from '@chakra-ui/react'
import { IMailAttempt, MailClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback } from 'react'
import { useError } from '../hooks/useError'
import { buildMailQueryKey } from '../utils/build-mail-query-key'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const ExpireMailAttempt = (props: {
  index: number
  attempt: IMailAttempt
  mailId: number
  isCaseClosed: boolean
}) => {
  const error = useError()

  const queryClient = useQueryClientSingleton()

  const canExpire = !!props.attempt.expiredAt || !!props.attempt.receivedAt

  const toast = useToast()

  const onExpireAttempt = useCallback(() => {
    toast.promise(MailClient.expireAttempt(props.attempt.id), {
      error: (err) => {
        title: error.getErrorMessage(err)
      },
      loading: {
        title: 'Expirando intento',
      },
      success: (err) => {
        queryClient.invalidateQueries([buildMailQueryKey(props.mailId)])
        return {
          title: 'Intento expirado correctamente',
        }
      },
    })
  }, [props.attempt.id])

  return (
    <MenuItem
      {...buildTestId(`mail-attempt-${props.index}-expire-btn`)}
      onClick={onExpireAttempt}
      isDisabled={canExpire}
    >
      Expirar intento
    </MenuItem>
  )
}
