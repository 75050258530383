import {
  Link as ChakraLink,
  Avatar,
  Box,
  BoxProps,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  IconProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  Collapse,
  Button,
} from '@chakra-ui/react'
import { AuthenticationClient, CompanyTypes, IUser } from 'kach-clients'
import { format } from 'date-fns'
import { useAtom } from 'jotai'
import React, { useCallback, useMemo } from 'react'
import { BiStats } from 'react-icons/bi'
import { FiChevronDown, FiChevronUp, FiMenu, FiSettings } from 'react-icons/fi'
import { IconBaseProps } from 'react-icons/lib'
import { SlPuzzle } from 'react-icons/sl'
import { QueryClient } from '@tanstack/react-query'
import { isExternalDialogOpenAtom } from '../atoms/is-external-dialog-open.atom'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useJobs } from '../hooks/useJobs'
import { formatDateToLocaleDays } from '../utils/format-date'
import { hasPermission } from '../utils/has-permission'
import { Admonition } from './Admonition'
import KachLogo from './KachLogo'
import { LocalRolesIcon } from './LocalRolesIcon'
import { LocalTeamIcon } from './LocalTeamIcon'
import { NavigatorFC } from './NotificationList'
import {
  INotificationsBellPopoverProps,
  NotificationsBellPopover,
} from './NotificationsBellPopover'
import { UnavailabilityJobAdmonition } from './UnavailabilityJobAdmonition'
import { ReportBug } from './ReportBug'
import { AvailabilityJobAdmonition } from './AvailabilityJobAdmonition'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { useMemoizedFn } from 'ahooks'
import Link from 'next/link'

export interface ILinkNavigation {
  nestedRoutes?: string[]
  hide?: boolean | undefined
  name: string
  href: string
  icon: React.FC<IconBaseProps | IconProps>
  sections?: ILinkNavigation[]
}

export interface ISidebarProps {
  styles: BoxProps
  auth: IUser
}

interface AvatarMenuItem {
  name: string
  type: 'action' | 'href'
  href?: string
  action?: () => void
  external?: boolean
  priority?: number
  hide?: boolean
}

export type Props = INotificationsBellPopoverProps & {
  pathname: string
  queryClient: QueryClient
  SidebarTop: React.ReactNode
  SidebarBottom: React.ReactNode
  Navigator: NavigatorFC
  type: CompanyTypes
  navigation: ILinkNavigation[]
  onLogout: () => void
  onNavigate: (route: string) => void
  components?: {
    AvatarMenuItems: React.FC
  }
  avatarMenuItems?: AvatarMenuItem[]
}

const SidebarItem = ({
  navigation,
  pathname,
  Navigator,
}: {
  navigation: ILinkNavigation
  pathname: string
  Navigator: NavigatorFC
}) => {
  const isCurrentSection = useCallback(
    (
      current: string,
      candidate: Pick<ILinkNavigation, 'nestedRoutes' | 'href'>,
    ): boolean => {
      if (candidate.nestedRoutes?.length) {
        return candidate.href.includes(current) ||
          typeof candidate.nestedRoutes === 'object'
          ? candidate.nestedRoutes.includes(current)
          : false
      }
      return current === candidate.href
    },
    [],
  )

  const current = isCurrentSection(pathname, navigation)
  const collapse = useDisclosure({ defaultIsOpen: current })

  if (navigation.sections) {
    return (
      <Flex
        align='start'
        px='2'
        mx='2'
        rounded='md'
        py='2'
        cursor='pointer'
        _hover={{
          bg: collapse.isOpen ? 'initial' : 'brand.200',
        }}
        role='group'
        fontWeight='semibold'
        fontSize='sm'
        transition='.15s ease'
        experimental_spaceX={2}
        onClick={collapse.onToggle}
        flexDirection='column'
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent='space-between'
          width='full'
          mb={collapse.isOpen ? 2 : 0}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            columnGap={2}
            whiteSpace='nowrap'
          >
            <navigation.icon boxSize={4} />
            <span>{navigation.name}</span>
          </Box>
          <IconButton
            ml={2}
            size='xs'
            variant='unstyled'
            icon={collapse.isOpen ? <FiChevronUp /> : <FiChevronDown />}
            aria-label='expand-and-close'
          />
        </Box>
        <Collapse in={collapse.isOpen} animateOpacity style={{ width: '100%' }}>
          <Box display={'flex'} flexDirection={'column'} gap={2} width='full'>
            {navigation.sections.map((link, index) => {
              const currentSection = isCurrentSection(pathname, link)
              return (
                <Navigator key={index} href={link.href}>
                  <Flex
                    align='center'
                    px='2'
                    mx='2'
                    rounded='md'
                    py='2'
                    cursor='pointer'
                    color={currentSection ? 'gray.200' : 'gray.600'}
                    bg={currentSection ? 'brand.500' : 'initial'}
                    _hover={{
                      bg: 'brand.200',
                    }}
                    role='group'
                    fontWeight='semibold'
                    fontSize='sm'
                    transition='.15s ease'
                    experimental_spaceX={2}
                  >
                    <>
                      <link.icon boxSize={4} />
                      <span>{link.name}</span>
                    </>
                  </Flex>
                </Navigator>
              )
            })}
          </Box>
        </Collapse>
      </Flex>
    )
  }

  return (
    <Navigator href={navigation.href}>
      <Flex
        align='center'
        px='2'
        mx='2'
        rounded='md'
        py='2'
        cursor='pointer'
        color={current ? 'gray.200' : 'gray.600'}
        bg={current ? 'brand.500' : 'initial'}
        _hover={{
          bg: 'brand.200',
        }}
        role='group'
        fontWeight='semibold'
        fontSize='sm'
        transition='.15s ease'
        experimental_spaceX={2}
      >
        <>
          <navigation.icon boxSize={4} />
          <span>{navigation.name}</span>
        </>
      </Flex>
    </Navigator>
  )
}

export const Layout: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const sidebar = useDisclosure()
  const error = useError()

  const auth = useAuth()
  const jobs = useJobs()

  const unavailabilityJob = jobs?.find(
    (job) => job.type === 'user_unavailability' && job.status === 'pending',
  )

  const availabilityJob = jobs?.find(
    (job) => job.type === 'user_availability' && job.status === 'pending',
  )

  const hasIntegrationPermission = () => {
    const aiPermission = hasPermission(auth.role.permissions, {
      action: 'read',
      resource: 'autoinspector-integration',
    })

    const integrationPermission = hasPermission(auth.role.permissions, {
      action: 'read',
      resource: 'integration',
    })

    if (aiPermission || integrationPermission) {
      return true
    }

    return false
  }

  const navigation: ILinkNavigation[] = [
    ...props.navigation,
    {
      hide: !hasPermission(auth.role.permissions, {
        action: 'read',
        resource: 'stats',
      }),
      name: 'Estadísticas',
      href: '/stats',
      icon: BiStats,
    },
    {
      name: 'Equipo',
      hide:
        !hasPermission(auth.role.permissions, {
          action: 'read',
          resource: 'user',
        }) &&
        !hasPermission(auth.role.permissions, {
          action: 'read',
          resource: 'role',
        }),
      href: '/team/users',
      icon: LocalTeamIcon,
      nestedRoutes: ['/team/invitations', '/team/users', '/roles'],
      sections: [
        {
          name: 'Equipo',
          hide: !hasPermission(auth.role.permissions, {
            action: 'read',
            resource: 'user',
          }),
          href: '/team/users',
          icon: LocalTeamIcon,
        },
        {
          name: 'Roles',
          href: '/roles',
          hide: !hasPermission(auth.role.permissions, {
            action: 'read',
            resource: 'role',
          }),
          icon: LocalRolesIcon,
        },
      ],
    },
  ]

  const queryClient = useQueryClientSingleton()

  const onLogout = useCallback(() => {
    AuthenticationClient.logout(props.type)
      .then(() => {
        queryClient.clear()

        console.log('cache cleared!')

        props.onLogout()
      })
      .catch(error.handleError)
  }, [queryClient])

  const userNavigation = useMemo(() => {
    const baseItems: AvatarMenuItem[] = [
      {
        name: 'Configuración',
        href: '/settings',
        type: 'href',
        external: false,
        priority: 1,
      },
      {
        name: 'Integraciones',
        href: '/integrations',
        type: 'href',
        hide: !hasIntegrationPermission(),
        priority: 2,
      },
      { name: 'Cerrar sesión', type: 'action', action: onLogout, priority: 4 },
    ]

    if (props.avatarMenuItems)
      return [...baseItems, ...props.avatarMenuItems].sort(
        (a, b) => a.priority - b.priority,
      )

    return baseItems
  }, [onLogout])

  const [isExternalDialogOpen] = useAtom(isExternalDialogOpenAtom)

  const SidebarContent = (childrenProps: ISidebarProps) => (
    <Box
      as='nav'
      pos='fixed'
      top='0'
      left='0'
      zIndex={isExternalDialogOpen ? '-10' : 'initial'}
      h='full'
      pb='10'
      overflowX='hidden'
      bg='brand.100'
      borderColor='blackAlpha.300'
      borderRightWidth='1px'
      w='60'
      {...childrenProps.styles}
      overflowY={'auto'}
    >
      <Flex px='4' py='5' align='center'>
        <KachLogo size='4rem' variant='primary' />
      </Flex>
      <Flex px={2} mt={4} mb={8}>
        {props.SidebarTop}
      </Flex>
      <Flex
        direction='column'
        experimental_spaceY={2}
        as='nav'
        fontSize='sm'
        aria-label='Main Navigation'
      >
        {navigation.map((navigation, index) => {
          if (navigation.hide) {
            return null
          }

          return (
            <SidebarItem
              key={index}
              navigation={navigation}
              pathname={props.pathname}
              Navigator={props.Navigator}
            />
          )
        })}
      </Flex>
    </Box>
  )

  return (
    <Box
      as='section'
      _dark={{
        bg: 'gray.700',
      }}
      minH='100vh'
    >
      <SidebarContent
        auth={auth}
        styles={{
          display: {
            base: 'none',
            md: 'unset',
          },
        }}
      />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement='left'
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent
            styles={{ w: 'full', borderRight: 'none' }}
            auth={auth}
          />
        </DrawerContent>
      </Drawer>
      <Box
        ml={{
          base: 0,
          md: 60,
        }}
        transition='.3s ease'
      >
        <Flex
          as='header'
          align='center'
          justify='space-between'
          w='full'
          px='4'
          bg='white'
          _dark={{
            bg: 'gray.800',
          }}
          borderBottomWidth='1px'
          borderColor='blackAlpha.300'
          h='14'
        >
          <IconButton
            aria-label='Menu'
            display={{
              base: 'inline-flex',
              md: 'none',
            }}
            onClick={sidebar.onOpen}
            icon={<FiMenu />}
            size='sm'
          />
          <Text fontWeight='bold' fontSize='xl'>
            {auth?.company?.name}
          </Text>

          <Flex align='center' experimental_spaceX={2}>
            <ReportBug />
            {props.SidebarBottom}

            <Box>
              <NotificationsBellPopover
                query={props.query}
                initialNotifications={props.initialNotifications}
                Navigator={props.Navigator}
              />
            </Box>

            <Menu>
              <MenuButton aria-label='Options' cursor='pointer' pl={1}>
                <Avatar
                  referrerPolicy='no-referrer'
                  variant='outline'
                  bg='primary'
                  name={`${auth?.firstName} ${auth?.lastName}`}
                  size='sm'
                  src={auth?.profilePhoto}
                />
              </MenuButton>
              <MenuList zIndex='3'>
                {userNavigation.map((userLink) => {
                  if (userLink.hide) return null
                  switch (userLink.type) {
                    case 'action':
                      return (
                        <MenuItem
                          textTransform={'capitalize'}
                          fontSize='xs'
                          key={userLink.name}
                          onClick={() => {
                            if (userLink.action) return userLink.action()
                            props.onNavigate(userLink.href!)
                          }}
                        >
                          {userLink.name}
                        </MenuItem>
                      )

                    default:
                      if (userLink.external)
                        return (
                          <ChakraLink href={userLink.href} target='_blank'>
                            {' '}
                            <MenuItem
                              textTransform={'capitalize'}
                              fontSize='xs'
                              key={userLink.name}
                            >
                              {userLink.name}
                            </MenuItem>
                          </ChakraLink>
                        )

                      return (
                        <Link href={userLink.href}>
                          <MenuItem
                            textTransform={'capitalize'}
                            fontSize='xs'
                            key={userLink.name}
                          >
                            {userLink.name}
                          </MenuItem>
                        </Link>
                      )
                  }
                })}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        <Box as='main' p='4'>
          <Box rounded='md'>
            {!!unavailabilityJob && (
              <UnavailabilityJobAdmonition job={unavailabilityJob} />
            )}

            {!!availabilityJob && (
              <AvailabilityJobAdmonition job={availabilityJob} />
            )}
            <Box mt='4'>{props.children}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
