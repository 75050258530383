import { IArtifactAck, IMail } from './case.client';
import httpClient from './http-client';

export interface ICreateMail {
  externalId: string;
  artifact: IArtifactAck;
}

export interface ICreateMailAttempt {
  sentAt: string;
  receivedAt?: string;
}

export class MailClient {
  static async create(caseId: number, body: ICreateMail) {
    const response = await httpClient.post(`/v1/case/mail/${caseId}`, body);

    return response.data;
  }

  static async detail(mailId: number): Promise<IMail> {
    const response = await httpClient.get(`/v1/case/mail/${mailId}`);

    return response.data;
  }

  static async expireAttempt(mailAttemptId: number): Promise<void> {
    const response = await httpClient.put(
      `/v1/case/mail/attempt/expire/${mailAttemptId}`,
      {
        expiredAt: new Date(),
      }
    );

    return response.data;
  }

  static async createAttempt(
    mailId: number,
    attempt: ICreateMailAttempt
  ): Promise<IMail> {
    const response = await httpClient.post(
      `/v1/case/mail/attempt/${mailId}`,
      attempt
    );

    return response.data;
  }

  static async removeAttempt(mailId: number): Promise<IMail> {
    const response = await httpClient.delete(`/v1/case/mail/attempt/${mailId}`);

    return response.data;
  }

  static async ackAttempt(mailId: number, receivedAt: Date): Promise<IMail> {
    const response = await httpClient.put(
      `/v1/case/mail/attempt/ack/${mailId}`,
      {
        receivedAt,
      }
    );

    return response.data;
  }

  static async addArtifact(
    mailId: number,
    body: Pick<ICreateMail, 'artifact'>
  ): Promise<IMail> {
    const response = await httpClient.put(
      `/v1/case/mail/artifact/${mailId}`,
      body
    );

    return response.data;
  }
}
