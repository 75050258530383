import { CaseType } from 'kach-constants';
import { ActivityTypes } from '../constants/notification-activity-types';
import { ICompany, IUser } from './authentication.client';
import {
  CaseEvents,
  ICase,
  ICaseInspection,
  IEnumOption,
  IMailAttempt,
  INewsAndRequest,
  INewsRequest,
  IThread,
} from './case.client';
import httpClient from './http-client';
import { IPriceGroup } from './price-group.client';

export interface IPagination<R = any> {
  limit: number;
  page: number;
  skip: number;
  take: number;
  results: R[];
  hasMore: boolean;
  pageTotal: number;
  total: number;
}

export type InvestigationStatus =
  | 'accepted'
  | 'rejected'
  | 'pending'
  | 'closed'
  | 'canceled';

export interface IMessage {
  externalIdentifier?: string;
  id: number;
  createdAt: string;
  sentBy: string | IUser;
  message: string;
  artifacts: IArtifact[];
}

export interface IInvestigationReport {
  acceptedBy: IUser;
  investigation: IInvestigation;
  conclusion: 'fraud' | 'negative';
  surrender?: boolean;
  status: 'rejected' | 'accepted' | 'pending';
  acceptedAt?: string;
  rejectedAt?: string;
  report: IArtifact;
  createdBy: IUser;
  messages: IMessage[];
  createdAt: string;
  updatedAt: string;
  id: number;
}

export interface IContact {
  hidden: boolean;
  id: number;
  name: string;
  email: string;
  priceGroup: IPriceGroup;
  sinisterTypesSupported: { name: string; company_id: number }[];
  cellphone?: string;
  address?: string;
  country?: string;
  state?: string;
  providerNumber?: string;
  contactCompany: ICompany;
  responsibleDocumentType?: string;
  responsibleDocumentNumber?: string;
  hiddenFrom?: string;
  hiddenTo?: string;
  hiddenReason?: string;
  active_cases: string;
}

export interface IContactForCaseDerivation {
  active_cases: string;
  id: number;
  name: string;
  email: string;
  investigations: {
    id: number;
    status: string;
  }[];
  company_id: number;
}

export interface ITag {
  id: number;
  name: string;
  description: string;
  type: 'native' | 'created' | 'notification';
  createdBy: IUser;
}

export interface IArtifact {
  id: number;
  downloadURL?: string;
  url?: string;
  previewURL?: string;
  mimetype: string;
  size: number;
  originalName: string;
  storedName: string;
  tags: ITag[];
  createdAt: string;
  deletedAt?: string;
}

export interface INews {
  id: number;
  title: string;
  createdAt: string;
  publishedBy: IUser;
  description: string;
  artifacts: IArtifact[];
  newsRequest?: INewsRequest;
  destinations?: string[];
}

export type InvestigationTypes = 'f2f' | 'virtual';

export interface IAmountUnit {
  unit: string;
  amount: number | string;
}

export interface IContactPrice {
  validFrom: string | Date;
  validTo?: string | Date;
  createdAt: string;
  price_repository_id: number;
  investigationType: InvestigationTypes;
  sinisterType: IEnumOption;
  id: number;
  negative?: IAmountUnit;
  surrender?: IAmountUnit;
  surrenderPrizeDays?: number;
  surrenderPrize?: IAmountUnit;
  rejected?: IAmountUnit;
  plus?: IAmountUnit;
  fraud?: IAmountUnit;
  km?: IAmountUnit;
  kmLowerLimit?: number;
  inability?: number;
  upperLimit?: number;
  isLatestVersion?: boolean;
}

export interface IInvestigation {
  acceptedBy: IUser;
  indicators: string;
  invoices: IArtifact[];
  invoice: IArtifact;
  budget: IContactPrice;
  report_accepted_id: number;
  reportAccepted: IInvestigationReport;
  token: string;
  case_id: number;
  case: ICase;
  reports: IInvestigationReport[];
  id: number;
  status: InvestigationStatus;
  acceptedAt: string;
  rejectedAt: string;
  canceledBy?: IUser;
  canceledAt?: string;
  canceledReason?: string;
  closedAt: string;
  rejectedReason?: string;
  createdAt: string;
  contact: IContact;
  type: InvestigationTypes;
  news: INewsAndRequest[];
}

export interface ICaseRequest {
  caseType: CaseType;
  annotation?: string;
  createdBy: IUser;
  users: IUser[];
  responsible: IUser;
  events: CaseEvents[];
  complaintRecorderName: string;
  vehicleModel: string;
  vehicleLicensePlate: string;
  sinisterMatricula: string;
  sinisterDate: Date;
  sinisterStatus: string;
  complaintReportingLocation: string;
  policyPlan: string;
  vehicleYear: string;
  vehicleUse: string;
  scrapedProcessorName: string;
  scrapedProcessorEmail: string;
  scrapedProcessorCellphone: string;
  policyOrder: string;
  policyAgency: string;
  processor: IUser;
  id: number;
  case: ICase & {
    investigation: Pick<
      IInvestigation,
      'contact' | 'acceptedAt' | 'id' | 'reportAccepted'
    >;
  } & {
    inspections: ICaseInspection[];
  };
  createdAt: string;
  sinisterType: IEnumOption;
  sinisterId: string;
  instance: IEnumOption;
  reason: IEnumOption;
  company: ICompany;
  policyId: string;
  description: string;
  denouncedAt: string;
  ramo: IEnumOption & { code: string };
  agency: IEnumOption & { code: string };
  externalStatus?: IEnumOption;
  caseReasons?: IEnumOption;
  stopCaseRequestOpenedCount: number;
  retentionRequestOpenedCount: number;
}

export type INotification = {
  tags: ITag[];
  receivers: {
    read: boolean;
    user_id: number;
  }[];
  id: number;
  createdAt: string;
  entityId: number;
  activityType: ActivityTypes;
  actor: IUser;
} & (
  | {
      entityType: 'investigation';
      context: IInvestigation;
    }
  | {
      entityType: 'case';
      context: ICase;
    }
  | {
      entityType: 'case_request';
      context: ICaseRequest;
    }
  | {
      entityType: 'thread';
      context: IThread;
    }
  | {
      entityType: 'report';
      context: IInvestigationReport;
    }
  | {
      entityType: 'mailAttempt';
      context: IMailAttempt;
    }
);

export interface IPaginationQuery {
  search?: string;
  page?: number;
  limit?: number;
}

export type NotificationFilterType = 'read' | 'unread' | '';

export interface INotificationFilter extends IPaginationQuery {
  filter: NotificationFilterType;
  tags?: number[];
}

export class NotificationClient {
  static async paginate(
    params: INotificationFilter,
    cookie?: string
  ): Promise<IPagination<INotification>> {
    const response = await httpClient.get('/v1/notification/me', {
      params,
      headers: {
        Cookie: cookie,
      },
    });

    return response.data;
  }

  static async batchRead(body: {
    notificationIds: number[];
  }): Promise<IPagination<INotification>> {
    const response = await httpClient.put('/v1/notification/read', body);

    return response.data;
  }

  static async read(
    notificationId: number
  ): Promise<IPagination<INotification>> {
    const response = await httpClient.put(
      `/v1/notification/${notificationId}/read`
    );

    return response.data;
  }
}
