import { Avatar, Box, Button, popperCSSVars, Tooltip } from '@chakra-ui/react'
import { IArtifactAck, IMessage, IUser } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { ArtifactsTokenizer } from '../hooks/useFileUploadV2'
import { MessageDisplayer } from './MessageDisplayer'
import { MessageTextarea } from './MessageTextarea'

export const Chat = (props: {
  footerRef?: React.RefObject<HTMLDivElement>
  disabledReason?: string
  messages: IMessage[]
  closed?: boolean
  tokenizer: ArtifactsTokenizer
  onSendMessage: (
    message: { message: string; artifacts: IArtifactAck[] },
    onFinish: () => void,
  ) => void
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const textareaChat = useRef<HTMLDivElement>()

  const isOverlappingElementsBottom = useCallback(
    (elementOne: Element, elementTwo: Element) => {
      const rect1 = elementOne.getBoundingClientRect()
      const rect2 = elementTwo.getBoundingClientRect()

      return !(
        rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom
      )
    },
    [],
  )

  const { ref, inView, entry } = useInView({
    threshold: 1,
    onChange: (inView, entry) => {
      if (
        !inView ||
        isOverlappingElementsBottom(textareaChat.current, entry.target)
      ) {
        entry.target.scrollIntoView({ behavior: 'smooth' })
      }
    },
  })

  const auth = useAuth()

  return (
    <div>
      <Box minH='100vh' bg='gray.100' pos={'relative'} w='full'>
        <Box m='-5' p={5}>
          <Box p={8}>
            <Box
              mx='auto'
              experimental_spaceY={'12'}
              className='mx-auto space-y-12 grid grid-cols-1'
            >
              {props.messages.map((message, index) => {
                const isReceiver =
                  typeof message.sentBy === 'string'
                    ? false
                    : message.sentBy?.id !== auth?.id

                return (
                  <Box
                    {...buildTestId('chat-message')}
                    {...(index === props.messages.length - 1
                      ? {
                          ref,
                        }
                      : {})}
                    key={index}
                    textAlign='right'
                    experimental_spaceY={'4'}
                    display='flex'
                    alignItems={'center'}
                    {...(!isReceiver
                      ? {
                          placeSelf: 'end',
                          flexDir: 'row-reverse',
                        }
                      : {
                          placeSelf: 'start',
                          flexDir: 'row',
                        })}
                  >
                    <Avatar
                      referrerPolicy='no-referrer'
                      src={
                        typeof message.sentBy === 'string'
                          ? message.sentBy
                          : (message.sentBy as IUser)?.profilePhoto
                      }
                    />
                    <Box
                      p={4}
                      rounded='2xl'
                      display={'flex'}
                      flexDir='column'
                      experimental_spaceY={'3'}
                      {...(!isReceiver
                        ? {
                            bg: 'green.50',
                            color: 'green.900',
                            roundedTopRight: 'none',
                            textAlign: 'right',
                          }
                        : {
                            bg: 'white',
                            color: 'green.900',
                            roundedTopLeft: 'none',
                            textAlign: 'left',
                          })}
                    >
                      <MessageDisplayer message={message} />
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box position={'sticky'} w='full' bottom={0} bg='White'>
        {props.closed && (
          <Box
            pos={'absolute'}
            zIndex={10}
            w='full'
            h='full'
            display={'flex'}
            alignItems='center'
            justifyContent={'center'}
            bg='gray.50'
          >
            <p>Conversación cerrada</p>
          </Box>
        )}
        <Box ref={textareaChat}>
          <MessageTextarea
            ref={props.footerRef}
            disabledReason={props.disabledReason}
            artifactsTokenizer={props.tokenizer}
            artifactsIdentifier='upload-files-chat'
          >
            {({ message, onClearMessage }) => {
              const Btn = (
                <Button
                  {...buildTestId('send-message-btn')}
                  isDisabled={!!props.disabledReason}
                  disabled={!!props.disabledReason}
                  isLoading={isLoading}
                  onClick={() => props.onSendMessage(message, onClearMessage)}
                  size='sm'
                  colorScheme='brand'
                >
                  Enviar
                </Button>
              )

              if (props.disabledReason) {
                return <Tooltip label={props.disabledReason}>{Btn}</Tooltip>
              }

              return Btn
            }}
          </MessageTextarea>
        </Box>
      </Box>
    </div>
  )
}
