import QueryString from 'qs';
import { ICaseExtended } from './case.client';
import { ICreateContactPrice } from './contact.client';
import httpClient from './http-client';
import { IContactPrice, IInvestigation } from './notification.client';

export interface IPriceGroup {
  id: number;
  name: string;
  prices: IContactPrice[];
}

export type ICompanySaving = {
  result: string;
  inability?: string;
  moralDamage?: string;
  medical?: string;
  attorney?: string;
  insuredAmount?: string;
};

export interface ILabSaving {
  inability?: string;
  insuredAmount?: string;
  appraisedPropertyDamage?: string;
  caseResult?: string;
  prize?: string;
  result: string;
}

export type CasePriceDetailVars = {
  investigation: {
    price: Pick<
      IContactPrice,
      | 'inability'
      | 'fraud'
      | 'surrender'
      | 'surrenderPrize'
      | 'surrenderPrizeDays'
      | 'rejected'
      | 'negative'
      | 'upperLimit'
    >;
  } & Pick<IInvestigation, 'status' | 'closedAt' | 'createdAt'>;
  case: Pick<
    ICaseExtended,
    | 'insuredAmount'
    | 'appraisedPropertyDamage'
    | 'inabilityCoefficientLab'
    | 'result'
    | 'labOverwrittenFormulaResult'
    | 'companyOverwrittenFormulaResult'
  >;
  formula: {
    rivadavia_company: {
      inabilityPointValue: string;
      moralDamagePercentage: string;
      judicialPercentage: string;
      attorneyPercentage: string;
      medicalExpensesPerPerson: string;
    };
  };
  companyConfig: {
    equivalences: {
      entityName: string;
      entityId: number;
      value: {
        type: string;
        literal: string;
      };
    }[];
  };
};

export interface ICasePriceDetail {
  company_saving?: ICompanySaving;
  lab_saving?: ILabSaving;
  vars: CasePriceDetailVars;
}

export class PriceGroupClient {
  static async fetch(cookie?: string): Promise<{
    priceGroups: IPriceGroup[];
  }> {
    return httpClient
      .get('/v1/price/group', {
        headers: {
          Cookie: cookie,
        },
      })
      .then((res) => res.data);
  }

  private static filterEmptyPricesFields(data: {
    prices: ICreateContactPrice[];
  }): ICreateContactPrice[] {
    return data.prices.map((price) =>
      this.filterEmptyPriceFields(price)
    ) as any;
  }

  private static filterEmptyPriceFields(
    price: ICreateContactPrice
  ): ICreateContactPrice {
    return Object.fromEntries(
      Object.entries(price).filter(([, value]) => {
        if (!!value && typeof value === 'object') {
          return 'amount' in value
            ? !Number.isNaN(value.amount) && !!value.unit
            : true;
        }

        return !Number.isNaN(value);
      })
    ) as any as ICreateContactPrice;
  }

  static async detail(
    priceGroupId: number,
    query?: {
      version?: 'all' | 'latest' | 'previous';
      priceRepositoryId?: number;
      validity: 'active' | 'inactive' | 'all';
    }
  ): Promise<IPriceGroup> {
    let url = `/v1/price/group/${priceGroupId}`;

    if (query) {
      url += `?${QueryString.stringify(query)}`;
    }

    return httpClient.get(url).then((res) => res.data);
  }

  static async caseDetail(caseId: number): Promise<ICasePriceDetail> {
    return httpClient
      .get(`/v1/price/group/case/${caseId}`)
      .then((res) => res.data);
  }

  static async remove(id: number): Promise<any> {
    return httpClient.delete('/v1/price/group/' + id).then((res) => res.data);
  }

  static async create(data: Partial<IPriceGroup>): Promise<any> {
    return httpClient.post('/v1/price/group/', {
      ...data,
      prices: this.filterEmptyPricesFields(data as any),
    });
  }

  static async addPriceToGroup(
    priceGroupId: number,
    input: {
      data: ICreateContactPrice;
      prevPriceId?: number;
      endpointPrefix?: string;
    }
  ): Promise<any> {
    return httpClient.post(`/v1/price/group/${priceGroupId}/price`, {
      ...this.filterEmptyPriceFields(input.data),
      prevPriceId: input.prevPriceId,
    });
  }
}
