import { Box, chakra, Icon, Spinner } from '@chakra-ui/react'
import { FolderPlusIcon } from '@heroicons/react/24/solid'
import { IArtifactAck } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { MAX_FILE_SIZE_IN_MB, useFileUpload } from '../hooks/useFileUpload'
import { FileListDisplayer } from './FileListDisplayer'

interface IDropTarget {
  files: File[]
}

export type UploadFilesOutput = {
  fetchURL?: string
  url: string
  filename: string
  token: string
}

export type OnUploadFiles = (
  files: File[],
  onFinish?: (artifacts?: IArtifactAck[]) => void,
) => Promise<UploadFilesOutput[]>

interface IProps {
  token?: string
  error?: boolean
  onUpload: OnUploadFiles
  isUploading?: boolean
  multiple?: boolean
  maxFiles?: number
}

type UploadingFile = Pick<File, 'name'>

export const FileUpload = (props: IProps) => {
  const [isUploading, setIsUploading] = useState(false)
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([])

  const onDropWrapper = useCallback((files: File[]) => {
    setIsUploading(true)

    setUploadingFiles(files.map((f) => ({ name: f.name })))
    return props.onUpload(files, () => setIsUploading(false))
  }, [])

  const { onInputFileChanges, onValidateFiles } = useFileUpload(props)

  const inputId = `upload-file-${props.token}`

  if (uploadingFiles.length) {
    return (
      <FileListDisplayer
        orientation='vertical'
        isUploading={isUploading}
        files={uploadingFiles}
      />
    )
  }

  return (
    <Box>
      <chakra.label
        cursor={'pointer'}
        _hover={{
          bg: 'gray.100',
        }}
        style={{
          transitionProperty: 'all',
          transitionDuration: '150ms',
          transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        }}
        htmlFor={inputId}
        mt={1}
        h='10rem'
        display='flex'
        justifyContent='center'
        alignItems={'center'}
        border='2px'
        borderStyle='dashed'
        borderRadius='md'
        color={props.error ? 'red.300' : 'gray.300'}
        px={6}
        pt={5}
        pb={6}
      >
        {props.isUploading ? (
          <Spinner {...buildTestId('file-upload-spinner')} size='sm' />
        ) : (
          <Box
            w='full'
            display={'flex'}
            flexDir='column'
            alignItems={'center'}
            experimental_spaceY={'1'}
            textAlign='center'
          >
            <Icon as={FolderPlusIcon} boxSize={6} />
            <Box display={'flex'} fontSize='sm' color='gray.600'>
              <chakra.label
                position='relative'
                cursor='pointer'
                color='primary'
              >
                <chakra.span
                  fontWeight={'bold'}
                  color='primary'
                  textAlign={'center'}
                >
                  {props.multiple ? 'Subí archivos' : 'Subí un archivo'}
                </chakra.span>
                <chakra.input
                  {...buildTestId('file-upload-input')}
                  onChange={onInputFileChanges(onDropWrapper)}
                  multiple
                  id={inputId}
                  name='file-upload'
                  srOnly
                  type='file'
                />
              </chakra.label>
            </Box>
            <chakra.p fontSize='xs' color='gray.400'>
              PNG, JPG, GIF, PDF, DOCX hasta {MAX_FILE_SIZE_IN_MB}MB cada uno
            </chakra.p>
          </Box>
        )}
      </chakra.label>
      {props.multiple && props.maxFiles && (
        <chakra.p mt={2} fontSize='sm' color='gray.500'>
          Máximo {props.maxFiles} archivos
        </chakra.p>
      )}
    </Box>
  )
}
