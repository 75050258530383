import {
  Avatar,
  Box,
  Button,
  Icon,
  Input,
  Popover as PopoverChakra,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import {
  AuthenticationClient,
  CaseClient,
  ICaseUser,
  IUser,
} from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useDebounce } from 'ahooks'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { SEARCH_USERS_QUERY_KEY } from '../constants/query-keys'
import { useError } from '../hooks/useError'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { buildUserName } from '../utils/build-user-name'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const UsersListSelectSearcher = (props: {
  onClickUser: (user: IUser) => void
  filterUsers?: (users: IUser[]) => IUser[]
  singular?: boolean
  isLoading?: (user: IUser) => boolean
}) => {
  const [search, setSearch] = useState('')

  const debounceSearch = useDebounce(search, {
    wait: 500,
  })

  const { isLoading, data } = useQuerySingleton(
    [SEARCH_USERS_QUERY_KEY, debounceSearch],
    (ctx) => {
      const [, search] = ctx.queryKey
      return AuthenticationClient.searchUsers(search as string)
    },
    {
      retry: 1,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const usersFiltered = props.filterUsers
    ? props.filterUsers(data?.results || [])
    : []

  return (
    <div>
      <Input
        {...buildTestId('search-input-manage-case-users')}
        onChange={(e) => setSearch(e.target.value)}
        placeholder='Ingresa un mail'
        type='text'
        size='sm'
      />
      <Box mt={2}>
        <Text fontSize='xs' color='gray.500'>
          {`Selecciona uno ${!props.singular ? 'o mas usuarios' : ''}`}{' '}
        </Text>
        <Box mt={2} display='flex' flexDir='column'>
          {isLoading || !data ? (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <Spinner size='xs' />
            </Box>
          ) : (
            usersFiltered.map((user, index) => {
              return (
                <Button
                  size='sm'
                  display='flex'
                  justifyContent='start'
                  onClick={() => props.onClickUser(user)}
                  key={index}
                  mb={index + 1 !== usersFiltered.length ? 2 : 0}
                  isDisabled={props?.isLoading ? props?.isLoading(user) : false}
                >
                  <Avatar
                    referrerPolicy='no-referrer'
                    size='xs'
                    bg='primary'
                    color='white'
                    name={buildUserName(user)}
                    src={user.profilePhoto}
                    mr={2}
                  />
                  <span {...buildTestId(`collaborator-name`)}>
                    {buildUserName(user)}
                  </span>
                </Button>
              )
            })
          )}
        </Box>
      </Box>
    </div>
  )
}

const ManageCaseUsersBody = (props: {
  users: ICaseUser[]
  caseId: number
  onClose: () => void
}) => {
  const queryClient = useQueryClientSingleton()

  const error = useError()

  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const [userId, setUserId] = useState(0)

  const isLoadingUser = (user: IUser) => {
    if (userId === user.id) {
      return true
    }

    return false
  }

  const onRemoveCollaboratorFromCase = useCallback(
    (caseUserId: number) => {
      setIsLoading(true)
      setUserId(caseUserId)

      toast.promise(CaseClient.removeCollaborator(props.caseId, caseUserId), {
        loading: {
          title: 'Eliminando colaborador...',
        },
        success: () => {
          queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
          props.onClose()
          setUserId(0)
          setIsLoading(false)
          return {
            title: 'Usuario removido como colaborador correctamente!',
          }
        },
        error: (err) => {
          setIsLoading(false)
          return { title: error.getErrorMessage(err) }
        },
      })
    },
    [props.caseId],
  )

  const onAddUserToCase = useCallback((user: IUser) => {
    setIsLoading(true)
    setUserId(user.id)

    toast.promise(CaseClient.addCollaborator(props.caseId, user.id), {
      loading: {
        title: 'Agregando usuario como colaborador...',
      },
      success: () => {
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
        props.onClose()
        setUserId(0)
        setIsLoading(false)
        return {
          title: 'Usuario agregado como colaborador correctamente!',
        }
      },
      error: (err) => {
        setUserId(0)
        setIsLoading(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [])

  return (
    <Box>
      {props.users
        .filter((user) => user.type === 'collaborator')
        .map(({ user, id }, index) => {
          return (
            <Box key={index} px={2}>
              <Tooltip label='Presiona para eliminar'>
                <Button
                  {...buildTestId('remove-collaborator-btn')}
                  size='sm'
                  display='flex'
                  w='full'
                  justifyContent='start'
                  onClick={() => onRemoveCollaboratorFromCase(user.id)}
                  key={index}
                >
                  <Avatar
                    referrerPolicy='no-referrer'
                    size='xs'
                    bg='primary'
                    color='white'
                    name={buildUserName(user)}
                    src={user.profilePhoto}
                    mr={2}
                  />
                  <span {...buildTestId(`collaborator-name`)}>
                    {buildUserName(user)}
                  </span>
                </Button>
              </Tooltip>
            </Box>
          )
        })}

      <Box
        {...buildTestId('manage-case-users-container')}
        bg='white'
        borderRadius='md'
        p={2}
      >
        <UsersListSelectSearcher
          onClickUser={(user) => onAddUserToCase(user)}
          filterUsers={(users) => {
            return (
              users.filter(
                (user) =>
                  !props.users.find((caseUser) => caseUser.user.id === user.id),
              ) || []
            )
          }}
          isLoading={isLoadingUser}
        />
      </Box>
    </Box>
  )
}

export const ManageCaseUsers: React.FC<
  React.PropsWithChildren<{
    users: ICaseUser[]
    caseId: number
  }>
> = ({ users, caseId }) => {
  const { isOpen, onOpen, onToggle } = useDisclosure()

  return (
    <PopoverChakra isLazy isOpen={isOpen} onClose={onToggle}>
      <PopoverTrigger>
        <Button
          {...buildTestId('manage-case-users-button')}
          size='xs'
          onClick={onOpen}
        >
          <Icon as={ChevronDownIcon} />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Agregar colaborador</PopoverHeader>
        <PopoverBody>
          {isOpen && (
            <ManageCaseUsersBody
              onClose={onToggle}
              caseId={caseId}
              users={users}
            />
          )}
        </PopoverBody>
      </PopoverContent>
    </PopoverChakra>
  )
}
