import { Box, Tag } from '@chakra-ui/react'
import { INewsRequest } from 'kach-clients'
import React from 'react'
import { NewsLine } from './NewsLine'
import { NewsRequestLine } from './NewsRequestLine'

export const NewsRequestLineV2 = (props: {
  newsRequest: INewsRequest
  onReply?: () => void
  withReply?: boolean
  withoutUser?: boolean
  withoutTag?: boolean
  withoutCreatedBy?: boolean
}) => {
  return (
    <Box alignItems={'start'} display={'flex'} flexDir='column'>
      {!props.withoutTag && (
        <Tag mb='2' fontSize={'xs'}>
          Solicitud de novedad
        </Tag>
      )}

      <NewsRequestLine
        withoutUser={props.withoutUser}
        onReply={props.onReply}
        newsRequest={props.newsRequest}
        withReply={props.withReply}
      />
      {(props.newsRequest?.news || []).length > 0 && (
        <Box pos='relative' p={2} mt='3' ml='4'>
          {(props.newsRequest?.news || []).length > 0 && (
            <Box
              w='20px'
              pos='absolute'
              top={0}
              left={'24px'}
              borderLeftWidth={'1px'}
              h={`60%`}
            />
          )}

          {(props.newsRequest?.news || []).map((news, index) => {
            return (
              <Box key={index} pos='relative' p={2} mt='3' ml='4'>
                <Box
                  w='20px'
                  pos='absolute'
                  top={0}
                  left={0}
                  borderLeftWidth={'1px'}
                  borderBottomWidth={'1px'}
                  h={`60%`}
                />
                <Box ml='5'>
                  <Tag fontSize={'xs'}>Novedad</Tag>
                  <NewsLine news={news} />
                </Box>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
