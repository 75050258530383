import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  MenuItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useRef, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  chakra,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { IMailAttempt, MailClient } from 'kach-clients'
import { useError } from '../hooks/useError'
import { useQueryClient } from '@tanstack/react-query'
import { buildMailQueryKey } from '../utils/build-mail-query-key'
import { formatDateToLocaleDays } from '../utils/format-date'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

const Body = (props: {
  attempt: IMailAttempt
  mailId: number
  onClose: () => void
}) => {
  const [isInvalid, setIsInvalid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()
  const inputRef = useRef<HTMLInputElement>()
  const error = useError()
  const toast = useToast()

  const onAck = useCallback(
    (receivedAt: Date) => {
      setIsLoading(true)

      toast.promise(MailClient.ackAttempt(props.attempt.id, receivedAt), {
        error: (err) => {
          return { title: error.getErrorMessage(err) }
        },
        loading: {
          title: 'Actualizando intento',
        },
        success: (res) => {
          queryClient.invalidateQueries([buildMailQueryKey(props.mailId)])

          props.onClose()
          return {
            title: 'Intento actualizado correctamente',
          }
        },
      })

      setIsLoading(true)
    },
    [props.attempt.id],
  )

  const formId = 'ack-mail-attempt-form'

  return (
    <>
      <ModalHeader>Marcar intento como recibido</ModalHeader>
      <ModalCloseButton isDisabled={isLoading} />
      <ModalBody>
        <chakra.form
          onSubmit={(e) => {
            e.preventDefault()

            if (!inputRef.current) return

            const isValid =
              new Date(inputRef.current.value) > new Date(props.attempt.sentAt)

            if (!isValid) {
              setIsInvalid(true)
              return
            }

            onAck(new Date(inputRef.current.value))
          }}
          id={formId}
        >
          <FormControl isInvalid={isInvalid} isRequired>
            <FormLabel>Fecha acuse</FormLabel>
            <Input ref={inputRef} type='datetime-local' />
            <FormHelperText>
              Tiene que ser posterior a fecha de envió (
              {formatDateToLocaleDays(props.attempt.sentAt)})
            </FormHelperText>
          </FormControl>
        </chakra.form>
      </ModalBody>

      <ModalFooter>
        <Button
          variant='ghost'
          isDisabled={isLoading}
          mr={3}
          onClick={props.onClose}
        >
          Cerrar
        </Button>
        <Button
          form={formId}
          type='submit'
          variant='primary'
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Actualizar
        </Button>
      </ModalFooter>
    </>
  )
}

export const AckMailAttempt = (props: {
  attempt: IMailAttempt
  mailId: number
  isCaseClosed: boolean
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const canAck = !!props.attempt.expiredAt || !!props.attempt.receivedAt

  return (
    <>
      <MenuItem onClick={onOpen} isDisabled={canAck}>
        Marcar como recibido
      </MenuItem>
      <Modal size='xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Body
            attempt={props.attempt}
            mailId={props.mailId}
            onClose={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  )
}
