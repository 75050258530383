export default {
  '&multiLine': {
    control: {
      borderRadius: '1rem',
      backgroundColor: '#fff',
      fontSize: 14,
      fontWeight: 'normal',
      border: 'none',
      minHeight: 60,
      boxShadow: 'none',
    },
    highlighter: {
      boxShadow: 'none',
      padding: 9,
      border: '1px solid transparent',
      outline: 'none',
    },
    input: {
      borderWidth: '0px',
      boxShadow: 'none',
      border: 'none !important',
      background: 'transparent !important',
      padding: 9,
      outline: 'none',
    },
    textarea: {
      borderWidth: '0px',
      boxShadow: 'none',
      border: 'none !important',
      outline: 'none',
    },
  },
};
