import {
  Box,
  chakra,
  Grid,
  GridItem,
  Spinner,
  Tag,
  Text,
} from '@chakra-ui/react'
import {
  IInvestigationReport,
  InvestigationClient,
  ReportClient,
} from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { format } from 'date-fns'
import React from 'react'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { buildReportQueryKey } from '../utils/build-report-query-key'
import { translator } from '../utils/translator'
import { ArtifactDisplayer } from './ArtifactDisplayer'
import { Chat } from './Chat'
import { CustomBreadCrumb } from './CustomBreadCrumb'
import { InvestigationReportActions } from './InvestigationReportActions'
import { UserDisplayer } from './UserDisplayer'

export function InvestigationReportDetail(props: {
  investigationId: number
  reportId: number
  caseId: number
  report?: IInvestigationReport
  onBack: () => void
}) {
  const queryClient = useQueryClientSingleton()

  const { isLoading, data } = useQuerySingleton(
    [buildReportQueryKey(props.reportId)],
    () => ReportClient.detail(props.reportId),
  )

  const error = useError()

  if (isLoading || !data) {
    return (
      <Box>
        <Spinner size='xs' />;
      </Box>
    )
  }

  return (
    <Box
      {...buildTestId('case-investigation-detail')}
      display={'flex'}
      flexDir='column'
      experimental_spaceY={'5'}
    >
      <Box
        my='5'
        display={'flex'}
        w='full'
        flexDir={'row'}
        alignItems='start'
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          flexDirection='column'
          experimental_spaceY={'3'}
          w='full'
        >
          <Box display={'flex'} flexDir='row' alignItems={'center'} mb='5'>
            <CustomBreadCrumb
              initialPageLabel={`Investigación #${props.investigationId}`}
              onBack={props.onBack}
              currentPageLabel={`Reporte #${data.id}`}
            />
          </Box>

          <Box
            w='full'
            display={'flex'}
            flexDir='row'
            alignItems={'center'}
            justifyContent='space-between'
            experimental_spaceX={'10'}
          >
            <Grid
              templateColumns={['repeat(1, 1fr)', null, 'repeat(5, 1fr)']}
              gap={6}
            >
              <GridItem>
                <div>
                  <Box display={'flex'} flexDir='column'>
                    <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                      Reporte
                    </chakra.label>
                    <ArtifactDisplayer mode='light' artifact={data.report} />
                  </Box>
                </div>
              </GridItem>
              <GridItem>
                <div>
                  <Box display={'flex'} flexDir='column'>
                    <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                      Creado por
                    </chakra.label>
                    <UserDisplayer user={data.createdBy} />
                  </Box>
                </div>
              </GridItem>
              <GridItem>
                <div>
                  <Box display={'flex'} flexDir='column'>
                    <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                      Estado
                    </chakra.label>
                    <Text fontSize={'sm'}>{translator(data.status)}</Text>
                  </Box>
                </div>
              </GridItem>

              {data.acceptedAt && (
                <GridItem>
                  <Box display={'flex'} flexDir='column'>
                    <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                      Aceptado el
                    </chakra.label>
                    <Text fontSize={'sm'}>
                      {format(new Date(data.acceptedAt), 'Pp')}
                    </Text>
                  </Box>
                </GridItem>
              )}

              <GridItem>
                <Box display={'flex'} flexDir='column'>
                  <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                    Conclusion:
                  </chakra.label>
                  <Tag w='min-content'>{translator(data.conclusion)}</Tag>
                </Box>
              </GridItem>

              {data.rejectedAt && (
                <GridItem>
                  <Box display={'flex'} flexDir='column'>
                    <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                      Devuelto el
                    </chakra.label>
                    <Text fontSize={'sm'}>
                      {format(new Date(data.rejectedAt), 'Pp')}
                    </Text>
                  </Box>
                </GridItem>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        w='full'
        display={'flex'}
        alignItems='flex-end'
        justifyContent={'end'}
      >
        <InvestigationReportActions
          caseId={props.caseId}
          report={data}
          investigationId={props.investigationId}
        />
      </Box>
      <Box display={'flex'} flexDir='column' experimental_spaceY={'12'}>
        <Chat
          tokenizer={(files) =>
            InvestigationClient.tokenizeArtifacts(props.investigationId, files)
          }
          onSendMessage={(message, onFinish) => {
            ReportClient.addMessage(props.reportId, message)
              .then((res) => {
                queryClient.invalidateQueries([
                  buildReportQueryKey(props.reportId),
                ])

                onFinish()
              })
              .catch(error.handleError)
          }}
          closed={data.status !== 'pending'}
          messages={data.messages}
        />
      </Box>
    </Box>
  )
}
