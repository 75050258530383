import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tab,
  TabList,
  Tabs,
  Tooltip,
  useFormControlStyles,
  useToast,
} from '@chakra-ui/react'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { atom, useAtom } from 'jotai'
import { IVirtualView, VirtualViewClient } from 'kach-clients'
import { translator } from 'kach-commons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IoEyeOutline } from 'react-icons/io5'
import { TbGripVertical } from 'react-icons/tb'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useMeasure } from '../hooks/useMeasure'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { useTableParams } from '../hooks/useTableParams'
import { useVirtualViews } from '../hooks/useVirtualViews'
import { useTableContext } from '../providers/TableFiltersProvider'
import { buildVirtualViewQueryKey } from '../utils/build-virtual-view-query-key'

const specsWidthAtom = atom<number | undefined>(undefined)

const TAB_WIDTH = 100

const SHOW_MORE_BUTTON_WIDTH = 140

export const SortableTabView = (props: {
  view: IVirtualView
  setVirtualView: () => void
  index: number
}) => {
  const { view } = props

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: view.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Tab
      ref={setNodeRef}
      fontWeight={'bold'}
      onClick={props.setVirtualView}
      key={props.index}
      color='gray.500'
      style={style}
      cursor={isDragging ? 'grabbing' : 'default'}
      {...attributes}
      {...listeners}
      ml={4}
      width={`${TAB_WIDTH}px`}
    >
      <Tooltip label={translator(view.scope)}>{view.name}</Tooltip>
    </Tab>
  )
}

const SortableViewListItem = ({
  virtualView,
}: {
  virtualView: IVirtualView
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: virtualView.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const { setVirtualView } = useTableContext()

  console.log('style', style)

  return (
    <Stack
      ref={setNodeRef}
      fontWeight={'bold'}
      color='gray.500'
      rounded='md'
      cursor={isDragging ? 'grabbing' : 'default'}
      _hover={{
        bg: 'gray.100',
      }}
      direction='row'
      spacing={4}
      alignItems={'center'}
      h='10'
      px={2}
      justifyContent={'space-between'}
      {...attributes}
      style={style}
    >
      <Stack alignItems={'center'} direction={'row'} spacing={2}>
        <Box cursor={'grab'}>
          <TbGripVertical {...listeners} />
        </Box>
        <span>{virtualView.name}</span>
      </Stack>
      <IconButton
        onClick={() => setVirtualView(virtualView)}
        icon={<IoEyeOutline />}
        variant={'outline'}
        size='xs'
      />
    </Stack>
  )
}

export const VirtualViewTabs = (props: {
  virtualViews: IVirtualView[]
  resource: IVirtualView['resource']
}) => {
  const { data } = useVirtualViews(props.virtualViews, props.resource)

  const [virtualViewsComputed, setVirtualViewsComputed] = useState<
    IVirtualView[] | undefined
  >(props.virtualViews)

  const { setVirtualView, virtualView } = useTableParams()

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const auth = useAuth()

  const toast = useToast()

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const onSaveVirtualView = useCallback(
    (
      data: { order: number; id: number }[],
      updatedVirtualViews: IVirtualView[],
    ) => {
      toast.promise(VirtualViewClient.updateOrder(data), {
        error: (err) => {
          return { title: error.getErrorMessage(err) }
        },
        loading: {
          description: 'Guardando cambios',
        },
        success: () => {
          setVirtualViewsComputed(updatedVirtualViews)

          queryClient.invalidateQueries([
            buildVirtualViewQueryKey(virtualView.resource, auth),
          ])

          return {
            description: 'Cambios guardados correctamente',
          }
        },
      })
    },
    [],
  )

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      const viewsIds = virtualViewsComputed?.map((view) => view.id)!
      const oldIndex = viewsIds.indexOf(parseInt(active!.id.toString()))
      const newIndex = viewsIds.indexOf(parseInt(over!.id.toString()))

      const reorderedSections = arrayMove(
        virtualViewsComputed!,
        oldIndex,
        newIndex,
      )

      const reorderedVirtualViews = reorderedSections.map((view, index) => ({
        ...view,
        order: index,
      }))

      onSaveVirtualView(
        reorderedVirtualViews.map((view) => ({
          id: view.id,
          order: view.order,
        })),
        reorderedVirtualViews,
      )
    }
  }, [])

  const [ref, specs] = useMeasure()

  const [specsWidth, setSpecsWidthAtom] = useAtom(specsWidthAtom)

  const exposedVirtualViews = useMemo(() => {
    const virtualViewGlobalIndex = data?.findIndex(
      (view) => view.id === virtualView?.id,
    )

    if (!specsWidth || virtualViewGlobalIndex == null) return []

    const MARGIN = 150

    const availableSlots = Math.floor(
      (specsWidth - SHOW_MORE_BUTTON_WIDTH - MARGIN) / TAB_WIDTH,
    )

    console.log('availableSlots', availableSlots)

    const calculateWindowSize = () => {
      if (availableSlots > data!.length)
        return {
          startIndex: 0,
          endIndex: data!.length,
        }

      console.log('availableSlots', availableSlots)

      const halfWindowSize = Math.floor(availableSlots / 2)
      let startIndex = virtualViewGlobalIndex - halfWindowSize
      let endIndex = virtualViewGlobalIndex + halfWindowSize

      if (startIndex < 0) {
        endIndex += Math.abs(startIndex)
        startIndex = 0
      }

      if (endIndex >= data!.length) {
        startIndex -= endIndex - data!.length + 1
        endIndex = data!.length - 1
      }

      if (startIndex < 0) {
        startIndex = 0
      }

      return { startIndex, endIndex }
    }

    const { startIndex, endIndex } = calculateWindowSize()

    return (virtualViewsComputed || []).slice(startIndex, endIndex)
  }, [specsWidth, virtualViewsComputed, virtualView?.id])

  console.log('exposedVirtualViews', exposedVirtualViews)

  console.log('specs.width', specs.width)

  const index = exposedVirtualViews.findIndex(
    (view) => view.id === virtualView?.id,
  )

  useEffect(() => {
    if (specs.width) {
      setSpecsWidthAtom(specs.width)
    }
  }, [specs.width])

  console.log('specs.width', specs.width)

  return (
    <Stack direction='row' alignItems={'center'} ref={ref}>
      <Tabs index={index} colorScheme={'brandFull'} size='sm' isLazy mb={2}>
        <TabList>
          <DndContext
            modifiers={[restrictToHorizontalAxis]}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={exposedVirtualViews?.map((view) => view.id)!}
            >
              {exposedVirtualViews?.map((view, index) => {
                return (
                  <SortableTabView
                    view={view}
                    setVirtualView={() => setVirtualView(view)}
                    index={index}
                  />
                )
              })}
            </SortableContext>
          </DndContext>
          {(virtualViewsComputed || []).length > exposedVirtualViews.length && (
            <Tab width={`${SHOW_MORE_BUTTON_WIDTH}px`}>
              <Popover>
                <PopoverTrigger>
                  <Button variant='ghost' size='sm' color='gray.500'>
                    Ver{' '}
                    {virtualViewsComputed!.length - exposedVirtualViews.length}{' '}
                    mas...
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverBody overflowY={'auto'} maxHeight={'56'}>
                    <DndContext
                      modifiers={[restrictToVerticalAxis]}
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={handleDragEnd}
                    >
                      <SortableContext
                        items={virtualViewsComputed?.map((view) => view.id)!}
                      >
                        {(virtualViewsComputed || []).map((view) => {
                          return (
                            <SortableViewListItem
                              key={view.id}
                              virtualView={view}
                            />
                          )
                        })}
                      </SortableContext>
                    </DndContext>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Tab>
          )}
        </TabList>
      </Tabs>
    </Stack>
  )
}
