import React from 'react'
import { Box, Spinner } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { useTags } from '../hooks/useTags'
import { translator } from '../utils/translator'

export const NotificationTags = (props: {
  onChangeTagsFilter: React.Dispatch<React.SetStateAction<number[]>>
}) => {
  const { isLoading, error, data } = useTags('notification')

  if (isLoading) {
    return <Spinner />
  }

  if (error || !data) {
    return null
  }

  return (
    <Select
      isMulti
      placeholder='Etiquetas'
      size='sm'
      useBasicStyles
      options={data.tags.map((tag) => {
        return {
          label: tag.name,
          value: tag.id,
        }
      })}
      onChange={(v, actionMeta) =>
        props.onChangeTagsFilter(v.map((v) => v.value))
      }
      formatOptionLabel={(data) => translator(data.label)}
      isClearable
    />
  )
}
