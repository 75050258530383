import { Box, Text } from '@chakra-ui/react'
import { ICompanySaving, ILabSaving } from 'kach-clients'
import React from 'react'
import {
  NewSavingFormulaCommand,
  ValidTypes,
} from '../utils/new-saving-formula.command'
import { LocalDecimal } from '../utils/local-decimal'
import { translator } from '../utils/translator'
import { KachColors } from '../constants/colors'

export interface IFormulaResultItem {
  item: string
  value: string
  acc: string
}

export const FormulaResultItem = (props: {
  item: IFormulaResultItem
  fontWeight?: string
  hide?: boolean
  color?: string
}) => {
  if (props.hide) return null

  return (
    <Box
      display='flex'
      w='100%'
      alignItems='flex-start'
      justifyContent='space-between'
      mt={1}
      mb={1}
    >
      <Text
        fontSize='md'
        fontWeight={props.fontWeight}
        w={'60%'}
        color={props.color}
      >
        {props.item.item}
      </Text>
      <Box display='flex' justifyContent='end' w='20%' fontSize='md'>
        {props.item.value || 'Dato no disponible'}
      </Box>
      <Box display='flex' justifyContent='end' w='20%' fontSize='md'>
        {props.item.acc || 'Dato no disponible'}
      </Box>
    </Box>
  )
}

export const SavingFormulaResult = (props: {
  command: NewSavingFormulaCommand<ValidTypes>
}) => {
  return (
    <>
      {props.command.sortedVars.map((key, index, array) => {
        const item = props.command.data[key]

        if (!item) {
          return
        }

        const operator = (i: number) => {
          if (i === 0 || i === array.length - 1) {
            return ' '
          }

          return '+'
        }

        const value = (acc: string, i: number) => {
          if (i === 0) {
            return LocalDecimal.new(acc).toString()
          }

          const key = array[i - 1]

          const last = props.command.data[key]

          if (!last) {
            return LocalDecimal.new(0)
          }

          return LocalDecimal.new(acc).sub(LocalDecimal.new(last)).toString()
        }

        if (index === array.length - 1) {
          return (
            <FormulaResultItem
              item={{
                item: `${operator(index)} Total calculado`,
                value: '-',
                acc: `$${item.toString()}`,
              }}
              fontWeight={'bold'}
              color={KachColors.green}
            ></FormulaResultItem>
          )
        }

        return (
          <FormulaResultItem
            item={{
              item: `${operator(index)} ${translator(key)}`,
              value: `$${value(item.toString(), index)}`,
              acc: `$${item?.toString()}`,
            }}
            fontWeight={'normal'}
          ></FormulaResultItem>
        )
      })}
    </>
  )
}
