import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { JobClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useRouter } from 'next/router'
import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlineWarning } from 'react-icons/ai'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import {
  IUnavailabilitySchema,
  UnavailabilitySchema,
} from '../schemas/unavailability.schema'
import { formatDateToLocaleDays } from '../utils/format-date'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { MEMBERS_QUERY_KEY } from '../constants/query-keys'
import { addMinutes, endOfDay, subSeconds } from 'date-fns'

const Body = (props: { fields: IUnavailabilitySchema }) => {
  const theme = useTheme()
  return (
    <Box display={'flex'} experimental_spaceX='4' flexDir='row'>
      <Box>
        <Box w='min' p={3} rounded='full' bg='red.100'>
          <AiOutlineWarning color={theme.colors.red['500']} />
        </Box>
      </Box>
      <Box>
        <Heading fontSize={'lg'}>Marcar como no disponible</Heading>
        <Text mt='2' fontSize={'sm'}>
          ¿Estas seguro que deseas marcarte como no disponible? No podrás
          realizar ninguna acción dentro de la plataforma hasta el{' '}
          {formatDateToLocaleDays(props.fields.to)}
        </Text>
      </Box>
    </Box>
  )
}

export const UnavailabilityForm = ({
  memberId,
  onClose,
}: {
  memberId?: number
  onClose?: () => void
}) => {
  const form = useForm<IUnavailabilitySchema>({
    resolver: zodResolver(UnavailabilitySchema),
  })

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = form

  const [isLoading, setIsLoading] = useState(false)

  const fields = watch()

  const modal = useDisclosure()
  const toast = useToast()

  const onSubmit = useCallback((data: IUnavailabilitySchema) => {
    modal.onOpen()
  }, [])

  const router = useRouter()

  const { getErrorMessage, handleError } = useError()

  const client = memberId
    ? JobClient.createUnavailabilityJob
    : JobClient.createOwnUnavailabilityJob

  const queryClient = useQueryClientSingleton()

  const onConfirm = useCallback(() => {
    setIsLoading(true)

    const values = form.getValues()

    console.log('values', values)

    const dataWithMemberId: Required<IUnavailabilitySchema> = {
      memberId,
      from: addMinutes(
        new Date(values.from),
        new Date(values.from).getTimezoneOffset(),
      ),
      to: subSeconds(
        endOfDay(
          addMinutes(
            new Date(values.to),
            new Date(values.to).getTimezoneOffset(),
          ),
        ),
        1,
      ),
    }

    toast.promise(client(dataWithMemberId as Required<IUnavailabilitySchema>), {
      loading: {
        title: 'Indisponibilizando usuario...',
      },
      success: (res) => {
        if (onClose) {
          onClose?.()
        } else {
          router.reload()
        }

        queryClient.invalidateQueries([MEMBERS_QUERY_KEY])

        return {
          title: `Usuario indisponibilizado`,
        }
      },
      error: (err) => {
        return { title: getErrorMessage(err) }
      },
    })

    setIsLoading(false)
  }, [fields])

  return (
    <>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={5}>
            <Body fields={fields} />
          </ModalBody>

          <ModalFooter>
            <Button
              {...buildTestId('unavailability-modal-confirm')}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={onConfirm}
              size='sm'
              variant='primary'
              mr={3}
            >
              Confirmar
            </Button>
            <Button
              disabled={isLoading}
              onClick={onClose}
              size='sm'
              variant='ghost'
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <chakra.form
        {...buildTestId('unavailability-form')}
        onSubmit={handleSubmit(onSubmit)}
        display={'flex'}
        flexDir='column'
        experimental_spaceY={'4'}
      >
        <Controller
          control={form.control}
          name='from'
          render={({ field, fieldState }) => {
            return (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel>Inicio</FormLabel>
                <Input type='date' {...field} />
                {fieldState.error && (
                  <FormErrorMessage>
                    {fieldState.error.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            )
          }}
        />

        <Controller
          control={form.control}
          name='to'
          render={({ field, fieldState }) => {
            return (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel>Fin</FormLabel>
                <Input type='date' {...field} />
                {fieldState.error && (
                  <FormErrorMessage>
                    {fieldState.error.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            )
          }}
        />

        <Button
          {...buildTestId('unavailability-form-submit')}
          variant='primary'
          type='submit'
          mt='5'
        >
          Confirmar
        </Button>
      </chakra.form>
    </>
  )
}
