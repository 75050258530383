import { RetrieveCaseViewsResponseBody } from 'kach-commons';
import {
  IArtifactTokenized,
  IEnumOption,
  INewsAndRequest,
} from './case.client';
import httpClient from './http-client';

export interface ITokenizeArtifacts {
  artifacts: Pick<File, 'size' | 'name'>[];
}

export interface IUseCaseRequestProps {
  sinisterType?: IEnumOption;
  reason?: IEnumOption;
}

export class CaseRequestClient {
  static async tokenizeArtifacts(data: ITokenizeArtifacts): Promise<{
    artifactsTokenized: IArtifactTokenized[];
  }> {
    const response = await httpClient.post(
      '/v1/case/request/batch/artifact/token',
      data
    );

    return response.data;
  }

  static async listNewsAndRequests(caseRequestId: number): Promise<{
    news: INewsAndRequest[];
  }> {
    const response = await httpClient.get(
      `/v1/case/request/${caseRequestId}/news-and-requests`
    );

    return response.data;
  }

  static async update(caseRequestId: number, data?: IUseCaseRequestProps) {
    const response = await httpClient.put(
      `/v1/case/request/${caseRequestId}`,
      data
    );

    return response.data;
  }

  static async retrieveViews(
    caseId: number | string
  ): Promise<RetrieveCaseViewsResponseBody> {
    const response = await httpClient.get(`/v1/case/request/${caseId}/views`);
    return response.data;
  }
}
