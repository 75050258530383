import {
  Box,
  chakra,
  Divider,
  Grid,
  GridItem,
  Icon,
  List,
  ListItem,
  Spinner,
  Tag,
  Text,
} from '@chakra-ui/react'
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import {
  IInvestigation,
  IInvestigationReport,
  ReportClient,
} from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { format } from 'date-fns'
import React from 'react'
import { useAuth } from '../hooks/useAuth'
import { AddInvestigationReport } from './AddInvestigationReport'
import { InvestigationSection } from './InvestigationSection'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildReportQueryKey } from '../utils/build-report-query-key'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { useError } from '../hooks/useError'
import { ArtifactDisplayer } from './ArtifactDisplayer'
import { translator } from '../utils/translator'
import { UserDisplayer } from './UserDisplayer'
import { InvestigationReportActions } from './InvestigationReportActions'

const ReportLine = (props: {
  investigation: IInvestigation
  report: IInvestigationReport
  index: number
  onClickReport: () => void
}) => {
  const queryClient = useQueryClientSingleton()

  const { isLoading, data } = useQuerySingleton(
    [buildReportQueryKey(props.report.id)],
    () => ReportClient.detail(props.report.id),
  )

  const error = useError()

  if (isLoading || !data) {
    return (
      <Box>
        <Spinner size='xs' />;
      </Box>
    )
  }

  const isFinalReport =
    props.report.id === props.investigation.report_accepted_id

  return (
    <ListItem
      {...buildTestId('investigation-report-line')}
      key={props.index}
      display='flex'
      flexDirection={'column'}
      rowGap={4}
      borderRadius='md'
      p={4}
    >
      <Box display='flex' alignItems='start' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <Box
            display='flex'
            justifyContent='space-between'
            flexDir='column'
            rowGap={1}
          >
            <Box display='flex' alignItems='center'>
              <Text noOfLines={1} mr={2}>
                Informe #
                <chakra.span {...buildTestId('report-id')}>
                  {props.report.id}
                </chakra.span>
              </Text>
              {isFinalReport ? (
                <Tag size='sm' colorScheme={'whatsapp'}>
                  Final
                </Tag>
              ) : (
                <Tag size='sm' colorScheme={'gray'}>
                  Pre-Informe
                </Tag>
              )}
            </Box>
            <Text fontSize='xs' color='gray.500'>
              Agregado el{' '}
              <chakra.time>
                {format(new Date(props.report.createdAt), 'Pp')}
              </chakra.time>
            </Text>
            <ArtifactDisplayer mode='light' artifact={data.report} />
          </Box>
        </Box>
        <Box display={'flex'} alignItems='end'>
          <InvestigationReportActions
            caseId={props.investigation.case_id}
            report={data}
            investigationId={props.investigation.id}
          />
        </Box>
      </Box>
      <Box>
        <Grid
          templateColumns={['repeat(1, 1fr)', null, 'repeat(4, 1fr)']}
          gap={6}
        >
          <GridItem>
            <div>
              <Box display={'flex'} flexDir='column'>
                <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                  Creado por
                </chakra.label>
                <UserDisplayer user={data.createdBy} />
              </Box>
            </div>
          </GridItem>
          <GridItem>
            <div>
              <Box display={'flex'} flexDir='column'>
                <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                  Estado
                </chakra.label>
                <Text fontSize={'sm'}>{translator(data.status)}</Text>
              </Box>
            </div>
          </GridItem>

          {data.acceptedAt && (
            <GridItem>
              <Box display={'flex'} flexDir='column'>
                <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                  Aceptado el
                </chakra.label>
                <Text fontSize={'sm'}>
                  {format(new Date(data.acceptedAt), 'Pp')}
                </Text>
              </Box>
            </GridItem>
          )}

          <GridItem>
            <Box display={'flex'} flexDir='column'>
              <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                Conclusion:
              </chakra.label>
              <Tag w='min-content'>{translator(data.conclusion)}</Tag>
            </Box>
          </GridItem>

          {data.rejectedAt && (
            <GridItem>
              <Box display={'flex'} flexDir='column'>
                <chakra.label color='gray.500' mb='2' fontSize={'sm'}>
                  Devuelto el
                </chakra.label>
                <Text fontSize={'sm'}>
                  {format(new Date(data.rejectedAt), 'Pp')}
                </Text>
              </Box>
            </GridItem>
          )}
        </Grid>
      </Box>
    </ListItem>
  )
}

export const InvestigationReports = (props: {
  reports: IInvestigationReport[]
  investigation: IInvestigation
  onSeeReportDetails: (reportId: number) => void
}) => {
  const auth = useAuth()

  return (
    <InvestigationSection
      title='Informes'
      description='Reportes agregados por el estudio de investigación'
      renderButton={
        auth.company.type === 'lab' &&
        props.investigation.status === 'accepted' && (
          <AddInvestigationReport investigationId={props.investigation.id} />
        )
      }
    >
      <List bg='white' borderRadius='md' py={2} experimental_spaceY={2}>
        {props.reports.map((report, index) => {
          return (
            <Box>
              <ReportLine
                investigation={props.investigation}
                onClickReport={() => props.onSeeReportDetails(report.id)}
                key={index}
                report={report}
                index={index}
              />
              {index + 1 !== props.reports.length ? <Divider /> : null}
            </Box>
          )
        })}
      </List>
    </InvestigationSection>
  )
}
