import { IJob, JobClient } from 'kach-clients'

export type TFunction = () => any

export interface IPageProps<Props = any> {
  props: Props
  redirect?: any
  notFound?: boolean
}

export type TMidllewareFunction<T = any, E = any> = (
  ctx: any,
  pageProps: IPageProps<T>,
  fn: TFunction,
  ...args: E[]
) => any

export interface IPagePropsPendingJobs {
  jobs: IJob[]
}

export const fetchPendingJobs: TMidllewareFunction<
  IPagePropsPendingJobs
> = async (ctx, pageProps, fn) => {
  try {
    const response = await JobClient.listPending(ctx.req.headers.cookie)
    pageProps.props.jobs = response.jobs
  } catch (err) {
    console.log('fetchPendingJobs err', err)
  }

  return fn()
}
