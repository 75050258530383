import { z } from 'zod';
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema';

//@ts-ignore
export const ResetPasswordSchema = z
  .object({
    password: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    passwordConfirmation: z.string().min(1, REQUIRED_FIELD_MESSAGE),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'Las contraseñas no coinciden',
    path: ['passwordConfirmation'],
  });

export interface IResetPasswordSchema
  extends z.infer<typeof ResetPasswordSchema> {}
