import React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SecurityClient } from 'kach-clients'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useError } from '../hooks/useError'
import {
  IResetPasswordSchema,
  ResetPasswordSchema,
} from '../schemas/reset-password.schema'
import KachLogo from './KachLogo'
import { ChangePasswordForm } from './ChangePasswordForm'

export const ResetPasswordToken = (props: { token: string }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IResetPasswordSchema>({
    resolver: zodResolver(ResetPasswordSchema),
  })

  const [isLoading, setIsLoading] = useState(false)

  const [changed, setChanged] = useState(false)

  const { handleError } = useError()

  const onResetPassword = useCallback((data: IResetPasswordSchema) => {
    setIsLoading(true)
    //@ts-ignore
    SecurityClient.resetPasswordToken(props.token, data)
      .then((res) => setChanged(true))
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Box
      display='flex'
      minH='full'
      flexDir='column'
      justifyContent='center'
      py={12}
      px={[2, 6, null, 8]}
    >
      <Box display='flex' justifyContent='center'>
        <KachLogo size='5rem' variant='primary' />
      </Box>

      <Box mt={8} mx='auto' w={['full', 'md']}>
        <Box bg='white' py={8} boxShadow='md' borderRadius='md' px={[4, 8]}>
          {!changed ? (
            <Box>
              <Box mb='5'>
                <Heading mb='1' fontSize={'lg'}>
                  Cambiar contraseña
                </Heading>
                <Text fontSize={'sm'}>
                  Asegúrate de utilizar una contraseña segura y única para
                  proteger tu cuenta y tu información personal.
                </Text>
              </Box>

              <ChangePasswordForm
                isLoading={isLoading}
                onSubmit={onResetPassword}
              />
            </Box>
          ) : (
            <Box
              flexDir={'column'}
              display={'flex'}
              alignItems='center'
              justifyContent={'center'}
            >
              <Box>
                <Heading mb='2' fontSize={'2xl'}>
                  Contraseña cambiada
                </Heading>

                <Text>
                  Tu contraseña ha sido cambiada correctamente. A partir de
                  ahora, utiliza tu nueva contraseña para acceder a tu cuenta.
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
