import { Box, Spinner } from '@chakra-ui/react'
import { IContact, IContactPrice } from 'kach-clients'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { useContactPriceColumns } from '../hooks/useContactPriceColumns'
import { ContactPriceActions } from './ContactPriceActions'
import { CustomTable } from './CustomTable'

export const ContactLabPricesTable = (props: {
  contact: IContact
  prices?: IContactPrice[]
}) => {
  const [commonCols] = useContactPriceColumns()

  const columns = useMemo<Column<IContactPrice>[]>(
    () => [
      {
        id: 'actions',
        //@ts-ignore
        Cell: (cell: Cell<IContactPrice>) => (
          <ContactPriceActions
            contact={props.contact}
            price={cell.row.original}
          />
        ),
      },
      ...commonCols,
    ],
    [],
  )

  if (!props.prices) {
    return <Spinner />
  }

  return (
    <Box>
      <CustomTable
        columns={columns}
        data={props.prices}
        orientation='center'
        noHover
      />
    </Box>
  )
}
