import React, { useCallback, useState } from 'react'
import { Box, Switch, Text, Tooltip, useToast } from '@chakra-ui/react'
import { IIntegrationConnection, IntegrationClient } from 'kach-clients'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { buildIntegrationConnectionQueryKey } from '../utils/build-integration-connection-query-key'
import { timeAgoFormat } from 'kach-commons/src/utils/time-ago-format'
import { buildTestId } from 'kach-commons'
import { hasPermission } from '../utils/has-permission'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const IntegrationConnectionStatus = (props: {
  integrationConnection: IIntegrationConnection
}) => {
  const error = useError()

  const auth = useAuth()

  const queryClient = useQueryClientSingleton()

  const [isLoading, setIsLoading] = useState(false)

  const hasPermissionToDisconnect = () => {
    return hasPermission(auth.role.permissions, {
      resource:
        props.integrationConnection.integration.name === 'autoinspector'
          ? 'autoinspector-integration'
          : 'integration',
      action: 'delete',
    })
  }

  const toast = useToast()

  const onDisconnect = useCallback(() => {
    setIsLoading(true)

    toast.promise(
      IntegrationClient.disconnect(
        props.integrationConnection.integration.name,
      ),
      {
        error: (err) => {
          return { title: error.getErrorMessage(err) }
        },
        loading: {
          title: `Desconectándose de ${props.integrationConnection.integration.label}`,
        },
        success: () => {
          queryClient.invalidateQueries([
            buildIntegrationConnectionQueryKey(
              props.integrationConnection.integration.name,
              auth,
            ),
          ])
          setIsLoading(false)

          return {
            title: `Desconectado correctamente de ${props.integrationConnection.integration.label}`,
          }
        },
      },
    )
  }, [props.integrationConnection])

  return (
    <Box
      mb='5'
      w='full'
      display={'flex'}
      flexDir='row'
      experimental_spaceX={'2'}
      alignItems={'center'}
      justifyContent='flex-start'
    >
      <Text fontSize={'xs'}>
        Conectado desde {timeAgoFormat(props.integrationConnection.createdAt)}
      </Text>
      <Tooltip label='Desconectar'>
        <span>
          {hasPermissionToDisconnect() && (
            <Switch
              {...buildTestId('integration-connection-status-switch')}
              size='sm'
              onChange={onDisconnect}
              disabled={isLoading}
              isDisabled={isLoading}
              id='isChecked'
              isChecked
            />
          )}
        </span>
      </Tooltip>
    </Box>
  )
}
