import httpClient from './http-client';

export interface IRivadaviaCompanyFormula {
  inabilityPointValue: string;
  moralDamagePercentage: string;
  judicialPercentage: string;
  attorneyPercentage: string;
  medicalExpensesPerPerson: string;
}

export interface IRivadaviaLabFormula {}

export interface IFormula {
  formula: string;
  type: string;
  rivadavia_company: IRivadaviaCompanyFormula;
  rivadavia_lab: IRivadaviaLabFormula;
}

export class FormulaClient {
  static async list(): Promise<{ formulas: IFormula[] }> {
    return await httpClient.get('/v1/formula').then(res => res.data);
  }
}
