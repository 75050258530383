import { Box, Button, chakra } from '@chakra-ui/react'
import { INewsRequest } from 'kach-clients'
import React from 'react'
import { CgMailReply } from 'react-icons/cg'
import { ArtifactDisplayer } from './ArtifactDisplayer'
import { CreatedByDate } from './CreatedByDate'
import { NewLineText } from './NewLineText'

export const NewsRequestLine = (props: {
  newsRequest: INewsRequest
  onReply?: () => void
  withReply?: boolean
  withoutUser?: boolean
  withoutCreatedBy?: boolean
}) => {
  return (
    <>
      <chakra.span>
        {!!props.newsRequest.annotation && (
          <NewLineText textProps={{ color: 'gray.600', fontWeight: 'normal' }}>
            "{props.newsRequest.annotation}"
          </NewLineText>
        )}

        {(props.newsRequest?.artifacts || []).map((artifact, index) => {
          return (
            <ArtifactDisplayer
              key={index}
              mode='light'
              artifact={artifact as any}
            />
          )
        })}
      </chakra.span>

      <Box
        experimental_spaceX={'3'}
        display={'flex'}
        flexDir='row'
        alignItems={'center'}
      >
        {!props.withoutCreatedBy && (
          <CreatedByDate
            date={props.newsRequest.createdAt}
            user={props.newsRequest.user}
          />
        )}

        {props.withReply && (
          <Button
            onClick={props.onReply}
            leftIcon={<CgMailReply />}
            size='xs'
            variant='ghost'
          >
            Responder
          </Button>
        )}
      </Box>
    </>
  )
}
