import {
  Box,
  chakra,
  Divider,
  Grid,
  GridItem,
  Icon,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { format } from 'date-fns'
import { INews } from 'kach-clients'
import React from 'react'
import { CustomBreadCrumb } from './CustomBreadCrumb'
import { buildTestId } from 'kach-commons'

export default function NewsDetail(props: {
  news: INews
  withNavigation?: boolean
  onBack?: () => void
}) {
  return (
    <Box px={4}>
      <Box display='flex' alignItems='center'>
        {props.withNavigation && (
          <CustomBreadCrumb
            onBack={props.onBack}
            currentPageLabel={`Novedad #${props.news.id}`}
          />
        )}
      </Box>
      <Box px={4}>
        <dl>
          <Grid
            py={[4]}
            templateColumns={[
              'repeat(1, minmax(0, 1fr))',
              'repeat(2, minmax(0, 1fr))',
            ]}
            gap={[0, 2]}
          >
            <chakra.dt fontWeight='medium' fontSize='sm' color='gray.500'>
              Titulo
            </chakra.dt>
            <chakra.dd
              mt={[1, 0]}
              display='flex'
              fontSize='sm'
              textAlign='right'
            >
              <chakra.span {...buildTestId('news-title')} flexGrow={1}>
                {props.news.title}
              </chakra.span>
            </chakra.dd>
          </Grid>

          <Grid
            py={[4]}
            templateColumns={[
              'repeat(1, minmax(0, 1fr))',
              'repeat(3, minmax(0, 1fr))',
            ]}
            gap={[0, 2]}
          >
            <chakra.dt fontWeight='medium' fontSize='sm' color='gray.500'>
              Descripción
            </chakra.dt>
            <chakra.dd
              as={GridItem}
              colSpan={2}
              mt={[1, 0]}
              display='flex'
              fontSize='sm'
            >
              <chakra.span
                {...buildTestId('news-description')}
                wordBreak={'break-all'}
                flexGrow={1}
              >
                {props.news.description}
              </chakra.span>
            </chakra.dd>
          </Grid>

          <Grid
            py={[4]}
            templateColumns={[
              'repeat(1, minmax(0, 1fr))',
              'repeat(3, minmax(0, 1fr))',
            ]}
            gap={[0, 2]}
          >
            <chakra.dt fontWeight='medium' fontSize='sm' color='gray.500'>
              Fecha
            </chakra.dt>
            <chakra.dd
              as={GridItem}
              colSpan={2}
              mt={[1, 0]}
              display='flex'
              fontSize='sm'
            >
              <chakra.span flexGrow={1}>
                {format(new Date(props.news.createdAt), 'Pp')}
              </chakra.span>
            </chakra.dd>
          </Grid>

          <Grid
            py={[4]}
            templateColumns={[
              'repeat(1, minmax(0, 1fr))',
              'repeat(3, minmax(0, 1fr))',
            ]}
            gap={[0, 2]}
          >
            <chakra.dt fontWeight='medium' fontSize='sm' color='gray.500'>
              Archivos
            </chakra.dt>
            <chakra.dd
              as={GridItem}
              colSpan={2}
              mt={[1, 0]}
              display='flex'
              fontSize='sm'
            >
              {props.news?.artifacts?.length ? (
                <List
                  borderRadius='md'
                  border='1px'
                  borderColor='gray.200'
                  w='full'
                >
                  {props.news.artifacts.map((artifact, index) => {
                    return (
                      <chakra.a
                        {...buildTestId(`news-artifact-${index}`)}
                        key={index}
                        href={artifact.url}
                        target={'_blank'}
                      >
                        <ListItem
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'
                          py={2}
                          pl={2}
                          pr={4}
                          fontSize='sm'
                        >
                          <Box display='flex' alignItems='center' flex={1}>
                            <Icon as={PaperClipIcon} />

                            <Text
                              noOfLines={1}
                              wordBreak={'break-all'}
                              ml={2}
                              flex={1}
                            >
                              {artifact.originalName}
                            </Text>
                          </Box>
                        </ListItem>
                      </chakra.a>
                    )
                  })}
                </List>
              ) : (
                <Text fontSize='sm'>Ningún archivo agregado</Text>
              )}
            </chakra.dd>
          </Grid>
          <Divider />
        </dl>
      </Box>
    </Box>
  )
}
