import { z } from 'zod'

export const investigationTypes = ['f2f', 'virtual'] as const

export type InvestigationType = (typeof investigationTypes)[number]

export const priceUnits = ['percentage', 'ars']

export const numberStringSchema = z
  .any()
  .optional()
  .refine((val) => {
    if (!val) {
      return true
    }

    switch (typeof val) {
      case 'string':
        return new RegExp(/^\d+$/).test(val)

      default:
        return true
    }
  })
  .transform((val) => Number(val) as number | string)

const priceAmountSchema = z
  .object({
    //@ts-ignore
    unit: z.string().optional().nullable().nullish(),
    amount: numberStringSchema,
  })
  .optional()
// .refine(
//   (val) => {
//     if (val.amount && !val.unit) {
//       return false
//     }

//     return true
//   },
//   {
//     message: 'Debe seleccionar una unidad de honorario',
//     path: ['unit'],
//   },
// )
// .refine(
//   (val) => {
//     console.log('val.amount', val)

//     if (val.unit && !val.amount) {
//       return false
//     }

//     return true
//   },
//   {
//     message: 'Debe ingresar un monto de honorario',
//     path: ['amount'],
//   },
// )

export const CreateContactPriceSchema = z
  .object({
    investigationType: z.enum(
      //@ts-ignore
      investigationTypes.concat(['']),
    ),
    sinisterTypeId: numberStringSchema,
    negative: priceAmountSchema,
    surrender: priceAmountSchema,
    surrenderPrizeDays: numberStringSchema,
    surrenderPrize: priceAmountSchema,
    rejected: priceAmountSchema,
    km: priceAmountSchema,
    inability: numberStringSchema,
    upperLimit: numberStringSchema,
    kmLowerLimit: numberStringSchema,
    validFrom: z.coerce.date({
      invalid_type_error: 'Campo requerido',
      required_error: 'Campo requerido',
    }),
    validTo: z.coerce
      .date({
        invalid_type_error: 'Campo requerido',
        required_error: 'Campo requerido',
      })
      .nullish(),
  })
  .refine(
    (val) => {
      if (!val.validTo) return true

      console.log('val.validTo', val.validTo, 'val.validFrom', val.validFrom)

      return (
        new Date(val.validTo).getTime() >= new Date(val.validFrom).getTime()
      )
    },
    {
      message: 'Valido hasta debe ser mayor que valido desde',
      path: ['validTo'],
    },
  )

export interface ICreateContactPriceSchema
  extends z.infer<typeof CreateContactPriceSchema> {}
