import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { IInvitation, InvitationClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const DeleteInvitationBtn: React.FC<{
  invitation: IInvitation
}> = ({ invitation }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const cancelRef = React.useRef()

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()
  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onDelete = useCallback(() => {
    setIsLoading(true)

    toast.promise(InvitationClient.deleteInvitation(invitation.id), {
      loading: { title: 'Eliminando invitación...' },
      success: () => {
        onClose()
        queryClient.invalidateQueries(['invitations'])
        setIsLoading(false)
        return { title: 'Invitación eliminada correctamente' }
      },
      error: (err) => {
        setIsLoading(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [invitation])

  return (
    <Box>
      <Button
        {...buildTestId('delete-invitation-btn')}
        variant='link'
        fontSize='sm'
        color='red.400'
        onClick={onOpen}
      >
        Eliminar
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Eliminar invitación
            </AlertDialogHeader>

            <AlertDialogBody>
              <Box
                {...buildTestId('delete-invitation-dialog')}
                display='flex'
                fontSize='sm'
                flexDir='column'
                experimental_spaceY={2}
              >
                <Text>
                  ¿Deseas eliminar la invitación a{' '}
                  <strong>{invitation.email}</strong>?
                </Text>
                <Text>
                  Recuerda que la persona que recibió la invitación no podrá
                  registrarse si eliminas estas invitación.
                </Text>
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                {...buildTestId('delete-invitation-confirm-btn')}
                colorScheme='red'
                isLoading={isLoading}
                isDisabled={isLoading}
                onClick={onDelete}
                ml={3}
              >
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}
