import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { IContact, ICreateContactPrice } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useContext, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { LAB_PRICES_QUERY_KEY } from '../constants/query-keys'
import { useError } from '../hooks/useError'
import { ContactContext } from '../providers/ContactProvider'
import { ICreateContactPriceSchema } from '../schemas/create-contact-price.schema'
import { ContactPriceForm } from './ContactPriceForm'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const CreateContactLabPriceModal = (props: {
  isOpen: boolean
  onClose: () => void
  contact: IContact
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const formId = 'create-contact-lab-price'

  const { getErrorMessage } = useError()

  const queryClient = useQueryClientSingleton()

  const { onCreate } = useContext(ContactContext)

  const toast = useToast()

  const onCreatePrice = useCallback(
    (data: Required<ICreateContactPriceSchema>) => {
      setIsLoading(true)

      toast.promise(
        onCreate({
          contactId: props.contact.id,
          data: data as ICreateContactPrice,
        }),
        {
          loading: { title: 'Agregando honorario...' },
          success: () => {
            queryClient.invalidateQueries([LAB_PRICES_QUERY_KEY])
            props.onClose()
            setIsLoading(false)
            return { title: 'Honorario agregado correctamente' }
          },
          error: (err) => {
            setIsLoading(false)
            return getErrorMessage(err)
          },
        },
      )
    },
    [props.contact],
  )

  return (
    <Modal size={'3xl'} isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Agregar honorario</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ContactPriceForm
            formId={formId}
            isLoading={isLoading}
            onSubmit={onCreatePrice}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            {...buildTestId('create-contact-lab-price-btn')}
            form={formId}
            type='submit'
            variant={'primary'}
            mr={3}
          >
            Agregar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
