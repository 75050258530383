import axios from 'axios';
import React, { Component, ErrorInfo } from 'react';
import { NavigatorFC } from './NotificationList';
import { UnexpectedError } from './UnexpectedError';

export class ErrorBoundary extends Component<
  { children: React.ReactNode; Navigator: NavigatorFC },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    axios.post('/api/register/error', {
      componentStack: errorInfo?.componentStack,
      error: error.stack,
    });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return <UnexpectedError Navigator={this.props.Navigator} />;
    }

    // Return children components in case of no error

    return this.props.children;
  }
}
