import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { CaseClient, ICreateNews, INewsRequest } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { MAX_FILE_SIZE_IN_MB } from '../hooks/useFileUpload'
import { ArtifactsTokenizer, useFileUploadV2 } from '../hooks/useFileUploadV2'
import { INewsSchema, NewsSchema } from '../schemas/news.schema'
import { FileUploadV2 } from './FileUploadV2'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { EmailListEditable } from './EmailListEditable'
import { Select } from 'chakra-react-select'
import { NewsRequestSelect } from './NewsRequestSelect'
import { CaseTimeline } from './CaseTimeline'
import { newsRequestToEvent } from '../utils/news-request-to-event'

export type OnSubmitNewsFN = (news: ICreateNews, onDone: () => void) => void

export default function CreateNewsForm(props: {
  context: string
  onSubmit: OnSubmitNewsFN
  onClose: () => void
  artifactsTokenizer: ArtifactsTokenizer
  withContext?: boolean
  hideDestinations?: boolean
  newsRequestsFetcher: () => Promise<INewsRequest[]>
  defaultValues?: Partial<Pick<INewsSchema, 'relatedNewsRequest'>>
}) {
  const {
    watch,
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<INewsSchema>({
    resolver: zodResolver(NewsSchema),
    defaultValues: {
      relatedNewsRequest: props.defaultValues?.relatedNewsRequest,
      files: [],
      destinations: [
        {
          email: null,
        },
      ],
    },
  })

  const { append, remove } = useFieldArray({
    control,
    name: 'destinations',
  })

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const fields = watch()

  const { onUploadFiles, fileList, onRemove, onUpdateFile } = useFileUploadV2(
    props.artifactsTokenizer,
  )

  const client = useQueryClientSingleton()

  const onSubmit = useCallback(
    (data: Required<INewsSchema>) => {
      setIsLoading(true)
      props.onSubmit(
        {
          description: data.description,
          title: data.title,
          //@ts-ignore
          artifacts: fileList,
          destinations: (data?.destinations || [])
            .filter((d) => !!d?.email)
            .map((d) => d.email),
          ...(data.relatedNewsRequest
            ? {
                relatedNewsRequestId: data.relatedNewsRequest.id,
              }
            : {}),
        },
        () => setIsLoading(false),
      )
    },
    [fileList, fields.destinations],
  )

  return (
    <chakra.form
      {...buildTestId('create-news-form')}
      onSubmit={handleSubmit(onSubmit)}
      experimental_spaceY={8}
      noValidate
    >
      <Box experimental_spaceY={8}>
        <Box experimental_spaceY={6}>
          {props.withContext && (
            <Box>
              <Text fontSize='lg' fontWeight='medium'>
                Novedad
              </Text>
              <Text mt={1} fontSize='sm' color='gray.500'>
                Crea una novedad para que los tramitadores estén al tanto del
                progreso del caso
              </Text>
            </Box>
          )}

          <FormControl isInvalid={!!errors['description']} isRequired>
            <FormLabel>Descripción</FormLabel>
            <Textarea {...register('description')} />
            {!!!errors['description'] ? (
              <FormHelperText></FormHelperText>
            ) : (
              <FormErrorMessage>
                {errors['description'].message}
              </FormErrorMessage>
            )}
          </FormControl>

          {!props.hideDestinations && (
            <FormControl>
              <FormLabel>Destinatarios adicionales</FormLabel>
              <EmailListEditable<INewsSchema>
                register={register}
                append={() => append({ email: '' })}
                errors={errors}
                list={fields.destinations || []}
                remove={remove}
                pathBuilder={(index) => `destinations.${index}.email`}
              />
            </FormControl>
          )}

          <FormControl>
            <FormLabel>Archivos</FormLabel>
            <FileUploadV2
              onUpdateFile={onUpdateFile}
              inputId={`${props.context}-news`}
              fileList={fileList}
              limit={3}
              maxFileSizeMB={MAX_FILE_SIZE_IN_MB}
              onRemove={onRemove}
              onUpload={onUploadFiles}
            />
          </FormControl>

          <FormControl>
            <Box
              display={'flex'}
              flexDir='row'
              alignItems={'center'}
              experimental_spaceX='2'
            >
              <FormLabel mb='0'>Pedido vinculado</FormLabel>
              {!fields.relatedNewsRequest ? (
                <NewsRequestSelect
                  onClick={(newsRequest, onDone) => {
                    setValue('relatedNewsRequest', newsRequest)
                    onDone()
                  }}
                  newsRequestsFetcher={props.newsRequestsFetcher}
                  queryKeyIdentifier={props.context}
                />
              ) : (
                <Button
                  size='xs'
                  variant='primary'
                  onClick={() => setValue('relatedNewsRequest', null)}
                >
                  Desvincular
                </Button>
              )}
            </Box>
            <Box mt='3'>
              {fields.relatedNewsRequest ? (
                <CaseTimeline
                  events={[newsRequestToEvent(fields.relatedNewsRequest)]}
                />
              ) : (
                <Text fontSize={'xs'}>
                  No se ha seleccionado ninguna solicitud de novedad
                </Text>
              )}
            </Box>
          </FormControl>
        </Box>
      </Box>

      <Box>
        <Box display='flex' justifyContent='flex-end' experimental_spaceX={2}>
          <Button isDisabled={isLoading} onClick={props.onClose} size='sm'>
            Cancelar
          </Button>
          <Button
            {...buildTestId('create-news-form-submit')}
            isLoading={isLoading}
            type='submit'
            variant='primary'
            size='sm'
          >
            Agregar novedad
          </Button>
        </Box>
      </Box>
    </chakra.form>
  )
}
