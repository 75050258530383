import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react'
import { ISession } from 'kach-clients'
import React from 'react'
import { AiOutlineInteraction } from 'react-icons/ai'
import { RxHamburgerMenu } from 'react-icons/rx'
import { SessionEventsModal } from './SessionEventsModal'

export const SessionActions = (props: { session: ISession }) => {
  const { onOpen, isOpen, onClose } = useDisclosure()

  return (
    <>
      <SessionEventsModal
        isOpen={isOpen}
        onClose={onClose}
        session={props.session}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<RxHamburgerMenu />}
          variant='outline'
        />
        <MenuList>
          <MenuItem onClick={onOpen} icon={<AiOutlineInteraction />}>
            Ver actividad
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
