import React, { useMemo } from 'react'
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  GridItem,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { RetrieveCaseViewsResponseBody } from 'kach-commons'
import { buildUserName } from '../utils/build-user-name'
import { formatDate, formatDateToLocaleDays } from '../utils/format-date'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  chakra,
} from '@chakra-ui/react'
import { UserDisplayer } from './UserDisplayer'
import { IoEyeSharp } from 'react-icons/io5'
import { BiCheckDouble } from 'react-icons/bi'

export const ResourceViews = ({
  views,
}: {
  views: RetrieveCaseViewsResponseBody['views']
}) => {
  const maxViews = 7

  const appearViews = useMemo(() => views.slice(0, maxViews), [views])

  return (
    <Box display='flex' alignItems='center' experimental_spaceX={2}>
      {appearViews.map((view, index) => {
        return (
          <Tooltip
            key={index}
            label={
              <span>
                {buildUserName(view.user)}
                <br />
                {formatDate(view.viewedAt)}
              </span>
            }
          >
            <Avatar
              referrerPolicy='no-referrer'
              name={buildUserName(view.user)}
              src={view.user.profilePhoto}
              size='xs'
              bg='primary'
            />
          </Tooltip>
        )
      })}

      {views.length > maxViews && (
        <Popover size='2xl'>
          <PopoverTrigger>
            <chakra.span>
              <Tooltip label='Ver todas las visualizaciones'>
                <Avatar
                  cursor={'pointer'}
                  _hover={{
                    bg: '#cbd5e0',
                  }}
                  width={'7'}
                  size='xs'
                  bg='#edf2f7'
                  color='blackAlpha.800'
                  getInitials={(name) => name}
                  name={`+${views.length - maxViews}`}
                />
              </Tooltip>
            </chakra.span>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Visualizaciones ({views.length})</PopoverHeader>
            <PopoverBody overflowY={'auto'} maxHeight='56'>
              <Stack alignItems={'center'} justifyContent='center' spacing={6}>
                {views.map((view) => {
                  return (
                    <Stack
                      spacing={1}
                      w='full'
                      key={view.id}
                      direction='column'
                    >
                      <UserDisplayer fullWidth key={view.id} user={view.user} />
                      <Box>
                        <Text ml='8' fontSize='xs' color='gray.500'>
                          {formatDate(view.viewedAt)}
                        </Text>
                      </Box>
                    </Stack>
                  )
                })}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Box>
  )
}
