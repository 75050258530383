import React from 'react'
import { useCallback } from 'react'
import { Box, Button, Text, useToast } from '@chakra-ui/react'
import { translator } from '../utils/translator'
import { ResendEmailVerification } from '../components/ResendEmailVerification'

export const useError = () => {
  const getErrorMessage = useCallback((err: any): any => {
    switch (err?.response?.data?.code) {
      case 'lab_limit_exceeded':
        return 'El limite de derivación para el estudio ha sido excedido'

      case 'limit_already_exists_for_user':
        return 'Ya existe un limite para el usuario'

      case 'case_responsible_count_exceeded':
        return `Se ha excedido el limite de responsables para el caso`

      case 'integration_not_found':
        return 'La integración no existe'

      case 'wrong_case_user':
        return 'El rol del usuario no permite realizar esta acción'

      case 'integration_invalid_credentials':
        return 'Las credenciales de la integración no son validas'

      case 'not_found_case_users':
        return 'Este caso no tiene un responsable o colaborador que pueda recibir la solicitud de novedades'

      case 'case_request_repeated':
        return 'Ya existe una solicitud de caso con el mismo numero de siniestro'

      case 'email_wrong_company_type':
        return 'El email ingresado pertenece a una empresa de otro tipo'

      case 'alert_name_duplicated':
        return 'Ya existe una alerta con este nombre'

      case 'contact_email_already_exists':
        return 'El email del estudio ya se encuentra registrado'

      case 'cannot_process_request':
        return 'No se ha podido procesar tu solicitud. Verifica el email y reintenta nuevamente'

      case 'not_found_agency':
        return 'El numero de siniestro tiene un código de agencia que no existe'

      case 'not_found_ramo':
        return 'El numero de siniestro tiene un código de ramo que no existe'

      case 'bad_credentials':
        return 'Usuario o contraseña incorrecto'

      case 'wrong_company_type':
      case 'not_found_user':
        return 'Credenciales invalidas'

      case 'ein_already_exists':
        return 'El CUIT ya existe actualmente en otra empresa'

      case 'name_already_exists':
        return 'El nombre ya existe actualmente en otra empresa'

      case 'email_already_exists':
        return 'El correo electrónico ya se encuentra en uso por otro usuario'

      case 'invitations_already_exists':
        return `${err.response?.data.errors
          .map((e) => e.email)
          .join(', ')} ya fueron invitados y su registro esta pendiente`

      case 'username_already_exists':
        return 'El nombre de usuario ya se encuentra en uso por otra persona'

      case 'emails_already_exists':
        return `${err.response?.data.errors
          .map((e) => e.email)
          .join(', ')} ya se encuentra registrado`

      case 'wrong_identity_email':
        return 'Tu correo electrónico no te permite formar parte de la empresa'

      case 'domain_already_exists':
        return 'El dominio ya existe actualmente en otra empresa'

      case 'invalid_credentials':
      case 'bad_credentials':
        return 'Usuario o contraseña incorrecto'

      case 'wrong_company_type':
      case 'not_found_user':
        return 'Credenciales invalidas'

      case 'ein_already_exists':
        return 'El CUIT ya existe actualmente en otra empresa'

      case 'name_already_exists':
        return 'El nombre ya existe actualmente en otra empresa'

      case 'email_already_exists':
        return 'El correo electrónico ya se encuentra en uso por otro usuario'

      case 'invitations_already_exists':
        return `${err.response?.data.errors
          .map((e) => e.email)
          .join(', ')} ya fueron invitados y su registro esta pendiente`

      case 'username_already_exists':
        return 'El nombre de usuario ya se encuentra en uso por otra persona'

      case 'emails_already_exists':
        return `${err.response?.data.errors
          .map((e) => e.email)
          .join(', ')} ya se encuentra registrado`

      case 'wrong_identity_email':
        return 'Tu correo electrónico no te permite formar parte de la empresa'

      case 'domain_already_exists':
        return 'El dominio ya existe actualmente en otra empresa'

      case 'integration_unavailable':
        return 'Integración no disponible por el momento. Intente mas tarde.'

      case 'wrong_integration_connection_credentials':
        return 'Credenciales de integración invalidas.\n Asegúrate de que el usuario y contraseña sean correctos.'

      case 'not_found_integration_connection':
        'No se encontró la integración'

      case 'customer_identification_missing':
        'El asegurado no posee identificación'

      case 'integration_connection_failed':
        'Conexión con integración fallida'

      case 'user_not_verified':
        return (
          <Box>
            <Text>
              El usuario no ha sido verificado. Para ingresar, verifica tu
              email.
            </Text>
            <ResendEmailVerification
              resendToken={err?.response?.data?.resendToken}
            />
          </Box>
        )

      case 'contact_does_not_support_investigation_type':
        return `El estudio de investigación no soporta el tipo de investigación que se desea derivar`

      case 'email_wrong_company_type':
        return 'El email ingresado no corresponde a un estudio de investigación'

      case 'investigation_already_exists':
        return 'Ya existe una investigación activa para este caso'

      case 'invalid_identification':
        return 'El número de identificación no contiene un numero CUIL valido'

      case 'contact_email_already_exists':
        return 'El correo electrónico ya fue registrado en tus estudios asociados'

      case 'not_found_prices':
        return 'No se encontraron honorarios para el estudio seleccionado'
      case 'cannot_process_request':
        return 'No se ha podido procesar tu solicitud. Verifica el email y reintenta nuevamente'

      case 'not_found_agency':
        return 'El numero de siniestro tiene un código de agencia que no existe'

      case 'not_found_ramo':
        return 'El numero de siniestro tiene un código de ramo que no existe'

      case 'bad_credentials':
        return 'Usuario o contraseña incorrecto'

      case 'bad_credentials':
        return 'Usuario o contraseña incorrecto'

      case 'contact_already_existent':
        return 'El estudio de investigación ya existe dentro de tus contactos'

      case 'wrong_company_type':
      case 'not_found_user':
        return 'Credenciales invalidas'

      case 'ein_already_exists':
        return 'El CUIT ya existe actualmente en otra empresa'

      case 'name_already_exists':
        return 'El nombre ya existe actualmente en otra empresa'

      case 'email_already_exists':
        return 'El correo electrónico ya se encuentra en uso por otro usuario'

      case 'invitations_already_exists':
        return `${err.response?.data.errors
          .map((e) => e.email)
          .join(', ')} ya fueron invitados y su registro esta pendiente`

      case 'limit_already_exists_for_contact':
        return 'Ya existe un limite para los estudios seleccionados'

      case 'username_already_exists':
        return 'El nombre de usuario ya se encuentra en uso por otra persona'

      case 'emails_already_exists':
        return `${err.response?.data.errors
          .map((e) => e.email)
          .join(', ')} ya se encuentra registrado`

      case 'wrong_integration_connection_credentials':
        const [{ integration }] = err.response.data.errors
        return `Las credenciales de la integración ${translator(
          integration,
        )} no son validas. Cámbiala por unas credenciales validas.`

      case 'wrong_identity_email':
        return 'Tu correo electrónico no te permite formar parte de la empresa'

      case 'domain_already_exists':
        return 'El dominio ya existe actualmente en otra empresa'

      case 'contact_does_not_support_sinister_type':
        return 'El contacto no soporta el tipo de siniestro actual'

      case 'integration_connection_not_found':
        return 'No se encontró ninguna conexión de integración con Autoinspector para su usuario.'

      default:
        return 'Ocurrió un error inesperado'
    }
  }, [])

  const toast = useToast()

  const handleError = useCallback((err: any) => {
    console.log('toast executed')

    toast({
      title: getErrorMessage(err),
      status: 'error',
    })
  }, [])

  return {
    handleError,
    getErrorMessage,
  }
}
