import { Box, Button, useDisclosure, useToast } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { ContactClient, ICreateContactSchema } from 'kach-clients'
import { buildTestId } from 'kach-commons'

import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { LAB_CONTACTS_QUERY_KEY } from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { IContactSchema } from '../schemas/contact.schema'
import { CreateContactAutocompleteSchema } from '../schemas/create-contact-autocomplete.schema'
import { hasPermission } from '../utils/has-permission'
import { ContactLabForm } from './ContactLabForm'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { filterFalsyValuesObj } from '../utils/filter-falsy-values-obj'

export const CreateContactLabModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [autocompleteEmail, setAutocompleteEmail] = useState('')

  const auth = useAuth()

  const formId = 'create-contact-lab'

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const { handleError, getErrorMessage } = useError()

  const router = useRouter()

  const toast = useToast()

  useEffect(() => {
    const parse = CreateContactAutocompleteSchema.safeParse(router.query)

    if (parse.success) {
      setAutocompleteEmail(parse.data.email)
      onOpen()
    }
  }, [])

  const onCreateContact = useCallback((data: Required<IContactSchema>) => {
    setIsLoading(true)
    toast.promise(ContactClient.create(filterFalsyValuesObj(data)), {
      loading: { title: 'Creando contacto...' },
      success: () => {
        setAutocompleteEmail('')
        queryClient.invalidateQueries([LAB_CONTACTS_QUERY_KEY])
        setIsLoading(false)
        onClose()
        return { title: 'Contacto creado con éxito' }
      },
      error: (err) => {
        setIsLoading(false)
        return getErrorMessage(err)
      },
    })
  }, [])

  return (
    <Box>
      <Modal size='6xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={5}>
            <ContactLabForm
              defaultValues={{ email: autocompleteEmail }}
              formId={formId}
              onSubmit={onCreateContact}
            />
          </ModalBody>

          <ModalFooter
            display='flex'
            justifyContent='end'
            experimental_spaceX={2}
          >
            <Button disabled={isLoading} size='sm' onClick={onClose}>
              Cancelar
            </Button>
            <Button
              form={formId}
              type='submit'
              disabled={isLoading}
              isLoading={isLoading}
              variant='primary'
              size='sm'
            >
              Crear
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {hasPermission(auth?.role?.permissions, {
        action: 'create',
        resource: 'contact',
      }) && (
        <Button
          {...buildTestId('add-lab-btn')}
          onClick={onOpen}
          variant={'primary'}
          size='sm'
        >
          Agregar estudio
        </Button>
      )}
    </Box>
  )
}
