import { Box, Text } from '@chakra-ui/react'
import React from 'react'

function HeaderPage(
  props: React.PropsWithChildren<{
    ActionComponent?: React.ReactNode
    title: string
    subtitle: string
  }>
) {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <Box>
        <Text fontSize='xl' fontWeight='semibold'>
          {props.title}
        </Text>
        <Text color='gray.600' fontSize='sm'>
          {props.subtitle}
        </Text>
      </Box>
      <Box>{props.ActionComponent}</Box>
    </Box>
  )
}

export default HeaderPage
