import { ContactClient } from 'kach-clients'
import React from 'react'

export interface IContactProvider {
  onFetch: typeof ContactClient.fetchPrices
  onCreate: typeof ContactClient.createPrice
}

export const ContactContext = React.createContext<IContactProvider>({} as any)

export const ContactProvider: React.FC<
  React.PropsWithChildren<IContactProvider>
> = (props) => {
  return (
    <ContactContext.Provider value={props}>
      {props.children}
    </ContactContext.Provider>
  )
}
