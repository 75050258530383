import React from 'react';

export interface IKachItem {
  size: string;
}

type variant = 'white' | 'primary' | 'secondary' | 'alternative';

export interface IKachLogo extends IKachItem {
  variant?: variant;
}

const WhiteLogo = (props: IKachItem) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 1"
    viewBox="0 0 1080 582.93"
    width={props.size}
  >
    <path
      d="M693 367.85v-61.2h-52.38v174.63H693v-59.09h31.32v47c0 26.9-13.86 40.62-41.08 40.62h-32.86c-27.22 0-41.08-14.25-41.08-42.21V320.9c0-28.49 13.86-42.74 41.08-42.74h32.85c27.22 0 41.08 13.72 41.08 41.15v48.54ZM133.39 336.23V58.3h-31.31v451.47h31.31V336.23zM894.24 336.23V58.3h-31.32v451.47h31.32V336.23zM214 376.82q3.08-5.54 3.08-15.83v-82.83h-31.32v96.55h-26.18v28.49h26.18v106.57h31.32v-92.85c0-6.86-1-12.67-3.08-16.89-2.05-3.69-6.16-7.91-11.81-11.6 5.65-3.7 9.81-7.92 11.81-11.61ZM387 481.28V403.2h31v-28.49h-21.24c-27.21 0-41.07 14.24-41.07 42.21v50.64c0 28 13.86 42.21 41.07 42.21H418v-28.49Z"
      style={{
        fill: '#fff',
        stroke: '#fff',
        strokeMiterlimit: 10,
        strokeWidth: '10.5517578125px',
      }}
    />
    <path
      d="M429.11 278.16h-32.35c-27.21 0-41.07 14.25-41.07 42.74V342H387v-35.35h52.37v203.12h31.31V320.9q.01-42.73-41.57-42.74ZM936.85 278.16h-21.24v28.49h31v203.12h31.31V320.9c0-28.49-13.86-42.74-41.07-42.74Z"
      style={{
        fill: '#fff',
        stroke: '#fff',
        strokeMiterlimit: 10,
        strokeWidth: '10.5517578125px',
      }}
    />
  </svg>
);

const PrimaryLogo = (props: IKachItem) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 1"
    viewBox="0 0 1080 582.93"
    width={props.size}
  >
    <path
      d="M693 367.85v-61.2h-52.38v174.63H693v-59.09h31.32v47c0 26.9-13.86 40.62-41.08 40.62h-32.86c-27.22 0-41.08-14.25-41.08-42.21V320.9c0-28.49 13.86-42.74 41.08-42.74h32.85c27.22 0 41.08 13.72 41.08 41.15v48.54ZM133.39 336.23V58.3h-31.31v451.47h31.31V336.23zM894.24 336.23V58.3h-31.32v451.47h31.32V336.23zM214 376.82q3.08-5.54 3.08-15.83v-82.83h-31.32v96.55h-26.18v28.49h26.18v106.57h31.32v-92.85c0-6.86-1-12.67-3.08-16.89-2.05-3.69-6.16-7.91-11.81-11.6 5.65-3.7 9.81-7.92 11.81-11.61ZM387 481.28V403.2h31v-28.49h-21.24c-27.21 0-41.07 14.24-41.07 42.21v50.64c0 28 13.86 42.21 41.07 42.21H418v-28.49Z"
      style={{
        fill: '#465466',
        stroke: '#465466',
        strokeMiterlimit: 10,
        strokeWidth: '10.5517578125px',
      }}
    />
    <path
      d="M429.11 278.16h-32.35c-27.21 0-41.07 14.25-41.07 42.74V342H387v-35.35h52.37v203.12h31.31V320.9q.01-42.73-41.57-42.74ZM936.85 278.16h-21.24v28.49h31v203.12h31.31V320.9c0-28.49-13.86-42.74-41.07-42.74Z"
      style={{
        fill: '#465466',
        stroke: '#465466',
        strokeMiterlimit: 10,
        strokeWidth: '10.5517578125px',
      }}
    />
  </svg>
);

const SecondaryLogo = (props: IKachItem) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 1"
    viewBox="0 0 1080 582.93"
    width={props.size}
  >
    <path
      d="M693 367.85v-61.2h-52.38v174.63H693v-59.09h31.32v47c0 26.9-13.86 40.62-41.08 40.62h-32.86c-27.22 0-41.08-14.25-41.08-42.21V320.9c0-28.49 13.86-42.74 41.08-42.74h32.85c27.22 0 41.08 13.72 41.08 41.15v48.54ZM133.39 336.23V58.3h-31.31v451.47h31.31V336.23zM894.24 336.23V58.3h-31.32v451.47h31.32V336.23zM214 376.82q3.08-5.54 3.08-15.83v-82.83h-31.32v96.55h-26.18v28.49h26.18v106.57h31.32v-92.85c0-6.86-1-12.67-3.08-16.89-2.05-3.69-6.16-7.91-11.81-11.6 5.65-3.7 9.81-7.92 11.81-11.61ZM387 481.28V403.2h31v-28.49h-21.24c-27.21 0-41.07 14.24-41.07 42.21v50.64c0 28 13.86 42.21 41.07 42.21H418v-28.49Z"
      style={{
        fill: '#484667',
        stroke: '#484667',
        strokeMiterlimit: 10,
        strokeWidth: '10.5517578125px',
      }}
    />
    <path
      d="M429.11 278.16h-32.35c-27.21 0-41.07 14.25-41.07 42.74V342H387v-35.35h52.37v203.12h31.31V320.9q.01-42.73-41.57-42.74ZM936.85 278.16h-21.24v28.49h31v203.12h31.31V320.9c0-28.49-13.86-42.74-41.07-42.74Z"
      style={{
        fill: '#484667',
        stroke: '#484667',
        strokeMiterlimit: 10,
        strokeWidth: '10.5517578125px',
      }}
    />
  </svg>
);

const AlternativeLogo = (props: IKachItem) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 1"
    viewBox="0 0 1080 582.93"
    width={props.size}
  >
    <path
      d="M693 367.85v-61.2h-52.38v174.63H693v-59.09h31.32v47c0 26.9-13.86 40.62-41.08 40.62h-32.86c-27.22 0-41.08-14.25-41.08-42.21V320.9c0-28.49 13.86-42.74 41.08-42.74h32.85c27.22 0 41.08 13.72 41.08 41.15v48.54ZM133.39 336.23V58.3h-31.31v451.47h31.31V336.23zM894.24 336.23V58.3h-31.32v451.47h31.32V336.23zM214 376.82q3.08-5.54 3.08-15.83v-82.83h-31.32v96.55h-26.18v28.49h26.18v106.57h31.32v-92.85c0-6.86-1-12.67-3.08-16.89-2.05-3.69-6.16-7.91-11.81-11.6 5.65-3.7 9.81-7.92 11.81-11.61ZM387 481.28V403.2h31v-28.49h-21.24c-27.21 0-41.07 14.24-41.07 42.21v50.64c0 28 13.86 42.21 41.07 42.21H418v-28.49Z"
      style={{
        fill: '#466567',
        stroke: '#466567',
        strokeMiterlimit: 10,
        strokeWidth: '10.5517578125px',
      }}
    />
    <path
      d="M429.11 278.16h-32.35c-27.21 0-41.07 14.25-41.07 42.74V342H387v-35.35h52.37v203.12h31.31V320.9q.01-42.73-41.57-42.74ZM936.85 278.16h-21.24v28.49h31v203.12h31.31V320.9c0-28.49-13.86-42.74-41.07-42.74Z"
      style={{
        fill: '#466567',
        stroke: '#466567',
        strokeMiterlimit: 10,
        strokeWidth: '10.5517578125px',
      }}
    />
  </svg>
);

const icons: { [key: string]: any } = {
  white: WhiteLogo,
  primary: PrimaryLogo,
  secondary: SecondaryLogo,
  black: AlternativeLogo,
};

function KachLogo(props: IKachLogo) {
  const Icon = icons[props.variant as variant];

  if (!Icon) return <WhiteLogo size={props.size} />;

  return <Icon size={props.size} />;
}

export default KachLogo;
