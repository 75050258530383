import { CompanyClient } from 'kach-clients'
import { translator } from 'kach-commons'
import {
  ColumnName,
  ConditionOperator,
  ConditionType,
  caseResults,
  investigationTypes,
} from 'kach-constants'
import { useMemo } from 'react'
import { caseStatus } from '../constants/case-status'

export type ReferenceTables = {
  type: ConditionType
  operator: ConditionOperator
  columnName: ColumnName
  inputType: 'select' | 'input'
  fetcher?: () => Promise<{ label: string; value: string }[]>
  literalEnum?: { label: string; value: string }[]
}

export const useReferenceTables = () => {
  const referenceTables = useMemo<ReferenceTables[]>(
    () => [
      {
        type: 'reference',
        operator: 'equal',
        columnName: 'agency',
        inputType: 'select',
        fetcher: async () =>
          CompanyClient.fetchCompanyEnums().then((res) =>
            res.agencies.map((agency) => ({
              label: agency.name,
              value: agency.id.toString(),
            })),
          ),
      },
      {
        type: 'reference',
        operator: 'equal',
        columnName: 'reason',
        inputType: 'select',
        fetcher: async () =>
          CompanyClient.fetchCompanyEnums().then((res) =>
            res.caseReasons.map((reason) => ({
              label: reason.name,
              value: reason.id.toString(),
            })),
          ),
      },
      {
        type: 'reference',
        operator: 'equal',
        columnName: 'sinisterType',
        inputType: 'select',
        fetcher: async () =>
          CompanyClient.fetchCompanyEnums().then((res) =>
            res.sinisterTypes.map((sinisterType) => ({
              label: sinisterType.name,
              value: sinisterType.id.toString(),
            })),
          ),
      },
      {
        type: 'literal',
        operator: 'equal',
        columnName: 'result',
        inputType: 'select',
        literalEnum: caseResults.map((result) => ({
          label: translator(result),
          value: result,
        })),
        fetcher: async () => {
          return await caseResults.map((result) => ({
            label: translator(result),
            value: result,
          }))
        },
      },
      {
        type: 'literal',
        operator: 'equal',
        columnName: 'internal_status',
        inputType: 'select',
        literalEnum: caseStatus.map((status) => ({
          label: translator(status),
          value: status,
        })),
        fetcher: async () => {
          return await caseStatus.map((status) => ({
            label: translator(status),
            value: status,
          }))
        },
      },
      {
        type: 'literal',
        operator: 'equal',
        columnName: 'investigationType',
        inputType: 'select',
        literalEnum: investigationTypes.map((i) => ({
          label: translator(i),
          value: i,
        })),
        fetcher: async () => {
          return await investigationTypes.map((i) => ({
            label: translator(i),
            value: i,
          }))
        },
      },
    ],
    [],
  )

  return {
    referenceTables,
  }
}
