import React from 'react'
import { Box, chakra } from '@chakra-ui/react'
import { IArtifact } from 'kach-clients'

export interface ICommercialReport {
  id: number
  artifact: IArtifact
}

export const CaseCustomerCommercialReportDetail = (props: {
  report: ICommercialReport
}) => {
  return (
    <Box>
      <chakra.iframe
        height={'800px'}
        width='100%'
        src={props.report.artifact.url}
      />
    </Box>
  )
}
