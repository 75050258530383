import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { useForm } from 'react-hook-form'
import {
  IResetPasswordSchema,
  ResetPasswordSchema,
} from '../schemas/reset-password.schema'

export const ChangePasswordForm = (props: {
  btnSize?: 'full' | 'min'
  isLoading: boolean
  onSubmit: (data: IResetPasswordSchema, reset: () => void) => void
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IResetPasswordSchema>({
    resolver: zodResolver(ResetPasswordSchema),
  })

  return (
    <Box>
      <chakra.form
        onSubmit={handleSubmit((data) => props.onSubmit(data, reset))}
        display={'flex'}
        flexDir='column'
        experimental_spaceY={'6'}
      >
        <FormControl>
          <FormLabel>Nueva contraseña</FormLabel>
          <Input
            isInvalid={!!errors.password}
            type='password'
            size='md'
            {...register('password')}
          />
          {errors.password && (
            <FormErrorMessage>{errors.password.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.passwordConfirmation}>
          <FormLabel>Confirmar nueva contraseña</FormLabel>
          <Input type='password' {...register('passwordConfirmation')} />
          {errors.passwordConfirmation && (
            <FormErrorMessage>
              {errors.passwordConfirmation.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <Button
          {...buildTestId('update-password-btn')}
          w={props.btnSize}
          type='submit'
          isLoading={props.isLoading}
          isDisabled={props.isLoading}
          variant={'primary'}
        >
          Actualizar contraseña
        </Button>
      </chakra.form>
    </Box>
  )
}
