import {
  Box,
  Divider,
  Heading,
  Spinner,
  Tag,
  Text,
  Tooltip,
  useFocusOnShow,
  useToast,
} from '@chakra-ui/react'
import { QueryClientProvider } from '@tanstack/react-query'
import {
  CaseClient,
  CaseInspectionClient,
  ICaseInspection,
  IIntegrationConnection,
} from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useAtom } from 'jotai'
import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { isExternalDialogOpenAtom } from '../atoms/is-external-dialog-open.atom'
import { useError } from '../hooks/useError'
import { useExternalDialogChanges } from '../hooks/useExternalDialogChanges'
import { useIntegrationConnectionCredentials } from '../hooks/useIntegrationConnectionCredentials'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { timeAgoFormat } from 'kach-commons/src/utils/time-ago-format'
import { CaseAccordion } from './CaseAccordion'
import { UserDisplayer } from './UserDisplayer'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import {
  DASHBOARD_URL,
  ModalAutoinspectorInspectionDetail,
} from './ModalAutoinspectorInspectionDetail'
import { RetrieveCaseInspectionTokenResource } from 'kach-constants'
import('ai-elems')

const CaseInspectionLine = (props: {
  caseInspection: ICaseInspection
  integrationConnection?: IIntegrationConnection
  resource: RetrieveCaseInspectionTokenResource
}) => {
  const { getErrorMessage } = useError()

  const [_, setIsExternalDialogOpen] = useAtom(isExternalDialogOpenAtom)

  const [token, setToken] = useState('')

  const toast = useToast()

  const onFetchInspectionToken = useCallback(() => {
    toast.promise(
      CaseInspectionClient.fetchInspectionToken(
        props.caseInspection.id,
        props.resource,
      ),
      {
        success: (res) => {
          setIsExternalDialogOpen(true)
          setToken(res.token)
          return { title: 'Detalle de inspección obtenido correctamente' }
        },
        error: (err) => {
          return getErrorMessage(err)
        },
        loading: {
          title: 'Obteniendo detalles de la inspección...',
        },
      },
    )
  }, [props.caseInspection.id])

  return (
    <>
      {!!token && (
        <ModalAutoinspectorInspectionDetail
          token={token}
          isOpen
          gatewayId={props.caseInspection.gatewayId}
          gatewayInspectionType={props.caseInspection.gatewayInspectionType}
          onClose={() => setToken('')}
        />
      )}

      <Box
        onClick={onFetchInspectionToken}
        cursor={'pointer'}
        className='transition-all'
        p={4}
        borderRadius='lg'
        m={-5}
        _hover={{
          bg: 'gray.100',
        }}
        display={'flex'}
        flexDir='row'
        alignItems={'flex-end'}
        justifyContent={'space-between'}
        h='full'
      >
        <Box>
          <Box mb='2'>
            <Heading mb='3' fontSize={'lg'} fontWeight={'bold'} as='h2'>
              Inspección #
              <span {...buildTestId('case-inspection-id')}>
                {props.caseInspection.gatewayId}
              </span>
            </Heading>
            <Box
              experimental_spaceX={'3'}
              display={'flex'}
              flexDir='row'
              alignItems={'center'}
            >
              <Tag {...buildTestId('case-inspection-type')}>
                {props.caseInspection.gatewayInspectionType}
              </Tag>
              <Tag {...buildTestId('case-inspection-template-name')}>
                {props.caseInspection.gatewayTemplateName}
              </Tag>
              <Tag {...buildTestId('case-inspection-template-type')}>
                {props.caseInspection.gatewayTemplateType}
              </Tag>
            </Box>
          </Box>

          <Box mt='3'>
            <UserDisplayer user={props.caseInspection.createdBy} />
          </Box>
        </Box>
        <Text fontSize={'xs'}>
          {timeAgoFormat(props.caseInspection.createdAt)}
        </Text>
      </Box>
    </>
  )
}

const CreateInspectionBtn = (props: {
  caseId: number
  integrationConnection?: IIntegrationConnection
}) => {
  interface InspectionCreatedEvent {
    event: string
    message: {
      inspectionId: string
      magicLink: string
      message: string
      productId?: string
      productIds?: string[]
    }
  }

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const toast = useToast()

  const onCreateInspection = useCallback(
    (customEvent: CustomEvent<InspectionCreatedEvent>) => {
      toast.promise(
        CaseInspectionClient.createInspection(
          props.caseId,
          customEvent.detail.message.inspectionId,
        ),
        {
          loading: { title: 'Agregando inspección al caso' },
          success: () => {
            queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
            return { title: 'Inspección agregada al caso correctamente' }
          },
          error: error.getErrorMessage(err),
        },
      )
    },
    [],
  )

  useExternalDialogChanges({
    elementId: 'create-inspection-btn',
    customBindings: [
      {
        event: 'ai:inspection-created',
        listener: onCreateInspection,
      },
    ],
  })

  const webComponent = (
    //@ts-ignore
    <ai-create-inspection
      dashboard-url={DASHBOARD_URL}
      color='black'
      bg='#EDF2F7'
      accesstoken={
        props.integrationConnection?.oauth.token ||
        'need_integration_connection'
      }
    />
  )

  if (!props.integrationConnection) {
    return (
      <Tooltip label='Necesitas la integración con Autoinspector para crear una inspección'>
        <span>
          <Box opacity={'0.5'} pointerEvents={'none'}>
            {webComponent}
          </Box>
        </span>
      </Tooltip>
    )
  }

  return webComponent
}

export const CaseInspections = (props: {
  inspections: ICaseInspection[]
  caseId: number
  withoutCreate?: boolean
  resource: RetrieveCaseInspectionTokenResource
}) => {
  const { isLoading, integrationConnection } =
    useIntegrationConnectionCredentials('autoinspector')

  return (
    <Box {...buildTestId('case-inspections-container')}>
      <CaseAccordion
        withoutCreate={props.withoutCreate}
        SuffixComponent={
          isLoading ? (
            <Spinner size='md' />
          ) : (
            <CreateInspectionBtn
              caseId={props.caseId}
              integrationConnection={integrationConnection}
            />
          )
        }
        count={props.inspections?.length}
        label={{
          plural: 'Inspecciones',
          singular: 'Inspección',
        }}
      >
        {props.inspections?.length > 0 ? (
          <Box
            display={'flex'}
            flexDir='column'
            rounded={'lg'}
            bg='white'
            p={5}
          >
            {props.inspections.map((inspection, index) => {
              return (
                <div {...buildTestId('case-inspection-item-line')} key={index}>
                  <CaseInspectionLine
                    integrationConnection={integrationConnection}
                    caseInspection={inspection}
                    resource={props.resource}
                  />
                  {index + 1 !== props.inspections.length && <Divider my='5' />}
                </div>
              )
            })}
          </Box>
        ) : null}
      </CaseAccordion>
    </Box>
  )
}
