import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import {
  IArtifact,
  IInvestigation,
  IInvestigationDetail,
  INews,
  InvestigationClient,
  InvestigationStatus,
} from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { addMoreIndicatorsRequestBodySchema, useError } from 'kach-components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillInfoCircle, AiOutlineSearch } from 'react-icons/ai'
import { FaPlus } from 'react-icons/fa'
import { Cell, Column } from 'react-table'
import { z } from 'zod'
import { FIVE_MINUTES_IN_MS } from '../constants/use-case'
import { useAuth } from '../hooks/useAuth'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { NestedParamsSchema } from '../schemas/nested-params.schema'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'
import { buildUserName } from '../utils/build-user-name'
import { formatDate } from '../utils/format-date'
import { timeAgoFormat, timeAgoFormatBusinessDays } from 'kach-commons'
import { translator } from '../utils/translator'
import { AcceptInvestigationBtn } from './AcceptInvestigationBtn'
import { ArtifactDisplayer } from './ArtifactDisplayer'
import { CancelInvestigation } from './CancelInvestigation'
import { CustomTable } from './CustomTable'
import { InvestigationBudget } from './InvestigationBudget'
import { InvestigationNews } from './InvestigationNews'
import { InvestigationReportDetail } from './InvestigationReportDetail'
import { InvestigationReports } from './InvestigationReports'
import { InvestigationSection } from './InvestigationSection'
import { InvestigationTypeSelect } from './InvestigationTypeSelect'
import { NewLineText } from './NewLineText'
import NewsDetail from './NewsDetail'
import { NotesMessages } from './NotesMessages'
import { RejectInvestigationBtn } from './RejectInvestigationBtn'
import { TimelineInvestigation } from './TimelineInvestigation'
import { UploadInvestigationInvoice } from './UploadInvestigationInvoice'
import { UserDisplayer } from './UserDisplayer'

type FormData = z.infer<typeof addMoreIndicatorsRequestBodySchema>

const AddMoreIndicators = ({
  investigation,
}: {
  investigation: IInvestigation
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onToggle, onClose } = useDisclosure()
  const form = useForm<FormData>({
    resolver: zodResolver(addMoreIndicatorsRequestBodySchema),
  })

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const error = useError()

  const onAddIndicators = useCallback(
    (data: FormData) => {
      setIsLoading(true)

      toast.promise(
        InvestigationClient.addMoreIndicators(investigation.id, {
          indicators: data.indicators,
        }),
        {
          loading: {
            title: 'Agregando indicadores a la investigación...',
          },
          success: () => {
            queryClient.invalidateQueries([
              buildInvestigationQueryKey(investigation.id),
            ])

            queryClient.invalidateQueries([
              buildCaseQueryKey(investigation.case_id),
            ])

            onClose()
            setIsLoading(false)
            return { title: 'Investigación actualizada' }
          },
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
        },
      )
    },
    [investigation.id, investigation.case_id],
  )

  return (
    <>
      <Box position={'relative'}>
        <IconButton
          aria-label='add-more-indicators'
          size={'xs'}
          onClick={onToggle}
          variant={'outline'}
          icon={<FaPlus color='white' height={8} width={8} />}
          position={'absolute'}
          top={'-3'}
          right={'0'}
          _hover={{ textColor: 'white' }}
        />
      </Box>
      <Modal isOpen={isOpen} onClose={onToggle}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={form.handleSubmit(onAddIndicators)}>
            <ModalHeader>Modificar investigación</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb='4'>Agrega indicadores a la investigación</Text>
              <FormControl
                isRequired
                isInvalid={!!form.formState.errors.indicators}
              >
                <FormLabel>Indicadores</FormLabel>
                <Textarea {...form.register('indicators')} />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup>
                <Button onClick={onToggle} variant={'ghost'}>
                  Cancelar
                </Button>
                <Button type='submit' variant='primary' isLoading={isLoading}>
                  Confirmar
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

const InvestigationInvoicesSection = (props: {
  canAddInvoice: boolean
  data: IInvestigation
}) => {
  const columns = useMemo<Column<IArtifact>[]>(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Nombre',
        accessor: 'originalName',
      },
      {
        Header: 'Fecha de subida',
        accessor: (row: IArtifact) => formatDate(row.createdAt),
      },
      {
        Header: 'Tags',
        Cell: ({ row }: Cell<IArtifact>) => {
          return (
            <Box>
              {row.original.tags?.map((tag, index) => {
                return (
                  <chakra.span
                    key={index}
                    mr={2}
                    px={2}
                    py={1}
                    rounded={'md'}
                    bg='gray.200'
                    color='gray.800'
                    fontSize={'xs'}
                  >
                    {tag.name}
                  </chakra.span>
                )
              })}
            </Box>
          )
        },
      },
    ],
    [],
  )

  return (
    <InvestigationSection
      description='Facturas cargadas por el estudio de investigación'
      title='Facturas'
      renderButton={
        props.canAddInvoice && (
          <UploadInvestigationInvoice investigation={props.data} />
        )
      }
    >
      <CustomTable noHover data={props.data.invoices} columns={columns} />
    </InvestigationSection>
  )
}

const InvestigationStatusContext = (props: {
  investigation: IInvestigation
}) => {
  const StatusWrapper: React.FC<
    React.PropsWithChildren<{ status: InvestigationStatus }>
  > = ({ children, status }) => {
    return (
      <Box
        borderRadius={'lg'}
        borderWidth={'1px'}
        bg='gray.100'
        borderColor={'gray.100'}
        p={2}
      >
        <Box borderTopRadius={'lg'} bg='primary' mx={-2} p={2} my={-2}>
          <Box alignItems={'center'} display={'flex'} flexDir='row'>
            <AiFillInfoCircle
              color='white'
              style={{
                marginRight: '5px',
              }}
            />
            <Heading color='white' fontSize={'lg'}>
              {translator(`investigation-status#${status}`)}
            </Heading>
          </Box>
        </Box>
        {children}
      </Box>
    )
  }

  switch (props.investigation.status) {
    case 'pending':
      return (
        <StatusWrapper status={props.investigation.status}>
          <Text mt='4'>
            La solicitud de investigación al estudio de investigación{' '}
            <strong>{props.investigation.contact.name}</strong> fue enviada{' '}
            {timeAgoFormat(props.investigation.createdAt)}. Se esta esperando la
            aceptación o rechazo del mismo.
          </Text>
        </StatusWrapper>
      )

    case 'rejected':
      return (
        <StatusWrapper status={props.investigation.status}>
          <Text mt='4'>
            La solicitud fue rechazada por el estudio de investigación{' '}
            <strong>{props.investigation.contact.name}</strong> debido al
            siguiente motivo:
            <br />
            <chakra.span
              fontStyle={'italic'}
            >{`"${props.investigation.rejectedReason}"`}</chakra.span>
            <br />
            <Box
              justifyContent={'end'}
              display={'flex'}
              w='full'
              alignItems={'flex-end'}
            >
              <chakra.span fontSize={'xs'}>
                {timeAgoFormat(props.investigation.rejectedAt)}
              </chakra.span>
            </Box>
          </Text>
        </StatusWrapper>
      )

    case 'canceled':
      return (
        <StatusWrapper status={props.investigation.status}>
          <Box mt='4'>
            La investigación fue cancelada por{' '}
            <strong>{buildUserName(props.investigation.canceledBy)}</strong>{' '}
            {!!props.investigation.canceledReason && (
              <>
                debido al siguiente motivo:
                <br />
                <chakra.span
                  fontStyle={'italic'}
                >{`"${props.investigation.canceledReason}"`}</chakra.span>
              </>
            )}
            <br />
            <Box
              justifyContent={'end'}
              display={'flex'}
              w='full'
              alignItems={'flex-end'}
            >
              <chakra.span fontSize={'xs'}>
                {timeAgoFormat(props.investigation.canceledAt)}
              </chakra.span>
            </Box>
          </Box>
        </StatusWrapper>
      )

    case 'accepted':
      return (
        <StatusWrapper status={props.investigation.status}>
          <Text mt='4'>
            La solicitud fue aceptada por el estudio de investigación{' '}
            <strong>{props.investigation.contact.name}</strong>{' '}
            {timeAgoFormat(props.investigation.acceptedAt)} y la investigación
            se encuentra en progreso
          </Text>
        </StatusWrapper>
      )

    case 'closed':
      return (
        <StatusWrapper status={props.investigation.status}>
          <Text mt='4'>
            La solicitud fue cerrada{' '}
            {timeAgoFormat(props.investigation.closedAt)}
          </Text>
        </StatusWrapper>
      )

    default:
      return null
  }
}

export default function InvestigationDetail(props: {
  withoutCreate?: boolean
  investigation: IInvestigation
  query?: any
  renderPrevSections?: React.FC<{ investigation: IInvestigationDetail }>
}) {
  const auth = useAuth()

  const { isLoading, data } = useQuerySingleton(
    [buildInvestigationQueryKey(props.investigation.id)],
    () => InvestigationClient.detail(props.investigation.id, auth.company.type),
    {
      retry: 1,
      refetchInterval: FIVE_MINUTES_IN_MS,
    },
  )

  const [reportId, setReportId] = useState<number>()

  const [news, setNews] = useState<INews>()

  const isUserIncludedInCase = data?.case?.users?.some(
    (user) => user.user_id === auth.id,
  )

  const canAddInvoice = auth.company.type === 'lab' && !!data?.acceptedAt

  useEffect(() => {
    const focusObjectParam = NestedParamsSchema.safeParse(props.query)

    if (
      focusObjectParam.success &&
      focusObjectParam.data.nested_object === 'report' &&
      props.investigation.reports.some(
        (report) => report.id === parseInt(focusObjectParam.data.nested_id),
      )
    ) {
      setReportId(parseInt(focusObjectParam.data.nested_id))
    }
  }, [props.query])

  if (isLoading || !data) {
    return (
      <Box padding='6' bg='white'>
        <SkeletonCircle size='10' />
        <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='2' />
      </Box>
    )
  }

  if (news) {
    return (
      <NewsDetail
        onBack={() => setNews(undefined)}
        withNavigation
        news={news}
      />
    )
  }

  if (reportId) {
    return (
      <div>
        <InvestigationReportDetail
          onBack={() => setReportId(undefined)}
          reportId={reportId}
          investigationId={props.investigation.id}
          caseId={props.investigation.case_id}
        />
      </div>
    )
  }

  return (
    <Box {...buildTestId('case-investigation-detail')} w='full'>
      <Box minH='full'>
        <chakra.main py='10'>
          <Box display='flex' justifyContent='space-between'>
            <Box display={'flex'} alignItems='center'>
              <Box>
                <Box mb='4'>
                  <chakra.h1 fontSize={'2xl'} fontWeight='bold'>
                    {data.contact.name}
                  </chakra.h1>
                  <Text fontSize={'sm'} fontWeight='medium' color='gray.500'>
                    {data.contact.email}
                  </Text>
                </Box>

                <UserDisplayer user={data.startedBy} withContactCellphone />
              </Box>
            </Box>

            {data.status === 'pending' && auth.company.type === 'lab' && (
              <Box
                justifyContent={'stretch'}
                mt='6'
                display={'flex'}
                flexDir='column-reverse'
                flexDirection={'row'}
                experimental_spaceX='5'
              >
                <RejectInvestigationBtn investigation={data} />
                <AcceptInvestigationBtn investigation={data} />
              </Box>
            )}

            {!props.withoutCreate && isUserIncludedInCase && (
              <Box
                justifyContent={'justify-between'}
                mt='4'
                display='flex'
                flexDir={'row'}
                experimental_spaceX={'4'}
              >
                {['pending', 'accepted'].includes(data.status) && (
                  <Box>
                    <InvestigationTypeSelect investigation={data} />
                  </Box>
                )}

                {['pending', 'accepted'].includes(data.status) && (
                  <Box>
                    <chakra.span
                      rounded='md'
                      shadow='sm'
                      display='flex'
                      isolation={'isolate'}
                      experimental_spaceX='2'
                    >
                      <CancelInvestigation investigation={data} />
                    </chakra.span>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          <Box mt='8'>
            <Box
              borderRadius={'lg'}
              borderWidth={'1px'}
              bg='gray.100'
              borderColor={'gray.100'}
              p={2}
            >
              <Box borderTopRadius={'lg'} bg='primary' mx={-2} p={2} my={-2}>
                <Box
                  alignItems={'center'}
                  display={'flex'}
                  flexDir='row'
                  justifyContent={'space-between'}
                >
                  <Box display='flex' alignItems='center'>
                    <AiOutlineSearch
                      color='white'
                      style={{
                        marginRight: '5px',
                      }}
                    />
                    <Heading color='white' fontSize={'lg'}>
                      Indicadores
                    </Heading>
                  </Box>
                  {['pending', 'accepted'].includes(data.status) &&
                    auth.company.type === 'insurer' && (
                      <AddMoreIndicators investigation={data} />
                    )}
                </Box>
              </Box>
              <NewLineText textProps={{ mt: '4' }}>
                {data.indicators}
              </NewLineText>
            </Box>
          </Box>

          <Box mt='8'>
            <InvestigationStatusContext investigation={data} />
          </Box>

          <Box mt='8'>
            <Box display='flex' flexDir={'column'} experimental_spaceY='10'>
              <Stack alignItems={'start'} spacing={5} direction={'column'}>
                <Text fontWeight={'bold'}>Facturas</Text>
                <Stack spacing={3} alignItems={'start'} dir='column'>
                  {data.invoices.length > 0 ? (
                    data.invoices.map((invoice, index) => {
                      const createdAt = `${format(
                        new Date(invoice.createdAt),
                        'Pp',
                      )} (${timeAgoFormatBusinessDays(invoice.createdAt)})`

                      return (
                        <Box
                          display={'flex'}
                          flexDir={'column'}
                          experimental_spaceX={1}
                        >
                          <ArtifactDisplayer key={index} artifact={invoice} />
                          <Box>
                            <Text
                              p={1}
                              wordBreak={'break-all'}
                              noOfLines={1}
                              fontSize='xs'
                            >
                              subida el {createdAt}
                            </Text>
                          </Box>
                        </Box>
                      )
                    })
                  ) : (
                    <Text fontSize={'xs'} fontStyle={'italic'}>
                      No se han subido facturas
                    </Text>
                  )}
                </Stack>
                {canAddInvoice && (
                  <UploadInvestigationInvoice investigation={data} />
                )}
              </Stack>

              {typeof props.renderPrevSections === 'function' &&
                props.renderPrevSections({ investigation: data })}

              <InvestigationReports
                investigation={data}
                onSeeReportDetails={setReportId}
                reports={data.reports}
              />

              <InvestigationNews
                isUserIncludedInCase={isUserIncludedInCase}
                withoutCreate={props.withoutCreate}
                investigation={data}
                onSeeNewsDetails={setNews}
                news={data.news}
              />

              <NotesMessages
                withoutCreate={props.withoutCreate}
                messages={data.messages}
                investigationId={data.id}
              />

              <InvestigationBudget investigation={data} />

              <TimelineInvestigation
                investigation={data}
                events={data.events}
              />
            </Box>
          </Box>
        </chakra.main>
      </Box>
    </Box>
  )
}
