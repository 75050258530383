import React from 'react'
import { Box, Button, Heading, Text } from '@chakra-ui/react'
import { AuthenticationClient } from 'kach-clients'
import { NavigatorFC } from './NotificationList'

export const AccountVerifiedMessage = (props: { Navigator: NavigatorFC }) => {
  return (
    <Box
      justifyContent={'center'}
      display={'flex'}
      alignItems='center'
      h='100vh'
    >
      <Box p={8} textAlign='center'>
        <Heading as='h1' mb='5' size='xl'>
          ¡Felicidades!
        </Heading>
        <Box maxW='lg'>
          <Text fontSize='xl'>
            Tu cuenta ha sido verificada correctamente. Ahora puedes disfrutar
            de todas las funcionalidades de nuestro servicio
          </Text>
          <Box mt='10'>
            <props.Navigator href={'/'}>
              <Button variant={'primary'}>Ir al dashboard</Button>
            </props.Navigator>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
