import {
  Box,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputLeftElement,
  ListProps,
} from '@chakra-ui/react'
import {
  INotification,
  IPagination,
  IPaginationQuery,
  NotificationClient,
  NotificationFilterType,
} from 'kach-clients'
import { NotificationEntityType, notificationEntityTypes } from 'kach-commons'
import { useInfiniteQuery } from '@tanstack/react-query'
import {
  components,
  OptionProps,
  Select as ChakraReactSelect,
} from 'chakra-react-select'
import flatten from 'lodash.flatten'
import React, { useContext, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { NOTIFICATIONS_PAGINATION_QUERY_KEY } from '../constants/query-keys'
import { useDebounceSearch } from '../hooks/useDebounceSearch'
import { KachContext } from '../providers/KachProvider'
import { getLastItem } from '../utils/get-last-item'
import { translator } from '../utils/translator'
import { LoadMorePagination } from './LoadMorePagination'
import { NavigatorFC, NotificationList } from './NotificationList'
import { NotificationTags } from './NotificationTags'
import { WaitForQuery } from './WaitForQuery'

export const FETCH_NOTIFICATIONS_FILTER: Partial<IPaginationQuery> & {
  filter: NotificationFilterType
} = {
  page: 1,
  limit: 10,
  filter: 'unread',
}

export const NotificationsManager = (props: {
  Navigator: NavigatorFC
  initNotifications: IPagination<INotification>
  listStyles?: ListProps
}) => {
  const { debounceSearch, search } = useDebounceSearch()

  const [filter, setFilter] = useState<NotificationFilterType>('')
  const [entityType, setEntityType] = useState<NotificationEntityType>()

  const { defaultContext } = useContext(KachContext)

  const [tags, setTags] = useState<number[]>([])

  const query = useInfiniteQuery({
    context: defaultContext,
    queryKey: [
      NOTIFICATIONS_PAGINATION_QUERY_KEY,
      search,
      filter,
      tags,
    ] as const,
    queryFn: (context) => {
      const [_, search, filter, tags] = context.queryKey

      return NotificationClient.paginate({
        search,
        limit: FETCH_NOTIFICATIONS_FILTER.limit,
        page: context.pageParam,
        filter,
        tags,
      })
    },
    getNextPageParam: (lastPage) => {
      const nextPage = (() => {
        if (!lastPage?.hasMore) {
          return undefined
        }

        return (lastPage.page || 0) + 1
      })()

      return nextPage
    },
    initialData: {
      pages: [props.initNotifications],
      pageParams: [1],
    },
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 1,
  })

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    isLoading,
    error,
  } = query

  const lastPage = getLastItem(data?.pages)

  return (
    <Box>
      <Box experimental_spaceX={'10'} display={'flex'} flexDir='row'>
        <ButtonGroup size={'md'} isAttached w='40%'>
          <Button
            onClick={() => setFilter(undefined)}
            bg={!filter ? 'gray.200' : 'gray.50'}
            borderColor={'gray.300'}
            borderWidth={'1px'}
            size='sm'
          >
            Todas
          </Button>
          <Button
            onClick={() => setFilter('unread')}
            bg={filter ? 'gray.200' : 'gray.50'}
            borderColor={'gray.300'}
            borderWidth={'1px'}
            size='sm'
          >
            No leídas
          </Button>
        </ButtonGroup>
        <InputGroup size={'sm'} width='60%'>
          <InputLeftElement
            pointerEvents='none'
            children={<BiSearch color='gray.300' />}
          />
          <Input
            borderRadius={'lg'}
            onChange={(e) => debounceSearch(e.target.value)}
            placeholder='Buscar notificación'
          />
        </InputGroup>
      </Box>

      <Box
        borderRadius={'lg'}
        mt='5'
        borderWidth={'1px'}
        borderColor='gray.300'
        p={5}
      >
        <Box zIndex={100} bg='white' mb='2'>
          <NotificationTags onChangeTagsFilter={setTags} />
        </Box>

        <WaitForQuery query={query}>
          {(notifications) => {
            return (
              <NotificationList
                listStyles={props.listStyles}
                Navigator={props.Navigator}
                invalidatePagination
                notifications={flatten(
                  notifications?.pages.map((page) => page.results),
                )}
                isLoading={isLoading}
              />
            )
          }}
        </WaitForQuery>

        <LoadMorePagination
          label='Notificaciones'
          onLoadMore={() => fetchNextPage()}
          isFetchingNextPage={isFetchingNextPage}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          pagination={lastPage}
        />
      </Box>
    </Box>
  )
}
