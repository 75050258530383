import {
  chakra,
  Box,
  Button,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  FormHelperText,
  FormControl,
  Icon,
  ModalFooter,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useToggle } from 'ahooks'
import {
  IIntegration,
  IIntegrationConnection,
  IntegrationClient,
} from 'kach-clients'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useIntegrationConnectionCredentials } from '../hooks/useIntegrationConnectionCredentials'
import {
  ConnectToIntegrationSchema,
  IConnectToIntegrationSchema,
} from '../schemas/connect-to-integration.schema'
import { buildIntegrationConnectionQueryKey } from '../utils/build-integration-connection-query-key'
import { IntegrationDetailWrapper } from './IntegrationDetailWrapper'
import { BsFillLockFill } from 'react-icons/bs'
import { buildTestId } from 'kach-commons'
import { FolderPlusIcon } from '@heroicons/react/24/outline'
import { hasPermission } from '../utils/has-permission'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const CredentialsForm = (props: {
  ignorePermission?: boolean
  integration: Pick<IIntegration, 'extraCredentialLabel'>
  defaultValue?: Partial<IIntegrationConnection['login']>
  btnLabel?: string
  onSubmit: (credentials: IConnectToIntegrationSchema) => void
  isLoading?: boolean
  update?: boolean
  formId?: string
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IConnectToIntegrationSchema>({
    defaultValues: props?.defaultValue,
    resolver: zodResolver(
      ConnectToIntegrationSchema(props.integration.extraCredentialLabel),
    ),
  })

  return (
    <form
      {...buildTestId('connect-to-integration-form')}
      onSubmit={handleSubmit(props.onSubmit)}
      id={props.formId}
    >
      <Box display={'flex'} flexDir='column' experimental_spaceY={'5'}>
        <FormControl>
          <FormLabel>Usuario</FormLabel>
          <Input {...register('user')} />
        </FormControl>
        <FormControl>
          <FormLabel>Contraseña</FormLabel>
          <Input {...register('password')} type='password' />
          {props.update && (
            <FormHelperText>
              Por cuestiones de seguridad, no podemos mostrarte la contraseña
              actual
            </FormHelperText>
          )}
        </FormControl>
        {props.integration.extraCredentialLabel && (
          <FormControl>
            <FormLabel>{props.integration.extraCredentialLabel}</FormLabel>
            <Input {...register('extraCredentialValue')} />
          </FormControl>
        )}
        <Box>
          <Box display={'flex'} flexDir='row' alignItems={'start'}>
            <chakra.span mt='2'>
              <BsFillLockFill fontSize={'1.5rem'} />
            </chakra.span>

            <Box
              ml='2'
              display={'flex'}
              flexDir='column'
              experimental_spaceY={'1'}
            >
              <Text fontSize={'md'} fontWeight={'bold'}>
                Credenciales protegidas
              </Text>
              <Text fontSize={'xs'} color='gray.600'>
                Tus credenciales se guardan encriptadas, por lo que están
                seguras.
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

const CreateCredentialsModal = (props: {
  toggle: () => void
  open: boolean
  integration: IIntegration
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const auth = useAuth()

  const toast = useToast()

  const onCreateCredentials = React.useCallback(
    (credentials: IConnectToIntegrationSchema) => {
      setIsLoading(true)

      toast.promise(
        IntegrationClient.connect(props.integration.name, credentials),
        {
          loading: {
            title: 'Configurando credenciales...',
          },
          success: () => {
            queryClient.invalidateQueries([
              buildIntegrationConnectionQueryKey(props.integration.name, auth),
            ])
            props.toggle()
            setIsLoading(false)
            return {
              title: 'Credenciales configuradas correctamente',
            }
          },
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
        },
      )
    },
    [props.integration],
  )

  const formId = 'create-credentials-form'

  return (
    <Modal isOpen={props.open} onClose={props.toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody p={5}>
          <CredentialsForm
            formId={formId}
            integration={props.integration}
            isLoading={isLoading}
            onSubmit={onCreateCredentials}
            btnLabel='Crear'
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={isLoading}
            isLoading={isLoading}
            type='submit'
            form={formId}
            variant='primary'
            mr={3}
          >
            Conectar
          </Button>
          <Button isDisabled={isLoading} onClick={props.toggle} variant='ghost'>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const EmptyState = (props: { integration: IIntegration }) => {
  const [open, { toggle }] = useToggle()

  const auth = useAuth()

  return (
    <>
      <CreateCredentialsModal
        integration={props.integration}
        open={open}
        toggle={toggle}
      />
      <Box textAlign={'center'} mt='10'>
        <Icon as={FolderPlusIcon} w='12' h='12' color='gray.400' />
        <Heading mt='2' fontSize={'sm'} fontWeight='medium' color='gray.900'>
          Credenciales sin configurar
        </Heading>
        <Text mt='1' fontSize={'sm'} color='gray.500'>
          Empieza creando las credenciales para esta integración
        </Text>
        <Box mt='6'>
          {hasPermission(auth.role.permissions, {
            resource: 'integration',
            action: 'create',
          }) && (
            <Button
              {...buildTestId('create-credentials-button')}
              size='md'
              variant={'primary'}
              onClick={toggle}
              type='button'
            >
              <AiOutlinePlus
                style={{
                  marginRight: '5px',
                }}
                aria-hidden='true'
              />
              Crear credenciales
            </Button>
          )}
        </Box>
      </Box>
    </>
  )
}

const UpdateIntegrationConnectionCredentials = (props: {
  integrationConnection: IIntegrationConnection
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()
  const auth = useAuth()

  const formId = 'update-credentials-form'

  const toast = useToast()

  const onUpdateDataProvider = React.useCallback(
    (data: IConnectToIntegrationSchema) => {
      setIsLoading(true)

      toast.promise(
        IntegrationClient.update(props.integrationConnection.id, data),
        {
          error: (err) => {
            return { title: error.getErrorMessage(err) }
          },
          loading: {
            title: 'Actualizando credenciales...',
          },
          success: (res) => {
            queryClient.invalidateQueries([
              buildIntegrationConnectionQueryKey(
                props.integrationConnection.integration.name,
                auth,
              ),
            ])

            return { title: 'Credenciales actualizadas correctamente' }
          },
        },
      )

      setIsLoading(false)
    },
    [props.integrationConnection],
  )

  return (
    <Box maxW={'lg'}>
      <Box display={'flex'} flexDir='column' experimental_spaceY={'10'}>
        <CredentialsForm
          update
          formId={formId}
          integration={props.integrationConnection.integration}
          defaultValue={props.integrationConnection.login}
          isLoading={isLoading}
          onSubmit={onUpdateDataProvider}
          btnLabel='Actualizar'
        />
        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          type='submit'
          form={formId}
          variant='primary'
          mr={3}
        >
          Guardar
        </Button>
      </Box>
    </Box>
  )
}

export const IntegrationConnectionLogin = (props: {
  integration: IIntegration
  onBack: () => void
}) => {
  const auth = useAuth()

  const { isLoading, integrationConnection } =
    useIntegrationConnectionCredentials(props.integration.name)

  return (
    <IntegrationDetailWrapper
      integrationConnection={integrationConnection}
      integration={props.integration}
      onBack={props.onBack}
      isLoading={isLoading}
    >
      {(() => {
        if (integrationConnection) {
          return (
            <UpdateIntegrationConnectionCredentials
              integrationConnection={integrationConnection}
            />
          )
        }

        return <EmptyState integration={props.integration} />
      })()}
    </IntegrationDetailWrapper>
  )
}
