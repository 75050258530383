import { Button, MenuItem, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { IoPersonRemoveSharp } from 'react-icons/io5'
import { AuthorizationClient, IMember } from 'kach-clients'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { MEMBERS_QUERY_KEY } from '../constants/query-keys'
import { hasPermission } from '../utils/has-permission'
import { useAuth } from '../hooks/useAuth'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const RemoveUser = (props: { member: IMember }) => {
  const { onOpen, isOpen, onToggle, onClose } = useDisclosure()

  const cancelRef = React.useRef()

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const auth = useAuth()

  const toast = useToast()

  const onRemoveUser = useCallback(() => {
    setIsLoading(true)

    toast.promise(AuthorizationClient.removeUser(props.member.id), {
      loading: {
        title: 'Eliminando usuario...',
      },
      success: () => {
        queryClient.invalidateQueries([MEMBERS_QUERY_KEY])
        onClose()
        setIsLoading(false)
        return {
          title: 'Usuario eliminado correctamente',
        }
      },
      error: (err) => {
        setIsLoading(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [props.member.id])

  return (
    <>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Eliminar usuario
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estas seguro? Esta es una <strong>acción irreversible</strong>{' '}
              que impedirá a {props.member.email} tener acceso a la plataforma
              de manera permanente.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose} isDisabled={isLoading} ref={cancelRef}>
                Cancelar
              </Button>
              <Button
                disabled={isLoading}
                colorScheme='red'
                onClick={onRemoveUser}
                ml={3}
              >
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <MenuItem
        isDisabled={
          !hasPermission(auth.role.permissions, {
            action: 'delete',
            resource: 'user',
          }) || auth.id === props.member.id
        }
        onClick={onOpen}
        icon={<IoPersonRemoveSharp />}
      >
        Eliminar usuario
      </MenuItem>
    </>
  )
}
