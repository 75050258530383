import { z } from 'zod';
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema';

//@ts-ignore
export const CreateRoleSchema = z.object({
  name: z.string().min(1, REQUIRED_FIELD_MESSAGE),
  //@ts-ignore
  permissions: z.array(z.boolean()),
});

export interface ICreateRoleSchema extends z.infer<typeof CreateRoleSchema> {}
