import { Alert, AlertIcon, Spinner } from '@chakra-ui/react'
import { IJob } from 'kach-clients'
import React from 'react'
import { formatDate } from '../utils/format-date'

export const JobProgress = (props: { job: IJob; label: string }) => {
  switch (props.job?.status) {
    case 'pending':
      return (
        <Alert fontSize={'sm'} borderRadius={'md'} status='info'>
          <AlertIcon />
          {props.label} está en cola
        </Alert>
      )

    case 'failed':
      return (
        <Alert fontSize={'sm'} borderRadius={'md'} status='error'>
          <AlertIcon />
          {props.job?.failedReason}
        </Alert>
      )

    case 'running':
      return (
        <Alert fontSize={'sm'} borderRadius={'md'} status='info'>
          <Spinner mr='3' size='xs' />
          {props.label} está en ejecución
        </Alert>
      )

    case 'completed':
      return (
        <Alert fontSize={'sm'} borderRadius={'md'} status='success'>
          <AlertIcon />
          {props.label} finalizó con éxito el{' '}
          {formatDate(props.job?.completedAt)}
        </Alert>
      )
  }
}
