import {
  Box,
  Button,
  Text,
  useDisclosure,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Portal,
  Heading,
} from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SecurityClient } from 'kach-clients'
import { AiOutlineMail } from 'react-icons/ai'
import { useError } from '../hooks/useError'
import {
  IForgotPasswordSchema,
  ForgotPasswordSchema,
} from '../schemas/forgot-password.schema'
import { buildTestId } from 'kach-commons'

export const ForgotPasswordModal = (props: {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}) => {
  const { register, handleSubmit } = useForm<IForgotPasswordSchema>({
    resolver: zodResolver(ForgotPasswordSchema),
  })

  const [isLoading, setIsLoading] = useState(false)

  const [sent, setSent] = useState(false)

  const { handleError } = useError()

  const onSubmit = useCallback((data: IForgotPasswordSchema) => {
    setIsLoading(true)

    SecurityClient.forgotPassword(data.email)
      .then((res) => setSent(true))
      .finally(() => setIsLoading(false))
      .catch(handleError)
  }, [])

  const formId = 'forgot-password-form'

  const onCloseWrapper = useCallback(() => {
    setSent(false)
    props.onClose()
  }, [])

  return (
    <>
      <Modal
        size='2xl'
        isOpen={props.isOpen}
        onClose={isLoading ? () => {} : onCloseWrapper}
      >
        <ModalOverlay />
        <ModalContent {...buildTestId('forgot-password-modal')}>
          {!sent ? <ModalHeader>Olvide mi contraseña</ModalHeader> : null}

          <ModalCloseButton />
          <ModalBody>
            {!sent ? (
              <Box>
                <Text>
                  Si te olvidaste tu contraseña, simplemente ingresa tu
                  dirección de correo electrónico y nosotros enviaremos un
                  enlace para que puedas cambiar tu contraseña.
                </Text>
                <chakra.form
                  mt='5'
                  onSubmit={handleSubmit(onSubmit)}
                  id={formId}
                >
                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input {...register('email')} required type='email' />
                  </FormControl>
                  <Box w='full' display={'flex'} justifyContent={'end'}>
                    <Button
                      {...buildTestId('forgot-password-btn')}
                      mt='5'
                      disabled={isLoading}
                      isLoading={isLoading}
                      form={formId}
                      type='submit'
                      variant='primary'
                    >
                      Cambiar contraseña
                    </Button>
                  </Box>
                </chakra.form>
              </Box>
            ) : (
              <Box
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                textAlign={'center'}
                py='10'
              >
                <AiOutlineMail fontSize={'5rem'} />
                <Box mt='2' maxW='md' textAlign={'left'}>
                  <Heading fontSize={'2xl'}>Correo enviado</Heading>
                  <Text fontSize={'sm'}>
                    Para completar el proceso de restauración, por favor revisa
                    tu correo electrónico y sigue las instrucciones que
                    encontrarás en el mensaje que acabamos de enviarte.
                  </Text>
                </Box>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
