import { Button, useToast } from '@chakra-ui/react'
import { AuthenticationClient } from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { AiOutlineSend } from 'react-icons/ai'
import { useError } from '../hooks/useError'

export const ResendEmailVerification = (props: { resendToken: string }) => {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const toast = useToast()

  const onResendEmailVerification = useCallback(() => {
    setIsLoading(true)

    toast.promise(
      AuthenticationClient.resendEmailVerification(props.resendToken),
      {
        loading: {
          title: 'Reenviando correo de verificación...',
        },
        success: () => {
          setIsLoading(false)
          return {
            title: 'Correo de verificación reenviado',
          }
        },
        error: (err) => {
          setIsLoading(false)
          return { title: error.getErrorMessage(err) }
        },
      },
    )
  }, [props.resendToken])

  return (
    <Button
      leftIcon={<AiOutlineSend />}
      mt='2'
      size='sm'
      onClick={onResendEmailVerification}
      isLoading={isLoading}
    >
      Reenviar correo de verificación
    </Button>
  )
}
