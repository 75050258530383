import { IAmountUnit, IContactPrice } from 'kach-clients'
import { useCallback, useMemo } from 'react'
import { Path } from 'react-hook-form'
import { Column } from 'react-table'
import { translator } from '../utils/translator'

export const useContactPriceColumns = () => {
  const amountRenderer = useCallback((path: Path<IContactPrice>) => {
    return (row: IContactPrice) => {
      const value = row[path as keyof IContactPrice] as IAmountUnit

      if (!value) {
        return 'No definido'
      }

      if (value.unit === 'percentage') {
        return `${value.amount}%`
      }

      return value.amount
    }
  }, [])

  const columns = useMemo<Column<IContactPrice>[]>(
    () => [
      {
        Header: 'Investigación',
        accessor: (row: IContactPrice) => translator(row.investigationType),
      },
      {
        Header: 'Siniestro',
        //@ts-ignore
        accessor: 'sinisterType.name',
      },
      {
        Header: 'Fraude',
        //@ts-ignore
        accessor: amountRenderer('fraud'),
      },
      {
        Header: 'Negativo',
        //@ts-ignore
        accessor: amountRenderer('negative'),
      },
      {
        Header: 'Desistimiento',
        //@ts-ignore
        accessor: amountRenderer('surrender'),
      },
      {
        Header: 'Dias laborales para el premio por rápido desisto',
        //@ts-ignore
        accessor: 'surrenderPrizeDays',
      },
      {
        Header: 'Premio por rápido desisto',
        //@ts-ignore
        accessor: amountRenderer('surrenderPrize'),
      },
      {
        Header: 'Rechazado',
        //@ts-ignore
        accessor: amountRenderer('rejected'),
      },
      {
        Header: 'Extra',
        //@ts-ignore
        accessor: amountRenderer('plus'),
      },
      {
        Header: 'KM Base',
        accessor: amountRenderer('kmLowerLimit'),
      },
      {
        Header: 'KM',
        //@ts-ignore
        accessor: amountRenderer('km'),
      },
      {
        Header: 'Incapacidad',
        accessor: 'inability',
      },
      {
        Header: 'Tope',
        accessor: 'upperLimit',
      },
    ],
    [],
  )

  return [columns]
}
