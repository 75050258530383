import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { ChevronDownIcon, FolderPlusIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { ssnPersonInvolvedGroups } from '../schemas/ssn.schema'
import { translator } from 'kach-commons'
import { CreateSsnPerson } from './CreateSsnPersonForm'
import { ICaseExtended } from 'kach-clients'
import { AiOutlinePlus } from 'react-icons/ai'
import { IoMdAdd } from 'react-icons/io'

export const AddSsnPerson = (props: { case: ICaseExtended }) => {
  const [groupName, setGroupName] = useState<string>()

  const [createSsnPerson, setCreateSsnPerson] = useState(false)

  return (
    <Box>
      {props.case?.ssn?.id && (
        <Box>
          <Menu>
            <MenuButton
              variant={'primary'}
              size={'sm'}
              as={Button}
              rightIcon={<Icon as={IoMdAdd} fontSize={'1.4rem'} />}
            >
              Crear
            </MenuButton>
            <MenuList>
              {ssnPersonInvolvedGroups.map((group, i) => {
                return (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      setGroupName(group.name)
                      setCreateSsnPerson(true)
                    }}
                  >
                    {translator(group.name)}
                  </MenuItem>
                )
              })}
            </MenuList>
          </Menu>
          <CreateSsnPerson
            isOpen={createSsnPerson}
            onClose={() => setCreateSsnPerson(false)}
            ssnId={props.case.ssn.id}
            caseId={props.case.id}
            groupName={groupName}
            case={props.case}
          />
        </Box>
      )}
    </Box>
  )
}
