import { Box, Tab, TabList, Tabs } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { hasPermission } from '../utils/has-permission';
import { NavigatorFC } from './NotificationList';

export interface ITeamTabsProps {
  ActionComponent?: React.ReactNode;
  pathname: string;
  Navigator: NavigatorFC;
}

export const TeamTabs = (props: React.PropsWithChildren<ITeamTabsProps>) => {
  const auth = useAuth();

  const tabs = [
    {
      name: 'Usuarios',
      href: '/team/users',
      currentIndex: 0,
    },
    {
      name: 'Invitados',
      href: '/team/invitations',
      currentIndex: 1,
      disabled: !hasPermission(auth.role.permissions, {
        action: 'read',
        resource: 'invitation',
      }),
    },
  ];

  const [activeIndex, setActiveIndex] = useState(
    tabs.find((tab) => tab.href === props.pathname)?.currentIndex
  );

  return (
    <Tabs
      colorScheme="brandFull"
      onChange={(index) => setActiveIndex(index)}
      defaultIndex={activeIndex}
      size="sm"
      mb={4}
      mt={4}
    >
      <TabList>
        <Box display="flex">
          {tabs.map((tab, index) => (
            <props.Navigator key={index} href={tab.disabled ? '#' : tab.href}>
              <Tab
                color="gray.500"
                isDisabled={tab.disabled}
                disabled={tab.disabled}
              >
                {tab.name}
              </Tab>
            </props.Navigator>
          ))}
        </Box>
        {props.ActionComponent}
      </TabList>
    </Tabs>
  );
};
