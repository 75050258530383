import React from 'react'
import KachLogo from './KachLogo'

export const PoweredByKach = () => {
  return (
    <div className='flex w-full items-center justify-center mt-4'>
      <div className='flex flex-row items-center space-x-2'>
        <p className='font-lg text-sm mt-2'>Powered by</p>
        <KachLogo size='5rem' variant='primary' />
      </div>
    </div>
  )
}
