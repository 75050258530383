import { Button, useToast } from '@chakra-ui/react'
import { IJob, JobClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useRouter } from 'next/router'
import React, { useCallback, useState } from 'react'
import { useError } from '../hooks/useError'
import { formatDateToLocaleDays } from '../utils/format-date'
import { Admonition } from './Admonition'

export const AvailabilityJobAdmonition = (props: { job: IJob }) => {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const router = useRouter()

  const toast = useToast()

  const onCancel = useCallback(() => {
    setIsLoading(true)

    toast.promise(JobClient.cancelUnavailabilityJob(props.job.id), {
      loading: {
        title: 'Cancelando indisponibilidad...',
      },
      success: () => {
        setIsLoading(false)
        router.reload()
        return { title: 'Indisponibilidad cancelada con éxito' }
      },
      error: (err) => {
        setIsLoading(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [props.job.id])

  return (
    <Admonition
      action={
        <Button
          {...buildTestId('unavailability-admonition-cancel')}
          color='gray.600'
          onClick={onCancel}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Finalizar indisponibilidad
        </Button>
      }
    >
      No te encuentras operativo, por lo que no podrás realizar ningún acción
      hasta el {formatDateToLocaleDays(new Date(props.job.executeAt), 'P')}
    </Admonition>
  )
}
