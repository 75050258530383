import { IconButton, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineExpandAlt } from 'react-icons/ai';

export const SeeDetailsBtn = (props: { onClick: () => void }) => {
  return (
    <Tooltip label="Ver detalle">
      <IconButton
        size="sm"
        aria-label="Ver detalle"
        icon={<AiOutlineExpandAlt className="text-gray-500 text-lg" />}
        onClick={props.onClick}
        className="p-3 hover:bg-gray-100 transition-all rounded-lg"
      />
    </Tooltip>
  );
};
