import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { CaseCustomerReportClient } from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { useCase } from '../hooks/useCase'
import { useError } from '../hooks/useError'
import { REQUIRED_FIELD_MESSAGE } from '../schemas/invitation.schema'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { useCaseDetail } from './CaseDetail'

const schema = z.object({
  identification: z
    .string()
    .min(1, REQUIRED_FIELD_MESSAGE)
    .max(255, 'Máximo 255 caracteres'),
})

type FormData = z.infer<typeof schema>

export const RequestCommercialCustomerReportForm = ({
  caseId,
  onClose,
}: {
  caseId: number
  onClose: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const detail = useCaseDetail()

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      identification: detail.customer?.identification,
    },
  })

  const toast = useToast()

  const error = useError()

  const queryClient = useQueryClient()

  const handleSubmit = useCallback(
    (data: FormData) => {
      setIsLoading(true)

      toast.promise(
        CaseCustomerReportClient.generate(caseId, 'commercial', data),
        {
          loading: {
            title: 'Solicitando informe...',
          },
          success: () => {
            queryClient.invalidateQueries([buildCaseQueryKey(caseId)])
            setIsLoading(false)
            onClose()
            return { title: 'Informe solicitado correctamente' }
          },
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
        },
      )
    },
    [caseId],
  )

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <ModalHeader>Solicitar informe</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text mb='1rem'>
          Completa el documento de la persona que deseas solicitar el informe
        </Text>
        <Controller
          control={form.control}
          name='identification'
          render={({ field, fieldState }) => {
            return (
              <FormControl>
                <FormLabel>Documento de identidad</FormLabel>
                <Input {...field} />
                {fieldState.error && (
                  <FormErrorMessage>
                    {fieldState.error.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            )
          }}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          isLoading={isLoading}
          colorScheme={'brand'}
          mr={3}
          type='submit'
        >
          Solicitar
        </Button>
        <Button onClick={onClose} variant='ghost'>
          Cerrar
        </Button>
      </ModalFooter>
    </form>
  )
}
