import { chakra, FormControl, FormLabel, Textarea } from '@chakra-ui/react'
import { CaseRequestClient } from 'kach-clients'
import React, { useState } from 'react'
import { IFileUploadV2, useFileUploadV2 } from '../hooks/useFileUploadV2'
import { FileUploadV2 } from './FileUploadV2'

export interface RequestNewsFormData {
  annotation: string
  artifacts: IFileUploadV2[]
}

export const RequestNewsForm = (props: {
  formId: string
  onSubmit: (data: RequestNewsFormData) => void
}) => {
  const [annotation, setAnnotation] = useState('')

  const fileUpload = useFileUploadV2((files) =>
    CaseRequestClient.tokenizeArtifacts({ artifacts: files }),
  )

  return (
    <chakra.form
      onSubmit={(e) => {
        e.preventDefault()
        props.onSubmit({
          annotation,
          artifacts: fileUpload.fileList,
        })
      }}
      id={props.formId}
      display={'flex'}
      flexDir='column'
      experimental_spaceY={'5'}
    >
      <FormControl>
        <FormLabel>Anotaciones</FormLabel>
        <Textarea onChange={(e) => setAnnotation(e.target.value)} />
      </FormControl>
      <FormControl>
        <FormLabel>Archivos</FormLabel>
        <FileUploadV2
          onUpdateFile={fileUpload.onUpdateFile}
          onUpload={fileUpload.onUploadFiles}
          fileList={fileUpload.fileList}
          inputId='file-upload'
          limit={10}
          maxFileSizeMB={20}
          mimetypes={[]}
          onRemove={fileUpload.onRemove}
        />
      </FormControl>
    </chakra.form>
  )
}
