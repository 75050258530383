import { IUserRole } from './company.client';
import httpClient from './http-client';
import { IJob } from './job.client';
import { IPagination } from './notification.client';

export interface IUser {
  unavailability?: IJob;
  role: IUserRole;
  owner: boolean;
  id: number;
  profilePhoto: string;
  createdAt: string;
  updatedAt: string;
  hasLocalCredentials: boolean;
  deletedAt?: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  verified: boolean;
  invitedAt: null;
  company: ICompany;
  cellphone?: string;
  countryISO?: string;
  isUnavailable?: boolean;
}

export interface ICompanyLink {
  name: string;
  url: string;
  icon: string;
}

export type CompanyTypes = 'lab' | 'insurer';

export interface ICompany {
  workingHoursStart?: string;
  workingHoursEnd?: string;
  links: ICompanyLink[];
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  name: string;
  ein: string;
  domain: string;
  type: CompanyTypes;
  api_key_prod?: string;
  api_key_testing?: string;
  labs: {
    email: string;
    name: string;
  }[];
}

export interface IIdentitySignIn {
  provider: 'google';
  access_token: string;
}

export interface IIdentityProviderInfo {
  firstName: string;
  lastName: string;
  profilePhoto: string;
  email: string;
}

export interface IMember
  extends Pick<
    IUser,
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'role'
    | 'id'
    | 'profilePhoto'
    | 'isUnavailable'
    | 'unavailability'
  > {}

export class AuthenticationClient {
  static async loginIdentity(input: IIdentitySignIn) {
    const response = await httpClient.post('/v1/auth/identity/lab/login', {
      identity: input,
    });

    return response.data;
  }

  static async fetchIdentityProviderInfo(params: {
    provider: 'google';
    access_token: string;
  }): Promise<IIdentityProviderInfo> {
    const response = await httpClient.get('/v1/auth/identity-provider', {
      params,
    });

    return response.data;
  }

  static async me(cookie?: string): Promise<IUser> {
    const response = await httpClient.get('/v1/auth/me', {
      headers: {
        cookie,
      },
    });
    return response.data;
  }

  static async verifyAccount(token: string): Promise<{ success: boolean }> {
    const response = await httpClient.put(
      '/v1/auth/verification/verify',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  }

  static async searchUsers(search: string = ''): Promise<IPagination<IUser>> {
    const response = await httpClient.get('/v1/auth/users/search', {
      params: {
        search,
        page: 1,
        limit: 10,
      },
    });

    return response.data;
  }

  static async logout(type: CompanyTypes) {
    const response = await httpClient.post(`/v1/auth/${type}/logout`);

    return response.data;
  }

  static async resendEmailVerification(resendToken: string) {
    const response = await httpClient.post(
      `/v1/auth/verification/resend`,
      {},
      {
        headers: {
          Authorization: `Bearer ${resendToken}`,
        },
      }
    );

    return response.data;
  }
}
