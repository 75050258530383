import React from 'react'
import {
  Heading,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Box,
  Text,
} from '@chakra-ui/react'
import { useJobs } from '../hooks/useJobs'
import { UnavailabilityForm } from './UnavailabilityForm'
import { buildTestId } from 'kach-commons'
import { DangerAction } from './DangerAction'

export const UnavailabilityButton = () => {
  const jobs = useJobs()

  const unavailabilityJob = jobs.find(
    (job) => job.type === 'user_unavailability',
  )

  const availabilityJob = jobs.find((job) => job.type === 'user_availability')

  return (
    <DangerAction
      Button={() => (
        <Popover closeOnBlur={false} closeOnEsc={false} isLazy>
          <PopoverTrigger>
            <Button
              {...buildTestId('unavailability-btn')}
              ml='10'
              w='16rem'
              disabled={!!unavailabilityJob || !!availabilityJob}
              variant='primary'
              size={'sm'}
            >
              Indicar indisponibilidad
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Indisponibilidad</PopoverHeader>
            <PopoverBody>
              <UnavailabilityForm />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
      title='Indisponibilidad'
      description='Indica a tus compañeros de trabajo que no estarás operativo por un
    periodo de tiempo para darle prioridad a otros.'
    />
  )
}
