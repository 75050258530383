import { Box } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineArrowDropDownCircle } from 'react-icons/md';
import { TableHeaderWrapper } from './TableHeaderWrapper';

export const EnumTableHeader: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <TableHeaderWrapper>
      <MdOutlineArrowDropDownCircle />
      <span>{children}</span>
    </TableHeaderWrapper>
  );
};
