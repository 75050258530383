import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react'
import React, { ComponentType } from 'react'
import { IMember } from 'kach-clients'
import { AiOutlineHistory } from 'react-icons/ai'
import { RxHamburgerMenu } from 'react-icons/rx'
import { UserSessionsModal } from './UserSessionsModal'
import { RemoveUser } from './RemoveUser'

export const UserOptions = (props: {
  member: IMember
  ExtraItems?: ComponentType
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure()

  const ExtraItems = props.ExtraItems || null

  return (
    <>
      <UserSessionsModal
        isOpen={isOpen}
        onClose={onClose}
        member={props.member}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<RxHamburgerMenu />}
          variant='outline'
          size='xs'
        />
        <MenuList>
          {ExtraItems && <ExtraItems member={props.member} />}
          <MenuItem onClick={onOpen} icon={<AiOutlineHistory />}>
            Ver sesiones
          </MenuItem>

          <RemoveUser member={props.member} />
        </MenuList>
      </Menu>
    </>
  )
}
