import { Avatar, Box, Divider, Heading, Text } from '@chakra-ui/react'
import { IMessage, IUser } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { useAuth } from '../hooks/useAuth'
import { buildUserName } from '../utils/build-user-name'
import { AddMessageToInvestigation } from './AddMessageToInvestigation'
import { MessageDisplayer } from './MessageDisplayer'

export const NotesMessages = (props: {
  withoutCreate?: boolean
  messages: IMessage[]
  investigationId?: number
}) => {
  const auth = useAuth()

  return (
    <section aria-labelledby='notes-title'>
      <Box
        bg='white'
        borderRadius='md'
        boxShadow='md'
        borderWidth='1px'
        borderColor={'gray.100'}
      >
        <Box>
          <Box px={4} py={5}>
            <Text id='notes-title' fontSize='lg' fontWeight='medium'>
              Chat
            </Text>
          </Box>
          <Divider />
          <Box px={4} py={6}>
            <Box
              role='list'
              display={'flex'}
              flexDir='column'
              experimental_spaceY={'8'}
            >
              {props.messages.map((message) => (
                <Box
                  {...buildTestId('investigation-message')}
                  display={'flex'}
                  flexDir='row'
                  alignItems={'flex-start'}
                >
                  <Avatar
                    size='sm'
                    mr='2'
                    referrerPolicy='no-referrer'
                    src={(message.sentBy as IUser)?.profilePhoto}
                  />
                  <Box>
                    <Heading ml='2px' fontSize={'xs'}>
                      {buildUserName(message.sentBy as IUser)}
                    </Heading>
                    <MessageDisplayer
                      timeAgoStyles={{
                        ml: '2px',
                      }}
                      message={message}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        {props.investigationId && (
          <AddMessageToInvestigation
            withoutCreate={
              auth.company.type === 'insurer' ? props.withoutCreate : false
            }
            investigationId={props.investigationId}
          />
        )}
      </Box>
    </section>
  )
}
