import { parsePhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

export const CustomerSchema = z
  .object({
    lastName: z.string().optional(),
    firstName: z.string().optional(),
    //@ts-ignore
    email: z.string().email('Email invalido!').optional().or(z.literal('')),
    identification: z
      .string()
      .refine(
        (val) => {
          if (!val) {
            return true;
          }

          return new RegExp(/^\d+$/).test(val);
        },
        { message: 'Unicamente dígitos!' }
      )
      .optional(),
    cellphone: z.string(),
    countryISO: z.string().optional(),
  })
  .refine(
    (val) => {
      if (!val.cellphone || !val.countryISO) {
        return true;
      }

      try {
        const parsed = parsePhoneNumber(val.cellphone, val.countryISO as any);

        if (!parsed) {
          return false;
        }

        return parsed.isValid;
      } catch (err) {
        return false;
      }
    },
    {
      message: 'Celular no valido!',
      path: ['cellphone'],
    }
  );

export type ICustomerSchema = z.infer<typeof CustomerSchema>;
