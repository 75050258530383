import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ContactClient, IContact } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { LAB_PRICES_QUERY_KEY } from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { ContactProvider } from '../providers/ContactProvider'
import { hasPermission } from '../utils/has-permission'
import { ContactLabPricesTable } from './ContactLabPricesTable'
import { CreateContactLabPriceModal } from './CreateContactLabPriceModal'
import { useQuerySingleton } from '../hooks/useQuerySingleton'

const ModalBodyWrapper = (props: { contact: IContact }) => {
  const { isLoading, data } = useQuerySingleton([LAB_PRICES_QUERY_KEY], () =>
    ContactClient.fetchPrices(props.contact.id),
  )

  if (isLoading || !data) {
    return <Spinner />
  }

  return (
    <Box {...buildTestId('contact-lab-prices-detail')}>
      <ContactLabPricesTable
        prices={data?.contactPrices}
        contact={props.contact}
      />
    </Box>
  )
}

export const ContactLabPricesDetail = (props: {
  isOpen: boolean
  onClose: () => void
  contact: IContact
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const auth = useAuth()

  return (
    <ContactProvider
      onCreate={(input) => ContactClient.createPrice(input)}
      onFetch={(...args) => ContactClient.fetchPrices(...args)}
    >
      <CreateContactLabPriceModal
        contact={props.contact}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Modal size='5xl' isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            justifyContent={'space-between'}
            display={'flex'}
            flexDir='row'
            alignItems={'center'}
          >
            <Text>Honorarios</Text>
            {hasPermission(auth.role.permissions, {
              action: 'create',
              resource: 'contact_price',
            }) && (
              <Button
                {...buildTestId('contact-lab-prices-detail-add-price')}
                onClick={onOpen}
                size='sm'
                variant='primary'
              >
                Agregar honorario
              </Button>
            )}
          </ModalHeader>
          <ModalBody>
            <ModalBodyWrapper contact={props.contact} />
          </ModalBody>

          <ModalFooter>
            <Button onClick={props.onClose} variant='ghost'>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ContactProvider>
  )
}
