import Decimal from 'decimal.js'

export class LocalDecimal {
  public static new(v: any): Decimal {
    try {
      return new Decimal(v)
    } catch (err: any) {
      console.log('Decimal error', err?.message)
      return new Decimal(0)
    }
  }
}
