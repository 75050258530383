import React from 'react'
import { Box, Button, Spinner, chakra } from '@chakra-ui/react'
import { IPagination } from 'kach-clients'
import { FiArrowDown } from 'react-icons/fi'

export const LoadMorePagination = (props: {
  pagination: Omit<IPagination<any>, 'results'>
  label: string
  onLoadMore: () => void
  hasNextPage?: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
}) => {
  return (
    <Box mt='5' w='full' alignItems={'center'} justifyContent='center'>
      {props.hasNextPage && (
        <Button variant={'ghost'} onClick={props.onLoadMore}>
          {props.isFetchingNextPage ? (
            <Spinner mr='3' size='xs' />
          ) : (
            <FiArrowDown
              style={{
                marginRight: '3px',
              }}
            />
          )}
          Cargar mas
        </Button>
      )}

      <Box
        w='full'
        display={'flex'}
        alignItems='center'
        justifyContent={'center'}
      >
        <chakra.strong mr='2'>{props.pagination.total}</chakra.strong>{' '}
        {props.label}
      </Box>
    </Box>
  )
}
