import { Box, Button, FormControl, Icon, Input } from '@chakra-ui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { get, Path, UseFormRegister } from 'react-hook-form'

export const EmailListEditable = <T extends object>(props: {
  list: { email?: string }[]
  register: UseFormRegister<T>
  errors: any
  pathBuilder: (index: number) => Path<T>
  remove: (index: number) => void
  append: () => void
}) => {
  return (
    <Box display='flex' flexDir='column' experimental_spaceY={2}>
      <Box>
        <Box w='full' display='flex' flexDir='column' experimental_spaceY={4}>
          {props.list.map(({ email }, index) => {
            return (
              <Box
                key={index}
                display='flex'
                experimental_spaceX={2}
                alignItems='center'
                justifyContent='space-between'
              >
                <FormControl w='full'>
                  <Input
                    size='sm'
                    placeholder='Email'
                    key={index}
                    {...props.register(props.pathBuilder(index), {
                      setValueAs: (val) =>
                        typeof val === 'string'
                          ? val.toLowerCase().trim()
                          : val,
                    })}
                    isInvalid={get(props.errors, props.pathBuilder(index))}
                  />
                </FormControl>

                {index !== 0 ? (
                  <Button size='sm' onClick={() => props.remove(index)}>
                    <Icon as={MinusSmallIcon} h='4' w='4' />
                  </Button>
                ) : (
                  <Button
                    {...buildTestId('add-new-invitation-btn')}
                    size='sm'
                    onClick={() => props.append()}
                  >
                    <Icon as={PlusSmallIcon} h='4' w='4' />
                  </Button>
                )}
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}
