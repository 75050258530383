import { Skeleton, Stack } from '@chakra-ui/react'
import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'
import React from 'react'

export function WaitForQuery<
  T extends UseQueryResult | UseInfiniteQueryResult,
>({
  query,
  children,
  fallback,
}: {
  query: T
  children: React.FC<Extract<T, { isSuccess: true }>['data']>
  fallback?: React.ReactNode
}) {
  if (query.isLoading || query.error || !query.data) {
    if (fallback) return fallback

    return (
      <Stack>
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
      </Stack>
    )
  }

  return children(query.data)
}
