import { Button, Icon, Tooltip } from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/24/solid'
import React from 'react'

export const AddCaseResource: React.FC<
  React.PropsWithChildren<{
    onClick: () => void
    label: string
  }>
> = props => {
  return (
    <Button
      onClick={e => {
        e.stopPropagation()
        props.onClick()
      }}
      size='sm'
    >
      <Tooltip label={props.label}>
        <Icon as={PlusIcon} />
      </Tooltip>
    </Button>
  )
}
