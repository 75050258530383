import {
  Alert,
  AlertIcon,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Spinner,
  Stack,
} from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { CaseClient, IJob } from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { ImRedo } from 'react-icons/im'
import { useError } from '../hooks/useError'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { formatDate } from '../utils/format-date'

export const ResyncButton = (props: {
  caseId: number
  buttonProps?: ButtonProps
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClient()

  const error = useError()

  const onResync = useCallback(() => {
    setIsLoading(true)

    CaseClient.resyncCase(props.caseId)
      .then((res) => {
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
      })
      .catch(error.handleError)
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Button
      isDisabled={isLoading}
      isLoading={isLoading}
      onClick={onResync}
      variant='primary'
      size='xs'
      px={3}
      leftIcon={<ImRedo />}
      {...props.buttonProps}
    >
      Sincronizar
    </Button>
  )
}

export const CrawlerJobProgress: React.FC<
  React.PropsWithChildren<{ job: IJob; boxProps?: BoxProps }>
> = ({ children, boxProps, ...props }) => {
  switch (props.job.status) {
    case 'pending':
      return (
        <Alert
          pos='relative'
          fontSize={'sm'}
          borderRadius={'md'}
          status='info'
          {...boxProps}
        >
          <Box w='full' display={'flex'} alignItems={'center'}>
            <AlertIcon />
            El proceso de recopilación de datos está en cola
          </Box>

          {children}
        </Alert>
      )

    case 'failed':
      return (
        <Alert
          pos='relative'
          fontSize={'sm'}
          borderRadius={'md'}
          status='error'
          {...boxProps}
        >
          <Box w='full' display={'flex'} alignItems={'center'}>
            <AlertIcon />
            {props.job.failedReason ||
              'Algo no salio como se esperaba. Se reintentara mas tarde'}
          </Box>

          {children}
        </Alert>
      )

    case 'running':
      return (
        <Alert
          pos='relative'
          fontSize={'sm'}
          borderRadius={'md'}
          status='info'
          {...boxProps}
        >
          <Box w='full' display={'flex'} alignItems={'center'}>
            <Spinner mr='3' size='xs' />
            El proceso de recopilación de datos está en ejecución
          </Box>

          {children}
        </Alert>
      )

    case 'completed':
      return (
        <Alert
          pos='relative'
          fontSize={'sm'}
          borderRadius={'md'}
          status='success'
          {...boxProps}
        >
          <Box w='full' display={'flex'} alignItems={'center'}>
            <AlertIcon />
            El proceso de recopilación de datos finalizó con éxito el{' '}
            {formatDate(props.job.completedAt)}
          </Box>

          {children}
        </Alert>
      )
  }
}
