import React from 'react'
import { Button } from '@chakra-ui/react'
import { CaseClient, IArtifactAck, ICreateMessage } from 'kach-clients'
import { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { MessageTextarea } from './MessageTextarea'
import { buildTestId } from 'kach-commons'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export default function CommentInputCase(props: { caseId: number }) {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const onAddMessage = useCallback(
    (message: ICreateMessage, onReset: () => void) => {
      setIsLoading(true)
      CaseClient.addMessage(props.caseId, message)
        .then((res) => {
          queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])

          onReset()
        })
        .catch(error.handleError)
        .finally(() => setIsLoading(false))
    },
    [],
  )

  return (
    <MessageTextarea
      artifactsTokenizer={(files) =>
        CaseClient.tokenizeArtifacts(props.caseId, files)
      }
      artifactsIdentifier='case-comments'
    >
      {({ message, onClearMessage }) => {
        return (
          <Button
            {...buildTestId('comment-input-case-send-btn')}
            isLoading={isLoading}
            onClick={() => onAddMessage(message, onClearMessage)}
            size='sm'
            colorScheme='brand'
            isDisabled={!message.artifacts.length && !message.message}
          >
            Enviar
          </Button>
        )
      }}
    </MessageTextarea>
  )
}
