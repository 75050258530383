import {
  Accordion,
  AccordionButton,
  AccordionItem as AccordionItemChakra,
  AccordionPanel,
  Box,
  Icon,
  Text,
} from '@chakra-ui/react'
import { FolderPlusIcon } from '@heroicons/react/24/outline'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { AiFillCaretRight, AiOutlineCaretDown } from 'react-icons/ai'

export const EmptyState = (props: {
  label: {
    singular: string
    plural: string
  }
  withoutCreate: boolean
}) => {
  return (
    <Box textAlign='center' pb={4}>
      <Icon as={FolderPlusIcon} h='10' w='10' />
      <Text mt={2} fontSize='sm' fontWeight='medium'>
        No hay {props.label.plural}
      </Text>
      {!props.withoutCreate && (
        <Text mt={1} fontSize='sm'>
          Empieza creando uno
        </Text>
      )}
    </Box>
  )
}

interface IProps {
  isExpanded?: boolean
  withoutEmptyState?: boolean
  withoutCreate?: boolean
  count?: number
  counter?: number
  label: {
    singular: string
    plural: string
  }
  SuffixComponent?: React.ReactNode
  children:
    | React.FC<{
        isExpanded: boolean
      }>
    | React.ReactNode
}

export const CaseAccordion = (props: IProps) => {
  return (
    <Accordion allowToggle defaultIndex={props.isExpanded ? 0 : 1}>
      <AccordionItemChakra border={'none'}>
        {({ isExpanded }) => {
          return (
            <>
              <Box>
                <AccordionButton
                  borderRadius='md'
                  as={Box}
                  {...buildTestId(`accordion-button-${props.label.singular}`)}
                >
                  <Box
                    display={'flex'}
                    flexDir='row'
                    w='full'
                    justifyContent={'space-between'}
                    alignItems='center'
                  >
                    <Box>
                      <Box
                        display='flex'
                        alignItems={'center'}
                        flexDir={'row'}
                        justifyContent='space-between'
                      >
                        <Text
                          fontWeight='bold'
                          textTransform='capitalize'
                          py={2}
                          mr={2}
                        >
                          {props.label.plural}
                        </Text>
                        {isExpanded ? (
                          <AiOutlineCaretDown />
                        ) : (
                          <AiFillCaretRight />
                        )}
                      </Box>
                    </Box>

                    {!props.withoutCreate && props.SuffixComponent}
                  </Box>
                </AccordionButton>
              </Box>
              <AccordionPanel p={0} pb={4}>
                {typeof props.children === 'function'
                  ? props.children({
                      isExpanded,
                    })
                  : props.children}
                {!props.withoutEmptyState && !props.count && (
                  <EmptyState
                    withoutCreate={props.withoutCreate}
                    label={props.label}
                  />
                )}
              </AccordionPanel>
            </>
          )
        }}
      </AccordionItemChakra>
    </Accordion>
  )
}
