import { CompanyClient, IMember, IPagination } from 'kach-clients'
import React, { ComponentType, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Cell, Column } from 'react-table'
import { MEMBERS_QUERY_KEY } from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { CustomTable } from './CustomTable'
import HeaderPage from './HeaderPage'
import { InviteForm } from './InviteForm'
import { PaginationFooter } from './PaginationFooter'
import { ILocalPermission } from './RolePermissions'
import { RoleSelector } from './RoleSelector'
import { ITeamTabsProps, TeamTabs } from './TeamTabs'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Box,
  Text,
  Tag,
} from '@chakra-ui/react'
import { UserOptions } from './UserOptions'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useDebounceSearch } from '../hooks/useDebounceSearch'
import { buildTestId } from 'kach-commons'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { format } from 'date-fns'

export const PAGINATION_INITIAL_PAGE = 1
export const PAGINATION_LIMIT = 10

export interface ITeamUsers extends ITeamTabsProps {
  initialUsers: IPagination<IMember>
  permissionsToRender: ILocalPermission[]
  extraItems?: ComponentType
}

export const TeamUsers = (props: ITeamUsers) => {
  const auth = useAuth()

  //@ts-ignore
  const columns = useMemo<Column<IMember>[]>(() => {
    return [
      {
        Header: 'Nombre',
        Cell: function (cell: Cell<IMember>) {
          return (
            <Box display={'flex'} columnGap={2} alignItems={'center'}>
              <Text>{`${cell.row.original.firstName} ${cell.row.original.lastName}`}</Text>
              {cell.row.original.unavailability &&
                cell.row.original.unavailability.type ===
                  'user_availability' && (
                  <Tag size={'sm'} colorScheme='red'>
                    No disponible hasta{' '}
                    {format(
                      new Date(cell.row.original.unavailability.executeAt!),
                      'dd/MM/yyyy',
                    )}
                  </Tag>
                )}

              {cell.row.original.unavailability &&
                cell.row.original.unavailability.type ===
                  'user_unavailability' && (
                  <Tag size={'sm'} colorScheme='gray'>
                    Indisponibilidad agendada para el{' '}
                    {format(
                      new Date(cell.row.original.unavailability.executeAt!),
                      'dd/MM/yyyy',
                    )}{' '}
                  </Tag>
                )}
            </Box>
          )
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Rol',
        Cell: function (cell: Cell<IMember>) {
          if (cell.row.original.id === auth.id) {
            return <p>{cell.row.original.role.name}</p>
          }

          return (
            <RoleSelector
              userId={cell.row.original.id}
              role={cell.row.original.role}
            />
          )
        },
      },
      {
        id: 'options',
        Cell: function (cell: Cell<IMember>) {
          return (
            <UserOptions
              member={cell.row.original}
              ExtraItems={props.extraItems}
            />
          )
        },
      },
    ]
  }, [])

  const [page, setPage] = useState(PAGINATION_INITIAL_PAGE)
  const { debounceSearch, search } = useDebounceSearch()

  const { data, isLoading } = useQuerySingleton(
    [MEMBERS_QUERY_KEY, page, search],
    (ctx) => {
      const [_, page, search] = ctx.queryKey

      return CompanyClient.paginateMembers({
        search: search as string,
        page: Number(page),
        limit: PAGINATION_LIMIT,
      })
    },
    {
      initialData: props.initialUsers,
    },
  )

  return (
    <>
      <HeaderPage
        title='Equipo'
        subtitle='Revisa tus usuarios e invitaciones enviadas'
        ActionComponent={
          <InviteForm permissionsToRender={props.permissionsToRender} />
        }
      />

      <TeamTabs Navigator={props.Navigator} pathname={props.pathname} />
      <Box>
        <InputGroup mb='4' maxW={'lg'} size='sm'>
          <InputLeftElement
            pointerEvents='none'
            children={<Icon as={MagnifyingGlassIcon} />}
          />
          <Input
            {...buildTestId('search-input')}
            placeholder={'Ingresa un email o nombre'}
            onChange={(e) => debounceSearch(e.target.value)}
            type='text'
          />
        </InputGroup>
        <CustomTable
          noHover
          orientation='left'
          columns={columns}
          data={data?.results || []}
        />
      </Box>

      <PaginationFooter page={page} setPage={setPage} paginationData={data} />
    </>
  )
}
