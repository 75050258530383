import React from 'react'
import { Box, Text } from '@chakra-ui/react'

export const LabelValueDisplayer = (props: {
  label: string
  hide?: boolean
  children: React.ReactNode
}) => {
  if (props.hide) return null

  return (
    <Box
      display='flex'
      w='100%'
      alignItems='start'
      justifyContent='space-between'
    >
      <Text fontSize='sm' fontWeight='light'>
        {props.label}
      </Text>
      <Box
        textAlign={'right'}
        display='flex'
        justifyContent='end'
        w='65%'
        fontSize='sm'
      >
        {props.children || 'Dato no disponible'}
      </Box>
    </Box>
  )
}
