import es from 'date-fns/locale/es'
import type { AppProps } from 'next/app'
import '../styles/globals.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ChakraProvider } from '@chakra-ui/react'
import { ErrorBoundary } from 'kach-components'
import Link from 'next/link'

setDefaultOptions({ locale: es })

// content
export default function App({ Component, pageProps }: AppProps) {
  console.log('')

  const [queryClient] = useState(() => new QueryClient())

  return (
    <ChakraProvider>
      <ErrorBoundary Navigator={Link}>
        <DndProvider backend={HTML5Backend}>
          <QueryClientProvider client={queryClient}>
            <Component {...pageProps} />
            {/* <Toaster /> */}
          </QueryClientProvider>
        </DndProvider>
      </ErrorBoundary>
    </ChakraProvider>
  )
}
