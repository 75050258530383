import { Box, Text } from '@chakra-ui/react'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { CaseAccordion } from './CaseAccordion'

export const CaseAnnotations: React.FC<
  React.PropsWithChildren<{
    annotation: string
    withoutCreate?: boolean
  }>
> = ({ children, annotation, withoutCreate }) => {
  return (
    <div {...buildTestId('case-annotations-container')}>
      <CaseAccordion
        withoutCreate={withoutCreate}
        count={1}
        label={{
          singular: 'información extra',
          plural: 'información extra',
        }}
      >
        <Box px={4} py={2}>
          <Text fontSize='sm'>{annotation}</Text>
        </Box>
      </CaseAccordion>
    </div>
  )
}
