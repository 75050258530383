import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useToggle } from 'ahooks'
import { INewsRequest, InvestigationClient } from 'kach-clients'
import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'
import CreateNewsForm from './CreateNewsForm'
import { buildTestId } from 'kach-commons'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

export const AddInvestigationNews = (props: {
  newsRequestToReply?: Partial<INewsRequest>
  investigationId: number
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}) => {
  const queryClient = useQueryClientSingleton()

  const { getErrorMessage } = useError()

  const toast = useToast()

  return (
    <>
      <Button
        {...buildTestId('add-investigation-news-btn')}
        size='sm'
        variant={'primary'}
        w={['full', null, 'auto']}
        leftIcon={<AiOutlinePlus className='mr-2' />}
        onClick={props.onOpen}
      >
        Agregar novedad
      </Button>

      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Agregar novedad</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CreateNewsForm
              defaultValues={{
                relatedNewsRequest: props.newsRequestToReply,
              }}
              newsRequestsFetcher={() =>
                InvestigationClient.fetchNewsRequests(
                  props.investigationId,
                ).then((res) => res.newsRequests)
              }
              hideDestinations
              context='investigation'
              artifactsTokenizer={(files) =>
                InvestigationClient.tokenizeArtifacts(
                  props.investigationId,
                  files,
                )
              }
              onSubmit={(news, onDone) => {
                toast.promise(
                  InvestigationClient.addNews(props.investigationId, news),
                  {
                    loading: {
                      title: 'Agregando novedad...',
                    },
                    error: (err) => getErrorMessage(err),
                    success: () => {
                      queryClient.invalidateQueries([
                        buildInvestigationQueryKey(props.investigationId),
                      ])

                      props.onClose()
                      onDone()

                      return {
                        title: 'Novedad agregada correctamente',
                      }
                    },
                  },
                )
              }}
              onClose={props.onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
