import {
  Box,
  chakra,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
} from '@chakra-ui/react'
import { ContactClient, IContact, IPagination } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsCircleFill } from 'react-icons/bs'
import { Cell, Column } from 'react-table'
import { KachColors } from '../constants/colors'
import { LAB_CONTACTS_QUERY_KEY } from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { useDebounceSearch } from '../hooks/useDebounceSearch'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { formatDateToLocaleDays } from '../utils/format-date'
import { hasPermission } from '../utils/has-permission'
import { ContactLabsActions } from './ContactLabsActions'
import { CreateContactLabModal } from './CreateContactLabModal'
import { CustomTable } from './CustomTable'
import { PaginationFooter } from './PaginationFooter'

export const ContactLabs = (props: { contacts: IPagination<IContact> }) => {
  const [page, setPage] = useState(1)

  const { search, debounceSearch } = useDebounceSearch()

  const auth = useAuth()

  const { data } = useQuerySingleton(
    [LAB_CONTACTS_QUERY_KEY, page, search],
    (ctx) => {
      const [, page] = ctx.queryKey

      return ContactClient.list({
        page: page as number,
        limit: 10,
        search,
        ...(search
          ? {
              page: 1,
            }
          : {}),
      })
    },
    {
      initialData: props.contacts,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  )

  const columns = useMemo<Column<IContact>[]>(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        id: 'markers',
        Header: 'Estado',
        //@ts-ignore
        Cell: (row: Cell<IContact>) => {
          if (row.row.original.hidden) {
            const contact = row.row.original
            const hiddenFromDate = contact.hiddenFrom
              ? `desde ${formatDateToLocaleDays(contact.hiddenFrom)}`
              : ''
            const hiddenToDate = contact.hiddenTo
              ? `hasta ${formatDateToLocaleDays(contact.hiddenTo)}`
              : ''
            const datesHiddenStr = `${hiddenFromDate} ${hiddenToDate}`
            return (
              <Box
                textAlign={'center'}
                w='full'
                display={'flex'}
                alignItems='center'
              >
                <Tooltip
                  label={`El estudio se encuentra oculto${
                    contact.hiddenReason ? ` (${contact.hiddenReason})` : ''
                  }`}
                >
                  <Box display={'flex'} alignItems='center' columnGap={2}>
                    <Icon as={BsCircleFill} color='orange.300' />
                    <chakra.span>
                      Registrado e inactivo {datesHiddenStr}
                    </chakra.span>
                  </Box>
                </Tooltip>
              </Box>
            )
          }

          if (row.row.original.contactCompany) {
            return (
              <Box
                textAlign={'center'}
                w='full'
                display={'flex'}
                alignItems='center'
              >
                <Tooltip label='El estudio ya se registro en la plataforma y se encuentra vinculado a tu empresa'>
                  <Box display={'flex'} alignItems='center' columnGap={2}>
                    <BsCircleFill color={KachColors.green} />
                    <chakra.span>Registrado y activo</chakra.span>
                  </Box>
                </Tooltip>
              </Box>
            )
          }

          return (
            <Tooltip
              textAlign={'center'}
              w='full'
              display={'flex'}
              alignItems='center'
              label='Se ha enviado una invitación al estudio para que se registre en la plataforma'
            >
              <Box display={'flex'} alignItems='center' columnGap={2}>
                <BsCircleFill color={KachColors.red} />
                <chakra.span>Invitación enviada</chakra.span>
              </Box>
            </Tooltip>
          )
        },
      },
      {
        id: 'actions',
        //@ts-ignore
        Cell: (cell: Cell<IContact>) => {
          if (
            !hasPermission(auth?.role?.permissions, {
              action: 'update',
              resource: 'contact',
            })
          ) {
            return null
          }

          return <ContactLabsActions contact={cell.row.original} />
        },
      },
    ],
    [auth?.role?.permissions],
  )

  if (!data) {
    return null
  }

  return (
    <Box>
      <Box mb='4' display={'flex'} flexDir='row' alignItems={'center'}>
        <InputGroup size='sm'>
          <InputLeftElement
            pointerEvents='none'
            children={<AiOutlineSearch color='gray.300' />}
          />
          <Input
            {...buildTestId('search-contact-lab-input')}
            maxW='md'
            placeholder='Buscar estudio'
            onChange={(e) => debounceSearch(e.target.value)}
          />
        </InputGroup>
        <CreateContactLabModal />
      </Box>

      <CustomTable
        noHover
        columns={columns}
        data={data.results}
        orientation='center'
      />
      <PaginationFooter page={page} setPage={setPage} paginationData={data} />
    </Box>
  )
}
