import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react'
import {
  CaseRequestClient,
  IInvestigation,
  InvestigationClient,
} from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { useError } from '../hooks/useError'
import { useFileUploadV2 } from '../hooks/useFileUploadV2'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'
import { remapFile } from '../utils/remap-file-list'
import { FileUploadV2 } from './FileUploadV2'

const Body = (props: {
  investigation: IInvestigation
  onClose: () => void
}) => {
  const fileUpload = useFileUploadV2((files) =>
    CaseRequestClient.tokenizeArtifacts({ artifacts: files }),
  )

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const toast = useToast()

  const onUpload = useCallback(() => {
    setIsLoading(true)

    const [invoice] = fileUpload.fileList

    toast.promise(
      InvestigationClient.uploadInvoiceReport(
        props.investigation.id,
        remapFile(invoice),
      ),
      {
        error: (err) => {
          setIsLoading(false)
          return { title: error.getErrorMessage(err) }
        },
        loading: { title: 'Subiendo factura...' },
        success: () => {
          queryClient.invalidateQueries([
            buildInvestigationQueryKey(props.investigation.id),
          ])
          props.onClose()
          return {
            title: 'Factura subida con éxito',
          }
        },
      },
    )
  }, [props.investigation.id, fileUpload.fileList])

  return (
    <PopoverContent>
      <PopoverArrow />
      <PopoverCloseButton isDisabled={isLoading} />
      <PopoverBody>
        <FileUploadV2
          // withoutTags
          onUpdateFile={fileUpload.onUpdateFile}
          onUpload={fileUpload.onUploadFiles}
          fileList={fileUpload.fileList}
          inputId='file-upload'
          limit={1}
          maxFileSizeMB={20}
          mimetypes={[]}
          onRemove={fileUpload.onRemove}
        />
      </PopoverBody>
      <PopoverFooter>
        <Button
          size='sm'
          onClick={onUpload}
          isDisabled={isLoading || !fileUpload.fileList.length}
          isLoading={isLoading}
          variant={'primary'}
        >
          Subir factura
        </Button>
        <Button
          size='sm'
          onClick={props.onClose}
          isDisabled={isLoading}
          variant={'ghost'}
        >
          Cancelar
        </Button>
      </PopoverFooter>
    </PopoverContent>
  )
}

export const UploadInvestigationInvoice = (props: {
  investigation: IInvestigation
}) => {
  return (
    <Popover>
      {({ isOpen, onClose }) => {
        return (
          <>
            <PopoverTrigger>
              <Button
                size='sm'
                variant={'primary'}
                leftIcon={<AiOutlinePlus />}
              >
                Agregar factura
              </Button>
            </PopoverTrigger>
            {isOpen && (
              <Body onClose={onClose} investigation={props.investigation} />
            )}
          </>
        )
      }}
    </Popover>
  )
}
