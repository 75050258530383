import { isSameDay } from 'date-fns'
import { z } from 'zod'
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema'

//@ts-ignore
export const UnavailabilitySchema = z
  .object({
    memberId: z.coerce.number().nullish(),
    from: z.coerce
      .date({
        invalid_type_error: 'Campo requerido',
        required_error: 'Campo requerido',
      })
      .refine(
        (val) => {
          if (!val) {
            return false
          }

          const dt = new Date(val)
          const dtDateOnly = new Date(
            dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
          )

          const isPastDate = isSameDay(dtDateOnly, new Date())
            ? false
            : new Date(val) < new Date()

          if (isPastDate) {
            return false
          }

          return true
        },
        {
          message: 'No puede ser una fecha pasada',
        },
      ),
    to: z.coerce.date({
      invalid_type_error: 'Campo requerido',
      required_error: 'Campo requerido',
    }),
  })
  .refine(
    (val) => {
      const from = new Date(val.from)
      const to = new Date(val.to)

      if (from > to) {
        return false
      }

      return true
    },
    {
      message: 'La fecha de inicio debe ser menor a la fecha de fin',
      path: ['from'],
    },
  )

export interface IUnavailabilitySchema
  extends z.infer<typeof UnavailabilitySchema> {}
