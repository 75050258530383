import {
  Box,
  Divider,
  Grid,
  GridItem,
  Skeleton,
  Tag,
  Text,
} from '@chakra-ui/react'
import { ICasePriceDetail, ICompanySaving, ILabSaving } from 'kach-clients'
import { translator } from 'kach-commons'
import React from 'react'
import { FormulaResultItem, SavingFormulaResult } from './SavingFormulaResult'
import { NewSavingFormulaCommand } from '../utils/new-saving-formula.command'
import { KachColors } from '../constants/colors'

type ValidTypes = ICompanySaving | ILabSaving

export const AbstractSaving = <T extends ValidTypes>(props: {
  data: T
  vars: Pick<ICasePriceDetail, 'vars'>
  totalResult: string
  isLoading: boolean
  error: unknown
  sortedVars: (keyof T)[]
  type: 'lab' | 'company'
  withoutCreate?: boolean
}) => {
  if (props.isLoading) {
    return <Skeleton />
  }

  if (props.error || !props.data) {
    return (
      <>
        <Text>Hubo un error al obtener las variables</Text>
      </>
    )
  }

  const title = props.type === 'company' ? 'compañía' : 'investigación'

  const vars =
    props.type === 'company'
      ? props.vars.vars.formula.rivadavia_company
      : props.vars.vars.investigation.price

  return (
    <Box mt='2' mb='4'>
      <Box>
        <Box pb={2}>
          <Text size={'md'} pb={3} fontWeight={'bold'}>
            {`Variables de la ${title}`}
          </Text>
          <Grid templateColumns='repeat(1, 1fr)' gap={2} pb={2}>
            {Object.keys(vars).map((key, index) => {
              const item = vars[key]

              const value = new Number(item)

              return (
                <GridItem>
                  <Tag size={'sm'} key={index}>
                    {`${translator(key)}: ${
                      value.toLocaleString('de-DE') || item
                    }`}
                  </Tag>
                </GridItem>
              )
            })}
          </Grid>
        </Box>

        <Divider />

        <Box pt={2}>
          <Text size={'md'} pb={1} pt={2} fontWeight={'bold'}>
            Resultado calculado
          </Text>

          <SavingFormulaResult
            command={
              new NewSavingFormulaCommand<T>(props.sortedVars, props.data)
            }
          />

          <Divider />

          <Box pt={2}>
            <FormulaResultItem
              item={{
                item: `Ahorro real ${
                  props.type === 'company' ? 'compañía' : 'estudio'
                }`,
                value: `-`,
                acc: `$${props.totalResult}`,
              }}
              fontWeight={'bold'}
              color={KachColors.blue}
            ></FormulaResultItem>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
