import {
  Box,
  Button,
  Popover as PopoverChakra,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react'
import { CompanyClient, IUserRole } from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { MEMBERS_QUERY_KEY } from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { useRoles } from '../hooks/useRoles'
import { hasPermission } from '../utils/has-permission'

export const RoleSelector = (props: { role: IUserRole; userId: number }) => {
  const { data } = useRoles()

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const auth = useAuth()

  const toast = useToast()

  const onUpdateRol = useCallback(
    (roleId: number, onSuccess: () => void) => {
      setIsLoading(true)

      toast.promise(CompanyClient.updateRole(props.userId, roleId), {
        success: () => {
          queryClient.invalidateQueries([MEMBERS_QUERY_KEY])
          onSuccess()
          setIsLoading(false)
          return {
            title: 'Rol actualizado correctamente',
          }
        },
        error: (err) => {
          setIsLoading(false)
          return { title: error.getErrorMessage(err) }
        },
        loading: { title: 'Actualizando rol...' },
      })
    },
    [props.userId],
  )

  return (
    <PopoverChakra>
      {({ onClose }) => {
        return (
          <>
            <PopoverTrigger>
              <Button
                isDisabled={
                  !hasPermission(auth.role.permissions, {
                    resource: 'user',
                    action: 'update',
                  })
                }
                size='xs'
              >
                {props.role.name}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Seleccionar rol</PopoverHeader>
              <PopoverBody>
                <Box
                  display='flex'
                  flexDir='column'
                  experimental_spaceY={2}
                  w='full'
                >
                  {data
                    ?.filter((role) => role.id !== props.role.id)
                    ?.map((role, index) => {
                      return (
                        <Button
                          w='full'
                          size='sm'
                          onClick={() => onUpdateRol(role.id, onClose)}
                          key={index}
                        >
                          {role.name}
                        </Button>
                      )
                    })}
                </Box>
              </PopoverBody>
            </PopoverContent>
          </>
        )
      }}
    </PopoverChakra>
  )
}
