import { ICaseExtended, UpdateCaseType } from 'kach-clients'
import { useContext } from 'react'
import { useMutation } from '@tanstack/react-query'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { useQueryClientSingleton } from './useQueryClientSingleton'
import { KachContext } from '../providers/KachProvider'

export const useMutateUpdateCase = (props: {
  caseId: number
  updateFn: (caseUpdated: UpdateCaseType) => Promise<ICaseExtended | undefined>
}) => {
  const queryClient = useQueryClientSingleton()

  const { defaultContext } = useContext(KachContext)

  const mutate = useMutation(props.updateFn, {
    context: defaultContext,
    onMutate: async (caseUpdated) => {
      const queryKey = buildCaseQueryKey(props.caseId)

      await queryClient.cancelQueries([queryKey])

      const snapshot = queryClient.getQueryData([queryKey])

      queryClient.setQueryData<ICaseExtended | undefined>(
        [queryKey],
        //@ts-ignore
        (casePrev) => {
          if (!casePrev) {
            return casePrev
          }

          return {
            ...casePrev,
            ...caseUpdated,
          }
        },
      )

      return {
        snapshot,
        queryKey,
      }
    },
    onError: (_, __, context) => {
      //@ts-ignore
      queryClient.setQueryData([context?.queryKey], context?.snapshot)
    },
    onSettled: (_, __, ___, context) => {
      //@ts-ignore
      queryClient.invalidateQueries([context?.queryKey])
    },
  })

  return [mutate]
}
