import { Box, Divider, IconButton, Tooltip } from '@chakra-ui/react'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { IoMdRemove } from 'react-icons/io'
import { UserDisplayer } from './UserDisplayer'

export const UserListEditable = <
  T extends {
    profilePhoto?: string | null | undefined
    id: number
    firstName: string
    lastName: string
    email: string
  },
>(props: {
  users: T[]
  remove: (index: number) => void
}) => {
  return (
    <Box mt='5' display={'flex'} flexDir='column' experimental_spaceY={'5'}>
      {(props?.users || []).map((user, index) => {
        return (
          <Box key={index}>
            <Box
              w='full'
              display={'flex'}
              flexDir='row'
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <UserDisplayer user={user as Required<T>} />
              <Tooltip label='Remover usuario'>
                <IconButton
                  {...buildTestId('remove-user-btn')}
                  onClick={() => props.remove(index)}
                  variant={'ghost'}
                  aria-label='remove user'
                  icon={<IoMdRemove />}
                />
              </Tooltip>
            </Box>
            {index + 1 !== props.users.length && <Divider />}
          </Box>
        )
      })}
    </Box>
  )
}
