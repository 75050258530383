import httpClient from './http-client';

export interface IResetPassword {
  password: string;
  passwordConfirmation: string;
}

export class SecurityClient {
  static async resetPasswordSession(
    input: IResetPassword
  ): Promise<{ success: boolean }> {
    const response = await httpClient.put(
      '/v1/security/password/session/reset',
      input
    );

    return response.data;
  }

  static async resetPasswordToken(
    token: string,
    input: IResetPassword
  ): Promise<{ success: boolean }> {
    const response = await httpClient.put(
      '/v1/security/password/token/reset',
      input,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async forgotPassword(email: string): Promise<{ success: boolean }> {
    const response = await httpClient.post('/v1/security/password/forgot', {
      email,
    });

    return response.data;
  }

  static async authenticateResetToken(
    token: string
  ): Promise<{ success: boolean }> {
    const response = await httpClient.get('/v1/security/password/token/reset', {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }
}
