import { IconButton, MenuItem, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { BsFillTrashFill } from 'react-icons/bs'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react'
import { AiFillEdit } from 'react-icons/ai'
import { ContactClient, IContact, ICreateContactSchema } from 'kach-clients'
import { ContactLabForm } from './ContactLabForm'
import { useError } from '../hooks/useError'
import { IContactSchema } from '../schemas/contact.schema'
import { useQueryClient } from '@tanstack/react-query'
import { LAB_CONTACTS_QUERY_KEY } from '../constants/query-keys'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

const Body = (props: { onClose: () => void; contact: IContact }) => {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const formId = 'update-contact-form'

  const toast = useToast()

  const onUpdate = useCallback(
    (data: IContactSchema) => {
      setIsLoading(true)
      console.log(data)

      toast.promise(
        ContactClient.update(props.contact.id, data as ICreateContactSchema),
        {
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
          loading: {
            title: 'Actualizando contacto...',
          },
          success: () => {
            queryClient.invalidateQueries([LAB_CONTACTS_QUERY_KEY])
            props.onClose()
            setIsLoading(false)
            return { title: 'Contacto actualizado correctamente' }
          },
        },
      )
    },
    [props.contact.id],
  )

  return (
    <>
      <ModalCloseButton isDisabled={isLoading} />
      <ModalBody p={5}>
        <ContactLabForm
          formId={formId}
          defaultValues={{
            ...props.contact,
            priceGroupId: props.contact.priceGroup?.id?.toString(),
          }}
          onSubmit={onUpdate}
        />
      </ModalBody>

      <ModalFooter display='flex' justifyContent='end' experimental_spaceX={2}>
        <Button onClick={props.onClose} isDisabled={isLoading} size='sm'>
          Cancelar
        </Button>
        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          form={formId}
          type='submit'
          variant='primary'
          size='sm'
        >
          Actualizar
        </Button>
      </ModalFooter>
    </>
  )
}

export const UpdateContact = (props: { contact: IContact }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <MenuItem onClick={onOpen}>Editar</MenuItem>
      <Modal size='6xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Body contact={props.contact} onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  )
}
