import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { IMember, ISession, SessionClient } from 'kach-clients'
import React, { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Cell, Column } from 'react-table'
import { formatDateToLocaleDays } from '../utils/format-date'
import { CustomTable } from './CustomTable'
import { PaginationFooter } from './PaginationFooter'
import { SessionActions } from './SessionActions'
import { useQuerySingleton } from '../hooks/useQuerySingleton'

const Body = (props: { member: IMember }) => {
  const [page, setPage] = useState<number>(1)

  const { isLoading, data } = useQuerySingleton(
    ['sessions', page],
    (ctx) => {
      const [session, page] = ctx.queryKey

      return SessionClient.listUserSessions(props.member.id, {
        limit: 10,
        page: page as number,
      })
    },
    {
      refetchOnMount: true,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  )

  const columns = useMemo<Column<ISession>[]>(
    () => [
      {
        Header: 'Empezó el',
        accessor: (session: ISession) =>
          formatDateToLocaleDays(session.createdAt, 'Pp'),
      },
      {
        Header: 'Cerro sesión el',
        accessor: (session: ISession) =>
          session.loggedOutAt
            ? formatDateToLocaleDays(session.loggedOutAt, 'Pp')
            : 'No cerró sesión',
      },
      {
        Header: 'IP',
        accessor: 'ip',
      },
      {
        id: 'actions',
        //@ts-ignore
        Cell: (cell: Cell<ISession>) => (
          <SessionActions session={cell.row.original} />
        ),
      },
    ],
    [],
  )

  if (!data || isLoading) {
    return <Spinner />
  }

  return (
    <Box>
      <CustomTable
        noHover
        orientation='center'
        columns={columns}
        data={data.results}
      />
      <PaginationFooter paginationData={data} page={page} setPage={setPage} />
    </Box>
  )
}

export const UserSessionsModal = (props: {
  member: IMember
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal size='4xl' isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sesiones</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Body member={props.member} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
