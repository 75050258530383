import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  IInvestigationReport,
  InvestigationClient,
  ReportClient,
} from 'kach-clients'
import React, { useCallback, useMemo, useState } from 'react'
import { BsCheck } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildReportQueryKey } from '../utils/build-report-query-key'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { useAuth } from '../hooks/useAuth'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'
import { hasPermission } from '../utils/has-permission'

const DismissReportAccepted = (props: {
  isDisabled: boolean
  isLoadingDismiss: boolean
  onDismissReport: () => void
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  return (
    <>
      <Button
        color='orange.400'
        size='sm'
        leftIcon={<IoClose fontSize={'1.3rem'} />}
        variant={'outline'}
        isDisabled={props.isDisabled}
        isLoading={props.isLoadingDismiss}
        onClick={onOpen}
      >
        Desestimar informe aceptado
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef!}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Desestimar informe aceptado
            </AlertDialogHeader>

            <AlertDialogBody>
              Estas seguro que desea desestimar el informe aceptado? Esta acción
              dejara el caso sin informe aceptado y la investigación en
              progreso.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={props.onDismissReport} ml={3}>
                Desestimar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const DismissReportRejection = (props: {
  isDisabled: boolean
  isLoadingDismiss: boolean
  onDismissReport: () => void
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  return (
    <>
      <Button
        color='orange.400'
        size='sm'
        leftIcon={<IoClose fontSize={'1.3rem'} />}
        variant={'outline'}
        isDisabled={props.isDisabled}
        isLoading={props.isLoadingDismiss}
        onClick={onOpen}
      >
        Desestimar devolución
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef!}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Desestimar devolución
            </AlertDialogHeader>

            <AlertDialogBody>
              Estas seguro que desea desestimar el rechazo? El reporte volverá a
              pendiente de revision.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={props.onDismissReport} ml={3}>
                Desestimar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export const InvestigationReportActions = (props: {
  report: IInvestigationReport
  investigationId: number
  caseId: number
}) => {
  const [isLoadingReject, setIsLoadingReject] = useState(false)
  const [isLoadingAccept, setIsLoadingAccept] = useState(false)
  const [isLoadingDismiss, setIsLoadingDismiss] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const { data, isLoading } = useQuerySingleton(
    [buildInvestigationQueryKey(props.investigationId)],
    () => InvestigationClient.detail(props.investigationId, auth.company.type),
  )

  const auth = useAuth()

  const onDismissReport = useCallback(() => {
    setIsLoadingDismiss(true)

    toast.promise(InvestigationClient.dismissReport(props.report.id), {
      loading: {
        title: 'Desestimando informe...',
      },
      success: () => {
        queryClient.invalidateQueries([
          buildInvestigationQueryKey(props.investigationId),
        ])
        queryClient.invalidateQueries([buildReportQueryKey(props.report.id)])
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
        setIsLoadingDismiss(false)
        return {
          title: 'Informe desestimando correctamente!',
        }
      },
      error: (err) => {
        setIsLoadingDismiss(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [props.report.id])

  const onAccept = useCallback(() => {
    setIsLoadingAccept(true)

    toast.promise(InvestigationClient.acceptReport(props.report.id), {
      loading: {
        title: 'Aceptando informe...',
      },
      success: () => {
        queryClient.invalidateQueries([
          buildInvestigationQueryKey(props.investigationId),
        ])
        queryClient.invalidateQueries([buildReportQueryKey(props.report.id)])
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
        setIsLoadingAccept(false)
        return {
          title: 'Informe aceptado correctamente!',
        }
      },
      error: (err) => {
        setIsLoadingAccept(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [props.report.id])

  const onReject = useCallback(() => {
    setIsLoadingReject(true)

    toast.promise(InvestigationClient.rejectReport(props.report.id), {
      loading: {
        title: 'Rechazando informe...',
      },
      success: () => {
        queryClient.invalidateQueries([
          buildInvestigationQueryKey(props.investigationId),
        ])
        queryClient.invalidateQueries([buildReportQueryKey(props.report.id)])
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
        setIsLoadingReject(false)
        return {
          title: 'Informe devuelto correctamente',
        }
      },
      error: (err) => {
        setIsLoadingReject(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [props.report.id])

  if (isLoading || !data) {
    return (
      <Box>
        <Spinner size='xs' />;
      </Box>
    )
  }

  const isCaseMember = useMemo(() => {
    return data.case.users.some((u) => u.user_id === auth.id)
  }, [data.case.users, auth.id])

  const canAcceptOrReject =
    auth.company.type === 'insurer' &&
    (isCaseMember ||
      hasPermission(auth.role.permissions, {
        action: 'update_all',
        resource: 'case',
      })) &&
    props.report.status === 'pending' &&
    !data.report_accepted_id

  const canDismissAcceptedReport =
    auth.company.type === 'insurer' &&
    (isCaseMember ||
      hasPermission(auth.role.permissions, {
        action: 'update_all',
        resource: 'case',
      })) &&
    props.report.status === 'accepted' &&
    props.report.id === data.report_accepted_id

  const canDismissRejectedReport =
    auth.company.type === 'insurer' &&
    (isCaseMember ||
      hasPermission(auth.role.permissions, {
        action: 'update_all',
        resource: 'case',
      })) &&
    props.report.status === 'rejected'

  return (
    <ButtonGroup
      isAttached
      display={'flex'}
      alignItems='center'
      flexDir={'row'}
    >
      {canDismissAcceptedReport && (
        <DismissReportAccepted
          isDisabled={isLoadingReject || isLoadingAccept || isLoadingDismiss}
          isLoadingDismiss={isLoadingDismiss}
          onDismissReport={() => onDismissReport()}
        />
      )}

      {canDismissRejectedReport && (
        <DismissReportRejection
          isDisabled={isLoadingReject || isLoadingAccept || isLoadingDismiss}
          isLoadingDismiss={isLoadingDismiss}
          onDismissReport={() => onDismissReport()}
        />
      )}

      {canAcceptOrReject && (
        <Button
          color='white'
          size='sm'
          variant={'primary'}
          leftIcon={<BsCheck fontSize={'1.3rem'} color='white' />}
          isDisabled={isLoadingReject || isLoadingAccept || isLoadingDismiss}
          isLoading={isLoadingAccept}
          onClick={onAccept}
        >
          Aceptar
        </Button>
      )}

      {canAcceptOrReject && (
        <Button
          size='sm'
          variant={'outline'}
          leftIcon={<IoClose fontSize={'1.3rem'} />}
          onClick={onReject}
          isDisabled={isLoadingReject || isLoadingAccept || isLoadingDismiss}
          isLoading={isLoadingReject}
        >
          Devolver
        </Button>
      )}
    </ButtonGroup>
  )
}
