export const SEARCH_USERS_QUERY_KEY = 'users'

export const MEMBERS_QUERY_KEY = 'members'

export const NOTIFICATIONS_QUERY_KEY = 'notifications'

export const NOTIFICATIONS_PAGINATION_QUERY_KEY = 'notifications-pagination'

export const CASES_QUERY_KEY = 'cases-pagination'

export const LAB_PRICES_QUERY_KEY = 'lab-prices'

export const PENDING_JOBS_QUERY_KEY = 'pending-jobs'

export const LAB_CONTACTS_QUERY_KEY = 'paginate-contacts'

export const PRICE_GROUPS_QUERY_KEY = 'price-groups'
export const INVESTIGATIONS_QUERY_KEY = 'investigations'

export const LIMITS_QUERY_KEY = 'limits'

export const CASE_DELEGATED_QUERY_KEY = 'cases-delegated'
