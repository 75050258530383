import { isSameDay, isAfter, addDays, add } from 'date-fns'
import { date, z } from 'zod'

export const HideLabSchema = z
  .object({
    hidden: z.boolean(),
    hiddenReason: z.string(),
    hiddenFrom: z.string().nullish(),
    hiddenTo: z.string().nullish(),
  })
  .refine(
    (val) => {
      if (val.hidden && val.hiddenFrom && val.hiddenTo)
        return isAfter(new Date(val.hiddenTo), new Date(val.hiddenFrom))

      return true
    },
    {
      message: 'La fecha de inicio debe ser menor a la fecha de fin',
      path: ['hiddenFrom'],
    },
  )

export interface IHideLabSchema extends z.infer<typeof HideLabSchema> {}
