import { Text, TextProps, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

export interface IOverflowText {
  children: React.ReactNode;
  noOfLines: number;
  textProps: TextProps;
}

export const OverflowText = ({ children, noOfLines, ...props }: TextProps) => {
  const textRef = useRef<HTMLParagraphElement>();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [linesCount, setLinesCount] = useState(noOfLines);

  useEffect(() => {
    const element = textRef.current;

    setIsEllipsisActive(
      element
        ? element.offsetWidth < element.scrollWidth ||
            element.offsetHeight < element.scrollHeight
        : false
    );
  }, []);

  return (
    <>
      <Tooltip
        label={children}
        isDisabled={!isEllipsisActive}
        shouldWrapChildren
      >
        <Text
          ref={textRef}
          noOfLines={linesCount}
          whiteSpace="initial"
          {...props}
        >
          {children}
        </Text>
      </Tooltip>
    </>
  );
};
