import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

export const CustomScrollBox = ({
  styles,
  children,
}: React.PropsWithChildren<{
  styles: BoxProps
}>) => {
  return (
    <Box
      __css={{
        '&::-webkit-scrollbar': {
          w: '2',
        },
        '&::-webkit-scrollbar-track': {
          w: '6',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10',
          bg: `gray.100`,
        },
      }}
      overflowY='auto'
      {...styles}
    >
      {children}
    </Box>
  )
}
