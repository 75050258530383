import {
  Box,
  chakra,
  Icon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { EnvelopeIcon } from '@heroicons/react/24/solid'
import { IMail } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useToggle } from 'ahooks'
import React, { useEffect, useState } from 'react'
import { FocusParamsSchema } from '../schemas/focus-params.schema'
import { AddCaseResource } from './AddCaseResource'
import { CaseAccordion } from './CaseAccordion'
import CreateMail from './CreateMail'
import MailDetail from './MailDetail'
import { SeeDetailsBtn } from './SeeDetailsBtn'

const MailLine = ({
  mail,
  onClickDetails,
  index,
}: {
  index: number
  mail: IMail
  onClickDetails: () => void
}) => {
  return (
    <ListItem>
      <Box>
        <Box display='flex' alignItems='center' pr={3}>
          <Box display='flex' minW={0} flex={1} alignItems='center'>
            <Box minW={1} flex={1} px={4}>
              <Box
                mt={2}
                display='flex'
                color='gray.500'
                alignItems='center'
                experimental_spaceX={3}
              >
                <Box
                  border='1px'
                  borderColor='gray.300'
                  p={2}
                  borderRadius='md'
                >
                  <Icon as={EnvelopeIcon} w='6' h='6' />
                </Box>

                <Box display='flex' flexDir='column'>
                  <chakra.span
                    {...buildTestId(`mail-${index}-externalId`)}
                    noOfLines={1}
                    fontWeight='bold'
                  >
                    #{mail.externalId}
                  </chakra.span>
                  <Text fontSize='sm'>{mail.attempts.length} intentos</Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box {...buildTestId(`mail-${index}-see-details-container`)}>
            <SeeDetailsBtn onClick={onClickDetails} />
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

export const CaseMails: React.FC<
  React.PropsWithChildren<{
    query?: object
    withoutCreate?: boolean
    isCaseClosed: boolean
    mails: IMail[]
    caseId: number
  }>
> = ({ children, mails, caseId, withoutCreate, query, isCaseClosed }) => {
  const [open, { toggle }] = useToggle()

  const [openDetail, { toggle: toggleDetail, set }] = useToggle()

  const [currentMailId, setCurrentMailId] = useState<number>()

  useEffect(() => {
    const focusObjectParam = FocusParamsSchema.safeParse(query)

    if (
      focusObjectParam.success &&
      focusObjectParam.data.focus_object === 'mailAttempt' &&
      mails.some((m) => m.id === Number(focusObjectParam.data.focus_id))
    ) {
      setCurrentMailId(Number(focusObjectParam.data.focus_id))
      set(true)
    }
  }, [])

  const ModalCreate = (
    <Modal size='xl' isOpen={open} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={6}>
          <CreateMail onClose={toggle} caseId={caseId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )

  const ModalDetail = (
    <Modal size='xl' isOpen={openDetail} onClose={toggleDetail}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton {...buildTestId('mail-detail-close-btn')} />
        <ModalBody>
          {openDetail && currentMailId && (
            <MailDetail
              caseId={caseId}
              withoutCreate={withoutCreate}
              isCaseClosed={isCaseClosed}
              onClose={toggleDetail}
              mailId={currentMailId}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )

  return (
    <>
      <CaseAccordion
        withoutCreate={withoutCreate}
        count={mails.length}
        SuffixComponent={
          <Box {...buildTestId('create-case-mail-btn')}>
            <AddCaseResource label='Agregar carta' onClick={toggle} />
          </Box>
        }
        label={{
          singular: 'carta',
          plural: 'cartas',
        }}
      >
        <Box>
          <Box overflow='hidden' bg='white' boxShadow='md' borderRadius='md'>
            <Box overflow='hidden' bg='white' boxShadow='md' borderRadius='md'>
              <List experimental_spaceY={2}>
                {mails.map((mail, index) => (
                  <MailLine
                    index={index}
                    onClickDetails={() => {
                      setCurrentMailId(mail.id)
                      toggleDetail()
                    }}
                    mail={mail}
                    key={index}
                  />
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </CaseAccordion>
      {ModalCreate}
      {ModalDetail}
    </>
  )
}
