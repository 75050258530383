import {
  ChevronDownIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/solid'
import { useToggle } from 'ahooks'
import { format } from 'date-fns'
import { IThread } from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { CgMoreAlt } from 'react-icons/cg'
import { GiPlainCircle } from 'react-icons/gi'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { ThreadClient } from 'kach-clients'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { classNames } from '../utils/classNames'
import { translator } from '../utils/translator'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Box,
  IconButton,
  chakra,
  Link,
  List,
  ListItem,
  Text,
  Icon,
  useToast,
} from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import CreateThreadForm from './CreateThreadForm'
import { CaseAccordion } from './CaseAccordion'
import { AddCaseResource } from './AddCaseResource'
import { NavigatorFC } from './NotificationList'
import { useAuth } from '../hooks/useAuth'
import CircleIcon from './CircleIcon'
import { buildTestId } from 'kach-commons'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

const items = [
  { name: 'Save and schedule', href: '#' },
  { name: 'Save and publish', href: '#' },
  { name: 'Export PDF', href: '#' },
]

const ThreadLine = ({
  withoutCreate,
  thread,
  Navigator,
  caseId,
  index,
}: {
  caseId: number
  thread: IThread
  Navigator: NavigatorFC
  withoutCreate?: boolean
  index: number
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { getErrorMessage } = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onCloseThread = useCallback(() => {
    toast.promise(ThreadClient.close(thread.id), {
      loading: { title: 'Cerrando hilo' },
      error: (error) => {
        return getErrorMessage(error)
      },
      success: () => {
        queryClient.invalidateQueries([buildCaseQueryKey(caseId)])
        return { title: 'Hilo cerrado correctamente' }
      },
    })
  }, [])

  const auth = useAuth()

  const isClosed = thread.status === 'closed'

  return (
    <ListItem>
      <Box>
        <Box display='flex' alignItems='center' py={4} pr={3}>
          <Box display='flex' minW={0} flex={1} alignItems='center'>
            <Box minW={0} flex={1} px={4}>
              <Box display='flex' alignItems='center'>
                <Text
                  mr={2}
                  display='flex'
                  alignItems='center'
                  fontSize='sm'
                  color='gray.600'
                >
                  <Icon as={EnvelopeIcon} h='4' w='4' mr={2} />
                  <chakra.span
                    {...buildTestId(`thread-${index}-destination`)}
                    noOfLines={1}
                  >
                    {thread.destination}
                  </chakra.span>
                </Text>
                <CircleIcon color={isClosed ? 'red.400' : 'green.400'} />
              </Box>
              <Box>
                <Box pl={6}>
                  <Text fontSize='xs' color='gray.500'>
                    Abierto el{' '}
                    <time dateTime={thread.createdAt}>
                      {format(new Date(thread.createdAt), 'Pp')}
                    </time>
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Menu>
              <MenuButton
                {...buildTestId(`thread-menu-btn`)}
                as={IconButton}
                aria-label='Options'
                size='sm'
                icon={<Icon as={ChevronRightIcon} />}
              />
              <MenuList>
                <MenuItem
                  {...buildTestId(`thread-link`)}
                  as={Link}
                  target='_blank'
                  href={`/case/thread/${thread.id}`}
                >
                  Ver hilo
                </MenuItem>
                {!withoutCreate && auth.company.type === 'insurer' && (
                  <MenuItem
                    isDisabled={thread.status === 'closed'}
                    disabled={thread.status === 'closed'}
                    onClick={onCloseThread}
                  >
                    Cerrar hilo
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

export const CaseThreads: React.FC<
  React.PropsWithChildren<{
    Navigator: NavigatorFC
    threads: IThread[]
    withoutCreate?: boolean
    caseId: number
  }>
> = ({ children, threads, withoutCreate, caseId, Navigator }) => {
  const [open, { toggle }] = useToggle()

  const ModalCreateThreadForm = (
    <Modal size={'2xl'} isOpen={open} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={6}>
          <CreateThreadForm onClose={toggle} caseId={caseId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )

  return (
    <>
      <CaseAccordion
        withoutCreate={withoutCreate}
        count={threads.length}
        SuffixComponent={
          <Box {...buildTestId('create-thread-btn')}>
            <AddCaseResource label='Agregar hilo' onClick={toggle} />
          </Box>
        }
        label={{
          singular: 'hilo',
          plural: 'hilos',
        }}
      >
        <div>
          <Box bg='white' boxShadow='md' borderRadius='md'>
            <Box bg='white' boxShadow='md' borderRadius='md'>
              <List>
                {threads.map((thread, index) => (
                  <ThreadLine
                    index={index}
                    withoutCreate={withoutCreate}
                    caseId={caseId}
                    Navigator={Navigator}
                    thread={thread}
                    key={index}
                  />
                ))}
              </List>
            </Box>
          </Box>
        </div>
      </CaseAccordion>
      {ModalCreateThreadForm}
    </>
  )
}
