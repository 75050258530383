import { InvestigationClient } from 'kach-clients'

export const registerInvestgationView = async (investigationId: number) => {
  console.log('investigacionId', investigationId)
  try {
    await InvestigationClient.registerView(investigationId)
  } catch (error) {
    console.log('Error registering view', error)
  }
}
