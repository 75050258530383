import { Link, Box, IconButton, Spinner, Text, Tooltip } from '@chakra-ui/react'
import { ISinisterDoc, ICaseExtended, CaseClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useMemo } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import { useQuery } from '@tanstack/react-query'
import { Cell, Column } from 'react-table'
import { formatDateToLocaleDays } from '../utils/format-date'
import { CaseAccordion } from './CaseAccordion'
import { CustomTable } from './CustomTable'
import { FetchErrorMessage } from './FetchErrorMessage'
import { useQuerySingleton } from '../hooks/useQuerySingleton'

const Table = (props: { sinisterDocs: ISinisterDoc[] }) => {
  const columns = useMemo<Column<ISinisterDoc>[]>(
    () => [
      {
        id: 'detail',
        accessor: 'description',
        //@ts-ignore
        Cell: (cell: Cell<ISinisterDoc>) => {
          return (
            <Box>
              <Tooltip label='Descargar documento'>
                <Link target={'_blank'} href={cell.row.original.document.url}>
                  <IconButton
                    variant={'ghost'}
                    aria-label='Descargar'
                    icon={<AiOutlineDownload />}
                  />
                </Link>
              </Tooltip>
            </Box>
          )
        },
      },
      {
        Header: 'Fecha de Caratula',
        accessor: (row: ISinisterDoc) =>
          formatDateToLocaleDays(row.originalDate, 'P'),
      },
      {
        Header: 'Tipo',
        id: 'type',
        accessor: 'type',
      },
      {
        Header: 'Vinculada',
        accessor: 'gatewayRelation',
      },
      {
        Header: 'Descripción',
        accessor: 'description',
      },
    ],
    [],
  )

  return (
    <CustomTable
      orientation='left'
      noHover
      columns={columns}
      data={props.sinisterDocs}
    />
  )
}

const Body = (props: { case: ICaseExtended }) => {
  const { isLoading, data, error } = useQuerySingleton(
    [`case-sinister-docs-${props.case.id}`],
    () => CaseClient.listSinisterDocs(props.case.id),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 180000,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 1,
    },
  )

  if (isLoading) {
    return (
      <Box
        h='full'
        display={'flex'}
        alignItems={'center'}
        justifyContent='center'
      >
        <Spinner />
      </Box>
    )
  }

  if (error || !data?.sinisterDocuments) {
    return <FetchErrorMessage />
  }

  return (
    <Box>
      <Table sinisterDocs={data.sinisterDocuments || []} />
    </Box>
  )
}

export const CaseSinisterDocuments = (props: { case: ICaseExtended }) => {
  return (
    <Box {...buildTestId('case-inspections-container')}>
      <CaseAccordion
        withoutEmptyState
        label={{
          plural: 'Digitalización',
          singular: 'Digitalización',
        }}
      >
        {({ isExpanded }) => {
          if (isExpanded) {
            return <Body case={props.case} />
          }

          return null
        }}
      </CaseAccordion>
    </Box>
  )
}
