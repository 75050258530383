import React from 'react'
import { CaseClient } from 'kach-clients'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { ResourceViews } from './ResourceViews'
import { WaitForQuery } from './WaitForQuery'
import { Skeleton, Stack } from '@chakra-ui/react'

export const CaseViews = ({ caseId }: { caseId: number }) => {
  const query = useQuerySingleton([`case-${caseId}-views`], () =>
    CaseClient.retrieveViews(caseId),
  )

  return (
    <WaitForQuery fallback={<Skeleton height='20px' />} query={query}>
      {({ views }) => {
        return <ResourceViews views={views} />
      }}
    </WaitForQuery>
  )
}
