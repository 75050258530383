import { useDisclosure } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { isExternalDialogOpenAtom } from '../atoms/is-external-dialog-open.atom';
import { isExternalMenuOpenAtom } from '../atoms/is-external-menu-open.atom';

export const useExternalDialogChanges = (props: {
  elementId: string;
  customBindings: {
    event: string;
    listener: EventListenerOrEventListenerObject;
  }[];
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [_, setIsExternalDialogOpen] = useAtom(isExternalDialogOpenAtom);
  const [__, setIsExternalMenuOpen] = useAtom(isExternalMenuOpenAtom);

  const onModalChange = useCallback(
    (event: CustomEvent<{ isOpen: boolean }>) => {
      setIsExternalDialogOpen(event.detail.isOpen);
      if (event.detail.isOpen) {
        onOpen();
      }

      if (!event.detail.isOpen) {
        onClose();
      }
    },
    []
  );

  const onMenuChange = useCallback(
    (event: CustomEvent<{ isOpen: boolean }>) => {
      setIsExternalMenuOpen(event.detail.isOpen);
    },
    []
  );

  useEffect(() => {
    const targetElement = document.getElementById(props.elementId);

    if (!targetElement) {
      return;
    }

    props.customBindings.forEach((binding) => {
      targetElement.addEventListener(binding.event, binding.listener);
    });

    targetElement.addEventListener('ai:modal-disclosure-change', onModalChange);
    targetElement.addEventListener('ai:menu-disclosure-change', onMenuChange);

    return () => {
      targetElement.removeEventListener(
        'ai:modal-disclosure-change',
        onModalChange
      );

      targetElement.removeEventListener(
        'ai:menu-disclosure-change',
        onMenuChange
      );

      props.customBindings.forEach((binding) => {
        targetElement.removeEventListener(binding.event, binding.listener);
      });
    };
  }, []);

  return {
    isOpen,
    onClose,
    onOpen,
    setIsExternalDialogOpen,
  };
};
