import React from 'react'
import {
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { HiOutlineIdentification } from 'react-icons/hi2'
import {
  CaseCustomerReportClient,
  IIntegrationConnection,
  IntegrationsType,
} from 'kach-clients'
import { UserDisplayer } from './UserDisplayer'
import { ICaseCustomerReport } from 'kach-clients'
import { CaseCustomerCommercialReportDetail } from './CaseCustomerCommercialReportDetail'
import { CaseCustomerSinisterReportDetail } from './CaseCustomerSinisterReportDetail'
import { useError } from '../hooks/useError'
import { timeAgoFormat } from 'kach-commons/src/utils/time-ago-format'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { ICustomerSchema } from '../schemas/customer.schema'
import { CaseAccordion } from './CaseAccordion'
import { buildTestId } from 'kach-commons'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { RequestCommercialCustomerReportForm } from './RequestCommercialCustomerReportForm'
import { FaUser } from 'react-icons/fa'
import { CiUser } from 'react-icons/ci'
import { RxHamburgerMenu } from 'react-icons/rx'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'

const MISSING_IDENTIFICATION_MESSAGE =
  'El dato de identificación del asegurado es requerido. Complétalo primero y luego solicita el reporte.'

const CUSTOMER_REPORT_LABEL = 'Informe comercial (Riesgo Online)'

const SINISTER_HISTORY_LABEL = 'Historial siniestral (IRIS)'

const FOUR_MINUTES_IN_MS = 240000

const CaseCustomerReportDetail = (props: {
  customerReport: ICaseCustomerReport
}) => {
  const { isLoading, data } = useQuerySingleton(
    [`case-customer-report-${props.customerReport.id}`],
    () => CaseCustomerReportClient.detail(props.customerReport.id),
    {
      refetchInterval: FOUR_MINUTES_IN_MS,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  if (isLoading || !data) {
    return <Spinner size='lg' />
  }

  switch (data.type) {
    case 'commercial':
      return <CaseCustomerCommercialReportDetail report={data.commercial} />

    case 'sinister':
      return <CaseCustomerSinisterReportDetail sinisters={data.sinisters} />

    default:
      return null
  }
}

const ReportLineOptions = ({
  customerReport,
}: {
  customerReport: ICaseCustomerReport
}) => {
  const menuDisclosure = useDisclosure()
  const dialogDisclosure = useDisclosure()

  const cancelRef = React.useRef()

  const [isLoading, setIsLoading] = useState(false)

  const { handleError } = useError()

  const queryClient = useQueryClient()

  const toast = useToast()

  const handleRemove = useCallback(() => {
    setIsLoading(true)

    CaseCustomerReportClient.remove(customerReport.case_id, customerReport.id)
      .then((res) => {
        queryClient.invalidateQueries([
          buildCaseQueryKey(customerReport.case_id),
        ])
        dialogDisclosure.onClose()

        toast({
          title: 'Informe eliminado correctamente',
          status: 'success',
          isClosable: true,
        })
      })
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }, [customerReport.case_id, customerReport.id])

  return (
    <>
      <Menu
        size='xs'
        onClose={menuDisclosure.onClose}
        isOpen={menuDisclosure.isOpen}
      >
        <MenuButton
          bg='white'
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            menuDisclosure.onToggle()
          }}
          as={IconButton}
          size='sm'
          icon={<RxHamburgerMenu />}
          variant='outline'
        />
        <MenuList>
          <MenuItem
            onClick={(ev) => {
              ev.preventDefault()
              ev.stopPropagation()
              dialogDisclosure.onOpen()
            }}
            fontSize={'sm'}
            color='red.500'
          >
            Eliminar
          </MenuItem>
        </MenuList>
      </Menu>
      <AlertDialog
        isOpen={dialogDisclosure.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={dialogDisclosure.onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Eliminar informe
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estas seguro que deseas eliminar este informe? No podrás deshacer
              esta acción.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={dialogDisclosure.onClose}>
                Cancelar
              </Button>
              <Button
                isLoading={isLoading}
                colorScheme='red'
                onClick={handleRemove}
                ml={3}
              >
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const CustomerReportLine = (props: { customerReport: ICaseCustomerReport }) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

  return (
    <>
      <Modal size={'4xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            {...buildTestId('customer-report-line-close-btn')}
          />
          <ModalBody>
            <CaseCustomerReportDetail customerReport={props.customerReport} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box
        {...buildTestId(
          `customer-report-line-${props.customerReport?.integrationConnection?.integration?.name}`,
        )}
        onClick={onOpen}
        cursor={'pointer'}
        className='transition-all'
        p={4}
        m={-4}
        _hover={{
          bg: 'gray.100',
        }}
        display={'flex'}
        flexDir='row'
        alignItems={'start'}
        justifyContent={'space-between'}
        h='full'
      >
        <Stack w='full' direction={'column'} spacing={2}>
          <Stack w='full' spacing={4} direction={'row'}>
            <Heading
              {...buildTestId('customer-report-line-label')}
              fontSize={'lg'}
              fontWeight={'bold'}
              as='h2'
            >
              {props.customerReport?.integrationConnection?.integration?.label}
            </Heading>
            {props.customerReport.identification && (
              <Stack spacing={1} direction={'row'} alignItems='center'>
                <HiOutlineIdentification width={4} height={4} />
                <Text fontSize={'xs'}>
                  {props.customerReport.identification}
                </Text>
              </Stack>
            )}
          </Stack>
          <UserDisplayer user={props.customerReport.requestedBy} />
          <Text fontSize={'xs'}>
            {timeAgoFormat(props.customerReport.createdAt)}
          </Text>
        </Stack>
        {props.customerReport.type === 'commercial' && (
          <ReportLineOptions customerReport={props.customerReport} />
        )}
      </Box>
    </>
  )
}

const RequestCustomerReportOpt = (props: {
  type: 'commercial' | 'sinister'
  label: string
  caseId: number
  customer: ICaseCustomerReport
  integration: IntegrationsType
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  useEffect(() => {
    let toastId: string

    if (isLoading) {
      toastId = new Date().getTime().toString()

      toast.update(toastId, {
        status: 'loading',
        title: `Solicitando ${props.label}`,
      })
    }

    return () => {
      toast.close(toastId)
    }
  }, [isLoading])

  const onRequestReport = useCallback(() => {
    setIsLoading(true)

    toast.promise(CaseCustomerReportClient.generate(props.caseId, props.type), {
      loading: {
        title: 'Solicitando informe...',
      },
      success: () => {
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
        setIsLoading(false)
        return { title: 'Informe solicitado correctamente' }
      },
      error: (err) => {
        setIsLoading(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [props.caseId, props.type])

  return (
    <>
      <MenuItem
        {...buildTestId(`request-${props.type}-report`)}
        onClick={
          props.type === 'commercial' ? () => setIsOpen(true) : onRequestReport
        }
      >
        {isLoading ? <Spinner size='xs' /> : props.label}
      </MenuItem>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />

        <ModalContent>
          <RequestCommercialCustomerReportForm
            caseId={props.caseId}
            onClose={() => setIsOpen(false)}
          />
        </ModalContent>
      </Modal>
    </>
  )
}

export const CaseCustomerReports = (props: {
  caseId: number
  withoutCreate?: boolean
  customer: ICustomerSchema
  customerReports: ICaseCustomerReport[]
  integrationConnections: IIntegrationConnection[]
}) => {
  const findDataProvider = useCallback(
    (integration: IntegrationsType) =>
      (props.integrationConnections || []).find(
        (d) => d.integration.name === integration,
      ),
    [],
  )

  const customerReportDisabled = !findDataProvider('riesgo_online')

  const customerSinisterHistoryDisabled =
    !props.customer?.identification || !findDataProvider('iris')

  return (
    <Box {...buildTestId('case-customer-reports-container')}>
      <CaseAccordion
        withoutCreate={props.withoutCreate}
        SuffixComponent={
          <Menu placement='bottom'>
            <MenuButton
              {...buildTestId('case-customer-reports-menu-btn')}
              as={Button}
              size='sm'
              onClick={(e) => e.stopPropagation()}
            >
              Solicitar
            </MenuButton>
            <MenuList>
              {customerReportDisabled ? (
                <Tooltip
                  label={
                    'Se necesitan las credenciales de la integración con Riesgo Online para obtener el informe'
                  }
                >
                  <MenuItem isDisabled>{CUSTOMER_REPORT_LABEL}</MenuItem>
                </Tooltip>
              ) : (
                <RequestCustomerReportOpt
                  label={CUSTOMER_REPORT_LABEL}
                  type='commercial'
                  integration={'riesgo_online'}
                  //@ts-ignore
                  customer={props.customer}
                  caseId={props.caseId}
                />
              )}

              {customerSinisterHistoryDisabled ? (
                <Tooltip
                  label={
                    !props.customer?.identification
                      ? MISSING_IDENTIFICATION_MESSAGE
                      : 'Se necesitan la conexión con la integraron Iris para obtener el historial. Configura las credenciales primero.'
                  }
                >
                  <MenuItem isDisabled>{SINISTER_HISTORY_LABEL}</MenuItem>
                </Tooltip>
              ) : (
                <RequestCustomerReportOpt
                  type='sinister'
                  integration={'iris'}
                  label={SINISTER_HISTORY_LABEL}
                  //@ts-ignore
                  customer={props.customer}
                  caseId={props.caseId}
                />
              )}
            </MenuList>
          </Menu>
        }
        count={props.customerReports.length}
        label={{
          plural: 'informes',
          singular: 'informe',
        }}
      >
        {props.customerReports.length > 0 ? (
          <Box
            display={'flex'}
            flexDir='column'
            rounded={'lg'}
            bg='white'
            p={5}
          >
            {props.customerReports.map((customerReport, index) => {
              return (
                <div key={index}>
                  <CustomerReportLine customerReport={customerReport} />
                  {index + 1 !== props.customerReports.length && (
                    <Divider my='5' />
                  )}
                </div>
              )
            })}
          </Box>
        ) : null}
      </CaseAccordion>
    </Box>
  )
}
