import { Divider, Box, chakra, useToast } from '@chakra-ui/react'
import { SecurityClient } from 'kach-clients'

import React, { useCallback, useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { IResetPasswordSchema } from '../schemas/reset-password.schema'
import { Account } from './Account'
import { ChangePasswordForm } from './ChangePasswordForm'
import { UnavailabilityButton } from './UnavailabilityButton'
import { useError } from '../hooks/useError'

const ChangePasswordSection = () => {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()
  const error = useError()

  const onSubmit = useCallback(
    (data: Required<IResetPasswordSchema>, reset: () => void) => {
      setIsLoading(true)

      toast.promise(SecurityClient.resetPasswordSession(data), {
        success: () => {
          reset()
          setIsLoading(false)
          return {
            title: 'Contraseña actualizada correctamente',
          }
        },
        error: (err) => {
          setIsLoading(false)
          return error.handleError(err)
        },
        loading: { title: 'Actualizando contraseña...' },
      })
    },
    [],
  )

  return (
    <Box>
      <chakra.h2 mb='4' fontWeight={'medium'} fontSize='lg'>
        Contraseña
      </chakra.h2>
      <ChangePasswordForm
        btnSize='min'
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Box>
  )
}

export const GeneralSettingsSections = () => {
  const auth = useAuth()

  return (
    <Box>
      <Account />
      <Divider my='8' />
      {auth.hasLocalCredentials && <ChangePasswordSection />}

      <Divider my='8' />
      <UnavailabilityButton />
    </Box>
  )
}
