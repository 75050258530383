import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { BugAntIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { CaseRequestClient } from 'kach-clients'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaHandsHelping } from 'react-icons/fa'
import { useAuth } from '../hooks/useAuth'
import { useFileUploadV2 } from '../hooks/useFileUploadV2'
import { IReportBugSchema, ReportBugSchema } from '../schemas/report-bug.schema'
import { FileUploadV2 } from './FileUploadV2'

export const ReportBug = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const fileUpload = useFileUploadV2((files) =>
    CaseRequestClient.tokenizeArtifacts({ artifacts: files }),
  )

  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<IReportBugSchema>({
    resolver: zodResolver(ReportBugSchema),
    defaultValues: {
      files: [],
    },
  })

  const auth = useAuth()

  const formId = 'report-bug-form'

  const onReport = useCallback(
    (data: IReportBugSchema) => {
      setIsLoading(true)

      axios
        .post('/api/report-bug', {
          ...data,
          files: fileUpload.fileList.map((f) => ({
            storedName: (f as any as { storedName: string }).storedName,
          })),
        })
        .then((res) => setSuccess(true))
        .finally(() => setIsLoading(false))
    },
    [fileUpload.fileList],
  )

  const cleanUp = () => {
    setSuccess(false)
    reset()
    fileUpload.onCleanUp()
  }

  console.log('errors', errors)

  useEffect(() => {
    return () => {
      cleanUp()
    }
  }, [])

  useEffect(() => {
    if (!isOpen) {
      cleanUp()
    }
  }, [isOpen])

  return (
    <>
      <Tooltip label='Reportar error'>
        <IconButton
          onClick={onOpen}
          aria-label='show-links'
          icon={<Icon as={BugAntIcon} w='6' h='6' />}
          borderRadius='full'
          variant='ghost'
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {!success && <ModalHeader>Reportar Error</ModalHeader>}

          <ModalCloseButton isDisabled={isLoading} />
          <ModalBody>
            {!success ? (
              <chakra.form
                onSubmit={handleSubmit(onReport)}
                id={formId}
                display={'flex'}
                flexDir='column'
                experimental_spaceY={'5'}
              >
                <FormControl isRequired>
                  <FormLabel>Descripción del error</FormLabel>
                  <Textarea {...register('description')} />
                  {errors.description && (
                    <FormErrorMessage>
                      {errors.description.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>Pasos para reproducir el error</FormLabel>
                  <Textarea {...register('reproductionSteps')} />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    Adjuntar archivos (capturas de pantalla, etc.)
                  </FormLabel>
                  <FileUploadV2
                    onUpdateFile={fileUpload.onUpdateFile}
                    fileList={fileUpload.fileList}
                    inputId='report-bug-files'
                    onUpload={fileUpload.onUploadFiles}
                    limit={10}
                    maxFileSizeMB={20}
                    mimetypes={[]}
                    onRemove={fileUpload.onRemove}
                  />
                </FormControl>
              </chakra.form>
            ) : (
              <Box my='10'>
                <Box
                  justifyContent={'center'}
                  alignItems={'center'}
                  display={'center'}
                >
                  <FaHandsHelping fontSize={'6rem'} />
                </Box>
                <Box mt='5'>
                  <Text fontSize={'2xl'} fontWeight={'bold'}>
                    Gracias por reportar el error
                  </Text>
                  <Text>
                    Cuando el problema este solucionado, te enviaremos un mail a{' '}
                    <strong>{auth.email}</strong>
                  </Text>
                </Box>
              </Box>
            )}
          </ModalBody>

          {!success && (
            <ModalFooter>
              <Button
                isDisabled={isLoading}
                isLoading={isLoading}
                form={formId}
                type='submit'
                variant='primary'
                mr={3}
              >
                Enviar
              </Button>
              <Button isDisabled={isLoading} onClick={onClose} variant='ghost'>
                Cancelar
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
