import React from 'react'
import { IconButton, useDisclosure } from '@chakra-ui/react'
import { AiOutlineEdit } from 'react-icons/ai'
import { IContact, IContactPrice } from 'kach-clients'
import { UpdateContactLabPriceModal } from './UpdateContactLabPriceModal'
import { buildTestId } from 'kach-commons'

export const ContactPriceActions = (props: {
  contact: IContact
  price: IContactPrice
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <IconButton
        {...buildTestId('contact-price-edit-btn')}
        variant={'ghost'}
        onClick={onOpen}
        aria-label='edit'
        icon={<AiOutlineEdit />}
      />
      <UpdateContactLabPriceModal
        isOpen={isOpen}
        onClose={onClose}
        contact={props.contact}
        price={props.price}
      />
    </>
  )
}
