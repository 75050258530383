import {
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { CaseClient, IUser } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { MdOutlineExpandMore } from 'react-icons/md'
import { useQueryClient } from '@tanstack/react-query'
import { CaseStatusType } from '../constants/case-status'
import { CASES_QUERY_KEY } from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { buildUserName } from '../utils/build-user-name'
import { hasPermission } from '../utils/has-permission'
import { UsersSelectSearchable } from './UsersSelectSearchable'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

const DefineCaseResponsible = (props: {
  caseId: number
  withLabel?: boolean
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const [candidateResponsible, setCandidateResponsible] = useState<IUser>()

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const auth = useAuth()

  const toast = useToast()

  const onAssignCase = useCallback(
    (onClose: () => void) => {
      return () => {
        setIsLoading(true)
        toast.promise(
          CaseClient.defineCaseResponsible(
            props.caseId,
            candidateResponsible.id,
          ),
          {
            success: () => {
              queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
              queryClient.invalidateQueries([CASES_QUERY_KEY])
              onClose()
              setIsLoading(false)
              return {
                title: `${buildUserName(
                  candidateResponsible,
                )} pasó a ser el responsable del caso`,
              }
            },
            error: (err) => {
              setIsLoading(false)
              return { title: error.getErrorMessage(err) }
            },
            loading: {
              title: `Asignando caso a ${buildUserName(
                candidateResponsible,
              )}...`,
            },
          },
        )
      }
    },
    [candidateResponsible],
  )

  const hasSudoPermissionOverCase = hasPermission(auth.role.permissions, {
    resource: 'case',
    action: 'update_all',
  })

  return (
    <Popover isLazy placement='bottom'>
      {({ onClose }) => {
        return (
          <>
            <PopoverTrigger>
              {props.withLabel ? (
                <Button
                  w='full'
                  {...buildTestId('assign-case-responsible-btn')}
                  rightIcon={<MdOutlineExpandMore />}
                >
                  Asignar responsable
                </Button>
              ) : (
                <IconButton
                  w='80px'
                  variant='primary'
                  aria-label='more options'
                  icon={<MdOutlineExpandMore />}
                />
              )}
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>Asignar responsable del caso</PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                <UsersSelectSearchable
                  filter={(user) =>
                    (hasSudoPermissionOverCase || auth.id !== user.id) &&
                    user.role.permissions.some(
                      (permission) =>
                        permission.action === 'take' &&
                        permission.resource === 'case',
                    )
                  }
                  onSelect={(user) => setCandidateResponsible(user)}
                />
              </PopoverBody>
              <PopoverFooter
                display='flex'
                justifyContent='end'
                experimental_spaceX={2}
              >
                <Button
                  size='sm'
                  variant={'ghost'}
                  isDisabled={isLoading}
                  onClick={onClose}
                >
                  Cancelar
                </Button>
                <Button
                  size='sm'
                  {...buildTestId('assign-case-responsible-btn-submit')}
                  onClick={onAssignCase(onClose)}
                  variant={'primary'}
                  isDisabled={isLoading || !candidateResponsible}
                  isLoading={isLoading}
                >
                  Asignar
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </>
        )
      }}
    </Popover>
  )
}

export const TakeCaseBtn = (props: {
  caseId: number
  status: CaseStatusType
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const auth = useAuth()

  const error = useError()

  const canTakeCase = props.status === 'not_assigned'

  const toast = useToast()

  const onTakeCase = useCallback(() => {
    setIsLoading(true)

    toast.promise(CaseClient.takeCase(props.caseId), {
      success: () => {
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
        queryClient.invalidateQueries([CASES_QUERY_KEY])
        onClose()
        setIsLoading(false)
        return {
          title: 'Caso tomado correctamente',
        }
      },
      error: (err) => {
        setIsLoading(false)
        return { title: error.getErrorMessage(err) }
      },
      loading: { title: 'Tomando el caso...' },
    })
  }, [props.caseId])

  const ModalTake = (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tomar caso</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            ¿Estás seguro que quieres tomar este caso para investigar?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            {...buildTestId('confirm-take-case-btn')}
            disabled={isLoading}
            isDisabled={isLoading}
            variant={'primary'}
            isLoading={isLoading}
            onClick={onTakeCase}
          >
            Aceptar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

  const ButtonTakeModal = (
    <Button
      {...buildTestId('take-case-btn')}
      position='relative'
      _before={{
        content: `""`,
        position: 'absolute',
        height: '20px',
        width: '1px',
        right: 0,
        top: '6px',
        bg: 'brand.600',
      }}
      variant='primary'
      onClick={onOpen}
    >
      Tomar caso
    </Button>
  )

  if (
    hasPermission(auth?.role?.permissions, {
      action: 'assign',
      resource: 'case',
    })
  ) {
    return (
      <>
        <ButtonGroup size='sm' isAttached>
          {canTakeCase && ButtonTakeModal}
          <DefineCaseResponsible
            withLabel={!canTakeCase}
            caseId={props.caseId}
          />
        </ButtonGroup>
        {ModalTake}
      </>
    )
  }

  if (canTakeCase) {
    return (
      <>
        {ButtonTakeModal}
        {ModalTake}
      </>
    )
  }

  return null
}
