import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import {
  CaseClient,
  CaseRequestClient,
  ICaseExtended,
  ICreateNews,
  INewsAndRequest,
  INewsRequest,
  NewsClient,
} from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useToggle } from 'ahooks'
import React, { useCallback, useEffect, useState } from 'react'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { buildCaseNewsQueryKey } from '../utils/build-case-news-query-key'
import { AddCaseResource } from './AddCaseResource'
import { CaseAccordion, EmptyState } from './CaseAccordion'
import CreateNewsForm, { OnSubmitNewsFN } from './CreateNewsForm'
import { FetchErrorMessage } from './FetchErrorMessage'
import { NewsLine } from './NewsLine'
import { NewsRequestLineV2 } from './NewsRequestLineV2'

export const CaseNewsAndRequestsList = ({
  data,
  withoutCreate,
  withReply,
  onReplyNewsRequest,
}: {
  data: {
    news: INewsAndRequest[]
  }
  withoutCreate?: boolean
  withReply?: boolean
  onReplyNewsRequest?: (newsRequest: INewsRequest) => void
}) => {
  if (!data?.news.length) {
    return (
      <EmptyState
        label={{
          singular: 'novedad',
          plural: 'novedades',
        }}
        withoutCreate={withoutCreate}
      />
    )
  }

  return (
    <Box px={4} experimental_spaceY={2}>
      {data.news.map((elem, index) => {
        return (
          <Box key={index}>
            {(() => {
              switch (elem.type) {
                case 'news':
                  return <NewsLine news={elem.news} />

                case 'request':
                  return (
                    <NewsRequestLineV2
                      withReply={withReply}
                      onReply={() => onReplyNewsRequest!(elem.request)}
                      newsRequest={elem.request}
                    />
                  )
              }
            })()}
            {index + 1 !== data.news.length && <Divider my='4' />}
          </Box>
        )
      })}
    </Box>
  )
}

export const CaseNewsAndRequestsListAsync = (props: {
  case: Pick<ICaseExtended, 'id'>
  withoutCreate: boolean
  onReplyNewsRequest: (newsRequest: INewsRequest) => void
  withReply?: boolean
  listNewsAndRequests: () => Promise<{
    news: INewsAndRequest[]
  }>
}) => {
  const { isLoading, data, error } = useQuerySingleton(
    [buildCaseNewsQueryKey(props.case.id)],
    props.listNewsAndRequests,
    {
      retry: 1,
    },
  )

  if (isLoading) {
    return (
      <Box
        h='full'
        display={'flex'}
        alignItems={'center'}
        justifyContent='center'
      >
        <Spinner />
      </Box>
    )
  }

  if (error || !data?.news) {
    return <FetchErrorMessage />
  }

  if (!data?.news.length) {
    return (
      <EmptyState
        label={{
          singular: 'novedad',
          plural: 'novedades',
        }}
        withoutCreate={props.withoutCreate}
      />
    )
  }

  return (
    <CaseNewsAndRequestsList
      data={data}
      onReplyNewsRequest={props.onReplyNewsRequest}
      withReply={props.withReply}
      withoutCreate={props.withoutCreate}
    />
  )
}

export const CaseNews: React.FC<
  React.PropsWithChildren<{
    withReply?: boolean
    caseExtended: ICaseExtended
    withoutCreate?: boolean
    caseId: number
    isExpanded?: boolean
    isCaseRequest?: boolean
  }>
> = ({
  children,
  caseExtended,
  withoutCreate,
  caseId,
  isExpanded,
  isCaseRequest,
  withReply,
}) => {
  const [open, { toggle }] = useToggle()

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const toast = useToast()

  const onCreateNews = useCallback<OnSubmitNewsFN>(
    (data: ICreateNews, onDone) => {
      toast.promise(NewsClient.createCaseNews(caseId, data), {
        loading: {
          title: 'Creando novedad...',
        },
        success: () => {
          queryClient.invalidateQueries([buildCaseNewsQueryKey(caseId)])
          toggle()
          onDone()
          return {
            title: 'Novedad agregada correctamente!',
          }
        },
        error: (err) => {
          onDone()
          return { title: error.getErrorMessage(err) }
        },
      })
    },
    [caseId],
  )

  const [newsRequestToReply, setNewsRequestToReply] =
    useState<Partial<INewsRequest>>()

  useEffect(() => {
    if (newsRequestToReply && !open) {
      setNewsRequestToReply(undefined)
    }
  }, [open])

  return (
    <div {...buildTestId('case-news-container')}>
      <CaseAccordion
        withoutCreate={isCaseRequest}
        count={1}
        SuffixComponent={
          <Box {...buildTestId('add-news-button')}>
            <AddCaseResource label='Agregar novedad' onClick={toggle} />
          </Box>
        }
        label={{
          singular: 'novedad',
          plural: 'novedades',
        }}
        isExpanded={isExpanded}
      >
        {({ isExpanded }) => {
          if (isExpanded) {
            return (
              <CaseNewsAndRequestsListAsync
                withReply={withReply}
                onReplyNewsRequest={(newsRequest) => {
                  const { news, ...rest } = newsRequest

                  setNewsRequestToReply(rest)
                  toggle()
                }}
                case={caseExtended}
                withoutCreate={isCaseRequest}
                listNewsAndRequests={
                  isCaseRequest
                    ? () =>
                        CaseRequestClient.listNewsAndRequests(
                          caseExtended.caseRequest.id,
                        )
                    : () => CaseClient.listNewsAndRequests(caseExtended.id)
                }
              />
            )
          }

          return null
        }}
      </CaseAccordion>
      <Modal isOpen={open} size='xl' onClose={toggle}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={6}>
            {open && (
              <CreateNewsForm
                defaultValues={{
                  relatedNewsRequest: newsRequestToReply,
                }}
                newsRequestsFetcher={() =>
                  CaseClient.fetchNewsRequests(caseId).then(
                    (res) => res.newsRequests,
                  )
                }
                context={`case-${caseId}`}
                withContext
                onSubmit={onCreateNews}
                artifactsTokenizer={(files) =>
                  CaseClient.tokenizeArtifacts(caseId, files)
                }
                onClose={toggle}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}
