import { Button } from '@chakra-ui/react'
import { IJob, JobClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useRouter } from 'next/router'
import React, { useCallback, useState } from 'react'
import { useError } from '../hooks/useError'
import { formatDateToLocaleDays } from '../utils/format-date'
import { Admonition } from './Admonition'
import { format } from 'date-fns'

export const UnavailabilityJobAdmonition = (props: { job: IJob }) => {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const router = useRouter()

  const onCancel = useCallback(() => {
    setIsLoading(true)

    JobClient.delete(props.job.id)
      .then((res) => {
        router.reload()
      })
      .catch((err) => {
        error.handleError(err)
        setIsLoading(false)
      })
  }, [])

  console.log('props.job.executeAt', props.job.executeAt)

  return (
    <Admonition
      action={
        <Button
          {...buildTestId('unavailability-admonition-cancel')}
          color='gray.600'
          onClick={onCancel}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Cancelar
        </Button>
      }
    >
      Dejaras de estar operativo desde el{' '}
      {format(new Date(props.job.executeAt!), 'P')} hasta el{' '}
      {format(new Date((props.job.metadata as { to: string }).to), 'P')}
    </Admonition>
  )
}
