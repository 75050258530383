import { IUserRole } from './company.client';
import httpClient from './http-client';
import { ActionsType, ResourcesType } from 'kach-constants';

export type PermissionActionType =
  | 'create'
  | 'update'
  | 'read'
  | 'delete'
  | 'take'
  | 'define'
  | 'assign'
  | 'create_all'
  | 'create_own'
  | 'update_own'
  | 'update_all'
  | 'remove_own'
  | 'remove_all'
  | 'read_all'
  | 'read_own'
  | 'manage';

export type PermissionResourceType =
  | 'case'
  | 'invitation'
  | 'company'
  | 'case_request'
  | 'case_request_processor'
  | 'investigation'
  | 'target_group'
  | 'case_load_balance_rule'
  | 'contact_price'
  | 'contact'
  | 'role'
  | 'alert'
  | 'stats'
  | 'user'
  | 'virtual_view'
  | 'integration'
  | 'price_group'
  | 'workflow'
  | 'statement'
  | 'trash_transaction'
  | 'company_link'
  | 'investigation_user'
  | 'case_request_news'
  | 'autoinspector-integration'
  | 'stop_case_request'
  | 'comparative_stats'
  | 'case_request_user';

export interface IPermission {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  resource: ResourcesType;
  action: ActionsType;
  scope?: string;
}

export interface IRolMinimized
  extends Pick<IUserRole, 'name' | 'permissions' | 'createdBy' | 'id'> {}

export interface ICreateRole {
  name: string;
  permissions: {
    id: number;
  }[];
}

export class AuthorizationClient {
  static async listRoles(): Promise<IRolMinimized[]> {
    const response = await httpClient.get(`/v1/authorization/role`);

    return response.data;
  }

  static async createRole(role: ICreateRole): Promise<any> {
    const response = await httpClient.post('/v1/authorization/role', role);

    return response.data;
  }

  static async updateRole(role: ICreateRole, roleId: number): Promise<any> {
    const response = await httpClient.put(
      `/v1/authorization/role/${roleId}`,
      role
    );

    return response.data;
  }

  static async deleteRole(roleId: number): Promise<any> {
    const response = await httpClient.delete(
      `/v1/authorization/role/${roleId}`
    );

    return response.data;
  }

  static async updateUserRole(userId: number, roleId: number): Promise<any> {
    const response = await httpClient.put(`/v1/authorization/user/${userId}`, {
      roleId,
    });

    return response.data;
  }

  static async listPermissions(): Promise<IPermission[]> {
    const response = await httpClient.get('/v1/authorization/permission');

    return response.data;
  }

  static async removeUser(userId: number): Promise<{ success: boolean }> {
    const response = await httpClient.delete(
      `/v1/authorization/user/${userId}`
    );

    return response.data;
  }
}
