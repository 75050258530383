import React, { useMemo } from 'react'
import { EnumOption, TableAbstract } from './TableAbstract'
import { LIMITS_QUERY_KEY } from '../constants/query-keys'
import {
  AuthenticationClient,
  CompanyClient,
  ILimit,
  IPagination,
  LimitClient,
} from 'kach-clients'
import { CustomTable } from './CustomTable'
import { Cell, Column } from 'react-table'
import { Badge, Box, IconButton, chakra, useDisclosure } from '@chakra-ui/react'
import { TableContextProvider } from '../providers/TableFiltersProvider'
import { UserDisplayer } from './UserDisplayer'
import { translator } from 'kach-commons'
import { format } from 'date-fns'
import { MdEdit, MdOutlineArrowDropDownCircle } from 'react-icons/md'
import { DeleteLimitForm } from './LimitForm'
import { useAuth } from '../hooks/useAuth'
import { buildUserName } from '../utils/build-user-name'

export const ModalUpdate = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <IconButton
        onClick={onClick}
        aria-label='Modificar variables'
        variant={'ghost'}
        icon={<MdEdit fontSize={'1rem'} />}
      />
    </>
  )
}

export const ModalDelete = ({
  companyId,
  limit,
}: {
  companyId: number
  limit: ILimit
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <DeleteLimitForm
        companyId={companyId}
        isOpen={isOpen}
        limit={limit}
        onClose={onClose}
        onOpen={onOpen}
      />
    </>
  )
}

export const LimitsDataTable = ({
  initialData,
  onClickRow,
}: {
  initialData: IPagination<ILimit>
  onClickRow: (row: ILimit) => void
}) => {
  const auth = useAuth()

  const columns = useMemo<Column<ILimit>[]>(
    () => [
      {
        id: 'name',
        Header: () => <chakra.span>Nombre</chakra.span>,
        accessor: (limit: ILimit) => {
          return <chakra.span>{limit.name}</chakra.span>
        },
      },
      {
        id: 'createdAt',
        Header: () => <chakra.span>Creado el</chakra.span>,
        accessor: (limit: ILimit) =>
          `${format(new Date(limit.createdAt), 'Pp')}`,
      },
      {
        id: 'type',
        Header: () => <chakra.span>Tipo</chakra.span>,
        accessor: (limit: ILimit) => {
          return <Badge>{translator(`limit_type_${limit.type}`)}</Badge>
        },
      },
      {
        Header: 'Afectados',
        id: 'affected',
        accessor: (row) =>
          row.contacts?.map((contact) => contact.name).join(', '),
      },
      {
        id: 'interval',
        Header: 'Intervalo',
        accessor: (limit) =>
          `${limit.intervalValue} ${translator(limit.interval)}`,
      },
      {
        id: 'limit',
        Header: () => <chakra.span>Cantidad maxima</chakra.span>,
        accessor: (limit: ILimit) => <Badge>{limit.limit}</Badge>,
      },
      {
        id: 'actions',
        Cell: (cell: Cell<ILimit>) => {
          return (
            <Box>
              <ModalUpdate onClick={() => onClickRow(cell.row.original)} />
              <ModalDelete
                companyId={auth.company.id}
                limit={cell.row.original}
              />
            </Box>
          )
        },
      },
    ],
    [],
  )

  return (
    <TableContextProvider>
      <TableAbstract
        withSearch={false}
        withCaption={false}
        candidateFilters={[
          {
            column: 'contactId',
            Icon: MdOutlineArrowDropDownCircle,
            label: 'Estudio',
            type: 'async-enum',
            search: (query) =>
              CompanyClient.searchContactsForCaseDerivation(query).then((res) =>
                res.results.map((r) => ({
                  ...r,
                  label: r.name || r.email,
                  value: r.id,
                })),
              ),
          },
        ]}
        labels={{ singular: 'Límite', plural: 'Límites' }}
        queryKey={LIMITS_QUERY_KEY}
        initPagination={initialData}
        fetcher={(params) => {
          return LimitClient.paginate({
            query: {
              ...params,
              limit: 10,
            },
          })
        }}
      >
        {({ pages }) => {
          return (
            <>
              <CustomTable columns={columns} data={pages.results || []} />
            </>
          )
        }}
      </TableAbstract>
    </TableContextProvider>
  )
}
