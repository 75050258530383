import { z } from 'zod';
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema';

export const ReportBugSchema = z.object({
  description: z.string().min(1, REQUIRED_FIELD_MESSAGE),
  reproductionSteps: z.string().optional().nullable().or(z.literal('')),
  files: z.array(
    z.object({
      storedName: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    })
  ),
});

export interface IReportBugSchema extends z.infer<typeof ReportBugSchema> {}
