import { Text } from '@chakra-ui/react'
import {
  AsyncSelect,
  chakraComponents,
  ChakraStylesConfig,
} from 'chakra-react-select'
import { CompanyClient, IContact } from 'kach-clients'
import debounce from 'lodash.debounce'
import React, { useCallback } from 'react'

export const LabContactsMultiselect = ({
  chakraStyles,
  ...parentProps
}: {
  onSelect: (contacts: IContact[]) => void
  chakraStyles?: ChakraStylesConfig
}) => {
  const loadSuggestions = useCallback(
    (
      value: string,
      callback: (opts: { label: string; id: number | string }[]) => void,
    ) => {
      CompanyClient.searchContactsForCaseDerivation(value).then((res) => {
        callback(
          res.results.map((r) => ({
            ...r,
            label: r.name || r.email,
            value: r.id,
          })),
        )
      })
    },
    [],
  )

  const loadSuggestionsBounced = useCallback(debounce(loadSuggestions, 500), [])

  return (
    <AsyncSelect
      chakraStyles={chakraStyles}
      isMulti
      useBasicStyles
      inputId='contact-select-search'
      onChange={parentProps.onSelect}
      noOptionsMessage={() => 'No se encontraron opciones'}
      components={{
        Placeholder: (props, data) => {
          return (
            <chakraComponents.Placeholder {...props}>
              <Text>Busca un estudio</Text>
            </chakraComponents.Placeholder>
          )
        },
      }}
      loadingMessage={() => 'Buscando estudios...'}
      name='Estudio de investigación'
      placeholder='Busca un estudio...'
      loadOptions={loadSuggestionsBounced}
    />
  )
}
