import { z } from 'zod';
import { companyTypes } from 'kach-constants';
import { conditionsSchema } from './condition-line.schema';

export const REQUIRED_FIELD_MESSAGE = 'Campo requerido!';

export const createLimitSchema = conditionsSchema.extend({
  name: z.string().min(1, REQUIRED_FIELD_MESSAGE),
  limit: z.number().min(1, REQUIRED_FIELD_MESSAGE),
  type: z.enum(companyTypes),
  contacts: z
    .array(
      z.object({
        id: z.number().min(1, REQUIRED_FIELD_MESSAGE),
      })
    )
    .optional(),
  users: z
    .array(
      z.object({
        id: z.number().min(1, REQUIRED_FIELD_MESSAGE),
      })
    )
    .optional(),
});

export type CreateLimitSchema = z.infer<typeof createLimitSchema>;
