import React, { useMemo } from 'react'
import {
  Box,
  Button,
  chakra,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  ModalOverlay,
  Spinner,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { IExpertExamination, ICaseExtended, CaseClient } from 'kach-clients'
import { BsImages } from 'react-icons/bs'
import { useQuery } from '@tanstack/react-query'
import { Cell, Column } from 'react-table'
import { THREE_MINUTES_MS } from './CaseClaims'
import { ChakraCarousel } from './ChakraCarousel'
import { FetchErrorMessage } from './FetchErrorMessage'
import { CaseAccordion } from './CaseAccordion'
import { CustomTable } from './CustomTable'
import { useQuerySingleton } from '../hooks/useQuerySingleton'

const PhotosBody = (props: { expertExamination: IExpertExamination }) => {
  const { isLoading, error, data } = useQuerySingleton(
    [`case-expert-examination-${props.expertExamination.id}-photos`],
    () =>
      CaseClient.listExpertExaminationPhotos(
        props.expertExamination.case_id,
        props.expertExamination.id,
      ),
    {
      refetchInterval: THREE_MINUTES_MS,
      refetchOnMount: true,
      retry: 1,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return <FetchErrorMessage />
  }

  return (
    <Box>
      {data?.photos.length ? (
        <ChakraCarousel gap={5}>
          {data?.photos.map((p, index) => {
            return <chakra.img key={index} src={p.url} />
          })}
        </ChakraCarousel>
      ) : (
        <Text>No hay fotos para esta peritación</Text>
      )}
    </Box>
  )
}

const PhotosModal = (props: { expertExamination: IExpertExamination }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Tooltip label='Ver fotos'>
        <IconButton
          onClick={onOpen}
          aria-label='Ver fotos'
          variant={'ghost'}
          icon={<BsImages />}
        />
      </Tooltip>
      <Modal size='2xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fotos de la peritación</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isOpen && (
              <PhotosBody expertExamination={props.expertExamination} />
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant='ghost'>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const Table = (props: { data: IExpertExamination[] }) => {
  const columns = useMemo<Column<IExpertExamination>[]>(
    () => [
      {
        id: 'actions',
        //@ts-ignore
        Cell: (cell: Cell<IExpertExamination>) => (
          <PhotosModal expertExamination={cell.row.original} />
        ),
      },
      {
        Header: 'Peritación',
        accessor: 'gatewayId',
      },
      {
        Header: 'Patente',
        accessor: 'licensePlate',
      },
      {
        Header: 'Nombre',
        accessor: 'customerFirstname',
      },
      {
        Header: 'Apellido',
        accessor: 'customerLastname',
      },
    ],
    [],
  )

  return (
    <CustomTable
      orientation='left'
      noHover
      columns={columns}
      data={props.data}
    />
  )
}

const AccordionBody = (props: { case: ICaseExtended }) => {
  const { isLoading, data, error } = useQuerySingleton(
    [`case-${props.case.id}-expert-examinations`],
    () => CaseClient.listExpertExaminations(props.case.id),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 180000,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 1,
    },
  )

  if (isLoading) {
    return (
      <Box
        h='full'
        display={'flex'}
        alignItems={'center'}
        justifyContent='center'
      >
        <Spinner />
      </Box>
    )
  }

  if (error || !data?.expertExaminations) {
    return <FetchErrorMessage />
  }

  return (
    <Box>
      <Table data={data?.expertExaminations} />
    </Box>
  )
}

export const CaseExpertExaminations = (props: { case: ICaseExtended }) => {
  return (
    <Box>
      <CaseAccordion
        withoutEmptyState
        label={{
          plural: 'Peritaciones',
          singular: 'Peritaciones',
        }}
      >
        {({ isExpanded }) => {
          if (isExpanded) {
            return <AccordionBody case={props.case} />
          }

          return null
        }}
      </CaseAccordion>
    </Box>
  )
}
