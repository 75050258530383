import {
  Box,
  Button,
  chakra,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Select,
  Stack,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useToggle } from 'ahooks'
import { CaseClient, ICaseExtended, ICompanyEnums } from 'kach-clients'
import { REQUIRED_FIELD_MESSAGE } from 'kach-clients/src/schemas/limit.schema'
import { translator } from 'kach-commons'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoMdInformationCircle } from 'react-icons/io'
import { z } from 'zod'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { CloseCaseSsnSchema } from '../schemas/ssn.schema'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { AddSsnPerson } from './AddSsnPerson'
import { CaseAccordion } from './CaseAccordion'
import { ISsnPersons } from './CaseDetail'
import { IDynamicObject } from './InvestigationLine'
import { SsnPersonsTable } from './SsnPersonsTable'

const ssnSchemaObj = {
  investigationObservations: z
    .string({ required_error: REQUIRED_FIELD_MESSAGE })
    .min(1, 'Campo requerido'),
  investigationLabManagementTasksDescription: z
    .string({ required_error: REQUIRED_FIELD_MESSAGE })
    .min(1, 'Campo requerido'),
  investigationLabManagementTasksResolution: z
    .string({ required_error: REQUIRED_FIELD_MESSAGE })
    .min(1, 'Campo requerido'),
  resultDescription: z.string().nullish(),
}

let ssnSchema = CloseCaseSsnSchema.extend(ssnSchemaObj)

type SsnFormData = z.infer<typeof ssnSchema>

export const UpdateCaseSsn = (props: {
  companyEnums: ICompanyEnums
  withoutSubmit?: boolean
  onClose: () => void
  case: ICaseExtended
  persons: ISsnPersons
}) => {
  const getResultDescription = useCallback(
    (result: number | undefined) => {
      if (!result) {
        return false
      }

      const others = props.companyEnums.ssnResults.filter((e) => e.isOther)

      if (!others.length) {
        return false
      }

      if (others.find((e) => e.id.toString() === result.toString())) {
        return true
      } else {
        return false
      }
    },
    [props.companyEnums.ssnResults],
  )

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    control,
  } = useForm<SsnFormData>({
    reValidateMode: 'onChange',
    resolver: async (data, context, options) => {
      const isResultDescription = getResultDescription(data.result)

      if (isResultDescription) {
        ssnSchema = CloseCaseSsnSchema.extend({
          ...ssnSchemaObj,
          resultDescription: z
            .string({ required_error: REQUIRED_FIELD_MESSAGE })
            .min(1, 'Campo requerido'),
        })
      } else {
        ssnSchema = CloseCaseSsnSchema.extend({
          ...ssnSchemaObj,
          resultDescription: z.string().nullish(),
        })
      }

      return await zodResolver(ssnSchema)(data, context, options)
    },
    defaultValues: {
      investigationSummary: props.case?.ssn?.investigationSummary,
      result: props.case?.ssn?.result?.id?.toString(),
      closeMotive: props.case?.ssn?.closeMotive?.id?.toString(),
      resultDescription: props.case?.ssn?.resultDescription,
      investigationLabManagementTasksDescription:
        props.case?.ssn?.investigationLabManagementTasksDescription,
      investigationLabManagementTasksResolution:
        props.case?.ssn?.investigationLabManagementTasksResolution,
      investigationObservations: props.case?.ssn?.investigationObservations,
    },
  })

  const [open, setOpen] = useState(false)

  const [groupName, setGroupName] = useState<string>()

  const [createSsnPerson, setCreateSsnPerson] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const fields = watch()

  const toast = useToast()

  const onSubmit = useCallback(
    (data: SsnFormData) => {
      setIsLoading(true)

      toast.promise(
        CaseClient.updateCase(props.case.id, {
          ssn: {
            ...data,
            closeMotive: {
              id: data.closeMotive,
            },
            result: {
              id: data.result,
            },
            resultDescription: data.resultDescription,
          },
        }),
        {
          loading: {
            title: 'Actualizando información para la SSN...',
          },
          success: () => {
            queryClient.invalidateQueries([buildCaseQueryKey(props.case.id)])
            props.onClose()
            setIsLoading(false)
            return { title: 'Información para la SSN actualizada' }
          },
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
        },
      )
    },
    [props.case.id],
  )

  const isResultDescription = useMemo(() => {
    return getResultDescription(fields.result)
  }, [fields.result])

  const formId = 'ssn-form'

  return (
    <Box>
      <chakra.form
        onSubmit={handleSubmit(onSubmit)}
        id={formId}
        experimental_spaceY={5}
        noValidate
      >
        <Heading color='gray.500' fontSize={'lg'} as={'h2'}>
          Resumen de investigación
        </Heading>

        {props.case?.ssn?.investigationSummary && (
          <Controller
            control={control}
            disabled
            name='investigationSummary'
            render={({ field }) => {
              return (
                <FormControl>
                  <Stack mb={2} direction={'row'} alignItems='center'>
                    <FormLabel m={0} fontSize={'sm'}>
                      Resumen de investigación
                    </FormLabel>
                    <Tooltip label='Campo deprecado en favor de los nuevos campos granulares'>
                      <chakra.span>
                        <IoMdInformationCircle />
                      </chakra.span>
                    </Tooltip>
                  </Stack>

                  <Textarea {...field} size={'sm'} />
                </FormControl>
              )
            }}
          />
        )}

        <Controller
          control={control}
          name='investigationObservations'
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isRequired
                isInvalid={!!errors.investigationObservations}
              >
                <FormLabel fontSize={'sm'}>
                  Indicadores u Observaciones
                </FormLabel>
                <Textarea
                  {...field}
                  size={'sm'}
                  isDisabled={props.withoutSubmit}
                />
                {errors.investigationObservations && (
                  <FormErrorMessage>
                    {errors.investigationObservations?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            )
          }}
        />

        <Controller
          control={control}
          name='investigationLabManagementTasksDescription'
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isRequired
                isInvalid={!!errors.investigationLabManagementTasksDescription}
              >
                <FormLabel fontSize={'sm'}>Gestiones del estudio</FormLabel>
                <Textarea
                  {...field}
                  size={'sm'}
                  isDisabled={props.withoutSubmit}
                />
                {errors.investigationLabManagementTasksDescription && (
                  <FormErrorMessage>
                    {errors.investigationLabManagementTasksDescription?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            )
          }}
        />
        <Controller
          control={control}
          name='investigationLabManagementTasksResolution'
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isRequired
                isInvalid={!!errors.investigationLabManagementTasksResolution}
              >
                <FormLabel fontSize={'sm'}>Resolución de gestiones</FormLabel>
                <Textarea
                  {...field}
                  size={'sm'}
                  isDisabled={props.withoutSubmit}
                />
                {errors.investigationLabManagementTasksResolution && (
                  <FormErrorMessage>
                    {errors.investigationLabManagementTasksResolution?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            )
          }}
        />

        <Divider my={2} />
        <FormControl isRequired isInvalid={!!errors.closeMotive}>
          <FormLabel fontSize={'sm'}>{translator('ssnCloseMotive')}</FormLabel>
          <Select
            {...register('closeMotive')}
            isDisabled={props.withoutSubmit}
            placeholder='Seleccionar Hecho descubierto'
            size='md'
          >
            {props.companyEnums.caseCloseMotives.map((e) => {
              return (
                <option key={e.id} value={e.id}>
                  {e.name.toUpperCase()}
                </option>
              )
            })}
            {errors?.closeMotive && (
              <FormErrorMessage>{errors.closeMotive.message}</FormErrorMessage>
            )}
          </Select>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.result}>
          <FormLabel fontSize={'sm'}>{translator('ssnResult')}</FormLabel>
          <Select
            isDisabled={props.withoutSubmit}
            {...register('result')}
            size='md'
            placeholder='Seleccionar Elemento de prueba recabado'
          >
            {props.companyEnums.ssnResults.map((e) => {
              return (
                <option key={e.id} value={e.id}>
                  {e.name.toUpperCase()}
                </option>
              )
            })}
            {errors?.result && (
              <FormErrorMessage>{errors.result.message}</FormErrorMessage>
            )}
          </Select>
        </FormControl>
        {isResultDescription && (
          <FormControl isRequired isInvalid={!!errors.resultDescription}>
            <FormLabel fontSize={'sm'}>
              {translator('ssnResultDescription')}
            </FormLabel>
            <Textarea
              {...register('resultDescription')}
              size={'sm'}
              isDisabled={props.withoutSubmit}
            />
            {errors.resultDescription && (
              <FormErrorMessage>
                {errors.resultDescription?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        )}
        <Box>
          {!props.withoutSubmit && (
            <Button
              form={formId}
              type='submit'
              size='sm'
              isDisabled={isLoading}
              isLoading={isLoading}
              variant={'primary'}
            >
              Guardar cambios
            </Button>
          )}
        </Box>
      </chakra.form>
      <Divider m={2} />
      <Box display={'flex'} flexDir='row' justifyContent={'space-between'}>
        <Heading size={'md'} mb={2}>
          Participantes
        </Heading>
        <AddSsnPerson case={props.case} />
      </Box>
      <SsnPersonsTable
        isOpen={open}
        onClose={() => setOpen(false)}
        persons={props.persons}
        ssnId={props.case?.ssn?.id}
        caseId={props.case.id}
      />
    </Box>
  )
}

export const CaseDetailsSsn = (props: {
  companyEnums: ICompanyEnums
  case: ICaseExtended
  withoutCreate?: boolean
  query: IDynamicObject
}) => {
  const [open, { toggle }] = useToggle()

  return (
    <>
      <CaseAccordion
        withoutCreate
        withoutEmptyState
        label={{
          singular: 'Superintendencia',
          plural: 'Superintendencia',
        }}
      >
        <Box p={5}>
          <UpdateCaseSsn
            companyEnums={props.companyEnums}
            withoutSubmit={props.withoutCreate}
            case={props.case}
            onClose={toggle}
            persons={props.case.ssn?.persons || []}
          />
        </Box>
      </CaseAccordion>
    </>
  )
}
