import { IVirtualView, VirtualViewClient } from 'kach-clients'
import { buildVirtualViewQueryKey } from '../utils/build-virtual-view-query-key'
import { useAuth } from './useAuth'
import { useQuerySingleton } from './useQuerySingleton'

export const useVirtualViews = (
  initialData: IVirtualView[],
  resource: IVirtualView['resource'],
) => {
  const auth = useAuth()

  return useQuerySingleton(
    [buildVirtualViewQueryKey(resource, auth)],
    () =>
      VirtualViewClient.fetch({
        resource,
      }).then((res) => res.virtualViews),
    {
      initialData,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  )
}
