import { PlusIcon } from '@heroicons/react/24/solid'
import {
  Box,
  Heading,
  Button,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react'
import { LimitsDataTable } from './LimitsDataTable'
import { ILimit, IPagination } from 'kach-clients'
import React, { useCallback } from 'react'
import { IoMdAdd } from 'react-icons/io'
import { CreateLimitForm, UpdateLimitForm } from './LimitForm'
import { useAuth } from '../hooks/useAuth'

export const Limits = ({
  initialData,
}: {
  initialData: IPagination<ILimit>
}) => {
  const auth = useAuth()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const disclosureUpdate = useDisclosure()

  const [targetLimit, setTargetLimit] = React.useState<ILimit | null>(null)

  const onClickRow = useCallback((row: ILimit) => {
    setTargetLimit(row)
    disclosureUpdate.onOpen()
  }, [])

  return (
    <Box experimental_spaceY={4}>
      <Box
        justifyContent={'space-between'}
        display={'flex'}
        flexDir='row'
        alignItems={'center'}
      >
        <Heading fontSize='xl'>Limites de derivación</Heading>
        <Box display={'flex'} flexDir='row' alignItems={'center'}>
          <Button
            size='md'
            onClick={onOpen}
            variant={'primary'}
            leftIcon={<IoMdAdd fontSize={'1.4rem'} />}
          >
            Crear
          </Button>
        </Box>
      </Box>
      <LimitsDataTable initialData={initialData} onClickRow={onClickRow} />
      <CreateLimitForm
        companyId={auth.company.id}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Box>
        {targetLimit && (
          <UpdateLimitForm
            companyId={auth.company.id}
            isOpen={disclosureUpdate.isOpen}
            onClose={() => {
              setTargetLimit(null)
              disclosureUpdate.onClose()
            }}
            limit={targetLimit}
          />
        )}
      </Box>
    </Box>
  )
}
