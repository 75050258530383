import {
  Badge,
  Box,
  chakra,
  Icon,
  Stack,
  Tag,
  Text,
  Tooltip,
  useFormControlStyles,
} from '@chakra-ui/react'
import { PhotoIcon } from '@heroicons/react/20/solid'
import {
  FilmIcon,
  InformationCircleIcon,
  PaperClipIcon,
} from '@heroicons/react/24/outline'
import { IArtifact } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useMemo } from 'react'
import { BiPlay } from 'react-icons/bi'
import { bytesToKB } from '../utils/bytes-to-kb'
import { useArtifactsSelect } from './CaseArtifacts'

const ArtifactPreview = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      height={40}
      width={'full'}
      position={'relative'}
      overflow='hidden'
      borderRadius='md'
    >
      {children}
    </Box>
  )
}

export const ArtifactIcon = (props: { mimeType: string }) => {
  let icon

  switch (props.mimeType) {
    case 'image/gif':
      icon = PhotoIcon
      break

    case 'application/pdf':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'text/csv':
      icon = PaperClipIcon
      break
    case 'image/webp':
    case 'image/jpeg':
    case 'image/png':
      icon = PhotoIcon
      break
    case 'video/x-msvideo':
    case 'video/mp4':
    case 'video/mpeg':
      icon = FilmIcon
      break
    default:
      InformationCircleIcon
      break
  }

  return <Icon as={icon} />
}

const ArtifactPreviewThumbnail = ({ artifact }: { artifact: Artifact }) => {
  const openInNewTab = useCallback((url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }, [])

  const artifactsSelect = useArtifactsSelect()

  const isSelected = useMemo(() => {
    if (artifactsSelect?.selected?.length)
      return artifactsSelect.selected.some(
        (artifactId) => artifactId === artifact.id,
      )

    return false
  }, [artifactsSelect?.selected, artifact.id])

  return (
    <Stack
      onClick={() => {
        if (artifactsSelect && artifactsSelect.setSelected) {
          if (isSelected) {
            artifactsSelect.setSelected((prev) =>
              prev.filter((id) => id !== artifact.id),
            )
          } else {
            artifactsSelect.setSelected((prev) => [...prev, artifact.id])
          }
        }
      }}
      onDoubleClick={() => openInNewTab(artifact.url!)}
      direction={'column'}
      spacing={2}
      h='40'
      p='2'
      bg={isSelected ? 'blue.100' : 'gray.100'}
    >
      <Stack alignItems={'center'} direction={'row'} spacing={1}>
        <ArtifactIcon mimeType={artifact.mimetype} />
        <Tooltip label={artifact.originalName}>
          <Text noOfLines={1} fontWeight={'semibold'} fontSize={'sm'}>
            {artifact.originalName}
          </Text>
        </Tooltip>
      </Stack>
      <chakra.img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null

          currentTarget.src = new URL(
            'kach-components/src/static/preview-placeholder.jpg',
            import.meta.url,
          ).toString()
        }}
        width={'full'}
        height='full'
        overflow='hidden'
        borderRadius='md'
        border='1px gray.200'
        objectFit='cover'
        objectPosition='center'
        src={artifact.previewURL}
      />
    </Stack>
  )
}

const LinkWrapper: React.FC<React.PropsWithChildren<{ url: string }>> = ({
  children,
  url,
}) => {
  return (
    <chakra.a cursor='pointer' target={'_blank'} href={url}>
      {children}
    </chakra.a>
  )
}

type Artifact = Omit<IArtifact, 'url' | 'size'> &
  Partial<Pick<IArtifact, 'url' | 'size'>>

export const ArtifactDisplayer = ({
  artifact,
  mode,
  view = 'list',
}: {
  artifact: Artifact
  mode?: 'dark' | 'light'
  view?: 'grid' | 'list'
}) => {
  const Body =
    view === 'grid' ? (
      <ArtifactPreview>
        <ArtifactPreviewThumbnail artifact={artifact} />
      </ArtifactPreview>
    ) : (
      <Box
        {...buildTestId(`artifact-${artifact.originalName}`)}
        display='flex'
        alignItems='center'
        experimental_spaceX={1}
        p={1}
        _hover={{ bg: 'brand.300', color: 'white' }}
        borderRadius='md'
      >
        <ArtifactIcon mimeType={artifact.mimetype} />

        <Box display='flex' alignItems='center' experimental_spaceX={2}>
          <Text
            {...buildTestId(`artifact-originalFilename`)}
            wordBreak={'break-all'}
            noOfLines={1}
            fontSize='xs'
          >
            {artifact.originalName}
          </Text>
          {artifact.size && (
            <Text fontSize='xs' fontWeight='light'>
              {bytesToKB(artifact.size)}kb
            </Text>
          )}
        </Box>
        {(artifact.tags || [])?.map((t, index) => {
          return (
            <Tag size='sm' key={index}>
              {t.name}
            </Tag>
          )
        })}
      </Box>
    )

  if (artifact.url)
    return (
      <Box {...buildTestId(`artifact-${artifact.originalName}`)}>
        {view !== 'grid' ? (
          <LinkWrapper url={artifact.url}>{Body}</LinkWrapper>
        ) : (
          Body
        )}
      </Box>
    )

  return Body
}
