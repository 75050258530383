import { z } from 'zod';

export const REQUIRED_FIELD_MESSAGE = 'Campo requerido!';

export const ConnectToIntegrationSchema = (additionalCredential?: string) => {
  let additionalCredentialSchema = z.string();

  if (additionalCredential) {
    additionalCredentialSchema = additionalCredentialSchema.min(
      1,
      REQUIRED_FIELD_MESSAGE
    );
  }

  if (!additionalCredential) {
    // @ts-ignore
    additionalCredentialSchema = additionalCredentialSchema
      .nullable()
      .optional();
  }

  // @ts-ignore
  return z.object({
    user: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    password: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    extraCredentialValue: additionalCredentialSchema,
  });
};

export interface IConnectToIntegrationSchema {
  user: string;
  password: string;
  extraCredentialValue?: string;
}
