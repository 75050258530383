import React from 'react'
import { format } from 'date-fns'
import { ICaseCustomerSinister } from 'kach-clients'
import { useMemo } from 'react'
import { Column } from 'react-table'
import { CustomTable } from './CustomTable'
import { Box } from '@chakra-ui/react'
import { buildTestId } from 'kach-commons'

export const CaseCustomerSinisterReportDetail = (props: {
  sinisters: ICaseCustomerSinister[]
}) => {
  const columns = useMemo<Column<ICaseCustomerSinister>[]>(
    () => [
      {
        Header: 'Aseguradora',
        accessor: 'insurer',
      },
      {
        Header: 'Aconteció el',
        accessor: (row: ICaseCustomerSinister) =>
          format(new Date(row.occurredAt), 'P'),
      },
      {
        Header: 'Dominio',
        accessor: 'domain',
      },
      {
        Header: 'Chasis',
        accessor: 'chasis',
      },
      {
        Header: 'Motor',
        accessor: 'motor',
      },
      {
        Header: 'Cobertura afectada',
        accessor: 'coverageAffected',
      },
    ],
    [],
  )

  return (
    <Box
      {...buildTestId('case-customer-sinister-report-detail-table-container')}
    >
      <CustomTable
        orientation='center'
        columns={columns}
        data={props.sinisters}
      />
    </Box>
  )
}
