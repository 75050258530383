import { Badge, Box, Button, Link, Text } from '@chakra-ui/react'
import {
  AsyncCreatableSelect,
  chakraComponents,
  ChakraStylesConfig,
} from 'chakra-react-select'
import { CompanyClient, IContact } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import debounce from 'lodash.debounce'
import React, { useCallback, useState } from 'react'
import { ProgressPlugin } from 'webpack'
import { isEmail } from '../utils/is-email'

export const ContactSelectSearch = <T extends boolean>({
  withCreate = true,
  chakraStyles,
  ...parentProps
}: {
  onSelect: (contact: T extends true ? IContact[] : IContact) => void
  isMulti?: T
  withCreate?: boolean
  chakraStyles?: ChakraStylesConfig
}) => {
  const [optionsCount, setOptionsCount] = useState(0)

  const loadSuggestions = useCallback(
    (
      value: string,
      callback: (opts: { label: string; id: number | string }[]) => void,
    ) => {
      CompanyClient.searchContactsForCaseDerivation(value).then((res) => {
        const contacts = res.results.map((r) => ({
          ...r,
          label: r.name || r.email,
          value: r.id,
        }))

        setOptionsCount(contacts.length)
        callback(contacts)
      })
    },
    [],
  )

  const loadSuggestionsBounced = useCallback(debounce(loadSuggestions, 500), [])

  return (
    <div>
      <AsyncCreatableSelect
        chakraStyles={chakraStyles}
        isMulti={parentProps.isMulti}
        inputId='contact-select-search'
        useBasicStyles
        onChange={parentProps.onSelect}
        formatCreateLabel={(v) => v}
        noOptionsMessage={() => 'No se encontraron opciones'}
        loadOptions={loadSuggestionsBounced}
        components={{
          Placeholder: (props, data) => {
            return (
              <chakraComponents.Placeholder {...props}>
                <Text>Busca un estudio</Text>
              </chakraComponents.Placeholder>
            )
          },
          Option: (props, data) => {
            //@ts-ignore
            if (!props.data.__isNew__)
              return (
                <chakraComponents.Option {...props}>
                  <Box {...buildTestId('contact-select-search-option')}>
                    <strong
                      {...buildTestId('contact-select-search-option-name')}
                    >
                      {
                        //@ts-ignore
                        props.data.name
                      }
                    </strong>
                    <Text
                      {...buildTestId('contact-select-search-option-email')}
                      fontSize={'xs'}
                    >
                      {
                        //@ts-ignore
                        props.data.email
                      }
                    </Text>
                    <Badge colorScheme={'green'}>
                      {props.data.active_cases} caso(s) activos
                    </Badge>
                  </Box>
                </chakraComponents.Option>
              )

            if (!optionsCount) {
              //@ts-ignore
              if (isEmail(props.data.label) && withCreate) {
                return (
                  <Box rounded='lg' p={3} bg='white'>
                    <Box
                      display={'flex'}
                      flexDir='column'
                      experimental_spaceY={'2'}
                    >
                      <Text mr='1' fontWeight={'light'}>
                        No se encontró el estudio que buscas
                      </Text>{' '}
                      <Link
                        target={'_blank'}
                        //@ts-ignore
                        href={`/settings/labs?focus=create&email=${props.data.label}`}
                      >
                        <Button size='sm' variant='primary'>
                          Agregar estudio
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                )
              }

              return (
                <chakraComponents.NoOptionsMessage {...props}>
                  No se encontraron resultados con tu búsqueda
                </chakraComponents.NoOptionsMessage>
              )
            }

            return null
          },
        }}
        loadingMessage={() => 'Buscando estudios...'}
        name='Estudio de investigación'
        placeholder='Busca un estudio...'
      />
    </div>
  )
}
