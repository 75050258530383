import { ICaseCustomerReport } from './case.client';
import httpClient from './http-client';

export interface IRiesgoOnlineReport {
  informe: {
    id: number;
    cliente: number;
    fecha_hora: number;
    usuario: string;
  };
  persona: {
    id: number;
    nombre: string;
    sexo: string;
    fecha_nacimiento: string;
    clase: number;
    edad: number;
    tipo: string;
    fallecido: boolean;
    jubilado: boolean;
    jubilado_beneficio: boolean;
    pep: boolean;
    estado_cuit: string;
    personas_relacionadas: {
      nombre: string;
      relacion: string;
    }[];
    homonimos: {
      id: number;
      nombre: string;
    }[];
    ids_relacionadas: [];
    domicilios: {
      domicilio: string;
      tipo: string;
    }[];
    personas_igual_domicilio: {
      nombre: string;
      sexo: string;
      domicilio: string;
      id: number;
    }[];
    vecinos: {
      guia: number;
      numero: number;
      titular: string;
      domicilio: string;
    }[];
    emails: [];
    telefonos: {
      anio_guia: number;
      numero: number;
      titular: string;
      domicilio: string;
    }[];
    actividad: {
      empleado_publico: boolean;
      empleador: {
        empleados: number;
        periodo: boolean;
      };
      autonomo: {
        categoria: boolean;
        desde: boolean;
        hasta: boolean;
      };
      anses: {
        trabajador_casa_particular: boolean;
        prestacion_desempleo: boolean;
        plan_social: boolean;
        prestacion_provincial: boolean;
        prestacion_nacional: boolean;
        asignacion_universal: boolean;
        progresar: boolean;
      };
      relacion_dependencia: {
        empleadores: {
          id: number;
          desde: string;
          hasta: string;
        }[];
      };
      afip_en_linea: {
        mes_cierre_ejercicio: number;
        fecha_contrato_social: boolean;
        forma_juridica: boolean;
        fecha_actualizacion: string;
      };
      afip_aportes: [
        {
          id_empleador: number;
          aportes: {
            periodo: string;
            incluido_declaracion_jurada: string;
            aportes_seguridad_social: string;
            aportes_obra_social: string;
            contribucion_patronal_obra_social: string;
          }[];
        }
      ];
      actividades_afip: {
        codigo: number;
        descripcion: string;
        formulario: number;
        principal: boolean;
      }[];
      condicion_tributaria: {
        monotributo: string;
        actividad: string;
        ganancias: string;
        iva: string;
        empleador: string;
        desde: string;
        hasta: string;
      }[];
      impuestos_afip: {
        activos: [];
        inactivos: [];
      };
    };
    bancarizacion: {
      entidades_ultima_informacion: [];
      sin_mora_desde: '';
      sin_mora_meses: '';
      entidades_historico: [];
      cheques_ultima_informacion: [];
      cheques_historico: [];
      notas_complementarias: [];
    };
    facturas_apocrifas: [];
    edictos: [];
    concursos_y_quiebras: [];
    art: [];
    marcas: [];
    dominios_nic: [];
    perfil: {
      letra: string;
      texto: string;
    };
    indicadores: '';
    ingresos: {
      codigo: number;
      nse: 'C2';
    };
    experto: [];
    resumen: {
      actividad: string[];
      bancarizacion: [];
      cumplimiento: [];
      judicial: [];
    };
  };
}

export type CaseCustomerReportTypes = 'commercial' | 'sinister';

export class CaseCustomerReportClient {
  static async generate<T extends CaseCustomerReportTypes>(
    caseId: number,
    type: T,
    data?: T extends 'commercial' ? { identification: string } : {}
  ): Promise<ICaseCustomerReport> {
    const response = await httpClient.post(
      `/v1/case/${caseId}/customer/report/${type}`,
      data
    );
    return response.data;
  }

  static async remove(
    caseId: number,
    caseCustomerReportId: number
  ): Promise<{ removed: boolean }> {
    const response = await httpClient.delete(
      `/v1/case/${caseId}/customer/report/${caseCustomerReportId}`
    );
    return response.data;
  }

  static async detail(
    caseCustomerReportId: number
  ): Promise<ICaseCustomerReport> {
    const response = await httpClient.get(
      `/v1/case/customer/report/${caseCustomerReportId}`
    );

    return response.data;
  }
}
