import { IUser, IIdentitySignIn } from './authentication.client';
import { IUserRole } from './company.client';
import httpClient from './http-client';
import { IPagination, IPaginationQuery } from './notification.client';

export interface IInvitation {
  id: number;
  role: IUserRole;
  email: string;
  createdAt: string;
  invitedBy: IUser;
}

export interface IInvitationInfo {
  token: string;
  id: number;
  email: string;
  invitedBy: {
    firstName: string;
    lastName: string;
    email: string;
    company: {
      name: string;
    };
  };
}

export interface IInviteUsers {
  invitations: { email: string }[];
  roleId: number;
}

export interface IRegisterUser {
  firstName: string;
  lastName: string;
  password: string;
  username: string;
}

export class InvitationClient {
  static async paginateInvitations(
    query?: IPaginationQuery
  ): Promise<IPagination<IInvitation>> {
    const response = await httpClient.get('/v1/invitation', { params: query });

    return response.data;
  }

  static async inviteUsers(data: IInviteUsers) {
    const response = await httpClient.post('/v1/invitation', data);

    return response.data;
  }

  static async deleteInvitation(id: number) {
    const response = await httpClient.delete(`/v1/invitation/${id}`);

    return response.data;
  }

  static async getInfo(invitationToken: string): Promise<IInvitationInfo> {
    const response = await httpClient.get(`/v1/invitation/info`, {
      headers: {
        'x-invitation-token': invitationToken,
      },
    });

    return response.data;
  }

  static async registerInvitationIdentity(
    invitationToken: string,
    identity: IIdentitySignIn
  ): Promise<IInvitationInfo> {
    const response = await httpClient.post(
      `/v1/invitation/identity/insurer/register`,
      { identity },
      {
        headers: {
          'x-invitation-token': invitationToken,
        },
      }
    );

    return response.data;
  }

  static async registerInvitationLocal(
    invitationToken: string,
    data: IRegisterUser
  ): Promise<IInvitationInfo> {
    const response = await httpClient.post(
      `/v1/invitation/local/register`,
      data,
      {
        headers: {
          'x-invitation-token': invitationToken,
        },
      }
    );

    return response.data;
  }
}
