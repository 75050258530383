import { IUser } from './authentication.client';
import { IPermission } from './authorization.client';
import { IEnumOption, IEnumWithDescription } from './case.client';
import httpClient from './http-client';
import {
  IContact,
  IContactForCaseDerivation,
  IPagination,
  IPaginationQuery,
} from './notification.client';

export interface IUserRole<P = IPermission> {
  createdBy?: IUser;
  usersCount?: number;
  createdAt: string;
  id: number;
  name: string;
  permissions: P[];
}

export interface ICompanyLab {
  id: number;
  email: string;
  name: string;
}

export interface IValidationPattern {
  column: string;
  pattern: string;
  preset?: 'rivadavia';
}

export interface ICompanyEnums {
  ramos: {
    name: string;
    code: string;
    id: number;
  }[];
  agencies: {
    name: string;
    code: string;
    id: number;
  }[];
  validationPatterns: IValidationPattern[];
  instance: IEnumOption[];
  stopCaseRequestActions: IEnumOption[];
  externalStatus: IEnumOption[];
  caseReasons: IEnumWithDescription[];
  caseCloseMotives: IEnumWithDescription[];
  sinisterTypes: IEnumOption[];
  retentionMotives: IEnumOption[];
  caseSurrenderActors: IEnumOption[];
  // I'm sorry for this
  sinisterType: IEnumOption[];
  reason: IEnumOption[];
  ssnProfessionals: IEnumOption[];
  ssnResults: (IEnumOption & {
    isOther: boolean;
  })[];
}

export class CompanyClient {
  static async searchContacts(search?: string): Promise<IPagination<IContact>> {
    const response = await httpClient.get('/v1/company/contact/lab', {
      ...(search ? { params: { search } } : {}),
    });

    return response.data;
  }

  static async searchContactsForCaseDerivation(
    search?: string
  ): Promise<IPagination<IContactForCaseDerivation>> {
    const response = await httpClient.get('/v1/company/contact/list', {
      ...(search ? { params: { search } } : {}),
    });

    return response.data;
  }

  static async paginateMembers(
    query: IPaginationQuery
  ): Promise<IPagination<IUser>> {
    const response = await httpClient.get('/v1/company/members', {
      params: query,
    });

    return response.data;
  }

  static async checkEIN(ein: string): Promise<{ exists: boolean }> {
    const response = await httpClient.get(`/v1/company/${ein}`);

    return response.data;
  }

  static async updateRole(
    userId: number,
    roleId: number
  ): Promise<{ exists: boolean }> {
    const response = await httpClient.put(`/v1/company/member/${userId}/role`, {
      roleId,
    });

    return response.data;
  }

  static async fetchCompanyEnums(): Promise<ICompanyEnums> {
    const response = await httpClient.get(`/v1/company/insurer/enums`);

    return response.data;
  }

  static async updateUserInfo(data: {
    cellphone?: string;
    countryISO?: string;
  }): Promise<{ success: boolean }> {
    const response = await httpClient.put(`/v1/company/member/info`, {
      ...data,
    });
    return response.data;
  }

  static async updateCompanyInfo(data: {
    workingHoursStart: string;
    workingHoursEnd: string;
  }): Promise<void> {
    const response = await httpClient.put(`/v1/company/info`, data);

    return response.data;
  }
}
