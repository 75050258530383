import {
  Box,
  chakra,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import Fuse from 'fuse.js'
import { IIntegration, IntegrationsType } from 'kach-clients'
import React, { useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { IntegrationConnectionLogin } from './IntegrationConnectionLogin'
import { IntegrationConnectionOAuth } from './IntegrationConnectionOAuth'
import { buildTestId } from 'kach-commons'
import { IntegrationDetailWrapper } from './IntegrationDetailWrapper'
import { useAuth } from '../hooks/useAuth'

interface Props {
  query: any
  integrations: IIntegration[]
}

export const Integrations = (props: Props) => {
  const auth = useAuth()

  const hasCurrentQuery =
    typeof props.query.current_integration === 'string' &&
    !!props.query.current_integration

  const initialCurrentIntegration = hasCurrentQuery
    ? props.integrations.find(
        (integration) =>
          (props.query.current_integration as string) === integration.name,
      )
    : undefined

  const [currentIntegration, setCurrentIntegration] = useState<
    IIntegration | undefined
  >(initialCurrentIntegration)

  const [integrationsMatched, setIntegrationsMatched] = useState(
    props.integrations,
  )

  const hasPermissionForUpdateIntegration = (integration: IIntegration) => {
    if (integration.name === 'autoinspector') {
      return auth.role.permissions.some(
        (p) =>
          p.resource === 'autoinspector-integration' && p.action === 'update',
      )
    }

    return auth.role.permissions.some(
      (p) => p.resource === 'integration' && p.action === 'update',
    )
  }

  const fuse = new Fuse(props.integrations, {
    keys: ['label'],
  })

  const totalCols = () => {
    return integrationsMatched.filter((integration) =>
      hasPermissionForUpdateIntegration(integration),
    ).length
  }

  return (
    <div>
      {(() => {
        if (!currentIntegration) {
          return (
            <Box display={'flex'} flexDir='column' experimental_spaceY={'5'}>
              <div>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<AiOutlineSearch color='gray.300' />}
                  />
                  <Input
                    onChange={(event) => {
                      if (!event.target.value) {
                        setIntegrationsMatched(props.integrations)
                        return
                      }

                      const search = fuse.search(event.target.value)

                      setIntegrationsMatched(search.map((ele) => ele.item))
                    }}
                    type='email'
                    name='email'
                    id='email'
                    placeholder='Busca una plataforma'
                  />
                </InputGroup>
              </div>
              <SimpleGrid columns={4} gap={6}>
                {integrationsMatched.length > 0 ? (
                  integrationsMatched.map((integration) => {
                    return (
                      <GridItem
                        hidden={!hasPermissionForUpdateIntegration(integration)}
                        {...buildTestId(`${integration.name}-integration`)}
                        cursor={'pointer'}
                        key={integration.id}
                        onClick={() => setCurrentIntegration(integration)}
                        _hover={{
                          transform: 'translateY(-2px)',
                        }}
                        p={3}
                        shadow='lg'
                        display='flex'
                        experimental_spaceY={'5'}
                        flexDir='column'
                        alignItems={'center'}
                        textAlign='center'
                        justifyContent={'center'}
                        borderColor='gray.300'
                        rounded='lg'
                        bg='white'
                        maxW='15rem'
                        h='15rem'
                      >
                        <chakra.img
                          h='10'
                          w='10'
                          objectFit={'cover'}
                          bg='gray.400'
                          src={integration.banner}
                        />
                        <div>
                          <Heading
                            fontSize={'lg'}
                            fontWeight={'bold'}
                            textTransform='uppercase'
                          >
                            {integration.label}
                          </Heading>
                          <Text fontWeight={'light'} fontSize='xs' mt='2'>
                            {integration.description}
                          </Text>
                        </div>
                      </GridItem>
                    )
                  })
                ) : (
                  <div>
                    <Text fontSize={'sm'} fontStyle='italic'>
                      No se encontraron resultados
                    </Text>
                  </div>
                )}
              </SimpleGrid>
            </Box>
          )
        }

        switch (currentIntegration.visibility) {
          case 'private':
            return (
              <IntegrationDetailWrapper
                onBack={() => setCurrentIntegration(undefined)}
                integration={currentIntegration}
              >
                <Text fontSize={'sm'}>
                  Esta integración esta administrada por Kach. Ante cualquier
                  duda, consultar con soporte.
                </Text>
              </IntegrationDetailWrapper>
            )

          case 'public':
            switch (currentIntegration.method) {
              case 'login':
                return (
                  <IntegrationConnectionLogin
                    onBack={() => setCurrentIntegration(undefined)}
                    integration={currentIntegration}
                  />
                )

              case 'oauth':
                return (
                  <IntegrationConnectionOAuth
                    integration={currentIntegration}
                    onBack={() => setCurrentIntegration(undefined)}
                  />
                )

              default:
                return null
            }

          default:
            return null
        }
      })()}
    </div>
  )
}
