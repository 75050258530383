import { Box, Divider, Text, useDisclosure, chakra } from '@chakra-ui/react'
import {
  IInvestigation,
  INews,
  INewsAndRequest,
  INewsRequest,
  InvestigationStatus,
} from 'kach-clients'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { AddInvestigationNews } from './AddInvestigationNews'
import { InvestigationRequestNewsBtn } from './InvestigationRequestNewsBtn'
import { InvestigationSection } from './InvestigationSection'
import { NewsLine } from './NewsLine'
import { NewsRequestLineV2 } from './NewsRequestLineV2'
import { hasPermission } from '../utils/has-permission'

export const InvestigationNews = (props: {
  investigation: IInvestigation
  news: INewsAndRequest[]
  onSeeNewsDetails: (news: INews) => void
  withoutCreate?: boolean
  isUserIncludedInCase?: boolean
}) => {
  const auth = useAuth()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const [newsRequestToReply, setNewsRequestToReply] =
    useState<Partial<INewsRequest>>()

  useEffect(() => {
    if (newsRequestToReply && !isOpen) {
      setNewsRequestToReply(undefined)
    }
  }, [isOpen])

  const actionableInvestigationStatus: InvestigationStatus[] = [
    'accepted',
    'pending',
    'closed',
  ]

  const adminPermission = hasPermission(auth.role.permissions, {
    action: 'update_all',
    resource: 'case',
  })

  const canLabUserPerformActions =
    actionableInvestigationStatus.includes(props.investigation.status) &&
    auth.company.type === 'lab'

  const canInsurerUserRequestNews =
    !!adminPermission ||
    (actionableInvestigationStatus.includes(props.investigation.status) &&
      props.isUserIncludedInCase)

  return (
    <InvestigationSection
      title='Novedades'
      description='Novedades y solicitudes de la investigación'
      renderButton={
        canInsurerUserRequestNews ? (
          <InvestigationRequestNewsBtn
            investigationId={props.investigation.id}
          />
        ) : canLabUserPerformActions ? (
          <AddInvestigationNews
            newsRequestToReply={newsRequestToReply}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            investigationId={props.investigation.id}
          />
        ) : undefined
      }
    >
      <Box px={4} mt='5' experimental_spaceY={2}>
        {props.news.map((elem, index) => {
          return (
            <Box key={index}>
              {(() => {
                switch (elem.type) {
                  case 'news':
                    return <NewsLine news={elem.news} />

                  case 'request':
                    return (
                      <NewsRequestLineV2
                        withReply={canLabUserPerformActions}
                        onReply={() => {
                          setNewsRequestToReply(elem.request)
                          onOpen()
                        }}
                        newsRequest={elem.request}
                      />
                    )
                }
              })()}
              {index + 1 !== props.news.length && <Divider my='4' />}
            </Box>
          )
        })}
      </Box>
    </InvestigationSection>
  )
}
