import { Button, useDisclosure, useToast } from '@chakra-ui/react'
import { InvestigationClient } from 'kach-clients'
import React, { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { RequestNewsForm } from './RequestNewsForm'
import { IFileUploadV2 } from '../hooks/useFileUploadV2'
import { remapFileList } from '../utils/remap-file-list'

export const InvestigationRequestNewsBtn = (props: {
  investigationId: number
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const { onClose, onOpen, isOpen } = useDisclosure()

  const formId = 'request-news-form'

  const toast = useToast()

  const onRequestInvestigationNews = useCallback(
    (data: { annotation: string; artifacts: IFileUploadV2[] }) => {
      setIsLoading(true)

      toast.promise(
        InvestigationClient.requestNews(props.investigationId, {
          annotation: data.annotation,
          artifacts: remapFileList(data.artifacts),
        }),
        {
          loading: {
            title: 'Solicitando novedades...',
          },
          success: () => {
            queryClient.invalidateQueries([
              buildInvestigationQueryKey(props.investigationId),
            ])
            onClose()
            setIsLoading(false)
            return {
              title: 'Novedades solicitadas correctamente',
            }
          },
          error: (err) => {
            return { title: error.getErrorMessage(err) }
          },
        },
      )
    },
    [props.investigationId],
  )

  return (
    <>
      <Button onClick={onOpen} type='button' size='sm'>
        Solicitar novedades
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Solicitar novedades</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isOpen && (
              <RequestNewsForm
                onSubmit={onRequestInvestigationNews}
                formId={formId}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              form={formId}
              type='submit'
              isDisabled={isLoading}
              isLoading={isLoading}
              variant='primary'
              mr={3}
            >
              Solicitar
            </Button>
            <Button isDisabled={isLoading} onClick={onClose} variant='ghost'>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
