import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { ISsnPerson, SsnService } from 'kach-clients'
import { useToggle } from 'ahooks'
import React, { useCallback } from 'react'
import { useMemo, useState } from 'react'
import { Cell, Column } from 'react-table'
import { useError } from '../hooks/useError'
import { useQueryClient } from '@tanstack/react-query'
import { FiEdit2 } from 'react-icons/fi'
import { BsFillTrashFill } from 'react-icons/bs'
import { CreateSsnPerson } from './CreateSsnPersonForm'
import { CustomTable } from './CustomTable'
import { translator } from 'kach-commons'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { ssnPersonInvolvedGroups } from '../schemas/ssn.schema'
import { RemoveSsnPerson } from './RemoveSsnPerson'
import { CASES_QUERY_KEY } from '../constants/query-keys'

export const SsnPersonsTable = (props: {
  persons: ISsnPerson[]
  isOpen: boolean
  onClose: () => void
  ssnId: number
  caseId: number
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const [open, setOpen] = useState(false)

  const [rowSelected, setRowSelected] = useState<ISsnPerson>()

  const toast = useToast()

  const onRemovePerson = useCallback(
    (id: number) => {
      setIsLoading(true)

      toast.promise(SsnService.deletePerson(id), {
        loading: {
          title: 'Eliminando persona...',
        },
        success: () => {
          queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
          queryClient.invalidateQueries([CASES_QUERY_KEY])
          setIsLoading(false)
          return {
            title: 'Persona eliminada',
          }
        },
        error: (err) => {
          setIsLoading(false)
          return { title: error.getErrorMessage(err) }
        },
      })
    },
    [rowSelected],
  )

  const columns = useMemo<Column<ISsnPerson>[]>(() => {
    const cols: Column<ISsnPerson>[] = [
      {
        Header: 'Tipo',
        accessor: (p: ISsnPerson) => {
          return (
            <Tag bg='brand.50' size='sm' color='inherit'>
              {translator(p.type)}
            </Tag>
          )
        },
        id: 'type',
      },
      {
        Header: 'Nombre',
        accessor: 'firstName',
        id: 'firstName',
      },
      {
        Header: 'Apellido',
        accessor: 'lastName',
        id: 'lastName',
      },
      {
        Header: 'Documento',
        accessor: 'documentNumber',
        id: 'documentNumber',
      },
      {
        Header: 'Cuit/cuil',
        accessor: 'ein',
        id: 'ein',
      },
      {
        Header: 'Dirección',
        accessor: 'address',
        id: 'address',
      },
      {
        Header: 'Provincia',
        accessor: 'city',
        id: 'city',
      },
      {
        Header: 'Localidad',
        accessor: 'state',
        id: 'state',
      },
      {
        Header: 'C.Postal',
        accessor: 'zipCode',
        id: 'zipCode',
      },
    ]

    cols.push({
      id: 'actions',
      //@ts-ignore
      Cell: (cell: Cell<ISsnPerson>) => {
        return (
          <ButtonGroup>
            <>
              <IconButton
                onClick={() => {
                  onOpen()
                  console.log(isOpen)
                  setRowSelected(cell.row.original)
                }}
                aria-label='delete'
                icon={<BsFillTrashFill />}
                size={'sm'}
              />
            </>
          </ButtonGroup>
        )
      },
    })

    return cols
  }, [])

  const data = props.persons ? props.persons : []

  return (
    <Box>
      <CustomTable columns={columns} data={data} noHover={true}></CustomTable>
      {rowSelected && (
        <RemoveSsnPerson
          caseId={props.caseId}
          personId={rowSelected.id}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Box>
  )
}
