import {
  Box,
  Button,
  chakra,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  AtSymbolIcon,
  BuildingOfficeIcon,
  EnvelopeIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { AuthenticationClient, IUser } from 'kach-clients'
import { AsYouType } from 'libphonenumber-js'
import get from 'lodash.get'
import React, { useCallback, useMemo, useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import {
  IUserInfoSchema,
  UserInfoSchema,
} from '../schemas/update-user-info.schema'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CompanyClient } from 'kach-clients'
import { useError } from '../hooks/useError'
import { hasPermission } from '../utils/has-permission'
import { zonedTimeToUtc, format, utcToZonedTime } from 'date-fns-tz'
import { DEFAULT_COUNTRY_ISO, DEFAULT_TIMEZONE } from 'kach-constants'
import { useRouter } from 'next/router'
import { useQuerySingleton } from '../hooks/useQuerySingleton'

const EditUserForm = ({ auth }: { auth: IUser }) => {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()
  const queryClient = useQueryClientSingleton()
  const toast = useToast()

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IUserInfoSchema>({
    resolver: zodResolver(UserInfoSchema),
    defaultValues: {
      cellphone: auth?.cellphone || '',
      countryISO: auth?.countryISO || DEFAULT_COUNTRY_ISO,
    },
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const router = useRouter()

  const onSubmit = useCallback((data: IUserInfoSchema) => {
    setIsLoading(true)

    CompanyClient.updateUserInfo({ ...data })
      .then(() => {
        queryClient.invalidateQueries(['user'])
        toast({
          title: 'Cuenta actualizada correctamente',
          description:
            'La información de la empresa ha sido actualizada correctamente',
          isClosable: true,
          status: 'success',
        })
        router.reload()
      })
      .catch((err) => {
        error.handleError(err)
      })
      .finally(() => {
        setIsLoading(false)
        onClose()
      })
  }, [])

  const fields = watch()
  const formId = `edit-user-form`

  return (
    <>
      <Button onClick={onOpen} size={'sm'}>
        Editar
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={'md'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Actualizar información de tu cuenta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <chakra.form onSubmit={handleSubmit(onSubmit)} id={formId}>
              <FormControl isInvalid={!!errors.cellphone}>
                <FormLabel>Celular</FormLabel>
                <Box display='flex'>
                  <Select
                    {...register('countryISO')}
                    minW='25%'
                    w='15%'
                    size='sm'
                    borderRight='none'
                    borderBottomRightRadius={0}
                    borderTopRightRadius={0}
                    isReadOnly={!!auth?.countryISO}
                  >
                    <option>AR</option>
                  </Select>
                  <Input
                    w='75%'
                    size='sm'
                    borderBottomLeftRadius={0}
                    borderLeft='none'
                    borderTopLeftRadius={0}
                    type='text'
                    placeholder='+54 (381) 363-5420'
                    {...register('cellphone')}
                  />
                </Box>
                {!!!errors.cellphone ? (
                  <FormHelperText></FormHelperText>
                ) : (
                  <FormErrorMessage>
                    {errors.cellphone.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </chakra.form>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='brand'
              size={'sm'}
              type='submit'
              form={formId}
              mr={3}
            >
              Guardar
            </Button>
            <Button
              variant='ghost'
              size={'sm'}
              onClick={onClose}
              isDisabled={isLoading}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const time2Date = (time: string) => {
  const [hours, minutes] = time.split(':')
  const date = new Date()
  date.setHours(+hours)
  date.setMinutes(+minutes)
  return date
}

const formatDate2Time = (hours: number, minutes: number) =>
  `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

const zonedTime2UTCTime = (time: string, timeZone: string) => {
  const date = time2Date(time)

  const utc = zonedTimeToUtc(date, timeZone)

  return formatDate2Time(utc.getUTCHours(), utc.getUTCMinutes())
}

const utcTime2ZonedTime = (time: string, timeZone: string) => {
  const date = time2Date(time)
  date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
  return formatDate2Time(date.getHours(), date.getMinutes())
}

const schema = z.object({
  workingHoursStart: z.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
  workingHoursEnd: z.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
})

type FormData = z.infer<typeof schema>

const CompanyUpdatableFieldsForm = () => {
  const auth = useAuth()

  const timeZone = useMemo(() => DEFAULT_TIMEZONE, [])

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      workingHoursStart: auth.company?.workingHoursStart
        ? utcTime2ZonedTime(auth.company?.workingHoursStart, timeZone)
        : undefined,
      workingHoursEnd: auth.company?.workingHoursEnd
        ? utcTime2ZonedTime(auth.company?.workingHoursEnd, timeZone)
        : undefined,
    },
  })

  const [isLoading, setIsLoading] = useState(false)

  const { handleError } = useError()

  const toast = useToast()

  const handleSubmit = useCallback((data: FormData) => {
    setIsLoading(true)

    CompanyClient.updateCompanyInfo({
      workingHoursStart: zonedTime2UTCTime(data.workingHoursStart, timeZone),
      workingHoursEnd: zonedTime2UTCTime(data.workingHoursEnd, timeZone),
    })
      .then(() => {
        toast({
          title: 'Información actualizada',
          description:
            'La información de la empresa ha sido actualizada correctamente',
          isClosable: true,
          status: 'success',
        })
      })
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <FormControl
        w={['100%', null, '85%', '75%']}
        as={Grid}
        templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
      >
        <FormLabel>Horario de trabajo</FormLabel>
        <Stack direction={'row'} alignItems='center'>
          <Controller
            control={form.control}
            name='workingHoursStart'
            render={({ field, formState, fieldState }) => {
              return (
                <FormControl isInvalid={!!fieldState.error}>
                  <Input {...field} type='time' />
                </FormControl>
              )
            }}
          />

          <Text>-</Text>
          <Controller
            control={form.control}
            name='workingHoursEnd'
            render={({ field, fieldState }) => {
              return (
                <FormControl isInvalid={!!fieldState.error}>
                  <Input {...field} type='time' />
                </FormControl>
              )
            }}
          />
        </Stack>
      </FormControl>
      <Button
        isDisabled={
          !hasPermission(auth.role.permissions, {
            action: 'update',
            resource: 'company',
          })
        }
        isLoading={isLoading}
        type='submit'
        mt='5'
        variant={'primary'}
      >
        Guardar
      </Button>
    </form>
  )
}

export const Account = () => {
  const { data } = useQuerySingleton(['user'], () => {
    return AuthenticationClient.me()
  })

  console.log('data', data)

  return (
    <Box>
      <chakra.div experimental_spaceY={8}>
        <Box experimental_spaceY={8} display='flex' flexDir='column'>
          <Box>
            <Box experimental_spaceY={4} pt={4}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box display='flex' experimental_spaceX={2} alignItems='center'>
                  <Icon as={UserIcon} h='6' w='6' />
                  <Text fontSize='xl' fontWeight='medium'>
                    Cuenta
                  </Text>
                </Box>
                <EditUserForm auth={data!} />
              </Box>
              <Divider />
              <Box experimental_spaceY={6}>
                <FormControl
                  w={['100%', null, '85%', '75%']}
                  as={Grid}
                  templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
                >
                  <FormLabel>Nombre</FormLabel>
                  <Input
                    type='text'
                    readOnly
                    name='first-name'
                    defaultValue={data?.firstName}
                  />
                </FormControl>

                <FormControl
                  w={['100%', null, '85%', '75%']}
                  as={Grid}
                  templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
                >
                  <FormLabel>Apellido</FormLabel>
                  <Input
                    type='text'
                    readOnly
                    name='last-name'
                    defaultValue={data?.lastName}
                  />
                </FormControl>

                <FormControl
                  w={['100%', null, '85%', '75%']}
                  as={Grid}
                  templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
                >
                  <FormLabel>Email</FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      pointerEvents='none'
                      children={<Icon as={EnvelopeIcon} />}
                    />
                    <Input
                      type='email'
                      name='email'
                      readOnly
                      defaultValue={data?.email}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl
                  w={['100%', null, '85%', '75%']}
                  as={Grid}
                  templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
                >
                  <FormLabel>Teléfono institucional</FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      pointerEvents='none'
                      children={<Icon as={PhoneIcon} />}
                    />
                    <Input
                      type='text'
                      name='cellPhone'
                      readOnly
                      defaultValue={data?.cellphone || 'Sin agregar'}
                      pr='5rem'
                    />
                  </InputGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box experimental_spaceY={4} pt={4}>
              <Box display='flex' experimental_spaceX={2} alignItems='center'>
                <Icon as={BuildingOfficeIcon} h='6' w='6' />
                <Text fontSize='xl' fontWeight='medium'>
                  Empresa
                </Text>
              </Box>
              <Divider />
              <Box experimental_spaceY={6}>
                <FormControl
                  w={['100%', null, '85%', '75%']}
                  as={Grid}
                  templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
                >
                  <FormLabel>Nombre</FormLabel>
                  <Input
                    type='text'
                    readOnly
                    name='company-name'
                    defaultValue={data?.company?.name}
                  />
                </FormControl>

                <FormControl
                  w={['100%', null, '85%', '75%']}
                  as={Grid}
                  templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
                >
                  <FormLabel>CUIT</FormLabel>
                  <Input
                    type='text'
                    readOnly
                    name='ein'
                    defaultValue={data?.company?.ein}
                  />
                </FormControl>

                <FormControl
                  w={['100%', null, '85%', '75%']}
                  as={Grid}
                  templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
                >
                  <FormLabel>Dominio</FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      pointerEvents='none'
                      children={<Icon as={AtSymbolIcon} />}
                    />
                    <Input
                      type='url'
                      name='domain'
                      readOnly
                      defaultValue={data?.company?.domain}
                    />
                  </InputGroup>
                </FormControl>

                <CompanyUpdatableFieldsForm />
              </Box>
            </Box>
          </Box>
        </Box>
      </chakra.div>
    </Box>
  )
}
