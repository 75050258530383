import { UseFormGetValues, useFieldArray, useForm } from 'react-hook-form'
import {
  CaseRequestResourceColumns,
  CaseResourceColumns,
  InvestigationResourceColumns,
  ValidColumns,
  VirtualViewResources,
  caseRequestResourceColumns,
  caseResourceColumns,
  investigationResourceColumns,
  stopCaseRequestResourceColumns,
  suspectsResourceColumns,
  retentionRequestResourceColumns,
} from 'kach-constants'
import { useContext, useState } from 'react'
import { Column } from 'react-table'
import { TableColumns } from '../components/TableColumns'
import React from 'react'

export interface IColumnsForm {
  resource: VirtualViewResources
  columns: ValidColumns[]
}

export interface ITableColumnsController {
  resource: VirtualViewResources
  columns: Column<any>[]
  getValues: UseFormGetValues<IColumnsForm>
  onRemoveCol: (columnId: string) => void
  resourceColumns: () => ReadonlyArray<ValidColumns>
  onAppendCol: (columnId: string) => void
  setColumns: (columns: string[]) => void
  resetColumns: () => void
}

export const useTableColumns = (props: {
  resource: VirtualViewResources
  columns?: Column<any>[]
}): ITableColumnsController => {
  const {
    control,
    watch: columnsObserver,
    getValues,
    setValue,
  } = useForm<IColumnsForm>({
    defaultValues: {
      resource: props.resource,
      columns: props?.columns?.map((c) => c.id),
    },
  })

  const defaultCols = props.columns

  const [cols, setCols] = useState(defaultCols)

  const resetColumns = () => {
    setCols(defaultCols)
  }

  const onRemoveCol = (columnId: string) => {
    console.log('removing', columnId)
    const newColumns = cols.filter((c) => c.id !== columnId)
    setCols(newColumns)
    console.log('newCols', newColumns)
    setValue(
      'columns',
      newColumns.map((c) => c.id),
    )
  }

  const onAppendCol = (columnId: string) => {
    if (!cols.some((c) => c.id === columnId)) {
      const targetIndex = defaultCols.findIndex(
        (defaultCol) => defaultCol.id === columnId,
      )

      if (targetIndex === -1) {
        return
      }

      const newColumn = cols

      newColumn.splice(targetIndex, 0, defaultCols[targetIndex])

      setCols([...newColumn])

      setValue(
        'columns',
        newColumn.map((c) => c.id),
      )
    }
  }

  const resourceColumns = () => {
    switch (props.resource) {
      case 'case':
        return caseResourceColumns

      case 'case_request':
        return caseRequestResourceColumns

      case 'investigation':
        return investigationResourceColumns

      case 'stop_case_request':
        return stopCaseRequestResourceColumns

      case 'suspect':
        return suspectsResourceColumns

      case 'retention_request':
        return retentionRequestResourceColumns
    }
  }

  const setColumns = (columns: string[]) => {
    const targetColumns = defaultCols.filter((c) => columns.includes(c.id))
    setCols(targetColumns)
    setValue(
      'columns',
      targetColumns.map((c) => c.id),
    )
  }

  return {
    getValues,
    resourceColumns,
    onRemoveCol,
    onAppendCol,
    resource: props.resource,
    columns: cols,
    setColumns,
    resetColumns,
  }
}
