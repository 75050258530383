import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { IInvestigation, InvestigationClient } from 'kach-clients'
import React, { useCallback, useRef, useState } from 'react'
import {
  chakra,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'
import { MdOutlineCancel } from 'react-icons/md'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'
import { AiOutlineClose } from 'react-icons/ai'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const CancelInvestigation = (props: {
  investigation: IInvestigation
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { onOpen, onClose, onToggle, isOpen } = useDisclosure()

  const formId = 'cancel-investigation-form'
  const inputRef = useRef(null)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onCancelInvestigation = useCallback(
    (onClose: () => void) => {
      return (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        setIsLoading(true)

        toast.promise(
          InvestigationClient.cancel(
            props.investigation.id,
            inputRef.current.value,
          ),
          {
            loading: {
              title: 'Cancelando investigación...',
            },
            success: () => {
              queryClient.invalidateQueries([
                buildInvestigationQueryKey(props.investigation.id),
              ])

              queryClient.invalidateQueries([
                buildCaseQueryKey(props.investigation.case_id),
              ])

              onClose()
              setIsLoading(false)
              return { title: 'Investigación cancelada' }
            },
            error: (err) => {
              setIsLoading(false)
              return { title: error.getErrorMessage(err) }
            },
          },
        )
      }
    },
    [props.investigation.id, props.investigation.case_id],
  )

  return (
    <>
      <Popover
        isLazy
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement='right'
        closeOnBlur={false}
      >
        {({ onClose }) => {
          return (
            <>
              <PopoverTrigger>
                <Button
                  colorScheme='red'
                  onClick={onOpen}
                  type='button'
                  size='sm'
                >
                  Cancelar Investigación
                </Button>
              </PopoverTrigger>
              <PopoverContent p={5}>
                <PopoverArrow />
                <PopoverCloseButton isDisabled={isLoading} />
                <PopoverBody>
                  <chakra.form
                    onSubmit={onCancelInvestigation(onClose)}
                    id={formId}
                  >
                    <FormControl>
                      <FormLabel>Motivo (opcional)</FormLabel>
                      <Textarea ref={inputRef} />
                    </FormControl>
                  </chakra.form>
                </PopoverBody>

                <PopoverFooter>
                  <Button
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    type='submit'
                    variant={'primary'}
                    form={formId}
                  >
                    Confirmar
                  </Button>
                  <Button isDisabled={isLoading} variant={'ghost'}>
                    Atrás
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </>
          )
        }}
      </Popover>
    </>
  )
}
