import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { IContact, IContactPrice, ICreateContactPrice } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useContext, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { LAB_PRICES_QUERY_KEY } from '../constants/query-keys'
import { useError } from '../hooks/useError'
import { ContactContext } from '../providers/ContactProvider'
import { ICreateContactPriceSchema } from '../schemas/create-contact-price.schema'
import { ContactPriceForm } from './ContactPriceForm'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const UpdateContactLabPriceModal = (props: {
  isOpen: boolean
  onClose: () => void
  contact: IContact
  price: IContactPrice
}) => {
  const formId = 'update-contact-price-form'

  const { onCreate } = useContext(ContactContext)

  const { handleError, getErrorMessage } = useError()

  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onUpdatePrice = useCallback(
    (data: Required<ICreateContactPriceSchema>) => {
      setIsLoading(true)

      toast.promise(
        onCreate({
          contactId: props.contact.id,
          data: data as ICreateContactPrice,
          previousContactPriceId: props.price.id,
        }),
        {
          loading: {
            title: 'Actualizando honorario...',
          },
          success: () => {
            queryClient.invalidateQueries([LAB_PRICES_QUERY_KEY])
            props.onClose()
            setIsLoading(false)
            return { title: 'Honorario actualizado correctamente' }
          },
          error: (err) => {
            setIsLoading(false)
            return getErrorMessage(err)
          },
        },
      )
    },
    [props.price],
  )

  return (
    <Modal size={'3xl'} isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Actualizar honorario</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box {...buildTestId('update-contact-price-form')}>
            <ContactPriceForm
              defaultValues={props.price}
              onSubmit={onUpdatePrice}
              isLoading={isLoading}
              formId={formId}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            {...buildTestId('update-contact-price-btn')}
            isLoading={isLoading}
            form={formId}
            type='submit'
            variant='primary'
          >
            Actualizar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
