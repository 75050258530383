import {
  Avatar,
  Box,
  BoxProps,
  chakra,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'
import { IMessage } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import defaultMentionChildrenStyles from '../styles/defaultMentionChildrenStyles'
import mentionStyles from '../styles/messageDisplayStyles'
import { timeAgoFormat } from 'kach-commons/src/utils/time-ago-format'
import { ArtifactDisplayer } from './ArtifactDisplayer'

export const MessageDisplayer = (props: {
  message: IMessage
  isTimeline?: boolean
  timeAgoStyles?: BoxProps
}) => {
  return (
    <Box
      whiteSpace={'nowrap'}
      wordBreak='break-all'
      {...buildTestId('message-displayer-container')}
    >
      <MentionsInput
        style={mentionStyles}
        disabled
        readOnly
        value={props.message.message}
      >
        <Mention style={defaultMentionChildrenStyles} data={[]} trigger='@' />
      </MentionsInput>
      {props.message.artifacts.length > 0 && (
        <UnorderedList
          margin={0}
          listStyleType={'none'}
          display='flex'
          flexDir={'column'}
        >
          {props.message.artifacts.map((artifact, index) => {
            return (
              <ListItem {...buildTestId('message-artifact')} key={index}>
                <ArtifactDisplayer mode='light' artifact={artifact} />
              </ListItem>
            )
          })}
        </UnorderedList>
      )}
      {!props.isTimeline && (
        <chakra.span
          fontSize={'xs'}
          fontWeight='light'
          {...props.timeAgoStyles}
        >
          {timeAgoFormat(props.message.createdAt)}
        </chakra.span>
      )}
    </Box>
  )
}
