import { z } from 'zod'
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema'

export const NewsSchema = z.object({
  relatedNewsRequest: z.any().optional().nullable(),
  destinations: z.array(
    z.object({
      email: z.string().email().optional().nullable().or(z.literal('')),
    }),
  ),
  title: z.string().optional().nullable(),
  description: z.string().min(1, REQUIRED_FIELD_MESSAGE),
  //@ts-ignore
  files: z.array(z.any()),
})

export interface INewsSchema extends z.infer<typeof NewsSchema> {}
