import {
  Avatar,
  Box,
  chakra,
  Icon,
  Spinner,
  Textarea,
  Tooltip,
  BoxProps,
} from '@chakra-ui/react'
import { PaperClipIcon } from '@heroicons/react/24/solid'
import {
  AuthenticationClient,
  ICreateMessage,
  IMessageUserMention,
  IUser,
} from 'kach-clients'
import { buildTestId } from 'kach-commons'
import debounce from 'lodash.debounce'
import React, { useCallback, useState } from 'react'
import { Mention, MentionItem, MentionsInput } from 'react-mentions'
import { useAuth } from '../hooks/useAuth'
import { MAX_FILE_SIZE_IN_MB, useFileUpload } from '../hooks/useFileUpload'
import { ArtifactsTokenizer, useFileUploadV2 } from '../hooks/useFileUploadV2'
import defaultMentionChildrenStyles from '../styles/defaultMentionChildrenStyles'
import defaultMentionStyle from '../styles/defaultMentionInputStyles'
import { buildUserName } from '../utils/build-user-name'
import { remapFileList } from '../utils/remap-file-list'
import { FileListDisplayerV2 } from './FileListDisplayerV2'
import { FileUploadInput, FileUploadInputClickable } from './FileUploadV2'
import { UserDisplayer } from './UserDisplayer'

export const MessageTextarea = ({
  withAvatar = true,
  ...props
}: {
  ref?: React.RefObject<HTMLDivElement>
  withNoMentions?: boolean
  withTagUsers?: boolean
  disabledReason?: string
  artifactsTokenizer: ArtifactsTokenizer
  artifactsIdentifier: string
  topInput?: React.ReactNode
  children: React.FC<{
    onClearMessage: () => void
    onUploadFiles: (files: File[]) => void
    message: ICreateMessage
  }>
  styles: BoxProps
  withAvatar?: boolean
}) => {
  const { fileList, onUploadFiles, onCleanUp, onRemove, onUpdateFile } =
    useFileUploadV2(props.artifactsTokenizer)

  const [mentions, setMentions] = useState<IMessageUserMention[]>([])
  const [message, setMessage] = useState('')

  const auth = useAuth()

  const onClearMessage = useCallback(() => {
    onCleanUp()
    setMessage('')
    setMentions([])
  }, [])

  const [isLoading, setIsLoading] = useState(false)

  interface IUserSuggestion {
    display: string
    id: string
    user: IUser
  }

  const fetchUsers = useCallback((query, callback) => {
    AuthenticationClient.searchUsers(query)
      .then((res) =>
        res.results.map((user): IUserSuggestion => {
          const name = buildUserName(user)

          return {
            display: name,
            id: user.id.toString(),
            user,
          }
        }),
      )
      .then((res) => callback(res))
      .finally(() => setIsLoading(false))
  }, [])

  const debounceFetchUsers = useCallback(debounce(fetchUsers, 500), [])

  const uniqueMentions = useCallback(
    (mentions: MentionItem[]) =>
      mentions.reduce<IMessageUserMention[]>((acc, mention) => {
        const alreadyExist = acc.some((m) => m.userId === parseInt(mention.id))

        if (!alreadyExist) {
          acc.push({
            userId: parseInt(mention.id),
          })
        }

        return acc
      }, []),
    [],
  )

  return (
    <Box
      ref={props.ref}
      display='flex'
      alignItems='center'
      experimental_spaceX={4}
      p={4}
      pl={2}
      bg='white'
      {...props.styles}
    >
      {withAvatar && (
        <Box flexShrink={0}>
          <Avatar
            referrerPolicy='no-referrer'
            size='sm'
            bg='primary'
            color='white'
            name={buildUserName(auth)}
            src={auth?.profilePhoto}
          />
        </Box>
      )}

      <Box minW={0} flex={1}>
        <Box position='relative'>
          <Box
            overflow='hidden'
            borderRadius='md'
            boxShadow='md'
            border='1px'
            borderColor='gray.200'
          >
            {props.topInput}

            {props.withNoMentions ? (
              <Textarea
                {...buildTestId('message-textarea')}
                readOnly={!!props.disabledReason}
                isReadOnly={!!props.disabledReason}
                isRequired
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={3}
                name='comment'
                resize='none'
                id='comment'
                border='none'
                placeholder='Agrega tu comentario...'
                defaultValue={''}
                _focus={{ border: 'none', boxShadow: 'none' }}
                _focusVisible={{ border: 'none', boxShadow: 'none' }}
              />
            ) : (
              <Box
                {...buildTestId('textarea-chat-mention-container')}
                _focusVisible={{ border: 'none', boxShadow: 'none' }}
              >
                <MentionsInput
                  customSuggestionsContainer={(children) => {
                    return (
                      <Box
                        py={3}
                        bg='white'
                        boxShadow={'lg'}
                        borderWidth='1px'
                        borderColor='gray.100'
                        pos={'relative'}
                      >
                        {isLoading && (
                          <Box
                            top={0}
                            bottom={0}
                            pos='absolute'
                            alignItems={'center'}
                            justifyContent='center'
                            w='full'
                            display={'flex'}
                          >
                            <Spinner size='sm' />
                          </Box>
                        )}
                        {children}
                      </Box>
                    )
                  }}
                  style={defaultMentionStyle}
                  value={message}
                  rows={3}
                  onChange={(e, _, __, mentions) => {
                    setMessage(e.target.value)
                    setMentions(uniqueMentions(mentions))
                  }}
                  readOnly={!!props.disabledReason}
                  disabled={!!props.disabledReason}
                  required
                >
                  <Mention
                    style={defaultMentionChildrenStyles}
                    isLoading={isLoading}
                    appendSpaceOnAdd
                    displayTransform={(_, display) => `@${display}`}
                    trigger={'@'}
                    renderSuggestion={(entry: IUserSuggestion) => (
                      <Box
                        {...buildTestId('mention-suggestion')}
                        cursor={'pointer'}
                        _hover={{
                          bg: 'gray.100',
                        }}
                        p={1}
                        experimental_spaceY={1}
                      >
                        <UserDisplayer
                          user={entry.user}
                          hideEmail
                          avatarSize='2xs'
                          textSize='xs'
                        />
                      </Box>
                    )}
                    data={(query, callback) => {
                      setIsLoading(true)
                      debounceFetchUsers(query, callback)
                    }}
                  />
                </MentionsInput>
              </Box>
            )}
            <Box py={2} aria-hidden='true'>
              <Box py='1px'>
                <Box h={10} />
              </Box>
            </Box>
          </Box>

          <Box
            position='absolute'
            bottom={0}
            display='flex'
            justifyContent='space-between'
            py={2}
            pl={3}
            pr={2}
            w='full'
          >
            <Box display='flex' alignItems='center' experimental_spaceX={4}>
              <Box display='flex' alignItems='center' w='full'>
                <Box mr='2'>
                  <Tooltip label='Agregar archivos'>
                    <chakra.label
                      htmlFor={props.artifactsIdentifier}
                      position='relative'
                      cursor='pointer'
                      fontWeight='medium'
                    >
                      <chakra.span
                        m='-0.625rem'
                        display='flex'
                        h={10}
                        w={10}
                        alignItems='center'
                        justifyContent='center'
                        color='gray.400'
                      >
                        <Icon
                          as={PaperClipIcon}
                          h={5}
                          w={5}
                          aria-hidden='true'
                        />
                      </chakra.span>

                      <FileUploadInputClickable
                        onUpdateFile={onUpdateFile}
                        fileList={fileList}
                        inputId={props.artifactsIdentifier}
                        limit={5}
                        maxFileSizeMB={MAX_FILE_SIZE_IN_MB}
                        onUpload={onUploadFiles}
                        onRemove={onRemove}
                      />
                    </chakra.label>
                  </Tooltip>
                </Box>

                {fileList.length > 0 && (
                  <FileListDisplayerV2
                    onUpdateFile={onUpdateFile}
                    hideIcon
                    onRemove={onRemove}
                    size='sm'
                    orientation='horizontal'
                    files={fileList}
                  />
                )}
              </Box>
            </Box>

            <Box flexShrink={0}>
              {props.children({
                message: {
                  artifacts: remapFileList(fileList),
                  mentions,
                  message,
                },
                onUploadFiles,
                onClearMessage,
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
