import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { IInvitation, InvitationClient, IPagination } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Cell, Column } from 'react-table'
import { useAuth } from '../hooks/useAuth'
import { useDebounceSearch } from '../hooks/useDebounceSearch'
import { hasPermission } from '../utils/has-permission'
import { CustomTable } from './CustomTable'
import { DeleteInvitationBtn } from './DeleteInvitationBtn'
import HeaderPage from './HeaderPage'
import { InviteForm } from './InviteForm'
import { PaginationFooter } from './PaginationFooter'
import { ILocalPermission } from './RolePermissions'
import { TeamTabs } from './TeamTabs'
import {
  ITeamUsers,
  PAGINATION_INITIAL_PAGE,
  PAGINATION_LIMIT,
} from './TeamUsers'
import { useQuerySingleton } from '../hooks/useQuerySingleton'

export interface ITeamInvitations extends Omit<ITeamUsers, 'users'> {
  invitations: IPagination<IInvitation>
  permissionsToRender: ILocalPermission[]
}

export const TeamInvitations = (props: ITeamInvitations) => {
  const auth = useAuth()

  const columns = useMemo<Column<IInvitation>[]>(
    () => [
      {
        Header: 'Email',
        accessor: (invitation: IInvitation) => invitation.email,
      },

      {
        Header: 'Rol',
        accessor: (invitation: IInvitation) => invitation.role.name,
      },
      {
        Header: 'Invitado por',
        accessor: (invitation: IInvitation) => invitation.invitedBy.email,
      },
      {
        id: 'action',
        //@ts-ignore
        Cell: (invitation: Cell<IInvitation>) => {
          if (
            invitation.row.original.invitedBy.id !== auth.id ||
            !hasPermission(auth.role.permissions, {
              resource: 'invitation',
              action: 'delete',
            })
          ) {
            return null
          }

          return <DeleteInvitationBtn invitation={invitation.row.original} />
        },
      },
    ],
    [],
  )

  const { debounceSearch, search } = useDebounceSearch()

  const [page, setPage] = useState(PAGINATION_INITIAL_PAGE)

  const { data } = useQuerySingleton(
    ['invitations', page, search],
    (ctx) => {
      const [_, page, search] = ctx.queryKey

      return InvitationClient.paginateInvitations({
        search: search as string,
        page: Number(page),
        limit: PAGINATION_LIMIT,
      })
    },
    {
      initialData: props.invitations,
    },
  )

  return (
    <>
      <HeaderPage
        title='Equipo'
        subtitle='Revisa tus usuarios e invitaciones enviadas'
        ActionComponent={
          <InviteForm permissionsToRender={props.permissionsToRender} />
        }
      />
      <TeamTabs Navigator={props.Navigator} pathname={props.pathname} />
      <Box>
        <InputGroup mb='4' maxW={'lg'} size='sm'>
          <InputLeftElement
            pointerEvents='none'
            children={<Icon as={MagnifyingGlassIcon} />}
          />
          <Input
            {...buildTestId('search-input')}
            placeholder={'Ingresa un email'}
            onChange={(e) => debounceSearch(e.target.value)}
            type='text'
          />
        </InputGroup>
        <CustomTable
          noHover
          orientation='left'
          columns={columns}
          data={data?.results || []}
        />
      </Box>

      <PaginationFooter paginationData={data} page={page} setPage={setPage} />
    </>
  )
}
