import httpClient from './http-client';
import { ITag } from './notification.client';

export class TagClient {
  static async list(params: { type?: ITag['type'] } = {}): Promise<{
    tags: ITag[];
  }> {
    return await httpClient.get('/v1/tag', { params }).then((res) => res.data);
  }

  static async batchCreate(tags: Pick<ITag, 'name'>[]): Promise<{
    tags: ITag[];
  }> {
    return await httpClient.post('/v1/tag', { tags }).then((res) => res.data);
  }
}
