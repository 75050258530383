import { Box, chakra, Text } from '@chakra-ui/react'
import React from 'react'
import reactSelect from 'react-select'

export const InvestigationSection = (
  props: React.PropsWithChildren<{
    title: string
    description: string
    renderButton?: React.ReactNode
  }>,
) => {
  return (
    <section aria-labelledby='applicant-information-title'>
      <Box
        bg='white'
        borderRadius='md'
        boxShadow='md'
        borderWidth='1px'
        borderColor={'gray.100'}
      >
        <Box
          px='4'
          py='5'
          display={'flex'}
          flexDir={['column', null, 'row']}
          experimental_spaceY={[4, null, 0]}
          justifyContent={'space-between'}
          alignItems='center'
        >
          <Box
            w='full'
            display={'flex'}
            flexDir='column'
            experimental_spaceY={'2'}
          >
            <Text
              id='applicant-information-title'
              fontSize='lg'
              fontWeight='medium'
            >
              {props.title}
            </Text>
            <Text maxW='2xl' fontSize='sm' color='gray.500'>
              {props.description}
            </Text>
          </Box>
          {props.renderButton && (
            <Box
              w={['full', null, 'auto']}
              rounded='md'
              shadow='sm'
              display='flex'
            >
              {props.renderButton}
            </Box>
          )}
        </Box>

        <Box px={4} experimental_spaceY={2}>
          {props.children}
        </Box>
      </Box>
    </section>
  )
}
