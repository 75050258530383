import { ICaseExtended } from 'kach-clients'
import { useAuth } from './useAuth'

export const useCaseAbility = (
  caseData: ICaseExtended,
  considerStatus: boolean = true,
) => {
  const auth = useAuth()

  const isCaseMember = caseData.users?.some(
    (user) => user?.user?.id === auth.id,
  )

  const canReadAllCases = auth.role.permissions.some(
    (p) => p.action === 'update_all' && p.resource === 'case',
  )

  const hasRelationWithCase = !!canReadAllCases || !!isCaseMember

  const canPerformActions =
    !!hasRelationWithCase &&
    (!considerStatus ? true : caseData.status === 'in_progress')

  return {
    canPerformActions,
  }
}
