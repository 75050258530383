import { z } from 'zod';
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema';

//@ts-ignore
export const CreateInvestigationSchema = z.object({
  indicators: z.string().min(1, REQUIRED_FIELD_MESSAGE),
  contactId: z.number().optional(),
  type: z.string().min(1, REQUIRED_FIELD_MESSAGE),
});

export interface ICreateInvestigationSchema
  extends z.infer<typeof CreateInvestigationSchema> {}
