import {
  Badge,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { IInvestigationDetail, InvestigationClient } from 'kach-clients'
import { translator } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import {
  InvestigationType,
  investigationTypes,
} from '../schemas/create-contact-price.schema'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'

const schema = z.object({
  reason: z.string().min(1, 'Campo requerido'),
})

type FormData = z.infer<typeof schema>

const Form = ({
  investigation,
  uncommittedType,
  onClose,
}: {
  investigation: IInvestigationDetail
  uncommittedType: InvestigationType
  onClose: () => void
}) => {
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const [isLoading, setIsLoading] = useState(false)

  const formId = 'update-investigation-form'

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onUpdateInvestigation = useCallback(
    (data: FormData) => {
      setIsLoading(true)

      toast.promise(
        InvestigationClient.update(investigation.id, {
          type: uncommittedType,
          reason: data.reason,
        }),
        {
          loading: {
            title: 'Actualizando investigación...',
          },
          success: () => {
            queryClient.invalidateQueries([
              buildInvestigationQueryKey(investigation.id),
            ])

            queryClient.invalidateQueries([
              buildCaseQueryKey(investigation.case_id),
            ])

            onClose()
            setIsLoading(false)
            return { title: 'Investigación actualizada' }
          },
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
        },
      )
    },
    [investigation.id, investigation.case_id, uncommittedType],
  )

  return (
    <form onSubmit={form.handleSubmit(onUpdateInvestigation)}>
      <ModalHeader>Modificar tipo de investigación</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text mb='4'>
          Estas a punto de cambiar el tipo de investigación a{' '}
          <Badge>{translator(uncommittedType)}</Badge>
        </Text>
        <FormControl isRequired isInvalid={!!form.formState.errors.reason}>
          <FormLabel>Motivo</FormLabel>
          <Textarea {...form.register('reason')} />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button onClick={onClose} variant={'ghost'}>
            Cancelar
          </Button>
          <Button isLoading={isLoading} type='submit' variant='primary'>
            Confirmar
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  )
}

export const InvestigationTypeSelect = ({
  investigation,
}: {
  investigation: IInvestigationDetail
}) => {
  const [uncommittedType, setUncommittedType] = useState<InvestigationType>()

  return (
    <>
      <Select
        borderRadius={'md'}
        onChange={(e) =>
          setUncommittedType(e.target.value as InvestigationType)
        }
        value={investigation.type}
        size='sm'
      >
        {investigationTypes.map((type) => {
          return <option value={type}>{translator(type)}</option>
        })}
      </Select>
      <Modal
        isOpen={!!uncommittedType}
        onClose={() => setUncommittedType(undefined)}
      >
        <ModalOverlay />
        <ModalContent>
          <Form
            onClose={() => setUncommittedType(undefined)}
            uncommittedType={uncommittedType!}
            investigation={investigation}
          />
        </ModalContent>
      </Modal>
    </>
  )
}
