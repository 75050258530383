import React from 'react'
import { Avatar, Box, Button, Tooltip } from '@chakra-ui/react'
import { IUser } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { buildUserName } from '../utils/build-user-name'
import { UsersListSelectSearcher } from './ManageCaseUsers'

export const ManageUserList = ({
  withRemove = true,
  ...props
}: {
  users: IUser[]
  onClose: () => void
  onRemove: (id: number) => void
  onAdd: (id: number) => void
  filterCandidates: (users: IUser[]) => IUser[]
  isLoading: (user: IUser) => boolean
  withRemove?: boolean
}) => {
  return (
    <Box>
      {props.users.map((user, index) => {
        const ButtonContent = (
          <Button
            {...buildTestId('remove-collaborator-btn')}
            size='sm'
            display='flex'
            w='full'
            justifyContent='start'
            onClick={() => props.onRemove(user.id)}
            key={index}
            isDisabled={props.isLoading(user) || !withRemove}
          >
            <Avatar
              referrerPolicy='no-referrer'
              size='xs'
              bg='primary'
              color='white'
              name={buildUserName(user)}
              src={user.profilePhoto}
              mr={2}
            />
            <span {...buildTestId(`collaborator-name`)}>
              {buildUserName(user)}
            </span>
          </Button>
        )

        if (!withRemove)
          return (
            <Box key={index} px={2}>
              {ButtonContent}
            </Box>
          )

        return (
          <Box key={index} px={2}>
            <Tooltip label='Presiona para eliminar'>{ButtonContent}</Tooltip>
          </Box>
        )
      })}

      <Box
        {...buildTestId('manage-case-users-container')}
        bg='white'
        borderRadius='md'
        p={2}
      >
        <UsersListSelectSearcher
          onClickUser={(user) => props.onAdd(user.id)}
          filterUsers={props.filterCandidates}
          isLoading={props.isLoading}
        />
      </Box>
    </Box>
  )
}
