import {
  Box,
  chakra,
  Heading,
  IconButton,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { IIntegration, IIntegrationConnection } from 'kach-clients'
import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { IntegrationConnectionStatus } from './IntegrationConnectionStatus'

export const IntegrationDetailWrapper = (props: {
  isLoading?: boolean
  onBack: () => void
  integration: IIntegration
  integrationConnection?: IIntegrationConnection
  children?: React.ReactNode
}) => {
  if (props.isLoading) {
    return (
      <Box
        w='full'
        h='75vh'
        alignItems={'center'}
        display='flex'
        justifyContent={'center'}
      >
        <Spinner size='lg' />
      </Box>
    )
  }

  return (
    <Box p='10'>
      <Box
        display={'flex'}
        flexDir='row'
        alignItems={'center'}
        position='relative'
      >
        <Box position={'absolute'} left={'-12'}>
          <Tooltip label='Volver'>
            <IconButton
              variant='ghost'
              aria-label='back'
              icon={<IoIosArrowBack />}
              onClick={props.onBack}
              mx='2'
              _hover={{
                bg: 'gray.200',
              }}
              p={3}
              rounded='lg'
            />
          </Tooltip>
        </Box>

        <chakra.img w='10' ml='2' mr='5' src={props.integration.banner} />
        <div>
          <Heading fontSize={'lg'} fontWeight={'bold'}>
            {props.integration.label}
          </Heading>
          <Text color='gray.600'>{props.integration.description}</Text>
        </div>
      </Box>
      <Box mt='5'>
        {props.integrationConnection && (
          <IntegrationConnectionStatus
            integrationConnection={props.integrationConnection}
          />
        )}

        {props.children}
      </Box>
    </Box>
  )
}
