import {
  Box,
  chakra,
  Divider,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
} from '@chakra-ui/react'
import { PaperClipIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { BsFillTrashFill } from 'react-icons/bs'
import { useAuth } from '../hooks/useAuth'
import { IFileUploadV2 } from '../hooks/useFileUploadV2'
import { ManageArtifactTags } from './ManageArtifactTags'

export interface IOptionValue {
  label: string
  value: number
}

export const FileListDisplayerV2 = (props: {
  readonly?: boolean
  withoutTags?: boolean
  files: IFileUploadV2[]
  onRemove: (index: number) => void
  orientation: 'vertical' | 'horizontal'
  size?: 'lg' | 'sm'
  hideIcon?: boolean
  onUpdateFile: (index: number, update: Partial<IFileUploadV2>) => void
}) => {
  const defaultSize = props.size || 'lg'

  const auth = useAuth()

  return (
    <List
      display='flex'
      flexDir={props.orientation === 'vertical' ? 'column' : 'row'}
      border='1px'
      borderColor='gray.100'
      borderRadius='md'
    >
      {props.files.map((file, index) => {
        const linkItem = (
          <ListItem
            h='40px'
            key={index}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            p={2}
          >
            <Box display='flex' w={0} flex={1} alignItems='center'>
              {!props.hideIcon && (
                <Icon as={PaperClipIcon} w='5' h='5' color='gray.400' />
              )}

              <chakra.span fontSize='sm' ml={2} w={0} flex={1} noOfLines={1}>
                {file.name}
              </chakra.span>
              {file.tags?.length > 0 && (
                <Box
                  mr='3'
                  experimental_spaceX={'3'}
                  display={'flex'}
                  flexDir='row'
                  alignItems={'center'}
                >
                  {file.tags.map((tag, index) => {
                    return (
                      <Tag key={index}>
                        <TagLabel>{tag.label || tag.name}</TagLabel>

                        {!props.readonly && (
                          <TagCloseButton
                            onClick={() =>
                              props.onUpdateFile(index, {
                                tags: file.tags.filter(
                                  (_, currIndex) => currIndex !== index,
                                ),
                              })
                            }
                          />
                        )}
                      </Tag>
                    )
                  })}
                </Box>
              )}
            </Box>
            {file.isUploading && <Spinner size='xs' />}

            {!file.isUploading && !props.readonly && (
              <>
                <Tooltip label='Eliminar archivo'>
                  <IconButton
                    padding={0}
                    onClick={() => props.onRemove(index)}
                    size='xs'
                    variant='ghost'
                    aria-label='Remove file'
                    icon={<BsFillTrashFill />}
                  />
                </Tooltip>
                {!!auth?.id && !props.withoutTags && (
                  <ManageArtifactTags
                    onUpdateFile={(update) => props.onUpdateFile(index, update)}
                    file={file}
                  />
                )}
              </>
            )}
          </ListItem>
        )

        return (
          <>
            {!!file.url && props.readonly ? (
              <Link
                _hover={{
                  textDecoration: 'underline',
                }}
                target='_blank'
                href={file.url}
              >
                {linkItem}
              </Link>
            ) : (
              linkItem
            )}

            {props.files.length !== index + 1 ? <Divider /> : null}
          </>
        )
      })}
    </List>
  )
}
