import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

export const NewLineText = (props: {
  children: React.ReactNode
  textProps?: TextProps
}) => {
  return (
    <Text whiteSpace={'pre-wrap'} {...props.textProps}>
      {props.children}
    </Text>
  )
}
