import { useContext } from 'react';
import { KachContext } from '../providers/KachProvider';
import {
  QueryFunction,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

export const useQuerySingleton = <K>(
  queryKey: QueryKey,
  queryFn: QueryFunction<K, QueryKey>,
  options: UseQueryOptions<K> = {}
) => {
  const { defaultContext } = useContext(KachContext);

  return useQuery(queryKey, queryFn, {
    ...options,
    context: defaultContext,
  });
};
