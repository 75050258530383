import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';

export const DangerAction = (props: {
  title: string;
  description: string;
  Button: React.FC;
}) => {
  return (
    <Box>
      <Box
        display={'flex'}
        flexDir="row"
        justifyContent={'space-between'}
        borderWidth={'2px'}
        borderColor="gray.200"
        alignItems={'center'}
        rounded="lg"
        p={3}
      >
        <Box display={'flex'} flexDir="column">
          <Heading fontSize={'lg'}>{props.title}</Heading>
          <Text fontSize={'sm'}>{props.description}</Text>
        </Box>

        <props.Button />
      </Box>
    </Box>
  );
};
