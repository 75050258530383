import { validateConfig } from 'next/dist/server/config-shared'
import { z } from 'zod'
import { documentType } from 'kach-constants'

export const numberString = (length: number, message: string) =>
  z.string().refine(
    (val) => {
      if (!val) {
        return true
      }

      if (!RegExp(/^\d+$/).test(val)) {
        return false
      }

      if (val.length !== length) {
        return false
      }

      return true
    },
    {
      message,
    },
  )

export const PersonSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  documentType: z.enum(documentType).default('dni'),
  documentNumber: numberString(8, 'Documento debe ser de 8 caracteres')
    .optional()
    .nullable(),
  ein: numberString(11, 'Ein debe ser de 11 caracteres').optional().nullable(),
  address: z.string().optional(),
  state: z.string().optional(),
  city: z.string().optional(),
  zipCode: z.string().optional(),
  enrollment: z
    .string()
    .optional()
    .nullable()
    .transform((v) => {
      if (!v) {
        return null
      }

      return v
    }),
})
