import { AuthenticationClient, IPagination, IUser } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { AsyncSelect, chakraComponents } from 'chakra-react-select'
import React, { useCallback } from 'react'
import { UserDisplayer } from './UserDisplayer'
import debounce from 'lodash.debounce'

export const UsersSelectSearchable = <T extends boolean>(props: {
  isMulti?: T
  onSelect: (user: T extends true ? IUser[] : IUser) => void
  filter?: (user: IUser) => boolean
  defaultValue?: {
    label: string
    value: number
    id: number
  }
}) => {
  const mapOptions = (response: IPagination<IUser>) =>
    (props.filter
      ? response?.results?.filter((user) => props.filter(user))
      : response.results
    ).map((result) => ({
      label: result.email,
      value: result.id,
      user: result,
    }))

  const loadSuggestions = (
    value: string,
    callback: (opts: ReturnType<typeof mapOptions>) => void,
  ) => {
    AuthenticationClient.searchUsers(value).then((res) =>
      callback(mapOptions(res)),
    )
  }

  const loadSuggestionsBounced = debounce(loadSuggestions, 500)

  return (
    <div>
      <AsyncSelect<ReturnType<typeof mapOptions>[number]>
        inputId={'users-select-searchable'}
        useBasicStyles
        isMulti={props.isMulti}
        onChange={(v) =>
          props.onSelect(Array.isArray(v) ? v.map((ele) => ele.user) : v?.user)
        }
        components={{
          Option: (props) => {
            return (
              <div {...buildTestId('user-select-opt')}>
                <chakraComponents.Option {...props}>
                  <UserDisplayer user={props.data.user} />
                </chakraComponents.Option>
              </div>
            )
          },
        }}
        noOptionsMessage={() => 'No se encontraron opciones'}
        loadingMessage={() => 'Buscando usuarios...'}
        name='Email o nombre'
        placeholder='Busca un usuario'
        loadOptions={loadSuggestionsBounced}
        defaultValue={props.defaultValue}
      />
    </div>
  )
}
