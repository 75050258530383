import { Box, Button, useToast } from '@chakra-ui/react'
import React from 'react'
import { MicrosoftIcon } from './MicrosoftIcon'

export const MicrosoftBtn = () => {
  const toast = useToast()

  return (
    <Button
      onClick={() => {
        toast({
          title: 'Pronto podrás iniciar sesión con tu cuenta de Microsoft',
          status: 'info',
          icon: '⏲️',
        })
      }}
      bg='white'
      w='full'
      border='1px'
      borderColor='gray.300'
      _hover={{ bg: 'white' }}
    >
      <Box display='flex' alignItems='center' experimental_spaceX={2}>
        <MicrosoftIcon
          style={{
            width: '1.6rem',
          }}
        />
        <span>Continuar con Microsoft</span>
      </Box>
    </Button>
  )
}
