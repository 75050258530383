import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { useError } from '../hooks/useError'
import { SsnService } from 'kach-clients'
import { buildCaseQueryKey } from '../utils/build-case-query-key'

export const RemoveSsnPerson = (props: {
  caseId: number
  personId: number
  isOpen: boolean
  onClose: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClient()

  const { handleError, getErrorMessage } = useError()

  const toast = useToast()

  const onRemovePerson = useCallback(
    (id: number) => {
      setIsLoading(true)

      toast.promise(SsnService.deletePerson(id), {
        loading: {
          title: 'Eliminando persona...',
        },
        success: () => {
          queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
          props.onClose()
          setIsLoading(false)
          return { title: 'Persona eliminada correctamente' }
        },
        error: (err) => {
          setIsLoading(false)
          return getErrorMessage(err)
        },
      })
    },
    [props.caseId],
  )

  return (
    <AlertDialog isOpen={props.isOpen} onClose={props.onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Eliminar colaborador
          </AlertDialogHeader>

          <AlertDialogBody>
            ¿Estás seguro de que quieres eliminar al participante?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button isDisabled={isLoading} onClick={props.onClose}>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                onRemovePerson(props.personId)
              }}
              isDisabled={isLoading}
              colorScheme='red'
              ml={3}
            >
              Eliminar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
