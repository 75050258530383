'use client'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  chakra,
  Divider,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  buildCaseNotesQueryKey,
  CaseClient,
  CreateNoteRequestBody,
  NoteSchema,
} from 'kach-clients'
import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsFillTrashFill } from 'react-icons/bs'
import { CiEdit } from 'react-icons/ci'
import { MdNoteAdd } from 'react-icons/md'
import { useAuth } from '../hooks/useAuth'
import { useDebounceSearch } from '../hooks/useDebounceSearch'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { createNoteRequestBody } from '../schemas/note.schema'
import { timeAgoFormat } from 'kach-commons/src/utils/time-ago-format'
import { translator } from '../utils/translator'
import { TunnelRat } from './TunnelRat'
import { UserDisplayer } from './UserDisplayer'
import { WaitForQuery } from './WaitForQuery'

export const CaseNoteButton = ({ onOpen }: { onOpen: () => void }) => {
  return (
    <Button
      aria-label='add-note'
      leftIcon={<MdNoteAdd />}
      variant='solid'
      onClick={onOpen}
      size={'sm'}
    >
      Agregar nota
    </Button>
  )
}

export const NoteForm = ({
  defaultValues,
  formId,
  onSubmit,
  itemId,
  children,
}: {
  formId?: string
  defaultValues?: Pick<NoteSchema, 'content' | 'title'>
  onSubmit?: (body: CreateNoteRequestBody) => void
  itemId?: number
  children?: React.ReactNode
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CreateNoteRequestBody>({
    resolver: zodResolver(createNoteRequestBody),
    defaultValues: defaultValues ? defaultValues : undefined,
  })

  return (
    <chakra.form
      onSubmit={onSubmit ? handleSubmit(onSubmit) : handleSubmit(() => {})}
      id={formId}
    >
      <Box
        width={'full'}
        height={'60'}
        display='flex'
        flexDir='column'
        border={'1px'}
        borderColor={'gray.300'}
        borderRadius={'md'}
      >
        <FormControl isInvalid={!!errors.title}>
          <Input
            readOnly={itemId ? true : false}
            style={{ border: 'none' }}
            border={'none'}
            placeholder={'Título'}
            size={'md'}
            fontSize={'x-large'}
            fontWeight='bold'
            {...register('title')}
          />
          {errors.title && (
            <FormErrorMessage>{errors.title.message}</FormErrorMessage>
          )}
        </FormControl>
        <Divider />
        <FormControl height={'full'} isInvalid={!!errors.content}>
          <Textarea
            readOnly={itemId ? true : false}
            resize={'none'}
            border={'none'}
            height={'full'}
            placeholder='Descripción'
            {...register('content')}
          />
          {errors.content && (
            <FormErrorMessage>{errors.content.message}</FormErrorMessage>
          )}
        </FormControl>
        {children}
      </Box>
    </chakra.form>
  )
}

const CreateNoteForm = ({ caseId }: { caseId: number }) => {
  const formId = 'create-note'

  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const queryClient = useQueryClient()

  const error = useError()

  const { onChangeView } = useView()

  const handleSubmit = useCallback(
    (body: CreateNoteRequestBody) => {
      setIsLoading(true)
      toast.promise(CaseClient.createCaseNote(caseId, body), {
        loading: { title: 'Creando nota...' },
        success: () => {
          queryClient.invalidateQueries([buildCaseNotesQueryKey(caseId)])
          setIsLoading(false)
          onChangeView({
            section: 'list-notes',
          })
          return { title: 'Nota creada' }
        },
        error: (err) => {
          setIsLoading(false)
          return {
            title: error.getErrorMessage(err),
          }
        },
      })
    },
    [caseId],
  )

  return (
    <Box>
      <NoteForm formId='create-note' onSubmit={handleSubmit} />
      <Box w='full' display='flex' alignItems={'flex-end'} justifyContent='end'>
        <Button
          type='submit'
          form={formId}
          m='2'
          size={'sm'}
          variant={'primary'}
        >
          Guardar
        </Button>
      </Box>
    </Box>
  )
}

const UpdateNoteForm = ({ caseId }: { caseId: number }) => {
  const formId = 'update-note'

  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const queryClient = useQueryClient()

  const error = useError()

  const view = useView()

  if (view.section !== 'update-note') throw new Error('Invalid section')

  const handleSubmit = useCallback(
    (body: CreateNoteRequestBody) => {
      setIsLoading(true)
      toast.promise(CaseClient.updateCaseNote(caseId, view.note.id, body), {
        loading: { title: 'Actualizando nota...' },
        success: () => {
          queryClient.invalidateQueries([buildCaseNotesQueryKey(caseId)])

          setIsLoading(false)

          view.onChangeView({
            section: 'list-notes',
          })

          return { title: 'Nota actualizada' }
        },
        error: (err) => {
          setIsLoading(false)
          return {
            title: error.getErrorMessage(err),
          }
        },
      })
    },
    [caseId, view.note.id],
  )

  return (
    <Box>
      <NoteForm
        defaultValues={view.note}
        formId={formId}
        onSubmit={handleSubmit}
      />
      <Box w='full' display='flex' alignItems={'flex-end'} justifyContent='end'>
        <Button
          type='submit'
          form={formId}
          m='2'
          size={'sm'}
          variant={'primary'}
        >
          Actualizar
        </Button>
      </Box>
    </Box>
  )
}

export const NoteItemActions = ({
  item,
  caseId,
}: {
  caseId: number
  item: NoteSchema
}) => {
  const { getErrorMessage } = useError()

  const { onChangeView } = useView()

  const queryClient = useQueryClientSingleton()

  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const deleteDisclosure = useDisclosure()

  const onDelete = useCallback(() => {
    setIsLoading(true)
    toast.promise(CaseClient.deleteCaseNote(caseId, item.id), {
      loading: { title: 'Eliminando nota...' },
      success: () => {
        queryClient.invalidateQueries([buildCaseNotesQueryKey(caseId)])
        setIsLoading(false)
        deleteDisclosure.onClose()
        return { title: 'Nota eliminada' }
      },
      error: (err) => {
        setIsLoading(false)
        deleteDisclosure.onClose()
        return getErrorMessage(err)
      },
    })
  }, [caseId, item.id])

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Box>
          <Popover
            onOpen={deleteDisclosure.onOpen}
            onClose={deleteDisclosure.onClose}
            isOpen={deleteDisclosure.isOpen}
            returnFocusOnClose={false}
            placement='right'
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <IconButton
                variant={'ghost'}
                aria-label='delete'
                icon={<BsFillTrashFill />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverHeader fontWeight='semibold'>Eliminar Nota</PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                ¿Estás seguro que deseas eliminar esta nota?
              </PopoverBody>
              <PopoverFooter display='flex' justifyContent='flex-end'>
                <ButtonGroup size='sm'>
                  <Button onClick={deleteDisclosure.onClose} variant={'ghost'}>
                    Cancelar
                  </Button>
                  <Button
                    isLoading={isLoading}
                    onClick={onDelete}
                    colorScheme='red'
                  >
                    Eliminar
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Box>
        <IconButton
          onClick={() =>
            onChangeView({
              section: 'update-note',
              note: item,
            })
          }
          variant={'ghost'}
          aria-label='update'
          icon={<CiEdit />}
        />
      </Box>
    </Box>
  )
}

export const NoteGridItem = ({
  item,
  caseId,
}: {
  caseId: number
  item: NoteSchema
}) => {
  const auth = useAuth()

  return (
    <GridItem w='100%'>
      <Box display={'flex'} flexDir={'column'}>
        <NoteForm defaultValues={item} itemId={item.id}>
          <Box
            p={2}
            w='full'
            alignItems={'center'}
            display='flex'
            justifyContent={'space-between'}
          >
            <Box display={'flex'} flexDir='column'>
              <UserDisplayer user={item.createdBy} />

              <Text mt='2' color='gray.500' fontSize='xs'>
                Ultima edición: {timeAgoFormat(item.updatedAt!)}
              </Text>
            </Box>

            {auth.id === item.createdBy.id && (
              <NoteItemActions item={item} caseId={caseId} />
            )}
          </Box>
        </NoteForm>
      </Box>
    </GridItem>
  )
}

type ViewRoute =
  | {
      section: 'list-notes' | 'create-note'
    }
  | {
      section: 'update-note'
      note: NoteSchema
    }

type ViewContextProps = ViewRoute & {
  onChangeView: (view: ViewRoute) => void
}

const ViewContext = React.createContext<ViewContextProps | undefined>(undefined)

const useView = () => useContext(ViewContext)!

const ViewProvider = ({ children }: { children: React.ReactNode }) => {
  const [view, setView] = useState<ViewRoute>({
    section: 'list-notes',
  })

  return (
    <ViewContext.Provider
      value={{
        ...view,
        onChangeView: setView,
      }}
    >
      {children}
    </ViewContext.Provider>
  )
}

const ListNotesView = ({ caseId }: { caseId: number }) => {
  const { debounceSearch, search } = useDebounceSearch()

  const query = useQuery({
    queryKey: [buildCaseNotesQueryKey(caseId), search],
    queryFn: (context) => {
      const [_, search] = context.queryKey
      return CaseClient.listCaseNotes(caseId, {
        ...(search ? { search } : {}),
      })
    },
    refetchOnMount: true,
  })

  const { onChangeView } = useView()

  console.log('query.data', query.data)

  return (
    <>
      <TunnelRat tunnelId={BREADCRUMB_PORTAL_ID}>
        <Button
          size={'sm'}
          position='relative'
          variant='primary'
          onClick={() => onChangeView({ section: 'create-note' })}
        >
          Agregar nota
        </Button>
      </TunnelRat>

      <Box>
        <InputGroup mb='4' size='sm'>
          <InputLeftElement
            pointerEvents='none'
            children={<Icon as={MagnifyingGlassIcon} />}
          />
          <Input
            rounded={'md'}
            placeholder={'Busque una nota...'}
            onChange={(e) => debounceSearch(e.target.value)}
            type='text'
          />
        </InputGroup>
      </Box>
      <WaitForQuery query={query}>
        {({ notes }) => {
          console.log('notes received!', notes)

          return (
            <Grid templateColumns='repeat(2, 1fr)' gap={6}>
              {notes.length > 0 ? (
                notes.map((note) => {
                  return (
                    <NoteGridItem
                      key={`${note.id}#${note.updatedAt}`}
                      caseId={caseId}
                      item={note}
                    />
                  )
                })
              ) : (
                <Text fontStyle={'italic'}>No se agregaron notas</Text>
              )}
            </Grid>
          )
        }}
      </WaitForQuery>
    </>
  )
}

const routeMappings: {
  [K in ViewRoute['section']]?: {
    back: ViewRoute[]
  }
} = {
  'create-note': {
    back: [{ section: 'list-notes' }],
  },
  'update-note': {
    back: [{ section: 'list-notes' }],
  },
}

const ViewSwitcher = ({ caseId }: { caseId: number }) => {
  const view = useView()

  switch (view.section) {
    case 'list-notes':
      return <ListNotesView caseId={caseId} />

    case 'create-note':
      return <CreateNoteForm caseId={caseId} />

    case 'update-note':
      return <UpdateNoteForm caseId={caseId} />

    default:
      return null
  }
}

const BREADCRUMB_PORTAL_ID = 'breadcrumb-portal'

const NavigationItems = () => {
  const view = useView()

  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbLink
          onClick={() => {
            const canBack = routeMappings[view.section]?.back?.some(
              (view) => view.section === 'list-notes',
            )

            if (canBack) {
              view.onChangeView({ section: 'list-notes' })
            }
          }}
          as={chakra.div}
          to='#'
        >
          Notas
        </BreadcrumbLink>
      </BreadcrumbItem>
      {routeMappings[view.section]?.back && (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={chakra.div} to='#'>
            {translator(`notes.views.${view.section}`)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  )
}

export const CaseNotes = ({ caseId }: { caseId: number }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure()

  const { debounceSearch, search } = useDebounceSearch()

  const query = useQuery({
    queryKey: [buildCaseNotesQueryKey(caseId), search],
    queryFn: (context) => {
      const [_, search] = context.queryKey
      return CaseClient.listCaseNotes(caseId, {
        ...(search ? { search } : {}),
      })
    },
  })

  const queryClient = useQueryClientSingleton()

  const { getErrorMessage } = useError()

  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  return (
    <ViewProvider>
      <Box>
        <Modal size={['full', null, '5xl']} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader display={'flex'} justifyContent={'space-between'}>
              <NavigationItems />
              <Box h={6} id={BREADCRUMB_PORTAL_ID} />
            </ModalHeader>
            <ModalBody p={6} minH={'48'} width={'full'}>
              <ViewSwitcher caseId={caseId} />
            </ModalBody>
          </ModalContent>
        </Modal>

        <CaseNoteButton onOpen={onOpen} />
      </Box>
    </ViewProvider>
  )
}
