import { useToast } from '@chakra-ui/react'
import { IArtifactAck, IArtifactTokenized } from 'kach-clients'
import { useCallback, useState } from 'react'

export interface IOptionIdValue {
  name?: string
  label: string
  value: number
  id: number
}

export interface IFileUploadV2 extends Omit<IArtifactAck, 'tags'> {
  tags: IOptionIdValue[]
  url: string
  fetchURL: string
  name: string
  isUploading: boolean
  uploadTime: number
}

export type ArtifactsTokenizer = (
  files: Pick<File, 'name' | 'size'>[],
) => Promise<{
  artifactsTokenized: IArtifactTokenized[]
}>
export const useFileUploadV2 = (
  tokenizer: ArtifactsTokenizer,
  defaultFiles: IFileUploadV2[] = [],
) => {
  const [fileList, setFileList] = useState<IFileUploadV2[]>(defaultFiles)

  const toast = useToast()

  const batchUploadFiles = useCallback(
    (files: File[], presignedURLs: IArtifactTokenized[]) => {
      return Promise.all(
        presignedURLs.map(
          async (
            artifact,
            index,
          ): Promise<IArtifactTokenized & { url: string }> => {
            const formData = new FormData()

            Object.keys(artifact.fields).forEach((key) => {
              const value = artifact.fields[key as keyof typeof artifact.fields]
              formData.append(key, value)
            })

            formData.append('file', files[index])

            await fetch(artifact.presignedURL, {
              method: 'POST',
              body: formData,
            })

            return {
              ...artifact,
              url: artifact.fetchURL,
            }
          },
        ),
      )
    },
    [],
  )

  const onUploadFiles = useCallback(async (files: File[]) => {
    const uploadTime = new Date().getTime()
    setFileList((prev) =>
      prev.concat(
        files.map(
          (f): IFileUploadV2 =>
            ({
              isUploading: true,
              uploadTime,
              name: f.name,
            } as any),
        ),
      ),
    )

    try {
      const presignedURLs = await tokenizer(
        files.map((f) => ({
          name: f.name,
          size: f.size,
        })),
      )

      const cloudstorageFiles = await batchUploadFiles(
        files,
        presignedURLs.artifactsTokenized,
      )

      setFileList((prev) => {
        return prev.map((fileListEle) => {
          const matchIndex = files.findIndex(
            (file) =>
              file.name === fileListEle.name &&
              fileListEle.uploadTime === uploadTime,
          )

          if (matchIndex !== -1) {
            return {
              ...fileListEle,
              ...cloudstorageFiles[matchIndex],
            }
          }

          return fileListEle
        })
      })
    } catch (err) {
      toast({
        title: 'Error al subir archivos',
        status: 'error',
      })
    } finally {
      setFileList((prev) => {
        return prev.map((fileListEle) => {
          const matchIndex = files.findIndex(
            (file) =>
              file.name === fileListEle.name &&
              fileListEle.uploadTime === uploadTime,
          )

          if (matchIndex !== -1) {
            return {
              ...fileListEle,
              isUploading: false,
            }
          }

          return fileListEle
        })
      })
    }
  }, [])

  const onRemove = useCallback(
    (index: number) =>
      setFileList((prev) => prev.filter((_, i) => i !== index)),
    [],
  )

  const onUpdateFile = useCallback(
    (targetIndex: number, update: Partial<IFileUploadV2>) =>
      setFileList((prev) =>
        prev.map((file, currIndex) => {
          if (currIndex !== targetIndex) {
            return file
          }

          return {
            ...file,
            ...update,
          }
        }),
      ),
    [],
  )

  const onCleanUp = useCallback(() => {
    setFileList([])
  }, [])

  return {
    fileList,
    onCleanUp,
    onUploadFiles,
    onRemove,
    onUpdateFile,
  }
}
