import { ReportConclusionsType } from 'kach-commons';
import { CompanyTypes, IUser } from './authentication.client';
import {
  CaseEvents,
  IArtifactAck,
  IArtifactTokenized,
  INewsRequest,
} from './case.client';
import httpClient from './http-client';
import { ICreateNews } from './news.client';
import {
  IInvestigation,
  IInvestigationReport,
  IMessage,
  InvestigationTypes,
} from './notification.client';

interface AddMoreIndicatorsRequestBody {
  indicators: string;
}

interface UpdateInvestigationRequestBody {
  type: InvestigationTypes;
  reason: string;
}

export interface IInvestigationDetail extends IInvestigation {
  messages: IMessage[];
  startedBy: IUser;
  reports: IInvestigationReport[];
  events: CaseEvents[];
}

export type ICreateInvestigation = {
  type: InvestigationTypes;
} & (
  | {
      contactId: number;
      persistanceStrategy: 'existent';
    }
  | {
      persistanceStrategy: 'store';
      email: string;
    }
);

export interface IAddReport {
  artifact: IArtifactAck;
  conclusion: ReportConclusionsType;
  surrender?: boolean;
}

export class InvestigationClient {
  static async create(caseId: number, data: ICreateInvestigation) {
    const response = await httpClient.post(`/v1/investigation/${caseId}`, data);

    return response.data;
  }

  static async accept(investigationId: number): Promise<{ success: boolean }> {
    const response = await httpClient.put(
      `/v1/investigation/${investigationId}/accept`,
      {}
    );

    return response.data;
  }

  static async registerView(
    investigationId: number
  ): Promise<{ success: boolean }> {
    const response = await httpClient.post(
      `/v1/investigation/${investigationId}/view`
    );

    return response.data;
  }

  static async reject(
    investigationId: number,
    reason: string
  ): Promise<{ success: boolean }> {
    const response = await httpClient.put(
      `/v1/investigation/${investigationId}/reject`,
      {
        reason,
      }
    );

    return response.data;
  }

  static async fetchNewsRequests(investigationId: number): Promise<{
    newsRequests: INewsRequest[];
  }> {
    const response = await httpClient.get(
      `/v1/investigation/${investigationId}/news/request`
    );

    return response.data;
  }

  static async addMessage(
    investigationId: number,
    message: {
      message: string;
      artifacts: IArtifactAck[];
    }
  ): Promise<void> {
    const response = await httpClient.post(
      `/v1/investigation/${investigationId}/message`,
      message
    );

    return response.data;
  }

  static async acceptReport(reportId: number) {
    const response = await httpClient.put(
      `/v1/investigation/report/${reportId}/accept`
    );

    return response.data;
  }

  static async dismissReport(reportId: number) {
    const response = await httpClient.post(
      `/v1/investigation/report/${reportId}/dismiss`
    );

    return response.data;
  }

  static async rejectReport(reportId: number) {
    const response = await httpClient.put(
      `/v1/investigation/report/${reportId}/reject`
    );

    return response.data;
  }

  static async uploadInvoiceReport(
    investigationId: number,
    artifact: IArtifactAck
  ) {
    const response = await httpClient.post(
      `/v1/investigation/${investigationId}/invoice`,
      {
        artifact,
      }
    );

    return response.data;
  }

  static async addNews(investigationId: number, news: ICreateNews) {
    const response = await httpClient.post(
      `/v1/investigation/${investigationId}/news`,
      news
    );

    return response.data;
  }

  static async addReport(
    investigationId: number,
    data: IAddReport
  ): Promise<IInvestigation> {
    const response = await httpClient.post(
      `/v1/investigation/${investigationId}/report`,
      data
    );

    return response.data;
  }

  static async detail(
    investigationId: number,
    type: CompanyTypes,
    cookie?: string
  ): Promise<IInvestigationDetail> {
    const response = await httpClient.get(
      `/v1/investigation/${type}/${investigationId}`,
      {
        headers: {
          Cookie: cookie,
        },
      }
    );

    return response.data;
  }

  static async requestNews(
    investigationId: number,
    data: { annotation: string; artifacts: IArtifactAck[] }
  ): Promise<IInvestigationDetail> {
    const response = await httpClient.post(
      `/v1/investigation/${investigationId}/request/news`,
      data
    );

    return response.data;
  }

  static async cancel(
    investigationId: number,
    canceledReason?: string
  ): Promise<IInvestigationDetail> {
    const response = await httpClient.put(
      `/v1/investigation/${investigationId}/cancel`,
      {
        canceledReason,
      }
    );

    return response.data;
  }

  static async update(
    investigationId: number,
    data: UpdateInvestigationRequestBody
  ): Promise<IInvestigationDetail> {
    const response = await httpClient.put(
      `/v1/investigation/${investigationId}`,
      data
    );

    return response.data;
  }

  static async addMoreIndicators(
    investigationId: number,
    data: AddMoreIndicatorsRequestBody
  ): Promise<IInvestigationDetail> {
    const response = await httpClient.put(
      `/v1/investigation/${investigationId}/indicators`,
      data
    );

    return response.data;
  }

  static async tokenizeArtifacts(
    investigationId: number,
    artifacts: {
      name: string;
      size: number;
    }[]
  ): Promise<{
    artifactsTokenized: IArtifactTokenized[];
  }> {
    const response = await httpClient.post(
      `/v1/investigation/batch/artifact/token/${investigationId}`,
      {
        artifacts,
      }
    );

    return response.data;
  }
}
