import { useQuery } from '@tanstack/react-query'
import { buildIntegrationConnectionQueryKey } from '../utils/build-integration-connection-query-key'
import { IntegrationClient, IntegrationsType, IUser } from 'kach-clients'
import { useAuth } from './useAuth'
import { useQuerySingleton } from './useQuerySingleton'
import { hasPermission } from '../utils/has-permission'

export const useIntegrationConnectionCredentials = (type: IntegrationsType) => {
  const auth = useAuth()

  const queryKey = buildIntegrationConnectionQueryKey(type, auth)

  const hasPermissionToRead = () => {
    return hasPermission(auth.role.permissions, {
      resource:
        type === 'autoinspector' ? 'autoinspector-integration' : 'integration',
      action: 'update',
    })
  }

  const { isLoading, data, error } = useQuerySingleton(
    [queryKey],
    async () => {
      if (hasPermissionToRead()) {
        return IntegrationClient.fetchCredentialsUnsafe(type)
      }

      return Promise.reject({
        status: 403,
      })
    },
    {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  return {
    error,
    isLoading,
    integrationConnection: !!error ? undefined : data,
  }
}
