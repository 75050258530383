import {
  List,
  chakra,
  ListItem,
  Icon,
  Divider,
  Text,
  Box,
  BoxProps,
  Tag,
} from '@chakra-ui/react'
import { PaperClipIcon } from '@heroicons/react/24/outline'
import { INews } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { CreatedByDate } from './CreatedByDate'
import { NewLineText } from './NewLineText'
import { NewsRequestLine } from './NewsRequestLine'

export const NewsLine = (props: {
  news: INews
  withDivider?: boolean
  boxStyles?: BoxProps
}) => {
  const Body = (
    <Box fontSize='sm' py={2} experimental_spaceY={2} {...props.boxStyles}>
      <Box>
        <NewLineText textProps={{ fontStyle: 'italic', fontSize: 'xs' }}>
          {props.news.description}
        </NewLineText>
      </Box>
      {props.news?.artifacts?.length > 0 && (
        <List w='full'>
          {props.news?.artifacts.map((artifact, index) => {
            return (
              <chakra.a
                {...buildTestId(`news-artifact-${index}`)}
                key={index}
                href={artifact.url}
                target={'_blank'}
                _hover={{ textDecor: 'underline' }}
              >
                <ListItem
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  py={1}
                  fontSize='xs'
                >
                  <Box display='flex' alignItems='center' flex={1}>
                    <Icon as={PaperClipIcon} />

                    <Text noOfLines={1} wordBreak={'break-all'} ml={2} flex={1}>
                      {artifact.originalName}
                    </Text>
                  </Box>
                </ListItem>
              </chakra.a>
            )
          })}
        </List>
      )}

      <CreatedByDate
        date={props.news.createdAt}
        user={props.news.publishedBy}
        destinations={props.news.destinations}
      />
      {props.withDivider && <Divider />}
    </Box>
  )

  if (props.news.newsRequest) {
    return (
      <Box>
        <Box alignItems={'start'} display={'flex'} flexDir='column'>
          <Tag fontSize={'xs'}>Solicitud de novedad</Tag>
          <NewsRequestLine newsRequest={props.news.newsRequest} />
        </Box>

        <Box pos='relative' p={2} mt='3' ml='4'>
          <Box
            w='20px'
            pos='absolute'
            top={0}
            left={0}
            borderLeftWidth={'1px'}
            borderBottomWidth={'1px'}
            h='60%'
          />
          <Box ml='5'>
            <Tag fontSize={'xs'}>Novedad</Tag>
            {Body}
          </Box>
        </Box>
      </Box>
    )
  }

  return Body
}
