import { IArtifactAck, IArtifactTokenized, IThread } from './case.client';
import httpClient from './http-client';

export interface ICreateThread {
  message: string;
  destination: string;
  artifacts: IArtifactAck[];
}

export class ThreadClient {
  static async create(caseId: number, thread: ICreateThread) {
    const response = await httpClient.post(`/v1/case/thread/${caseId}`, thread);
    return response.data;
  }

  static async close(threadId: number) {
    const response = await httpClient.put(`/v1/case/thread/close/${threadId}`);
    return response.data;
  }

  static async detail(
    threadId: string | number,
    token?: any
  ): Promise<IThread> {
    const response = await httpClient.get(`/v1/case/thread/${threadId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async addMessage(
    threadId: string | number,
    message: Omit<ICreateThread, 'destination'>,
    token?: any
  ): Promise<IThread> {
    const response = await httpClient.post(
      `/v1/case/thread/message/${threadId}`,
      message,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async tokenizeMessageArtifacts(
    threadId: number,
    artifacts: {
      name: string;
      size: number;
    }[],
    token?: any
  ): Promise<{
    artifactsTokenized: IArtifactTokenized[];
  }> {
    const response = await httpClient.post(
      `/v1/case/thread/batch/artifact/token/${threadId}`,
      {
        artifacts,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }
}
