import React from 'react'
import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { ICaseExtended, ICompanyEnums, UpdateCaseType } from 'kach-clients'
import { useEffect, useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { useMutateUpdateCase } from '../hooks/useMutateUpdateCase'
import { buildTestId } from 'kach-commons'
import { useCompanyEnums } from '../hooks/useCompanyEnums'
import { hasPermission } from '../utils/has-permission'

export const COMPANY_ENUMS_QUERY_KEY = 'enums'

export const CaseEnumSelect: React.FC<{
  enumKey: keyof Pick<
    ICompanyEnums,
    'externalStatus' | 'instance' | 'sinisterType' | 'reason'
  >
  caseExtended: ICaseExtended
  onUpdateCase: (caseUpdated: UpdateCaseType) => void
  readonly?: boolean
  additonalValidations?: boolean
}> = ({
  caseExtended,
  readonly,
  enumKey,
  additonalValidations,
  onUpdateCase,
}) => {
  const auth = useAuth()

  const [mutate] = useMutateUpdateCase({
    caseId: caseExtended.id,
    updateFn: onUpdateCase,
  })

  const caseEnumKey = additonalValidations
    ? caseExtended.caseRequest[enumKey]?.name
    : caseExtended[enumKey]?.name

  const { data } = useCompanyEnums()

  const unSelected = 'Sin definir'

  const [selected, setSelected] = useState(caseEnumKey || unSelected)

  useEffect(() => {
    const optSelected = caseEnumKey || unSelected

    if (optSelected) {
      setSelected(optSelected)
    }
  }, [])

  const isAdmin = hasPermission(auth.role.permissions, {
    action: 'update_all',
    resource: 'case',
  })

  const isCurrentUserIncluded = caseExtended.users.some(
    (caseUser) => caseUser.user.id === auth.id,
  )

  const DisabledText = ({ text }: { text: string }) => {
    return (
      <Box>
        <Text display='block' noOfLines={1}>
          {text}
        </Text>
      </Box>
    )
  }

  if (additonalValidations) {
    if (readonly && (!isAdmin || !isCurrentUserIncluded)) {
      return <DisabledText text={selected} />
    }
  } else {
    if (readonly || !isCurrentUserIncluded) {
      return <DisabledText text={selected} />
    }
  }

  const renamedData = {
    ...data,
    sinisterType: data?.sinisterTypes,
    reason: data?.caseReasons,
  }

  delete renamedData.sinisterTypes
  delete renamedData.caseReasons

  return (
    <Menu>
      <MenuButton
        {...buildTestId(`case-enum-${enumKey}-menu-btn`)}
        size='xs'
        as={Button}
        rightIcon={<Icon as={ChevronDownIcon} />}
      >
        {selected}
      </MenuButton>
      <MenuList {...buildTestId(`case-enum-${enumKey}`)}>
        {renamedData?.[enumKey]?.map((enumOpt, index) => {
          return (
            <MenuItem
              key={index}
              {...buildTestId(`menu-item`)}
              onClick={() => {
                setSelected(enumOpt.name)
                mutate.mutate({
                  [enumKey]: {
                    name: enumOpt.name,
                  },
                })
              }}
            >
              {enumOpt.name}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
