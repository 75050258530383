import { IUser } from './authentication.client';
import httpClient from './http-client';

export type JobStatus = 'pending' | 'completed' | 'failed' | 'running';

type JobTypes =
  | 'user_availability'
  | 'user_unavailability'
  | 'case_crawler'
  | 'statement';

export type JobOperationTypes =
  | 'crawler_case_artifacts'
  | 'crawler_case_report_inspections'
  | 'crawler_sinister_documents'
  | 'crawler_expert_examinations'
  | 'crawler_claims';

export interface IJobProgress {
  operation: JobOperationTypes;
  doneAt: Date;
}

export interface IJob {
  completedAt: string;
  startedAt: string;
  progress: IJobProgress[];
  id: number;
  type: JobTypes;
  schedule?: string;
  executeAt?: string;
  failedReason?: string;
  status: JobStatus;
  createdBy: IUser;
  metadata: object;
}

export class JobClient {
  static async createUnavailabilityJob(data: { from: string; to: string }) {
    const response = await httpClient.post('/v1/job/unavailability', data);

    return response.data;
  }

  static async createOwnUnavailabilityJob(data: { from: string; to: string }) {
    const response = await httpClient.post('/v1/job/unavailability/own', data);

    return response.data;
  }

  static async delete(jobId: number) {
    const response = await httpClient.delete(`/v1/job/${jobId}`);

    return response.data;
  }

  static async listPending(cookie?: string): Promise<{ jobs: IJob[] }> {
    const response = await httpClient.get('/v1/job/pending', {
      headers: {
        Cookie: cookie,
      },
    });

    return response.data;
  }

  static async cancelUnavailabilityJob(jobId: number) {
    const response = await httpClient.delete(`/v1/job/unavailability/${jobId}`);

    return response.data;
  }
}
