import React, { useId, useMemo, useState } from 'react'
import {
  Box,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { buildTestId } from 'kach-commons'
import { SlDocs } from 'react-icons/sl'
import { useQuery } from '@tanstack/react-query'
import { Column, Cell } from 'react-table'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import { FetchErrorMessage } from './FetchErrorMessage'
import { CaseClient, IArtifact, ICaseExtended, IClaim } from 'kach-clients'
import { CustomTable } from './CustomTable'
import { CaseAccordion } from './CaseAccordion'
import { ArtifactDisplayer } from './ArtifactDisplayer'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { View } from './CaseArtifacts'
import { FilesDataTable } from './FilesDataTable'
import { MdGridOn } from 'react-icons/md'
import { FaList } from 'react-icons/fa'

export const THREE_MINUTES_MS = 180000

const ClaimsArtifactsViewSwitcher = ({
  view,
  artifacts,
  caseId,
}: {
  view: View
  artifacts: IArtifact[]
  caseId: number
}) => {
  switch (view) {
    case 'list':
      return (
        <Box display={'flex'} flexDir='column' experimental_spaceY={2}>
          {artifacts.map((artifact, index) => (
            <ArtifactDisplayer
              mode='dark'
              key={index}
              artifact={artifact}
              view={view}
            />
          ))}
        </Box>
      )

    default:
      return (
        <SimpleGrid
          columns={view === 'grid' ? [2, null, 3, null, null, 4] : 1}
          spacing={2}
        >
          {artifacts.map((artifact, index) => (
            <ArtifactDisplayer
              mode='dark'
              key={index}
              artifact={artifact}
              view={view}
            />
          ))}
        </SimpleGrid>
      )
  }
}

const ClaimDocumentsBody = (props: { claim: IClaim }) => {
  const [view, setView] = useState<View>('grid')
  const { isLoading, error, data } = useQuerySingleton(
    [`claim-${props.claim.id}-docs`],
    () => CaseClient.listClaimDocs(props.claim.case_id, props.claim.id),
    {
      refetchInterval: THREE_MINUTES_MS,
      refetchOnMount: true,
      retry: 1,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  if (isLoading) {
    return <Spinner />
  }

  if (error || !data?.docs) {
    return <FetchErrorMessage />
  }

  return (
    <Box display={'flex'} flexDir='column' experimental_spaceY={'2'}>
      {data?.docs.length ? (
        <Box
          {...buildTestId('case-artifacts-list')}
          display='flex'
          flexDir='column'
          experimental_spaceY={2}
          p={4}
          position={'relative'}
        >
          <ButtonGroup size='sm' isAttached variant='outline' alignSelf={'end'}>
            <IconButton
              isDisabled={view === 'grid'}
              aria-label='grid-view'
              icon={<MdGridOn />}
              size={'xs'}
              onClick={() => setView('grid')}
            />
            <IconButton
              isDisabled={view === 'list'}
              aria-label='list-view'
              icon={<FaList />}
              size={'xs'}
              onClick={() => setView('list')}
            />
          </ButtonGroup>

          <ClaimsArtifactsViewSwitcher
            artifacts={data?.docs}
            caseId={props.claim.case_id}
            view={view}
          />
        </Box>
      ) : (
        <Text>No hay documentos</Text>
      )}
    </Box>
  )
}

const ClaimDocuments = (props: { claim: IClaim }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Tooltip label='Ver documentos'>
        <IconButton
          onClick={onOpen}
          variant={'ghost'}
          aria-label='Ver documentos'
          icon={<SlDocs />}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {isOpen && (
            <>
              <ModalHeader>Documentos del reclamo</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ClaimDocumentsBody claim={props.claim} />
              </ModalBody>

              <ModalFooter>
                <Button variant='ghost' mr={3} onClick={onClose}>
                  Cerrar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

const Table = (props: { claims: IClaim[] }) => {
  const columns = useMemo<Column<IClaim>[]>(
    () => [
      {
        id: 'actions',
        //@ts-ignore
        Cell: (cell: Cell<IClaim>) => {
          return <ClaimDocuments claim={cell.row.original} />
        },
      },
      {
        Header: 'Reclamo',
        accessor: 'gatewayId',
      },
      {
        Header: 'Pedido',
        accessor: 'request',
      },
      {
        Header: 'Nombre',
        accessor: 'customerFirstname',
      },
      {
        Header: 'Apellido',
        accessor: 'customerLastname',
      },
      {
        Header: 'Patente',
        accessor: 'licensePlate',
      },
    ],
    [],
  )

  return (
    <CustomTable
      orientation='left'
      noHover
      columns={columns}
      data={props.claims}
    />
  )
}

const Body = (props: { case: ICaseExtended }) => {
  const { isLoading, data, error } = useQuerySingleton(
    [`case-${props.case.id}-claims-docs`],
    () => CaseClient.listClaims(props.case.id),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 180000,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 1,
    },
  )

  if (isLoading) {
    return (
      <Box
        h='full'
        display={'flex'}
        alignItems={'center'}
        justifyContent='center'
      >
        <Spinner />
      </Box>
    )
  }

  if (error) {
    return (
      <Box>
        <Text>Algo no ha salido como lo esperado</Text>
      </Box>
    )
  }

  return (
    <Box>
      <Table claims={data?.claims} />
    </Box>
  )
}

export const CaseClaims = (props: { case: ICaseExtended }) => {
  return (
    <Box {...buildTestId('case-inspections-container')}>
      <CaseAccordion
        withoutEmptyState
        label={{
          plural: 'Reclamos',
          singular: 'Reclamos',
        }}
      >
        {({ isExpanded }) => {
          if (isExpanded) {
            return <Body case={props.case} />
          }

          return null
        }}
      </CaseAccordion>
    </Box>
  )
}
