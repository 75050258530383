import {
  Box,
  chakra,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/solid'
import { useToggle } from 'ahooks'
import { format } from 'date-fns'
import { IInvestigation, IInvestigationDetail } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { parseUrl } from 'next/dist/shared/lib/router/utils/parse-url'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { registerInvestgationView } from '../hooks/useRegisterInvestigationView'
import { FocusParamsSchema } from '../schemas/focus-params.schema'
import { translator } from '../utils/translator'
import CircleIcon from './CircleIcon'
import InvestigationDetail from './InvestigationDetail'

export interface IDynamicObject {
  [k: string]: any
}

export const InvestigationLine = (props: {
  index: number
  withoutCreate?: boolean
  investigation: IInvestigation
  caseId?: number
  query: IDynamicObject
  renderPrevSections?: React.FC<{ investigation: IInvestigationDetail }>
}) => {
  const [open, { toggle, set }] = useToggle()
  const router = useRouter()

  const unnecessaryKeys = [
    'focus_object',
    'focus_id',
    'nested_object',
    'nested_id',
  ]

  const cleanUrl = (url: string): string => {
    const parsedUrl = parseUrl(url)
    let finalUrl = parsedUrl.pathname

    if (parsedUrl.query) {
      const cleanedParams = Object.entries(parsedUrl.query)
        .filter(([key]) => !unnecessaryKeys.includes(key))
        .reduce((acc, [key, value]) => {
          return { ...acc, [key]: value }
        }, {})

      if (Object.keys(cleanedParams).length > 0) {
        finalUrl += '?' + new URLSearchParams(cleanedParams).toString()
      }
    }

    return finalUrl
  }

  const handleClose = () => {
    const { asPath } = router
    const cleanedUrl = cleanUrl(asPath)

    if (asPath !== cleanedUrl) {
      router.replace(router.pathname, cleanedUrl, { shallow: true })
    }

    toggle()
  }

  const handleViewDetail = () => {
    toggle()
    registerInvestgationView(props.investigation.id)
  }

  useEffect(() => {
    const focusObjectParam = FocusParamsSchema.safeParse(props.query)

    if (
      focusObjectParam.success &&
      focusObjectParam.data.focus_object === 'investigation' &&
      Number(focusObjectParam.data.focus_id) === props.investigation.id
    ) {
      set(true)
    }
  }, [props.query])

  return (
    <>
      <Modal size={['full', null, '5xl']} isOpen={open} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <InvestigationDetail
              renderPrevSections={props.renderPrevSections}
              query={props.query}
              withoutCreate={props.withoutCreate}
              investigation={props.investigation}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <ListItem
        {...buildTestId(`investigation-item-${props.index}`)}
        onClick={handleViewDetail}
        cursor='pointer'
        borderRadius='md'
        _hover={{ bg: 'gray.100' }}
      >
        <Box>
          <Box display='flex' alignItems='center' p={2}>
            <Box display='flex' w='full' alignItems='center'>
              <Box display='flex' justifyContent='space-between' w='full'>
                <Box>
                  <Box display='flex' alignItems='center'>
                    <Icon
                      as={MagnifyingGlassCircleIcon}
                      h='8'
                      w='8'
                      color='gray.500'
                      mr={2}
                    />
                    <Box display='flex' flexDir='column'>
                      <Text {...buildTestId(`lab-name`)} noOfLines={1}>
                        {props.investigation.contact?.name}
                      </Text>
                      <chakra.span
                        fontStyle='italic'
                        {...buildTestId(`lab-email`)}
                      >
                        {props.investigation.contact?.email}
                      </chakra.span>
                    </Box>
                  </Box>
                  <Text
                    fontSize='xs'
                    color='gray.500'
                    className='text-sm text-gray-900'
                    ml={10}
                  >
                    Iniciado el{' '}
                    <chakra.time>
                      {format(new Date(props.investigation.createdAt), 'Pp')}
                    </chakra.time>
                  </Text>
                </Box>
                <Box display='flex' alignItems='start'>
                  <Text display='flex' alignItems='center'>
                    <CircleIcon
                      color={
                        props.investigation.status !== 'closed'
                          ? 'green.400'
                          : 'red.400'
                      }
                      mr={2}
                    />
                    <chakra.span fontSize='xs'>
                      {translator(props.investigation.status)}
                    </chakra.span>
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ListItem>
    </>
  )
}
