import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { AuthorizationClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../hooks/useAuth'
import { hasPermission } from '../utils/has-permission'
import {
  ILocalPermission,
  IRoleSaved,
  RolePermissions,
} from './RolePermissions'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { useError } from '../hooks/useError'

export const CreateRol = (props: {
  permissionsToRender: ILocalPermission[]
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const queryClient = useQueryClientSingleton()

  const auth = useAuth()

  const toast = useToast()

  const error = useError()

  const onCreateRol = useCallback(async (rol: IRoleSaved) => {
    toast.promise(AuthorizationClient.createRole(rol), {
      loading: {
        title: 'Creando rol...',
      },
      success: () => {
        onClose()
        queryClient.invalidateQueries(['roles'])
        return {
          title: 'Rol creado correctamente',
        }
      },
      error: (err) => {
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [])

  return (
    <div>
      {hasPermission(auth.role.permissions, {
        action: 'create',
        resource: 'role',
      }) && (
        <Button
          {...buildTestId('create-role-btn')}
          size='sm'
          variant='primary'
          onClick={onOpen}
        >
          Crear rol
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crear rol</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RolePermissions
              permissionsToRender={props.permissionsToRender}
              onRoleSave={onCreateRol}
              buttonLabel='Crear rol'
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}
