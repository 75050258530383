import React from 'react'
import {
  Box,
  BoxProps,
  chakra,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import get from 'lodash.get'
import {
  Controller,
  FieldPath,
  useForm,
  UseFormRegister,
} from 'react-hook-form'
import { IContactPrice } from 'kach-clients'
import {
  ICreateContactPriceSchema,
  priceUnits,
  CreateContactPriceSchema,
  investigationTypes,
} from '../schemas/create-contact-price.schema'
import { translator } from '../utils/translator'
import { useCompanyEnums } from '../hooks/useCompanyEnums'
import {
  addMinutes,
  endOfDay,
  format,
  formatISO,
  parseISO,
  startOfDay,
  subMinutes,
  subSeconds,
} from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { DEFAULT_TIMEZONE } from 'kach-constants'

const AmountUnitField = (props: {
  register: UseFormRegister<ICreateContactPriceSchema>
  errors: any
  path: FieldPath<ICreateContactPriceSchema>
  translation?: string
}) => {
  const amountPath =
    `${props.path}.amount` as FieldPath<ICreateContactPriceSchema>

  const unitPath = `${props.path}.unit` as FieldPath<ICreateContactPriceSchema>

  const translationTitle = props.translation
    ? props.translation
    : translator(props.path)

  return (
    <Box
      display={'flex'}
      flexDir='row'
      alignItems={'end'}
      experimental_spaceY={2}
    >
      <FormControl>
        <FormLabel>{translationTitle}</FormLabel>
        <Input min={0} type='number' {...props.register(amountPath)} />
        {get(props.errors, amountPath) && (
          <FormErrorMessage>
            {get(props.errors, amountPath).message}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl>
        <Select {...props.register(unitPath)}>
          <option selected disabled value=''>
            Selecciona una opción
          </option>
          {priceUnits.map((unit) => {
            return <option value={unit}>{translator(unit)}</option>
          })}
        </Select>
        {get(props.errors, unitPath) && (
          <FormErrorMessage>
            {get(props.errors, unitPath).message}
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  )
}

export const ContactPriceForm = (props: {
  defaultValues?: IContactPrice | ICreateContactPriceSchema
  formId: string
  onSubmit: (data: ICreateContactPriceSchema) => void
  isLoading: boolean
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
  } = useForm<ICreateContactPriceSchema>({
    resolver: zodResolver(CreateContactPriceSchema),
    defaultValues: props.defaultValues
      ? {
          ...props.defaultValues,
          sinisterTypeId: props.defaultValues['sinisterType']?.id,
        }
      : {},
  })

  console.log(errors)

  const { data, isLoading } = useCompanyEnums()

  const sectionProps: BoxProps = {
    display: 'flex',
    flexDirection: 'column',
    experimental_spaceY: 5,
  }

  return (
    <chakra.form
      id={props.formId}
      onSubmit={handleSubmit(props.onSubmit)}
      experimental_spaceY={4}
    >
      <Grid templateColumns='repeat(2, 1fr)' gap={6}>
        <GridItem>
          <Controller
            control={control}
            name='validFrom'
            render={({ field, fieldState }) => {
              return (
                <FormControl isInvalid={!!fieldState.error} isRequired>
                  <FormLabel>Valido desde</FormLabel>
                  <Input
                    ref={field.ref}
                    type='date'
                    onChange={(e) => {
                      field.onChange(
                        addMinutes(
                          e.target.valueAsDate!,
                          e.target.valueAsDate!.getTimezoneOffset(),
                        ),
                      )
                    }}
                    defaultValue={
                      props.defaultValues?.validFrom
                        ? format(
                            new Date(props.defaultValues.validFrom),
                            'yyyy-MM-dd',
                          )
                        : ''
                    }
                  />
                  {fieldState.error && (
                    <FormErrorMessage>
                      {fieldState.error.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              )
            }}
          />
        </GridItem>
        <GridItem>
          <Controller
            control={control}
            name='validTo'
            render={({ field, fieldState }) => {
              return (
                <FormControl isInvalid={!!fieldState.error}>
                  <FormLabel>Valido hasta</FormLabel>
                  <Input
                    ref={field.ref}
                    type='date'
                    onChange={(e) => {
                      const value = subSeconds(
                        endOfDay(
                          addMinutes(
                            e.target.valueAsDate!,
                            e.target.valueAsDate!.getTimezoneOffset(),
                          ),
                        ),
                        1,
                      )

                      field.onChange(value)
                    }}
                    defaultValue={
                      props.defaultValues?.validTo
                        ? format(
                            new Date(props.defaultValues.validTo),
                            'yyyy-MM-dd',
                          )
                        : ''
                    }
                  />
                  {fieldState.error && (
                    <FormErrorMessage>
                      {fieldState.error.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              )
            }}
          />
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors.investigationType} isRequired>
            <FormLabel>Tipo de investigación</FormLabel>

            <Select {...register('investigationType')}>
              <option value='' selected disabled hidden>
                Selecciona una opción
              </option>
              {investigationTypes.map((type) => {
                return <option value={type}>{translator(type)}</option>
              })}
            </Select>
            {errors.investigationType && (
              <FormErrorMessage>
                {errors.investigationType.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Tipo de siniestro</FormLabel>
            {isLoading ? (
              <Spinner />
            ) : (
              <Select {...register('sinisterTypeId')}>
                <option value='' selected disabled hidden>
                  Selecciona una opción
                </option>
                {data.sinisterTypes.map((sinisterType) => {
                  return (
                    <option value={sinisterType.id}>{sinisterType.name}</option>
                  )
                })}
              </Select>
            )}
          </FormControl>
        </GridItem>
      </Grid>

      <Divider />

      <Box {...sectionProps}>
        <Text textTransform={'uppercase'} color='gray.500' fontSize={'lg'}>
          Resultados
        </Text>
        <Box>
          <AmountUnitField
            errors={errors}
            register={register}
            path='surrender'
            translation='Desistido(positivo)'
          />
        </Box>

        <Box>
          <AmountUnitField
            errors={errors}
            register={register}
            path='negative'
          />
        </Box>

        <Box>
          <AmountUnitField
            errors={errors}
            register={register}
            path='rejected'
          />
        </Box>
      </Box>

      <Divider />

      <Box {...sectionProps}>
        <Text textTransform={'uppercase'} color='gray.500' fontSize={'lg'}>
          Franja de desisto
        </Text>
        <FormControl>
          <FormLabel>
            Dias laborales para el premio por rápido desisto
          </FormLabel>
          <Input min={0} type='number' {...register('surrenderPrizeDays')} />
          {typeof errors?.surrenderPrizeDays?.message === 'string' &&
            !!errors.surrenderPrizeDays.message && (
              <FormErrorMessage>
                {errors.surrenderPrizeDays.message}
              </FormErrorMessage>
            )}
        </FormControl>

        <AmountUnitField
          errors={errors}
          register={register}
          path='surrenderPrize'
        />
      </Box>

      <Divider />

      <Box {...sectionProps}>
        <Text textTransform={'uppercase'} color='gray.500' fontSize={'lg'}>
          Desplazamiento
        </Text>
        <AmountUnitField errors={errors} register={register} path='km' />
        <Box>
          <FormControl>
            <FormLabel>KM Base</FormLabel>
            <Input min={0} type='number' {...register('kmLowerLimit')} />
            {typeof errors?.kmLowerLimit?.message === 'string' &&
              !!errors.kmLowerLimit.message && (
                <FormErrorMessage>
                  {errors.kmLowerLimit.message}
                </FormErrorMessage>
              )}
          </FormControl>
        </Box>
      </Box>

      <Divider />

      <Box {...sectionProps}>
        <Text textTransform={'uppercase'} color='gray.500' fontSize={'lg'}>
          Puntos y topes
        </Text>
        <Box>
          <FormControl>
            <FormLabel>Incapacidad</FormLabel>
            <Input min={0} type='number' {...register('inability')} />
            {typeof errors?.inability?.message === 'string' &&
              !!errors.inability?.message && (
                <FormErrorMessage>{errors.inability.message}</FormErrorMessage>
              )}
          </FormControl>
        </Box>

        <Box>
          <FormControl>
            <FormLabel>Tope</FormLabel>
            <Input min={0} type='number' {...register('upperLimit')} />
            {typeof errors?.upperLimit?.message === 'string' &&
              !!errors.upperLimit.message && (
                <FormErrorMessage>{errors.upperLimit.message}</FormErrorMessage>
              )}
          </FormControl>
        </Box>
      </Box>
    </chakra.form>
  )
}
