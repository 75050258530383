import {
  Box,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { TagClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useQuery } from '@tanstack/react-query'
import { chakraComponents, CreatableSelect } from 'chakra-react-select'
import React, { useCallback, useState } from 'react'
import { AiFillTag } from 'react-icons/ai'
import { useError } from '../hooks/useError'
import { IFileUploadV2, IOptionIdValue } from '../hooks/useFileUploadV2'
import { useQuerySingleton } from '../hooks/useQuerySingleton'

export const ManageArtifactTags = (props: {
  file: IFileUploadV2
  onUpdateFile?: (update: Partial<IFileUploadV2>) => void
}) => {
  const { data, isLoading, error, refetch } = useQuerySingleton(
    ['tags'],
    () => TagClient.list().then((res) => res.tags),
    {
      refetchOnMount: true,
      retry: 1,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  const [isLoadingCreation, setIsLoadingCreation] = useState(false)

  const { handleError } = useError()

  const onCreateTag = useCallback(
    (tag: string) => {
      setIsLoadingCreation(true)
      TagClient.batchCreate([{ name: tag }])
        .then((res) => {
          refetch()

          const [tagCreated] = res.tags

          props.onUpdateFile({
            tags: (props.file?.tags || []).concat([
              {
                label: tagCreated.name,
                value: tagCreated.id,
                id: tagCreated.id,
              },
            ]),
          })
        })
        .finally(() => setIsLoadingCreation(false))
        .catch(handleError)
    },
    [props.file],
  )

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return <Text>Ha ocurrido un error al recuperar las etiquetas</Text>
  }

  return (
    <Popover>
      <Tooltip label='Etiquetas'>
        <Box>
          <PopoverTrigger>
            <IconButton
              padding={0}
              size='xs'
              variant='ghost'
              aria-label='Remove file'
              icon={<AiFillTag />}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Tags</PopoverHeader>
        <PopoverBody>
          <CreatableSelect<IOptionIdValue, true>
            isMulti
            inputId='contact-select-search'
            useBasicStyles
            value={props.file.tags}
            isLoading={isLoadingCreation}
            onChange={(tags, actionMeta) => {
              if (actionMeta.action === 'create-option') {
                onCreateTag(actionMeta.option.value?.toString())
                return
              }

              props.onUpdateFile({
                tags: tags.map((tag) => ({
                  label: tag.label,
                  value: tag.value,
                  id: tag.value,
                })),
              })
            }}
            formatCreateLabel={(v) => v}
            noOptionsMessage={() => 'No se encontraron opciones'}
            components={{
              Placeholder: (props, data) => {
                return (
                  <chakraComponents.Placeholder {...props}>
                    <Text>Busca una etiqueta</Text>
                  </chakraComponents.Placeholder>
                )
              },
              Option: (props, data) => {
                //@ts-ignore
                if (!props.data.__isNew__) {
                  return (
                    <chakraComponents.Option {...props}>
                      <Box {...buildTestId('contact-select-search-option')}>
                        <Tag
                          {...buildTestId('contact-select-search-option-name')}
                        >
                          {
                            //@ts-ignore
                            props.data.label
                          }
                        </Tag>
                      </Box>
                    </chakraComponents.Option>
                  )
                }

                return (
                  <chakraComponents.Option {...props}>
                    <Box
                      display={'flex'}
                      flexDir='row'
                      experimental_spaceX={'2'}
                      alignItems={'center'}
                    >
                      <Text>Crear nueva etiqueta</Text>
                      <Tag>{props?.data.label}</Tag>
                    </Box>
                  </chakraComponents.Option>
                )
              },
            }}
            loadingMessage={() => 'Buscando etiquetas'}
            name='Etiquetas'
            placeholder='Busca una etiqueta'
            options={data?.map((tag) => ({
              label: tag.name,
              value: tag.id,
              id: tag.id,
            }))}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
