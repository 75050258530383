import React from 'react'
import { Button, useToast } from '@chakra-ui/react'
import { IInvestigation, InvestigationClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { INVESTIGATIONS_QUERY_KEY } from '../constants/query-keys'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'
import { hasPermission } from '../utils/has-permission'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const AcceptInvestigationBtn = (props: {
  investigation: IInvestigation
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const auth = useAuth()

  const toast = useToast()

  const onAccept = useCallback(() => {
    setIsLoading(true)

    toast.promise(InvestigationClient.accept(props.investigation.id), {
      error: (err) => {
        title: error.getErrorMessage(err)
      },
      loading: {
        title: 'Aceptando investigación',
      },
      success: (res) => {
        queryClient.invalidateQueries([
          buildInvestigationQueryKey(props.investigation.id),
        ])

        queryClient.invalidateQueries([INVESTIGATIONS_QUERY_KEY])

        return {
          title: 'Investigación aceptada correctamente',
        }
      },
    })

    setIsLoading(false)
  }, [props.investigation.id])

  const canTakeInvestigation = hasPermission(auth.role?.permissions, {
    action: 'take',
    resource: 'investigation',
  })

  return (
    <Button
      {...buildTestId('accept-investigation-btn')}
      isDisabled={!canTakeInvestigation}
      disabled={!canTakeInvestigation}
      variant={'primary'}
      onClick={onAccept}
      isLoading={isLoading}
      type='button'
      className='inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100'
    >
      Aceptar investigación
    </Button>
  )
}
