import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { CaseClient, MailClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useError } from '../hooks/useError'
import { MAX_FILE_SIZE_IN_MB } from '../hooks/useFileUpload'
import { useFileUploadV2 } from '../hooks/useFileUploadV2'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import {
  AddMailArtifactSchema,
  IAddMailArtifactSchema,
} from '../schemas/add-mail-artifact.schema'
import {
  CreateMailSchema,
  ICreateMailSchema,
} from '../schemas/create-mail.schema'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { buildMailQueryKey } from '../utils/build-mail-query-key'
import { remapFile } from '../utils/remap-file-list'
import { FileUploadV2 } from './FileUploadV2'

export default function AddMailArtifactForm(props: {
  caseId: number
  mailId: number
  onClose: () => void
}) {
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<IAddMailArtifactSchema>({
    resolver: zodResolver(AddMailArtifactSchema),
  })

  const { fileList, onUploadFiles, onRemove, onUpdateFile } = useFileUploadV2(
    (files) => CaseClient.tokenizeArtifacts(props.caseId, files),
  )

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const isMailFileNotUploaded = useMemo(
    () => !fileList || !Array.isArray(fileList) || !fileList.length,
    [fileList],
  )

  const toast = useToast()

  const onSubmit = useCallback(
    (data: ICreateMailSchema) => {
      if (isMailFileNotUploaded) {
        setError('artifact', {
          message: 'Archivo requerido!',
        })
        return
      }
      const [artifact] = fileList

      setIsLoading(true)

      toast.promise(
        MailClient.addArtifact(props.mailId, {
          artifact: remapFile(artifact),
        }),
        {
          error: (err) => {
            title: error.getErrorMessage(err)
          },
          loading: {
            title: 'Subiendo carta',
          },
          success: (res) => {
            queryClient.invalidateQueries([buildMailQueryKey(props.mailId)])
            props.onClose()
            return {
              title: 'Carta subida correctamente',
            }
          },
        },
      )

      setIsLoading(false)
    },
    [fileList, isMailFileNotUploaded],
  )

  useEffect(() => {
    if (!isMailFileNotUploaded) {
      clearErrors('artifact')
    }
  }, [isMailFileNotUploaded])

  console.log('errors', errors.artifact)

  return (
    <chakra.form
      onSubmit={handleSubmit(onSubmit)}
      experimental_spaceY={4}
      noValidate
    >
      <Box experimental_spaceY={8}>
        <Box experimental_spaceY={6}>
          <Box experimental_spaceY={4}>
            <FormControl isRequired isInvalid={!!errors.artifact}>
              <FormLabel>Carta</FormLabel>
              <FileUploadV2
                onUpdateFile={onUpdateFile}
                fileList={fileList}
                onRemove={onRemove}
                inputId='artifact-mail'
                limit={1}
                maxFileSizeMB={MAX_FILE_SIZE_IN_MB}
                onUpload={onUploadFiles}
              />
              {errors.artifact && (
                //@ts-ignore
                <FormErrorMessage>{errors.artifact?.message}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box display='flex' justifyContent='end' experimental_spaceX={2}>
          <Button isDisabled={isLoading} onClick={props.onClose} size='sm'>
            Cancelar
          </Button>
          <Button
            {...buildTestId('add-mail-artifact-button')}
            isLoading={isLoading}
            type='submit'
            colorScheme='brand'
            size='sm'
          >
            Subir carta
          </Button>
        </Box>
      </Box>
    </chakra.form>
  )
}
