import { Box } from '@chakra-ui/react'
import { ICaseInspection } from 'kach-clients'
import React, { useCallback } from 'react'
import { useExternalDialogChanges } from '../hooks/useExternalDialogChanges'

export const DASHBOARD_URL =
  process.env.NEXT_PUBLIC_AUTOINSPECTOR_DASHBOARD_APP_BASE_URL

export const ModalAutoinspectorInspectionDetail = (props: {
  token: string
  onClose: () => void
  isOpen: boolean
  gatewayInspectionType: string
  gatewayId: string
}) => {
  const onClose = useCallback(() => {
    props.onClose()
  }, [])

  useExternalDialogChanges({
    elementId: 'ai-inspection-detail',
    customBindings: [
      {
        event: 'ai:modal-closed',
        listener: onClose,
      },
    ],
  })

  return (
    <Box zIndex={100}>
      {
        //@ts-ignore
        <ai-detail-inspection
          dashboard-url={DASHBOARD_URL}
          is-open={props.isOpen}
          inspection-type={props.gatewayInspectionType}
          inspection-id={props.gatewayId}
          accesstoken={props.token}
        />
      }
    </Box>
  )
}
