import { Button, MenuItem, useDisclosure, useToast } from '@chakra-ui/react'
import { IMailAttempt, MailClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useRef, useState } from 'react'
import { useError } from '../hooks/useError'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { buildMailQueryKey } from '../utils/build-mail-query-key'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

interface Props {
  mailId: number
  caseId: number
  attempt: IMailAttempt
  isCaseClosed: boolean
}

const Body = (props: Props & { onClose: () => void }) => {
  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onDelete = useCallback(() => {
    setIsLoading(true)

    toast.promise(MailClient.removeAttempt(props.attempt.id), {
      loading: {
        title: 'Eliminando intento...',
      },
      success: () => {
        queryClient.invalidateQueries([buildMailQueryKey(props.mailId)])
        queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
        props.onClose()
        return {
          title: 'Intento eliminado',
        }
      },
      error: (err) => {
        title: error.getErrorMessage(err)
      },
    })

    setIsLoading(false)
  }, [props.attempt.id])

  const cancelRef = useRef()

  return (
    <>
      <AlertDialogHeader fontSize='lg' fontWeight='bold'>
        Eliminar intento
      </AlertDialogHeader>

      <AlertDialogBody>
        ¿Estas seguro? Luego no podrás revertir la acción.
      </AlertDialogBody>

      <AlertDialogFooter>
        <Button isDisabled={isLoading} ref={cancelRef} onClick={props.onClose}>
          Cancelar
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          colorScheme='red'
          onClick={onDelete}
          ml={3}
        >
          Eliminar
        </Button>
      </AlertDialogFooter>
    </>
  )
}

export const RemoveMailAttempt = (props: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <MenuItem isDisabled={props.isCaseClosed} onClick={onOpen}>
        Eliminar intento
      </MenuItem>
      {
        //@ts-ignore
        <AlertDialog size='xl' isOpen={isOpen} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <Body {...props} onClose={onClose} />
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      }
    </>
  )
}
