import { Box, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';

export const Admonition: React.FC<
  React.PropsWithChildren<{
    action?: React.ReactNode;
  }>
> = ({ action, children }) => {
  return (
    <Box
      display={'flex'}
      flexDir="row"
      alignItems={'center'}
      justifyContent={'space-between'}
      mt="-20px"
      mx={'-15px'}
      p={5}
      bg="primary"
      color="white"
    >
      <Text>{children}</Text>
      {action}
    </Box>
  );
};
