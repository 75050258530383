import { ICaseExtended } from 'kach-clients'
import { translator } from './translator'

export const buildCaseResultText = (
  caseInput: Pick<ICaseExtended, 'result' | 'surrenderActor'>,
) => {
  return `${translator(caseInput.result) || 'Sin definir'} ${
    caseInput.result === 'positive' && caseInput.surrenderActor
      ? ` / ${caseInput.surrenderActor.name}`
      : ''
  }`
}
