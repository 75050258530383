import {
  Box,
  Button,
  chakra,
  FormControl,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import { InvitationClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useEffect, useState } from 'react'
import { get, useFieldArray, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useRoles } from '../hooks/useRoles'
import { IInvitations, InvitationsSchema } from '../schemas/invitation.schema'
import { hasPermission } from '../utils/has-permission'
import { ILocalPermission, RolePermissions } from './RolePermissions'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { EmailListEditable } from './EmailListEditable'

export const InviteForm = (props: {
  permissionsToRender: ILocalPermission[]
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isInviting, setIsInviting] = useState(false)

  const {
    setValue,
    register,
    formState: { errors },
    watch,
    control,
    handleSubmit,
    reset,
  } = useForm<IInvitations>({
    defaultValues: {
      invitations: [{ email: '' }],
    },
    resolver: zodResolver(InvitationsSchema),
  })

  const { append, remove } = useFieldArray({
    control,
    name: 'invitations',
  })

  const queryClient = useQueryClientSingleton()

  const { data, isLoading } = useRoles()

  const fields = watch()

  const role = data?.find((role) => role.id === fields.roleId)

  const error = useError()

  const auth = useAuth()

  const toast = useToast()

  const onSubmit = useCallback((data: Required<IInvitations>) => {
    setIsInviting(true)

    toast.promise(InvitationClient.inviteUsers(data), {
      loading: {
        title: 'Enviando invitaciones...',
      },
      success: () => {
        queryClient.invalidateQueries(['invitations'])
        onClose()
        reset()
        setIsInviting(false)
        return {
          title: 'Usuarios invitados correctamente!',
        }
      },
      error: (err) => {
        setIsInviting(false)
        return { title: error.getErrorMessage(err) }
      },
    })
  }, [])

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const [mainRole] = data
      setValue('roleId', mainRole.id)
    }
  }, [data])

  return (
    <>
      {hasPermission(auth.role.permissions, {
        resource: 'invitation',
        action: 'create',
      }) && (
        <Button
          {...buildTestId('invite-users-btn')}
          size='sm'
          variant={'primary'}
          ml='auto'
          onClick={onOpen}
        >
          Invitar usuarios
        </Button>
      )}

      {!!data && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize='lg'>
              Invitar usuarios a {auth.company.name}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <chakra.form
                onSubmit={handleSubmit(onSubmit)}
                display='flex'
                flexDir='column'
                experimental_spaceY={4}
              >
                <Box display='flex' flexDir='column' experimental_spaceY={2}>
                  <Box>
                    <EmailListEditable<IInvitations>
                      register={register}
                      remove={remove}
                      append={() => append({ email: '' })}
                      errors={errors}
                      list={fields.invitations}
                      pathBuilder={(index) => `invitations.${index}.email`}
                    />
                  </Box>
                  <Box>
                    <Select
                      {...register('roleId', {
                        setValueAs: (val) => Number(val),
                      })}
                      name='roleId'
                      size='sm'
                    >
                      {data.map((role, index) => {
                        return (
                          <option value={role.id} key={index}>
                            {role.name}
                          </option>
                        )
                      })}
                    </Select>
                    <Box mt={4}>
                      <RolePermissions
                        permissionsToRender={props.permissionsToRender}
                        readonly
                        onlyTable
                        defaultValues={role}
                      />
                    </Box>
                  </Box>
                </Box>

                <Button
                  {...buildTestId('send-invitations-btn')}
                  isDisabled={isInviting}
                  isLoading={isInviting}
                  type='submit'
                  variant='primary'
                  size='sm'
                >
                  Enviar invitaciones
                </Button>
              </chakra.form>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
