import {
  Badge,
  Button,
  Checkbox,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { CaseClient, IArtifact } from 'kach-clients'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GiConsoleController } from 'react-icons/gi'
import { IoMdDownload } from 'react-icons/io'
import { IoTrashOutline } from 'react-icons/io5'
import { Column, useRowSelect, useTable } from 'react-table'
import { useCase } from '../hooks/useCase'
import { useCaseAbility } from '../hooks/useCaseAbility'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { bytesToKB } from '../utils/bytes-to-kb'
import { formatDateToLocaleDays } from '../utils/format-date'
import { ArtifactIcon } from './ArtifactDisplayer'
import {
  CASE_ARTIFACTS_SUFFIX_COMPONENT_PORTAL_ID,
  useArtifactsSelect,
} from './CaseArtifacts'
import { useCaseDetail } from './CaseDetail'
import { CustomTable } from './CustomTable'
import { TunnelRat } from './TunnelRat'

export const FilesDataTable = ({
  files,
  caseId,
}: {
  files: IArtifact[]
  caseId: number
}) => {
  const columns = useMemo(
    (): Column<IArtifact>[] => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => {
          const checkboxProps = getToggleAllRowsSelectedProps()

          return (
            <div>
              <Checkbox
                {...checkboxProps}
                isIndeterminate={checkboxProps.indeterminate}
                isChecked={checkboxProps.checked}
              />
            </div>
          )
        },
        Cell: ({ row }) => {
          const checkboxProps = row.getToggleRowSelectedProps()

          return (
            <div>
              <Checkbox
                {...checkboxProps}
                isIndeterminate={checkboxProps.indeterminate}
                isChecked={checkboxProps.checked}
              />
            </div>
          )
        },
      },
      {
        Header: 'Nombre',
        accessor: 'originalName',
        Cell: (cell) => {
          return (
            <Link
              _hover={{
                textDecor: 'underline',
              }}
              target='_blank'
              href={cell.row.original.url}
            >
              <Stack alignItems={'center'} direction='row'>
                <ArtifactIcon mimeType={cell.row.original.mimetype} />
                <Text noOfLines={1} maxW={'36'}>
                  {cell.row.original.originalName}
                </Text>
              </Stack>
            </Link>
          )
        },
      },
      {
        Header: 'Etiquetas',
        accessor: (row) => {
          return (
            <Stack>
              {row.tags.map((tag) => {
                return (
                  <Badge maxW={'fit-content'} key={tag.id}>
                    {tag.name}
                  </Badge>
                )
              })}
            </Stack>
          )
        },
      },
      {
        Header: 'Peso',
        accessor: (row) => `${bytesToKB(row.size)}kb`,
      },
      {
        Header: 'Fecha subida',
        accessor: (row) => formatDateToLocaleDays(row.createdAt),
      },
    ],
    [],
  )

  const table = useTable(
    {
      columns,
      data: files,
      autoResetHiddenColumns: false,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
    },
    useRowSelect,
  )

  const { setSelected, selected } = useArtifactsSelect()

  useEffect(() => {
    setSelected(
      (table.selectedFlatRows as { index: number }[]).map(
        (selected) => files[selected.index].id,
      ),
    )
  }, [table.selectedFlatRows])

  return <CustomTable customUseTable={table} columns={columns} data={files} />
}
