import { z } from 'zod';
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema';

//@ts-ignore
export const CreateMailSchema = z.object({
  externalId: z.string().min(1, REQUIRED_FIELD_MESSAGE),
  artifact: z.any().optional(),
});

export interface ICreateMailSchema extends z.infer<typeof CreateMailSchema> {}
