import { Box, Text } from '@chakra-ui/react'
import { IThread, ThreadClient } from 'kach-clients'
import React, { useEffect, useRef } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { messages } from '../constants/messages'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { buildThreadQuery } from '../utils/build-thread-query-key'
import { Chat } from './Chat'
import { PoweredByKach } from './PoweredByKach'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const ThreadChat = (props: { thread: IThread; token?: string }) => {
  const { data } = useQuerySingleton(
    [buildThreadQuery(props.thread.id)],
    () => ThreadClient.detail(props.thread.id, props.token),
    {
      initialData: props.thread,
    },
  )

  const auth = useAuth()

  const ref = useRef<HTMLDivElement>(null)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const isClosed = data.status === 'closed'

  return (
    <Box
      w='full'
      display={'flex'}
      flexDir='column'
      p={10}
      bg='gray.100'
      justifyContent={'center'}
      alignItems='center'
      borderRadius='md'
    >
      <Box
        display={'flex'}
        flexDir='row'
        alignItems={'center'}
        justifyContent='center'
        maxW='3xl'
        w='full'
      >
        <Box
          w='full'
          shadow={'lg'}
          border='1px'
          borderColor={'gray.100'}
          rounded='lg'
        >
          <Box
            display={'flex'}
            flexDir='column'
            experimental_spaceY={'2'}
            p={5}
            w='full'
            bg='white'
            pos={'sticky'}
            top={-1}
            zIndex={10}
          >
            <header>
              Hilo con <strong>{data.destination}</strong>
            </header>
            <Text fontSize={'sm'} color='gray.600'>
              Numero de siniestro: {data.case.caseRequest.sinisterId}
            </Text>

            <Text fontSize={'sm'} color='gray.600'>
              Abierto por {data.openedBy.email}
            </Text>
          </Box>
          <Chat
            disabledReason={
              isClosed
                ? messages.case_thread_closed
                : null ||
                  props.token ||
                  props.thread.case.users.some(
                    (caseUser) => caseUser.user_id === auth?.id,
                  )
                ? undefined
                : messages.case_user_relation_missing
            }
            tokenizer={(files) =>
              ThreadClient.tokenizeMessageArtifacts(data.id, files, props.token)
            }
            messages={data.messages}
            onSendMessage={(message, onFinish) => {
              ThreadClient.addMessage(props.thread.id, message, props.token)
                .then((res) => {
                  queryClient.invalidateQueries([buildThreadQuery(data.id)])
                  onFinish()
                })
                .catch(error.handleError)
            }}
          />
        </Box>
      </Box>
      <div className='opacity-0' ref={ref} />
      <PoweredByKach />
    </Box>
  )
}
