import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { StatLayout } from './StatLayout'

export const CounterStatWrapper = (props: {
  label: string
  counters: {
    label: string
    count: number
  }[]
  children?: React.ReactNode
}) => {
  const counter = [
    ...props?.counters,
    {
      label: 'total',
      count: props.counters.reduce((acc, current, i) => {
        return acc + Number(current.count)
      }, 0),
    },
  ]

  return (
    <StatLayout label={props.label}>
      {props.children}
      <Box
        alignItems={'center'}
        justifyContent='space-between'
        display={'flex'}
        flexDir='row'
      >
        {counter.length > 0 ? (
          counter.map(({ count, label }) => {
            return (
              <Box textAlign={'center'}>
                <Heading size={'lg'} color='primary'>
                  {count.toLocaleString()}
                </Heading>
                <Text fontSize='sm'>{label}</Text>
              </Box>
            )
          })
        ) : (
          <Box>
            <Text fontSize={'xs'} fontStyle={'italic'}>
              No hay datos para mostrar
            </Text>
          </Box>
        )}
      </Box>
    </StatLayout>
  )
}
