import { ICompany } from './authentication.client';
import httpClient from './http-client';

export type IntegrationsType =
  | 'autoinspector'
  | 'riesgo_online'
  | 'iris'
  | 'cesvi'
  | 'sofia'
  | 'piramid'
  | 'rivadavia';

export interface IntegrationApiKey {
  id: number;
  company: ICompany;
  integration: IIntegration;
  token: string;
}

export interface IIntegration {
  createdAt: string;
  id: number;
  label: string;
  oauthURL?: string;
  extraCredentialLabel: string;
  name: IntegrationsType;
  description: string;
  banner: string;
  method: 'oauth' | 'login';
  scope: 'company' | 'user';
  visibility?: 'public' | 'private';
  whitelist?: ICompany[];
  credentials?: ILoginCredentials[];
  apiKeys?: IntegrationApiKey[];
}

export interface ILoginCredentials {
  id: number;
  user: string;
  password: string;
  additionalCredential?: string;
}

export interface IIntegrationConnection {
  createdAt: string;
  id: number;
  integration: IIntegration;
  login: ILoginCredentials;
  oauth: {
    token: string;
    refreshToken: string;
  };
}

export interface IConnectIntegration {
  user: string;
  password: string;
  extraCredentialValue?: string;
}

export interface IUpdateIntegrationConnection extends IConnectIntegration {}

export interface ICookies {
  [key: string]: string;
}

export class IntegrationClient {
  static async list(
    cookie?: string
  ): Promise<{ integrations: IIntegration[] }> {
    const response = await httpClient.get('/v1/integration/supported', {
      headers: {
        Cookie: cookie,
      },
    });

    return response.data;
  }

  static async listConnections(cookie?: string): Promise<{
    integrationConnections: IIntegrationConnection[];
  }> {
    const response = await httpClient.get('/v1/integration/connection', {
      headers: {
        Cookie: cookie,
      },
    });
    return response.data;
  }

  static async fetchCredentials(
    integration: IntegrationsType
  ): Promise<IIntegrationConnection | undefined> {
    try {
      const response = await httpClient.get(
        '/v1/integration/connection/credentials',
        {
          params: {
            integration,
          },
        }
      );

      return response.data;
    } catch (err) {
      return undefined;
    }
  }

  static async fetchCredentialsUnsafe(
    integration: IntegrationsType
  ): Promise<IIntegrationConnection> {
    return await httpClient
      .get('/v1/integration/connection/credentials', {
        params: {
          integration,
        },
      })
      .then((res) => res.data);
  }

  static async connect(
    integration: IntegrationsType,
    body: IConnectIntegration
  ): Promise<{ success: boolean }> {
    const response = await httpClient.post(
      `/v1/integration/connection/connect/${integration}`,
      body
    );

    return response.data;
  }

  static async disconnect(integration: IntegrationsType) {
    const response = await httpClient.delete(
      `/v1/integration/connection/disconnect/${integration}`
    );

    return response.data;
  }

  static async update(
    id: number,
    update: IUpdateIntegrationConnection
  ): Promise<IIntegrationConnection> {
    const response = await httpClient.put(
      `/v1/integration/connection/${id}`,
      update
    );

    return response.data;
  }
}
