import debounce from 'lodash.debounce';
import React, { useCallback, useState } from 'react';

export const useDebounceSearch = () => {
  const [search, setSearch] = useState<string>('');

  const onChangeSearch = useCallback((search: string) => setSearch(search), []);

  const debounceSearch = useCallback(debounce(onChangeSearch, 500), []);

  return {
    search,
    debounceSearch,
  };
};
