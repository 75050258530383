import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Badge,
  Box,
  Button,
  chakra,
  List,
  ListItem,
  ListProps,
  Tag,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { CaseEvents, ICase, IInvestigation, IUser } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { MdOutlineRestore, MdOutlineRestorePage } from 'react-icons/md'
import { buildCaseTimelineTrigger } from '../utils/build-case-timeline-trigger'
import { buildUserName } from '../utils/build-user-name'
import { getIsOutOfOfficeHours } from '../utils/get-is-out-of-office-hours'
import { timeAgoFormat } from 'kach-commons/src/utils/time-ago-format'
import { translator } from '../utils/translator'
import { ArtifactDisplayer } from './ArtifactDisplayer'
import { MessageDisplayer } from './MessageDisplayer'
import { NewsLine } from './NewsLine'
import { NewsRequestLine } from './NewsRequestLine'
import { RestoreArtifactButton } from './RestoreArtifactButton'

const EventTriggeredDisplayer = (props: {
  event: CaseEvents
  onClick?: (event: CaseEvents) => void
  AccordionContent?: React.ReactNode
  withAccordion?: boolean
  children?: React.ReactNode
  email?: string
  triggeredBy?: IUser
  createdAt: string
  LabelComponent?: React.FC<any>
  ColumnComponent?: React.FC<any>
  literalTimeAgo?: string
}) => {
  const EventLine = (
    <Box
      {...(props.onClick
        ? {
            onClick: () => props.onClick(props.event),
            _hover: {
              bg: 'gray.100',
            },
            padding: 4,
            cursor: 'pointer',
            borderRadius: 'lg',
          }
        : {})}
      w='full'
      pos={'relative'}
    >
      <Box alignItems={'start'} display={'flex'} flexDir='row'>
        <Avatar
          referrerPolicy='no-referrer'
          mt='1'
          mr='2'
          bg='primary'
          size='sm'
          color='white'
          name={buildUserName(props.triggeredBy)}
          src={props?.triggeredBy?.profilePhoto}
        />
        <Box display={'flex'} flexDir='column'>
          <Box
            display='flex'
            alignItems='center'
            flexWrap='wrap'
            experimental_spaceX={1}
            fontSize='xs'
          >
            <chakra.span fontWeight={'medium'} ml={1}>
              {props.triggeredBy
                ? buildCaseTimelineTrigger(props.triggeredBy)
                : props.email}
            </chakra.span>

            {props.LabelComponent && <props.LabelComponent />}
          </Box>
          {props.ColumnComponent && (
            <Box ml='1'>
              <props.ColumnComponent />
            </Box>
          )}
          <chakra.span
            fontSize={'xs'}
            fontWeight='normal'
            color='gray.500'
            ml={1}
          >
            {format(new Date(props.createdAt), 'Pp')}
            <chakra.span ml='1'>
              {`(${timeAgoFormat(props.createdAt)}${
                props.literalTimeAgo ? ` ${props.literalTimeAgo}` : ''
              })`}
            </chakra.span>
          </chakra.span>
        </Box>
      </Box>
    </Box>
  )

  if (props.withAccordion) {
    return (
      <Accordion w='full' variant={'unstyled'} allowToggle>
        <AccordionItem border={'none'}>
          <Box display={'flex'} flexDir='row'>
            {EventLine}
            <Box>
              <AccordionButton
                borderRadius='md'
                px={2}
                {...buildTestId('accordion-case-event-btn')}
              >
                <AccordionIcon />
              </AccordionButton>
            </Box>
          </Box>

          <AccordionPanel pb={4} pl={8} pr={0}>
            {props.AccordionContent}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    )
  }

  return EventLine
}

const PropertyDifferenceItem = (props: {
  property?: string
  oldValue: any
  newValue: any
}) => {
  const oldValue = props.oldValue || 'Sin definir'

  const Item = (
    <Box
      display={'flex'}
      maxW='full'
      flexDir={'row'}
      alignItems='center'
      justifyContent={'space-between'}
      py='4'
      fontSize={'xs'}
    >
      <Text
        {...buildTestId(`old-value-${oldValue}`)}
        bg='red.50'
        color='red.500'
        opacity={'50'}
        p='2'
        rounded='lg'
      >
        {oldValue}
      </Text>
      <BsArrowRight className='w-5' />
      <Box w='100px' bg='green.100' color='green.500' p={2} rounded='lg'>
        <Text {...buildTestId(`new-value-${props.newValue}`)}>
          {props.newValue}
        </Text>
      </Box>
    </Box>
  )

  if (!props.property) return Item

  return (
    <Box
      {...buildTestId(`change-property-${props.property}`)}
      border='1px'
      borderColor='gray.100'
      p={2}
      borderRadius='md'
      position='relative'
      fontSize={'xs'}
      mt={4}
    >
      <Box
        position='absolute'
        color='gray.500'
        right={0}
        top={'-0.75rem'}
        border='1px'
        fontSize='xs'
        bg='gray.100'
        p={1}
        borderRadius='md'
      >
        <p>{translator(props.property)}</p>
      </Box>
      <Box>{Item}</Box>
    </Box>
  )
}

export const CaseTimeline = (props: {
  onClick?: (event: CaseEvents) => void
  case?: ICase
  events: CaseEvents[]
  isSessionActivity?: boolean
  investigation?: IInvestigation
  styles?: ListProps
  auth?: IUser
}) => {
  return (
    <div>
      <List
        {...buildTestId('case-timeline')}
        mt={4}
        p={2}
        w={['auto', 'full']}
        {...props.styles}
      >
        {props.events.map((event, index) => {
          return (
            <ListItem {...buildTestId(`case-event-${event.event}`)} key={index}>
              <Box position='relative' pb={8}>
                {index !== props.events.length - 1 ? (
                  <chakra.span
                    position='absolute'
                    top={5}
                    ml={4}
                    h='full'
                    w='0.125rem'
                    bg='gray.200'
                    aria-hidden='true'
                  />
                ) : null}
                <Box
                  position='relative'
                  display='flex'
                  alignItems='start'
                  experimental_spaceX={4}
                >
                  {(() => {
                    switch (event.event) {
                      case 'retention_request_created':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            LabelComponent={() => {
                              return (
                                <div>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='medium'
                                  >
                                    {event.triggeredBy.firstName}{' '}
                                    {event.triggeredBy.lastName}
                                  </chakra.span>{' '}
                                  solicito la retención del socio del caso
                                </div>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'stop_case_request_created':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            LabelComponent={() => {
                              return (
                                <div>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='medium'
                                  >
                                    {event.triggeredBy.firstName}{' '}
                                    {event.triggeredBy.lastName}
                                  </chakra.span>{' '}
                                  solicito la detención del caso
                                </div>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_processor_updated':
                        const [processor, ...rest] = event.details

                        if (!processor) {
                          return null
                        }

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <div>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='medium'
                                  >
                                    {event.triggeredBy.firstName}{' '}
                                    {event.triggeredBy.lastName}
                                  </chakra.span>{' '}
                                  asigno a{' '}
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='medium'
                                  >
                                    {processor.firstName} {processor.lastName}{' '}
                                    como procesador del caso.
                                  </chakra.span>
                                </div>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'investigation_reopen':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return <div>Reabrió la investigación</div>
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'investigation_report_accepted_dismissed':
                        const [acceptedReportDismissed] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <div>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='medium'
                                  >
                                    {event.triggeredBy.firstName}{' '}
                                    {event.triggeredBy.lastName}
                                  </chakra.span>{' '}
                                  desestimo el informe previamente aceptado{' '}
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='medium'
                                  >
                                    #{acceptedReportDismissed.id}
                                  </chakra.span>
                                </div>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'investigation_report_accepted_dismissed':
                        const [rejectedReportDismissed] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <div>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='medium'
                                  >
                                    {event.triggeredBy.firstName}{' '}
                                    {event.triggeredBy.lastName}
                                  </chakra.span>{' '}
                                  desestimo el rechazo sobre el informe
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='medium'
                                  >
                                    #{rejectedReportDismissed.id}
                                  </chakra.span>
                                </div>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'investigation_invoice_uploaded':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <chakra.span
                                  color='gray.600'
                                  fontWeight='normal'
                                >
                                  Agrego una nueva factura.
                                </chakra.span>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'investigation_report_accepted':
                        const [reportMapped] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    aceptó el informe
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    #{reportMapped.id}
                                  </chakra.span>
                                  <ArtifactDisplayer
                                    mode='light'
                                    artifact={reportMapped.report}
                                  />
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'retention_request_approved': {
                        const [retentionRequest] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    aprobó la solicitud de retención
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    #{retentionRequest.id}:
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )
                      }

                      case 'stop_case_request_approved': {
                        const [stopCaseRequest] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    aprobó la solicitud de detención
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    #{stopCaseRequest.id}:
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )
                      }

                      case 'stop_case_request_rejected':
                        const [stopCaseRequest] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    rechazó la solicitud de detención
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    #{stopCaseRequest.id}:
                                  </chakra.span>
                                </>
                              )
                            }}
                            ColumnComponent={() => {
                              return (
                                <>
                                  <chakra.span fontSize='sm' fontStyle='italic'>
                                    "{stopCaseRequest.rejectedReason!}"
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'retention_request_rejected':
                        const [retentionRequest] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    rechazó la solicitud de retención
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    #{retentionRequest.id}:
                                  </chakra.span>
                                </>
                              )
                            }}
                            ColumnComponent={() => {
                              return (
                                <>
                                  <chakra.span fontSize='sm' fontStyle='italic'>
                                    "{retentionRequest.rejectedReason!}"
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'investigation_report_rejected':
                        const [reportRejected] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    rechazó el informe
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    #{reportRejected.id}:
                                  </chakra.span>
                                  <ArtifactDisplayer
                                    mode='light'
                                    artifact={reportRejected.report}
                                  />
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_investigation_rejected':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    de
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    {event?.triggeredBy?.company?.name}
                                  </chakra.span>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    rechazó la solicitud de investigación
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_investigation_derived':
                        const [investigation] =
                          !event.details ||
                          !event.details.length ||
                          !event.details[0]
                            ? [{ contact: { name: '', email: '' } }]
                            : event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    derivó el caso al estudio
                                  </chakra.span>

                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    {investigation.contact?.name ||
                                      investigation.contact?.email}
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_unlocked':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    desbloqueo el caso
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_locked':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    bloqueó el caso
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_reopened':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    reabrió el caso
                                  </chakra.span>
                                  {props.isSessionActivity && (
                                    <Box>
                                      <Tag size={'sm'}>
                                        {event?.details?.map((detail) => {
                                          return (
                                            <chakra.span>
                                              {detail?.caseRequest?.sinisterId}
                                            </chakra.span>
                                          )
                                        })}
                                      </Tag>
                                    </Box>
                                  )}
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'investigation_report_created':
                        const [report] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    agregó el informe
                                  </chakra.span>
                                  <ArtifactDisplayer
                                    mode='light'
                                    artifact={report.report}
                                  />
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_inspection_created':
                        const [inspection] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    creó la inspección
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    #{inspection.gatewayId}
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_investigation_accepted':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    del estudio
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    {event.triggeredBy?.company?.name}
                                  </chakra.span>
                                  <chakra.span>
                                    aceptó la investigación
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )
                      case 'case_investigation_viewed':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    del estudio
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    {event.triggeredBy?.company?.name}
                                  </chakra.span>
                                  <chakra.span>
                                    visualizó la investigación
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'retention_request_news_created':
                      case 'stop_case_request_news_created':
                      case 'case_investigation_news_created':
                      case 'case_news_created': {
                        const [news] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            withAccordion
                            AccordionContent={<NewsLine news={news} />}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    agregó una novedad
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )
                      }

                      case 'news_requested': {
                        const [caseNewsRequest] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            ColumnComponent={() => {
                              return (
                                <>
                                  <NewsRequestLine
                                    withoutCreatedBy
                                    newsRequest={caseNewsRequest}
                                  />
                                </>
                              )
                            }}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    solicitó novedades del caso
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )
                      }

                      case 'investigation_updated': {
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            AccordionContent={
                              <Box
                                mt={5}
                                display='flex'
                                ml={2}
                                flexDir='column'
                                experimental_spaceY={4}
                              >
                                {event.metadata.reason && (
                                  <chakra.span
                                    fontStyle={'italic'}
                                    fontSize='xs'
                                  >
                                    "{event.metadata.reason}"
                                  </chakra.span>
                                )}

                                {event.metadata.diff.length ? (
                                  event.metadata.diff.map((ele, index) => {
                                    const isObject =
                                      typeof ele.oldValue === 'object' &&
                                      typeof ele.newValue === 'object'

                                    return (
                                      <Box
                                        border='1px'
                                        borderColor='gray.100'
                                        p={2}
                                        borderRadius='md'
                                        position='relative'
                                        key={index}
                                      >
                                        <Box
                                          position='absolute'
                                          color='gray.500'
                                          right={0}
                                          top={'-0.75rem'}
                                          border='1px'
                                          fontSize='xs'
                                          bg='gray.100'
                                          p={1}
                                          borderRadius='md'
                                        >
                                          <label>
                                            {translator(
                                              `investigation.attrs.${ele.column}`,
                                            )}
                                          </label>
                                        </Box>
                                        {!isObject ? (
                                          <PropertyDifferenceItem
                                            newValue={translator(ele.newValue)}
                                            oldValue={translator(ele.oldValue)}
                                          />
                                        ) : (
                                          <Box
                                            display='flex'
                                            flexDir='column'
                                            p={2}
                                            my={2}
                                            borderRadius='md'
                                          >
                                            {Object.keys(ele.newValue).map(
                                              (property, index) => {
                                                return (
                                                  <PropertyDifferenceItem
                                                    key={index}
                                                    property={property}
                                                    newValue={
                                                      ele.newValue?.[property]
                                                    }
                                                    oldValue={
                                                      ele.oldValue?.[property]
                                                    }
                                                  />
                                                )
                                              },
                                            )}
                                          </Box>
                                        )}
                                      </Box>
                                    )
                                  })
                                ) : (
                                  <Text fontSize={'xs'}>No hubo cambios</Text>
                                )}
                              </Box>
                            }
                            withAccordion
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    actualizó la investigación
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )
                      }

                      case 'indicators_added': {
                        const [investigation] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    agregó indicadores a la investigación
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )
                      }

                      case 'retention_request_news_requested': {
                        const [newsRequest] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <chakra.span
                                  color='gray.600'
                                  fontWeight='normal'
                                >
                                  solicitó novedades de la retención
                                </chakra.span>
                              )
                            }}
                            ColumnComponent={() => {
                              return (
                                <>
                                  <chakra.span fontSize={'xs'}>
                                    {!!newsRequest.annotation && (
                                      <chakra.span
                                        color='gray.600'
                                        fontWeight='normal'
                                      >
                                        "{newsRequest.annotation}"
                                      </chakra.span>
                                    )}
                                  </chakra.span>
                                  <br />
                                  {(newsRequest?.artifacts || []).map(
                                    (artifact) => {
                                      return (
                                        <ArtifactDisplayer
                                          mode='light'
                                          artifact={artifact as any}
                                        />
                                      )
                                    },
                                  )}
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )
                      }

                      case 'stop_case_news_requested': {
                        const [newsRequest] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <chakra.span
                                  color='gray.600'
                                  fontWeight='normal'
                                >
                                  solicitó novedades de la detención
                                </chakra.span>
                              )
                            }}
                            ColumnComponent={() => {
                              return (
                                <>
                                  <chakra.span fontSize={'xs'}>
                                    {!!newsRequest.annotation && (
                                      <chakra.span
                                        color='gray.600'
                                        fontWeight='normal'
                                      >
                                        "{newsRequest.annotation}"
                                      </chakra.span>
                                    )}
                                  </chakra.span>
                                  <br />
                                  {(newsRequest?.artifacts || []).map(
                                    (artifact) => {
                                      return (
                                        <ArtifactDisplayer
                                          mode='light'
                                          artifact={artifact as any}
                                        />
                                      )
                                    },
                                  )}
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )
                      }

                      case 'investigation_news_requested': {
                        const [investigationNewsRequest] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    solicitó novedades de la investigación
                                  </chakra.span>

                                  <chakra.span>
                                    {!!investigationNewsRequest.annotation && (
                                      <chakra.span
                                        color='gray.600'
                                        fontWeight='normal'
                                      >
                                        "{investigationNewsRequest.annotation}"
                                      </chakra.span>
                                    )}

                                    {(
                                      investigationNewsRequest?.artifacts || []
                                    ).map((artifact) => {
                                      return (
                                        <ArtifactDisplayer
                                          mode='light'
                                          artifact={artifact as any}
                                        />
                                      )
                                    })}
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )
                      }

                      case 'case_created':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    creó el caso
                                  </chakra.span>
                                  {getIsOutOfOfficeHours(
                                    event.createdAt,
                                    props.auth?.company?.workingHoursStart,
                                    props.auth?.company?.workingHoursEnd,
                                  ) && (
                                    <Tag size='sm'>
                                      Fuera de horario laboral
                                    </Tag>
                                  )}
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                            literalTimeAgo='corridos'
                          />
                        )

                      case 'case_closed':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    cerró el caso
                                  </chakra.span>
                                  {props.isSessionActivity && (
                                    <Box>
                                      <Tag size={'sm'}>
                                        {event?.details?.map((detail) => {
                                          return (
                                            <chakra.span>
                                              {detail?.caseRequest?.sinisterId}
                                            </chakra.span>
                                          )
                                        })}
                                      </Tag>
                                    </Box>
                                  )}
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'investigation_closed':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    cerró la investigación
                                  </chakra.span>
                                  {props.isSessionActivity && (
                                    <Box>
                                      <Tag size={'sm'}>
                                        {event?.details?.map((detail) => {
                                          return (
                                            <chakra.span>
                                              {detail?.id}
                                            </chakra.span>
                                          )
                                        })}
                                      </Tag>
                                    </Box>
                                  )}
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'investigation_canceled':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    canceló la investigación
                                  </chakra.span>
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_investigation_requested_news':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <chakra.span
                                  color='gray.600'
                                  fontWeight='normal'
                                >
                                  solicitó novedades de la investigación
                                </chakra.span>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_updated':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            AccordionContent={
                              <Box
                                mt={5}
                                display='flex'
                                ml={2}
                                flexDir='column'
                                experimental_spaceY={4}
                              >
                                {event.metadata.diff.length ? (
                                  event.metadata.diff.map((ele, index) => {
                                    const isObject =
                                      typeof ele.oldValue === 'object' &&
                                      typeof ele.newValue === 'object'

                                    return (
                                      <Box
                                        border='1px'
                                        borderColor='gray.100'
                                        p={2}
                                        borderRadius='md'
                                        position='relative'
                                        key={index}
                                      >
                                        <Box
                                          position='absolute'
                                          color='gray.500'
                                          right={0}
                                          top={'-0.75rem'}
                                          border='1px'
                                          fontSize='xs'
                                          bg='gray.100'
                                          p={1}
                                          borderRadius='md'
                                        >
                                          <label>
                                            {translator(ele.column)}
                                          </label>
                                        </Box>
                                        {!isObject ? (
                                          <PropertyDifferenceItem
                                            newValue={translator(ele.newValue)}
                                            oldValue={translator(ele.oldValue)}
                                          />
                                        ) : (
                                          <Box
                                            display='flex'
                                            flexDir='column'
                                            p={2}
                                            my={2}
                                            borderRadius='md'
                                          >
                                            {Object.keys(ele.newValue).map(
                                              (property, index) => {
                                                return (
                                                  <PropertyDifferenceItem
                                                    key={index}
                                                    property={property}
                                                    newValue={
                                                      ele.newValue?.[property]
                                                    }
                                                    oldValue={
                                                      ele.oldValue?.[property]
                                                    }
                                                  />
                                                )
                                              },
                                            )}
                                          </Box>
                                        )}
                                      </Box>
                                    )
                                  })
                                ) : (
                                  <Text fontSize={'xs'}>No hubo cambios</Text>
                                )}
                              </Box>
                            }
                            withAccordion
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    actualizó el caso
                                  </chakra.span>
                                  {props.isSessionActivity && (
                                    <Box>
                                      <Tag size={'sm'}>
                                        {event?.details?.map((detail) => {
                                          return (
                                            <chakra.span>
                                              {detail?.caseRequest?.sinisterId}
                                            </chakra.span>
                                          )
                                        })}
                                      </Tag>
                                    </Box>
                                  )}
                                </>
                              )
                            }}
                            triggeredBy={event.triggeredBy}
                            createdAt={event.createdAt}
                          />
                        )

                      case 'case_taken':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <chakra.span
                                  color='gray.600'
                                  fontWeight='normal'
                                >
                                  tomó el caso
                                </chakra.span>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'stop_case_request_taken':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <chakra.span
                                  color='gray.600'
                                  fontWeight='normal'
                                >
                                  tomó la solicitud de detención
                                </chakra.span>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'collaborator_added_to_case':
                        const [collaboratorAdded] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    asignó a
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {buildUserName(collaboratorAdded)}
                                  </chakra.span>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    como ayudante
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'collaborator_added_to_case_request':
                        const [collaborator] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    asignó a
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {buildUserName(collaborator)}
                                  </chakra.span>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    como ayudante de la solicitud
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'collaborator_removed_from_case_request':
                        const [collab] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    removió a
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {buildUserName(collab)}
                                  </chakra.span>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    como ayudante de la solicitud
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_message_created':
                        const [message] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    agregó el siguiente comentario:
                                  </chakra.span>
                                  <MessageDisplayer
                                    isTimeline
                                    message={message}
                                  />
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_responsibility_delegated':
                        const [newResponsible] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    delegó la responsabilidad a
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {buildUserName(newResponsible)}
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_assigned':
                        const [caseUser] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    asignó a
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {buildUserName(caseUser)}
                                  </chakra.span>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    como responsable del caso
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'stop_case_request_assigned':
                        const [caseDetentionUser] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    asignó a
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {buildUserName(caseDetentionUser)}
                                  </chakra.span>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    como responsable de la solicitud de
                                    detención
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_thread_created':
                        const [threadCreated] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    abrió un hilo con
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    {threadCreated.destination}{' '}
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_mail_created':
                        const [mailCreated] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    creó la carta
                                  </chakra.span>{' '}
                                  <chakra.span fontWeight='medium'>
                                    #{mailCreated.externalId}
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_thread_closed':
                        const [threadClosed] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    cerró el hilo con
                                  </chakra.span>{' '}
                                  <chakra.span fontWeight='medium'>
                                    {' '}
                                    {threadClosed.destination}{' '}
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_commercial_report_requested':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    solicitó un informe comercial
                                  </chakra.span>
                                </>
                              )
                            }}
                            ColumnComponent={() => {
                              return (
                                <Badge>
                                  <chakra.span fontWeight={'normal'}>
                                    Documento:
                                  </chakra.span>{' '}
                                  {event.metadata.identification}
                                </Badge>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_commercial_report_removed':
                        const [caseCustomerReport] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    eliminó un informe comercial
                                  </chakra.span>
                                </>
                              )
                            }}
                            ColumnComponent={() => {
                              return (
                                <Badge>
                                  <chakra.span fontWeight={'normal'}>
                                    Documento:
                                  </chakra.span>{' '}
                                  {caseCustomerReport.identification}
                                </Badge>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_customer_commercial_report_requested':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    solicitó un informe comercial del asegurado
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_customer_sinister_history_report_requested':
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    solicitó un historial siniestral del
                                    asegurado
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'case_artifact_removed': {
                        const [artifact] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    removió el archivo
                                  </chakra.span>
                                  <ArtifactDisplayer
                                    mode='light'
                                    artifact={artifact}
                                  />
                                </>
                              )
                            }}
                            ColumnComponent={() => {
                              if (!artifact.deletedAt) return null

                              return (
                                <RestoreArtifactButton
                                  artifactId={artifact.id}
                                  caseId={props.case?.id!}
                                />
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )
                      }

                      case 'case_artifact_restored': {
                        const [artifact] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    recuperó el archivo
                                  </chakra.span>
                                  <ArtifactDisplayer
                                    mode='light'
                                    artifact={artifact}
                                  />
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )
                      }

                      case 'case_artifact_uploaded':
                        const [artifact] = event.details
                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    subió el archivo
                                  </chakra.span>
                                  <ArtifactDisplayer
                                    mode='light'
                                    artifact={artifact}
                                  />
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      case 'collaborator_removed_from_case':
                        const [collaboratorRemoved] = event.details

                        return (
                          <EventTriggeredDisplayer
                            event={event}
                            onClick={props.onClick}
                            LabelComponent={() => {
                              return (
                                <>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    removió a
                                  </chakra.span>
                                  <chakra.span fontWeight='medium'>
                                    {buildUserName(collaboratorRemoved)}
                                  </chakra.span>
                                  <chakra.span
                                    color='gray.600'
                                    fontWeight='normal'
                                  >
                                    como ayudante
                                  </chakra.span>
                                </>
                              )
                            }}
                            createdAt={event.createdAt}
                            triggeredBy={event.triggeredBy}
                          />
                        )

                      default:
                        return null
                    }
                  })()}
                </Box>
              </Box>
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}
