import {
  Button,
  chakra,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Select,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { IVirtualView, VirtualViewClient } from 'kach-clients'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiSave } from 'react-icons/bi'
import { BsFillTrashFill } from 'react-icons/bs'
import { CiViewTable } from 'react-icons/ci'
import { useAuth } from '../hooks/useAuth'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { useTableParams } from '../hooks/useTableParams'
import { VirtualViewSchema } from '../schemas/virtual-view.schema'
import { buildVirtualViewQueryKey } from '../utils/build-virtual-view-query-key'
import { hasPermission } from '../utils/has-permission'
import { params2VirtualView } from '../utils/virtual-view'

const components = {
  modal: {
    body: ModalBody,
    closeButton: ModalCloseButton,
    content: ModalContent,
    footer: ModalFooter,
    header: ModalHeader,
  },
  popover: {
    body: PopoverBody,
    closeButton: PopoverCloseButton,
    content: PopoverContent,
    footer: PopoverFooter,
    header: PopoverHeader,
  },
}

export const CreateVirtualViewForm = (props: {
  isLoading: boolean
  onClose: () => void
  formId: string
  onSave: (data: IVirtualView) => void
  type?: 'modal' | 'popover'
}) => {
  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<IVirtualView>({
    resolver: zodResolver(VirtualViewSchema),
    defaultValues: {
      scope: 'user',
    },
  })

  const auth = useAuth()

  const ComponentToRender = components[props.type || 'modal']

  const handleButtonClick = async () => {
    const result = await trigger()
    if (result) {
      handleSubmit(props.onSave)()
    }
  }

  return (
    <>
      <ComponentToRender.header>
        Guardar vista de la tabla
      </ComponentToRender.header>
      <ComponentToRender.closeButton isDisabled={props.isLoading} />
      <ComponentToRender.body>
        <chakra.form
          display={'flex'}
          flexDir='column'
          experimental_spaceY={'5'}
          id={props.formId}
          noValidate
        >
          <FormControl isInvalid={!!errors.name} isRequired>
            <FormLabel>Nombre</FormLabel>
            <Input {...register('name')} />
          </FormControl>
          {hasPermission(auth.role.permissions, {
            action: 'create_all',
            resource: 'virtual_view',
          }) && (
            <FormControl isInvalid={!!errors.scope} isRequired>
              <FormLabel>Alcance</FormLabel>
              <Select {...register('scope')}>
                <option value='company'>Empresa </option>
                <option value='user'>Propio</option>
              </Select>
              <FormHelperText lineHeight={'1.2rem'} fontSize={'xs'}>
                Si eliges "Propio" la vista solo será visible para ti. Si eliges
                "Empresa" la vista será visible para todos los usuarios de tu
                empresa.
              </FormHelperText>
            </FormControl>
          )}
        </chakra.form>
      </ComponentToRender.body>

      <ComponentToRender.footer
        display='flex'
        justifyContent='end'
        experimental_spaceX={2}
      >
        <Button onClick={props.onClose} isDisabled={props.isLoading} size='sm'>
          Cancelar
        </Button>
        <Button
          isDisabled={props.isLoading}
          isLoading={props.isLoading}
          variant='primary'
          mr={3}
          size='sm'
          onClick={handleButtonClick}
        >
          Guardar
        </Button>
      </ComponentToRender.footer>
    </>
  )
}

const Body = (props: {
  onClose: () => void
  params: any
  resource: IVirtualView['resource']
  type?: 'modal' | 'popover'
}) => {
  const [paramsLocally, setParamsLocally] = useState(props.params)
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const { setVirtualView, cols } = useTableParams()

  const formId = 'virtual-view-form'

  const auth = useAuth()

  const columns = cols.getValues().columns

  const toast = useToast()

  useEffect(() => {
    setParamsLocally(new Object(props.params))
  }, [props.params])

  const onSave = useCallback(
    (data: IVirtualView) => {
      setIsLoading(true)

      const virtualView = {
        ...data,
        resource: props.resource,
        filters: params2VirtualView(paramsLocally),
        columns,
      }

      toast.promise(
        VirtualViewClient.create(virtualView as Omit<IVirtualView, 'order'>),
        {
          error: (err) => {
            return { title: error.getErrorMessage(err) }
          },
          loading: {
            title: 'Guardando vista',
          },
          success: (res) => {
            queryClient.invalidateQueries([
              buildVirtualViewQueryKey(props.resource, auth),
            ])

            setVirtualView({
              ...virtualView,
              id: res.virtualViewId,
            } as IVirtualView)

            props.onClose()

            return {
              description: 'Vista guardada correctamente',
            }
          },
        },
      )

      setIsLoading(false)
    },
    [paramsLocally, props.resource, auth, columns],
  )

  return (
    <CreateVirtualViewForm
      isLoading={isLoading}
      onClose={props.onClose}
      formId={formId}
      onSave={onSave}
      type={props.type}
    />
  )
}

export const ModalCreateVirtualView = (props: {
  params: any
  resource: IVirtualView['resource']
  isOpen: boolean
  onClose: () => void
  type?: 'modal' | 'popover'
}) => {
  if (props.type === 'popover') {
    return (
      <Popover
        isOpen={props.isOpen}
        onClose={props.onClose}
        closeOnBlur={false}
        placement='left'
      >
        <PopoverContent>
          <Body
            resource={props.resource}
            params={props.params}
            onClose={props.onClose}
            type={props.type}
          />
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <Body
          resource={props.resource}
          params={props.params}
          onClose={props.onClose}
          type={props.type}
        />
      </ModalContent>
    </Modal>
  )
}

export const DeleteVirtualView = () => {
  const { virtualView, params, setVirtualView } = useTableParams()

  const queryClient = useQueryClientSingleton()

  const error = useError()
  const auth = useAuth()

  const toast = useToast()

  const onRemove = useCallback(() => {
    if (!virtualView) return

    toast.promise(VirtualViewClient.remove(virtualView?.id), {
      error: (err) => {
        title: error.getErrorMessage(err)
      },
      loading: {
        title: 'Eliminado vista',
      },
      success: () => {
        queryClient.invalidateQueries([
          buildVirtualViewQueryKey(virtualView.resource, auth),
        ])

        setVirtualView(null)

        return {
          title: 'Vista eliminada correctamente',
        }
      },
    })
  }, [virtualView, params, auth])

  return (
    <MenuItem onClick={onRemove} icon={<BsFillTrashFill fontSize={'1rem'} />}>
      Eliminar vista
    </MenuItem>
  )
}

export const SaveChanges = () => {
  const { virtualView, params, cols } = useTableParams()

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const auth = useAuth()

  const columns = cols.getValues().columns

  const toast = useToast()

  const onSaveVirtualView = useCallback(() => {
    if (!virtualView) return

    toast.promise(
      VirtualViewClient.update(virtualView?.id, {
        filters: params2VirtualView(params),
        columns,
      }),
      {
        error: (err) => {
          return { title: error.getErrorMessage(err) }
        },
        loading: {
          description: 'Guardando cambios',
        },
        success: () => {
          queryClient.invalidateQueries([
            buildVirtualViewQueryKey(virtualView.resource, auth),
          ])
          return {
            description: 'Cambios guardados correctamente',
          }
        },
      },
    )
  }, [virtualView, params, auth, columns])

  return (
    <MenuItem onClick={onSaveVirtualView} icon={<BiSave fontSize={'1rem'} />}>
      Guardar vista
    </MenuItem>
  )
}

export const VirtualView = (props: {
  params: any
  resource: IVirtualView['resource']
  type?: 'modal' | 'popover'
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { virtualView } = useTableParams()

  return (
    <>
      <MenuItem onClick={onOpen} icon={<CiViewTable fontSize={'1rem'} />}>
        Crear nueva vista
      </MenuItem>
      {virtualView && <SaveChanges />}
      {virtualView && <DeleteVirtualView />}

      <ModalCreateVirtualView
        isOpen={isOpen}
        onClose={onClose}
        resource={props.resource}
        params={props.params}
        type={props.type}
      />
    </>
  )
}
