import { IJob, IUser } from 'kach-clients'
import { QueryClient } from '@tanstack/react-query'
import React from 'react'

export interface IKachContext {
  defaultContext?: React.Context<QueryClient>
  auth: IUser
  jobs: IJob[]
}

export const KachContext = React.createContext<IKachContext>({} as any)

export const KachProvider: React.FC<React.PropsWithChildren<IKachContext>> = (
  props,
) => {
  return (
    <KachContext.Provider value={props}>{props.children}</KachContext.Provider>
  )
}
