import React, { useEffect } from 'react'
import {
  Box,
  Button,
  chakra,
  Checkbox,
  CheckboxGroup,
  FormLabel,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react'
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/24/outline'
import { buildTestId } from 'kach-commons'

import { add, format } from 'date-fns'
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import {
  EnumOption,
  ICandidateFilter,
  IFilter,
  IFiltersForm,
} from './TableAbstract'
import { ColumnsSearcher } from './ColumnsSearcher'
import { ITableColumnsController } from '../hooks/useTableColumns'
import { TableColumns } from './TableColumns'
import { useDebounceSearch } from '../hooks/useDebounceSearch'
import { useQuery } from '@tanstack/react-query'
import { formatAmount } from '../utils/format-amount'

const findColumnIcon = (
  candidatesFilters: ICandidateFilter[],
  column: string,
) => candidatesFilters.find((ele) => ele.column === column)?.Icon

const ComboboxInput = ({
  search,
  name,
  filterIndex,
  registerFilter,
  setFiltersValue,
}: {
  search: NonNullable<IFilter['search']>
  name: string
  filterIndex: number
  registerFilter: UseFormRegister<IFiltersForm>
  setFiltersValue: UseFormSetValue<IFiltersForm>
}) => {
  const { debounceSearch, search: debouncedSearchTerm } = useDebounceSearch()

  const query = useQuery({
    queryKey: [name, debouncedSearchTerm],
    queryFn: (context) => {
      const [_, debouncedSearchTerm] = context.queryKey

      return search(debouncedSearchTerm)
    },
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  return (
    <Stack>
      <Input
        onChange={(e) => debounceSearch(e.target.value)}
        placeholder='Buscar'
        type='text'
        size='sm'
      />
      {query.data && query.data.length > 0 && (
        <EnumOptionsList
          setFiltersValue={setFiltersValue}
          options={query.data}
          filterIndex={filterIndex}
          registerFilter={registerFilter}
        />
      )}
    </Stack>
  )
}

const EnumOptionListItem = ({
  value,
  label,
  filterIndex,
  optIndex,
  registerFilter,
  setFiltersValue,
}: {
  value: number
  label: string
  filterIndex: number
  optIndex: number
  registerFilter: UseFormRegister<IFiltersForm>
  setFiltersValue: UseFormSetValue<IFiltersForm>
}) => {
  useEffect(() => {
    setFiltersValue(`filters.${filterIndex}.enums.${optIndex}.id`, value)
  }, [])

  return (
    <Checkbox
      size='sm'
      {...buildTestId(`filter-checkbox-${label}`)}
      {...registerFilter(`filters.${filterIndex}.enums.${optIndex}.checked`)}
    >
      {label}
    </Checkbox>
  )
}

const EnumOptionsList = ({
  options,
  filterIndex,
  registerFilter,
  setFiltersValue,
}: {
  options: EnumOption[]
  filterIndex: number
  registerFilter: UseFormRegister<IFiltersForm>
  setFiltersValue: UseFormSetValue<IFiltersForm>
}) => {
  return (
    <CheckboxGroup colorScheme='brand'>
      <Stack spacing={[2]} direction='column'>
        {options.map((opt, optIndex) => {
          const displayLabel = opt.label || opt.name || opt.value

          return (
            <EnumOptionListItem
              key={opt.value}
              filterIndex={filterIndex}
              label={displayLabel}
              optIndex={optIndex}
              registerFilter={registerFilter}
              setFiltersValue={setFiltersValue}
              value={opt.value}
            />
          )
        })}
      </Stack>
    </CheckboxGroup>
  )
}

export const Filters = ({
  withToggleColumns = true,
  ...props
}: {
  filters: IFilter[]
  filtersObserver: UseFormWatch<IFiltersForm>
  candidatesFilters: ICandidateFilter[]
  registerFilter: UseFormRegister<IFiltersForm>
  onRemoveFilter: (index: number) => void
  onAddFilter: (filter: IFilter) => void
  columnsController?: ITableColumnsController
  setFiltersValue: UseFormSetValue<IFiltersForm>
  withToggleColumns?: boolean
}) => {
  return (
    <Box>
      <HStack spacing={'2'}>
        <Box display='flex' alignItems='start'>
          <Box display='flex' alignItems='start' mt='0.1rem'>
            <Tag mb={[2, null, 0]} mr={[0, 2, 2]}>
              <TagLeftIcon as={FunnelIcon} />
              <TagLabel color='gray.600' fontSize='xs'>
                {!props.filters.length
                  ? 'Ningún filtro'
                  : `${props.filters.length} filtros`}
              </TagLabel>
            </Tag>
            <Box display='flex' flexWrap='wrap' flex={[1, 0, '21%']}>
              {props.filters.map((filter, index) => {
                if (filter.disabledRender) {
                  return null
                }

                const IconFilter = findColumnIcon(
                  props.candidatesFilters,
                  filter.column,
                )

                return (
                  <Box key={index}>
                    <Popover isLazy>
                      <PopoverTrigger>
                        <Button
                          leftIcon={IconFilter ? <IconFilter /> : null}
                          variant='outline'
                          size='xs'
                          rightIcon={<Icon as={ChevronDownIcon} />}
                          mr={2}
                          mb={1}
                        >
                          {filter.label}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader fontSize='sm'>
                          Filtrar por{' '}
                          <chakra.span textTransform='lowercase'>
                            {filter.label}
                          </chakra.span>
                        </PopoverHeader>
                        <PopoverBody>
                          <Box display='flex' justifyContent='end' mb={2}>
                            <Button
                              size='xs'
                              onClick={() => props.onRemoveFilter(index)}
                            >
                              Remover filtro
                            </Button>
                          </Box>

                          <Box overflowY={'auto'} maxH={'20rem'}>
                            {(() => {
                              switch (filter.type) {
                                case 'text':
                                  return (
                                    <Input
                                      {...props.registerFilter(
                                        `filters.${index}.value`,
                                      )}
                                      placeholder='Escribe un valor...'
                                      type='text'
                                      size='sm'
                                    />
                                  )

                                case 'range-slider': {
                                  const value = props.filters[index].value || [
                                    0, 0,
                                  ]
                                  console.log('RangeSlidervalue', value)
                                  return (
                                    <Stack
                                      overflow={'hidden'}
                                      direction={'column'}
                                      spacing={0}
                                    >
                                      <Box
                                        display={'flex'}
                                        w='full'
                                        alignItems={'end'}
                                        justifyContent={'end'}
                                      >
                                        <Text fontSize={'xs'}>
                                          {formatAmount(value[0])} -{' '}
                                          {formatAmount(value[1])}
                                        </Text>
                                      </Box>

                                      <Box display={'flex'} flexDir='column'>
                                        <Box mb='4'>
                                          <FormLabel fontSize='xs'>
                                            Mínimo
                                          </FormLabel>
                                          <Input
                                            onChange={(e) => {
                                              props.setFiltersValue(
                                                `filters.${index}.value`,
                                                [
                                                  parseInt(e.target.value),
                                                  value[1],
                                                ],
                                              )
                                            }}
                                            size='xs'
                                            type='number'
                                          />
                                        </Box>
                                        <Box>
                                          <FormLabel fontSize='xs'>
                                            Máximo
                                          </FormLabel>
                                          <Input
                                            onChange={(e) => {
                                              props.setFiltersValue(
                                                `filters.${index}.value`,
                                                [
                                                  value[0],
                                                  parseInt(e.target.value),
                                                ],
                                              )
                                            }}
                                            size='xs'
                                            type='number'
                                          />
                                        </Box>
                                      </Box>
                                    </Stack>
                                  )
                                }

                                case 'async-enum':
                                  return (
                                    <ComboboxInput
                                      setFiltersValue={props.setFiltersValue}
                                      filterIndex={index}
                                      registerFilter={props.registerFilter}
                                      name={filter.column}
                                      search={filter.search!}
                                    />
                                  )

                                case 'enum':
                                  const options = filter?.enumData

                                  if (!options) return null

                                  return (
                                    <EnumOptionsList
                                      setFiltersValue={props.setFiltersValue}
                                      filterIndex={index}
                                      options={options}
                                      registerFilter={props.registerFilter}
                                    />
                                  )

                                case 'date':
                                  return (
                                    <Box display={'flex'} flexDir='column'>
                                      <Box mb='5'>
                                        <FormLabel fontSize='xs'>
                                          Desde
                                        </FormLabel>
                                        <Input
                                          {...props.registerFilter(
                                            `filters.${index}.from`,
                                          )}
                                          size='xs'
                                          type='date'
                                        />
                                      </Box>
                                      <Box>
                                        <FormLabel fontSize='xs'>
                                          Hasta
                                        </FormLabel>
                                        <Input
                                          {...props.registerFilter(
                                            `filters.${index}.to`,
                                            {
                                              setValueAs: (date) => {
                                                if (!date) {
                                                  return
                                                }
                                                return format(
                                                  add(new Date(date), {
                                                    days: 1,
                                                  }),
                                                  'yyyy-MM-dd',
                                                )
                                              },
                                            },
                                          )}
                                          size='xs'
                                          type='date'
                                        />
                                      </Box>
                                    </Box>
                                  )
                              }
                            })()}
                          </Box>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>
                )
              })}
            </Box>
          </Box>

          {props.filters.length !== props.candidatesFilters.length && (
            <ColumnsSearcher
              watch={props.filtersObserver}
              filtersState={props.filters}
              onAddColumn={props.onAddFilter}
              candidatesFilters={props.candidatesFilters}
            />
          )}

          {props?.columnsController && withToggleColumns && (
            <TableColumns columnsController={props.columnsController} />
          )}
        </Box>
      </HStack>
    </Box>
  )
}
