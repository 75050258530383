import React from 'react';
import { TableHeaderWrapper } from './TableHeaderWrapper';

export const CustomIconTableHeader: React.FC<
  React.PropsWithChildren<{ icon: React.ReactNode }>
> = ({ children, icon }) => {
  return (
    <TableHeaderWrapper>
      {icon}
      <span>{children}</span>
    </TableHeaderWrapper>
  );
};
