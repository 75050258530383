import {
  Box,
  Button,
  Icon,
  Input,
  List,
  ListItem,
  Text,
  useToast,
} from '@chakra-ui/react'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import { CaseClient, ICreateMessage, ThreadClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { IThreadSchema, ThreadSchema } from '../hooks/thread.schema'
import { useError } from '../hooks/useError'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { MessageTextarea } from './MessageTextarea'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export default function CreateThreadForm(props: {
  caseId: number
  onClose: () => void
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<IThreadSchema>({
    resolver: zodResolver(ThreadSchema),
  })

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const queryClient = useQueryClientSingleton()

  const toast = useToast()

  const onSubmit = useCallback(
    (message: ICreateMessage) => {
      setIsLoading(true)

      toast.promise(
        ThreadClient.create(props.caseId, {
          ...message,
          destination: getValues().destination,
        }),
        {
          loading: {
            title: 'Creando hilo...',
          },
          success: () => {
            queryClient.invalidateQueries([buildCaseQueryKey(props.caseId)])
            props.onClose()
            setIsLoading(false)
            return {
              title: 'Hilo creado correctamente',
            }
          },
          error: (err) => {
            setIsLoading(false)
            return { title: error.getErrorMessage(err) }
          },
        },
      )
    },
    [getValues],
  )

  return (
    <Box {...buildTestId('create-thread-form')} experimental_spaceY={8}>
      <Box experimental_spaceY={4}>
        <Box experimental_spaceY={4}>
          <Box>
            <Text fontSize='lg' fontWeight='medium'>
              Hilo
            </Text>
            <Text mt={1} fontSize='sm' color='gray.500'>
              Crea un hilo con algún experto para intercambiar mensajes y
              archivos con el fin de ayudarte con el caso
            </Text>
          </Box>

          <div>
            <MessageTextarea
              withNoMentions
              artifactsTokenizer={(files) =>
                CaseClient.tokenizeArtifacts(props.caseId, files)
              }
              artifactsIdentifier='thread-files'
              topInput={
                <Input
                  name='destination'
                  type='email'
                  {...register('destination')}
                  placeholder='experto@gmail.com'
                  border='none'
                  _focus={{ boxShadow: 'none' }}
                />
              }
            >
              {({ message }) => {
                return (
                  <Box pt='5'>
                    <Box display={'flex'} justifyContent='end'>
                      <Button
                        disabled={isLoading}
                        onClick={props.onClose}
                        size='sm'
                      >
                        Cancelar
                      </Button>
                      <Button
                        {...buildTestId('create-thread-button')}
                        ml='2'
                        onClick={handleSubmit(() => onSubmit(message))}
                        isLoading={isLoading}
                        colorScheme='brand'
                        size='sm'
                      >
                        Abrir hilo
                      </Button>
                    </Box>
                  </Box>
                )
              }}
            </MessageTextarea>
            <List
              mx={16}
              display='flex'
              flexDir='column'
              experimental_spaceY={2}
              my={0}
            >
              {Object.keys(errors).length > 0 &&
                Object.entries(errors).map(([key, value]) => {
                  return (
                    <ListItem
                      display='flex'
                      experimental_spaceX={2}
                      alignItems='center'
                      key={key}
                    >
                      <Icon
                        as={ExclamationCircleIcon}
                        color='red.500'
                        h='4'
                        w='4'
                      />
                      <Text fontSize='xs' color='red.600'>
                        {value.message}
                      </Text>
                    </ListItem>
                  )
                })}
            </List>
          </div>
        </Box>
      </Box>
    </Box>
  )
}
