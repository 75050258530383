import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { CaseClient, IArtifact } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { FaList } from 'react-icons/fa'
import { IoMdDownload } from 'react-icons/io'
import { IoTrashOutline } from 'react-icons/io5'
import { MdGridOn } from 'react-icons/md'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import { buildCaseQueryKey } from '../utils/build-case-query-key'
import { AddCaseArtifacts } from './AddCaseArtifacts'
import { ArtifactDisplayer } from './ArtifactDisplayer'
import { CaseAccordion } from './CaseAccordion'
import { FilesDataTable } from './FilesDataTable'
import { TunnelRat } from './TunnelRat'

export const CASE_ARTIFACTS_SUFFIX_COMPONENT_PORTAL_ID =
  'case-artifacts-suffix-component-portal'

export type View = 'grid' | 'list'

const CaseArtifactsViewSwitcher = ({
  view,
  artifacts,
  caseId,
}: {
  view: View
  artifacts: IArtifact[]
  caseId: number
}) => {
  switch (view) {
    case 'list':
      return <FilesDataTable caseId={caseId} files={artifacts} />

    default:
      return (
        <SimpleGrid
          columns={view === 'grid' ? [2, null, 3, null, null, 4] : 1}
          spacing={2}
        >
          {artifacts.map((artifact, index) => (
            <ArtifactDisplayer
              mode='dark'
              key={index}
              artifact={artifact}
              view={view}
            />
          ))}
        </SimpleGrid>
      )
  }
}

interface ArtifactsSelectContextProps {
  selected: number[]
  setSelected: React.Dispatch<React.SetStateAction<number[]>>
}

const ArtifactsSelectContext = React.createContext<
  ArtifactsSelectContextProps | undefined
>(undefined)!

export const ArtifactsSelectProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [selected, setSelected] = useState<number[]>([])

  return (
    <ArtifactsSelectContext.Provider
      value={{
        selected,
        setSelected,
      }}
    >
      {children}
    </ArtifactsSelectContext.Provider>
  )
}

export const useArtifactsSelect = () =>
  React.useContext(ArtifactsSelectContext)!

export const DeleteFilesButton = ({
  isPreview,
  deleteFiles,
  queryKey,
  onRemove,
  variant = 'filled',
}: {
  isPreview?: boolean
  deleteFiles: (ids: number[]) => Promise<any>
  queryKey: string
  onRemove?: () => any
  variant?: 'outline' | 'filled'
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { handleError } = useError()

  const toast = useToast()

  const queryClient = useQueryClientSingleton()

  const { selected, setSelected } = useArtifactsSelect()

  const onDeleteFiles = useCallback(() => {
    setIsLoading(true)

    deleteFiles(selected)
      .then(() => {
        queryClient.invalidateQueries([queryKey])

        toast({
          title: 'Archivos eliminados',
          description: 'Los archivos seleccionados han sido eliminados',
          status: 'success',
          isClosable: true,
        })

        setSelected([])
      })
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }, [selected])

  const wrapDeleteFiles = () => {
    if (onRemove) {
      onRemove()
    }

    if (!onRemove) {
      onDeleteFiles()
    }
  }

  if (isPreview)
    return (
      <IconButton
        variant={variant}
        onClick={wrapDeleteFiles}
        size='sm'
        icon={<IoTrashOutline />}
        color='red.500'
        isLoading={isLoading}
      />
    )

  return (
    <Button
      variant={variant}
      onClick={wrapDeleteFiles}
      size='sm'
      leftIcon={<IoTrashOutline />}
      color='red.500'
      isLoading={isLoading}
    >
      Eliminar {selected.length} archivo(s)
    </Button>
  )
}

export const DownloadFilesButton = ({
  files,
  isPreview,
  variant = 'filled',
}: {
  files: IArtifact[]
  isPreview?: boolean
  variant?: 'outline' | 'filled'
}) => {
  const { selected, setSelected } = useArtifactsSelect()

  console.log('selected', selected)

  const onDownloadFile = useCallback(
    async (presignedURL: string, filename: string) => {
      const response = await fetch(presignedURL)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    [],
  )

  const onDownloadSelectedFiles = useCallback(() => {
    if (!selected.length) return

    const downloadFiles = async () => {
      for (const artifactId of selected) {
        const file = files.find((file) => file.id === artifactId)

        console.log('selected', selected, 'file', file, 'target', artifactId)

        if (file) {
          await onDownloadFile(file.downloadURL!, file.originalName!)
        }
      }
      setSelected([])
    }
    downloadFiles()
  }, [selected])

  if (isPreview)
    return (
      <IconButton
        onClick={onDownloadSelectedFiles}
        size='sm'
        icon={<IoMdDownload />}
        aria-label='download-selected-files'
        variant={variant}
      />
    )

  return (
    <Button
      onClick={onDownloadSelectedFiles}
      size='sm'
      leftIcon={<IoMdDownload />}
      variant={variant}
    >
      Descargar {selected.length} archivo(s)
    </Button>
  )
}

const ArtifactsActions = ({
  caseId,
  files,
  isPreview,
  withoutCreate,
}: {
  caseId: number
  files: IArtifact[]
  isPreview?: boolean
  withoutCreate?: boolean
}) => {
  const { selected } = useArtifactsSelect()

  return (
    <TunnelRat tunnelId={CASE_ARTIFACTS_SUFFIX_COMPONENT_PORTAL_ID}>
      {selected.length > 0 && (
        <Stack direction={'row'}>
          <DeleteFilesButton
            isPreview={isPreview}
            deleteFiles={(ids) => CaseClient.deleteCaseArtifacts(caseId, ids)}
            queryKey={buildCaseQueryKey(caseId)}
          />

          <DownloadFilesButton isPreview={isPreview} files={files} />
        </Stack>
      )}
    </TunnelRat>
  )
}

export const CaseArtifacts = (props: {
  artifacts: IArtifact[]
  withoutCreate?: boolean
  caseId: number
  isPreview?: boolean
}) => {
  const [view, setView] = useState<'grid' | 'list'>('grid')

  return (
    <ArtifactsSelectProvider>
      <CaseAccordion
        withoutEmptyState={view === 'list'}
        withoutCreate={props.withoutCreate}
        count={props.artifacts.length}
        label={{
          singular: 'archivo',
          plural: 'archivos',
        }}
        SuffixComponent={
          <Stack direction={'row'} alignItems='center'>
            <div id={CASE_ARTIFACTS_SUFFIX_COMPONENT_PORTAL_ID} />
            <AddCaseArtifacts caseId={props.caseId} />
          </Stack>
        }
      >
        <Box
          {...buildTestId('case-artifacts-list')}
          display='flex'
          flexDir='column'
          experimental_spaceY={2}
          p={4}
          position={'relative'}
        >
          <ButtonGroup size='sm' isAttached variant='outline' alignSelf={'end'}>
            <IconButton
              isDisabled={view === 'grid'}
              aria-label='grid-view'
              icon={<MdGridOn />}
              size={'xs'}
              onClick={() => setView('grid')}
            />
            <IconButton
              isDisabled={view === 'list'}
              aria-label='list-view'
              icon={<FaList />}
              size={'xs'}
              onClick={() => setView('list')}
            />
          </ButtonGroup>
          {!props.withoutCreate && (
            <ArtifactsActions
              isPreview={props.isPreview}
              caseId={props.caseId}
              files={props.artifacts}
            />
          )}

          <CaseArtifactsViewSwitcher
            artifacts={props.artifacts}
            caseId={props.caseId}
            view={view}
          />
        </Box>
      </CaseAccordion>
    </ArtifactsSelectProvider>
  )
}
