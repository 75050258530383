import React from 'react'
import { Button } from '@chakra-ui/react'
import { IArtifactAck, ICreateMessage, InvestigationClient } from 'kach-clients'
import { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useError } from '../hooks/useError'
import { buildInvestigationQueryKey } from '../utils/build-investigation-query-key'
import { MessageTextarea } from './MessageTextarea'
import { messages } from '../constants/messages'
import { buildTestId } from 'kach-commons'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'

export const AddMessageToInvestigation = (props: {
  withoutCreate?: boolean
  investigationId: number
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClientSingleton()

  const error = useError()

  const onSendMessage = useCallback(
    (message: ICreateMessage, onFinish: () => void) => {
      setIsLoading(true)

      InvestigationClient.addMessage(props.investigationId, message)
        .then((res) => {
          queryClient.invalidateQueries([
            buildInvestigationQueryKey(props.investigationId),
          ])

          onFinish()
        })
        .catch(error.handleError)
        .finally(() => setIsLoading(false))
    },
    [],
  )

  return (
    <MessageTextarea
      disabledReason={
        props.withoutCreate ? messages.case_user_relation_missing : ''
      }
      artifactsTokenizer={(files) =>
        InvestigationClient.tokenizeArtifacts(props.investigationId, files)
      }
      artifactsIdentifier='upload-files-chat'
    >
      {({ message, onClearMessage }) => {
        return (
          <Button
            {...buildTestId('send-message-btn')}
            isDisabled={!!props.withoutCreate}
            disabled={!!props.withoutCreate}
            isLoading={isLoading}
            onClick={() => onSendMessage(message, onClearMessage)}
            size='sm'
            colorScheme='brand'
          >
            Enviar
          </Button>
        )
      }}
    </MessageTextarea>
  )
}
