import {
  BoxProps,
  Button,
  Collapse,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'

export const CollapsibleContent: React.FC<{
  children: React.ReactNode
  boxStyles?: BoxProps
  title: string
  visibleItemCount?: number
  withCollapse?: boolean
}> = ({
  children,
  boxStyles,
  title,
  visibleItemCount = 5,
  withCollapse = true,
}) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <VStack
      display={'flex'}
      flexDir={'column'}
      justifyContent={'space-between'}
      p={2}
      border={'1px'}
      borderColor='gray.200'
      borderRadius={4}
      boxShadow={'xs'}
      {...boxStyles}
      align='stretch'
      spacing={2}
    >
      <Text fontWeight='semibold'>{title}</Text>
      {React.Children.toArray(children).slice(0, visibleItemCount)}

      <Collapse in={isOpen} animateOpacity>
        <VStack align='stretch' spacing={2}>
          {React.Children.toArray(children).slice(visibleItemCount)}
        </VStack>
      </Collapse>
      {withCollapse && (
        <Button
          width={24}
          onClick={onToggle}
          variant='unstyled'
          size={'xs'}
          textAlign='left'
          pl={0}
        >
          {isOpen ? 'Mostrar menos' : 'Mostrar más...'}
        </Button>
      )}
    </VStack>
  )
}
