import React, { useMemo } from 'react'
import {
  Heading,
  Box,
  Button,
  chakra,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Tooltip,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import { CaseClient, ICaseExtended, IReportInspection } from 'kach-clients'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { TbListDetails } from 'react-icons/tb'
import { useQuery } from '@tanstack/react-query'
import { Cell, Column } from 'react-table'
import { formatDate, formatUTC2UTC3 } from '../utils/format-date'
import { THREE_MINUTES_MS } from './CaseClaims'
import { FetchErrorMessage } from './FetchErrorMessage'
import { ChakraCarousel } from './ChakraCarousel'
import { CustomTable } from './CustomTable'
import { CaseAccordion } from './CaseAccordion'
import { useQuerySingleton } from '../hooks/useQuerySingleton'
import { CiImageOff, CiImageOn } from 'react-icons/ci'

const LabelValue = (props: { label: string; value: string }) => {
  return (
    <Box>
      <Text fontWeight={'bold'} color='gray.600'>
        {props.label}
      </Text>
      <Text fontSize={'sm'} fontWeight={'normal'}>
        {props.value || 'Dato no disponible'}
      </Text>
    </Box>
  )
}

const InspectionDetailBody = (props: {
  reportInspection: IReportInspection
}) => {
  const { isLoading, error, data } = useQuerySingleton(
    [`report-inspection-${props.reportInspection.id}-photos`],
    () =>
      CaseClient.listReportInspectionPhotos(
        props.reportInspection.case_id,
        props.reportInspection.id,
      ),
    {
      refetchInterval: THREE_MINUTES_MS,
      refetchOnMount: true,
      retry: 1,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return <FetchErrorMessage />
  }

  return (
    <Box>
      <SimpleGrid columns={2} spacing={4}>
        <LabelValue
          label='Nombre'
          value={props.reportInspection.customerFirstname}
        />

        <LabelValue
          label='Apellido'
          value={props.reportInspection.customerLastname}
        />

        <LabelValue label='Póliza' value={props.reportInspection.policyId} />

        <LabelValue
          label='Patente'
          value={props.reportInspection.licensePlate}
        />

        <LabelValue label='Plan' value={props.reportInspection.plan} />

        <LabelValue label='Motor' value={props.reportInspection.motor} />

        <LabelValue label='Chasis' value={props.reportInspection.chasis} />

        <LabelValue label='KM' value={props.reportInspection.km} />

        <LabelValue
          label='Llantas'
          value={`${props.reportInspection.tires}%`}
        />

        <LabelValue label='Color' value={props.reportInspection.color} />

        <LabelValue label='Estado' value={props.reportInspection.status} />

        <LabelValue
          label='Condición'
          value={props.reportInspection.condition}
        />
        <LabelValue
          label='Cod. verificación'
          value={props.reportInspection.verificationCode}
        />

        <LabelValue label='Lugar' value={props.reportInspection.place} />

        <LabelValue
          label='Fecha'
          value={formatDate(props.reportInspection.inspectionDate)}
        />
        <LabelValue
          label='Validación'
          value={props.reportInspection.validity}
        />

        <LabelValue
          label='Observaciones'
          value={props.reportInspection.observations}
        />

        <LabelValue label='Informe' value={props.reportInspection.report} />

        <LabelValue label='Autoriza' value={props.reportInspection.authorize} />

        <LabelValue label='Pedido' value={props.reportInspection.request} />
      </SimpleGrid>
      <Box my='10'>
        <Heading fontSize={'lg'}>Fotos</Heading>
        {(() => {
          if (!data?.photos.length) {
            return <Text>Esta inspección no cuenta con imágenes</Text>
          }

          return (
            <ChakraCarousel gap={5}>
              {data?.photos.map((p, index) => {
                return <chakra.img key={index} src={p.url} />
              })}
            </ChakraCarousel>
          )
        })()}
      </Box>
    </Box>
  )
}

const InspectionDetail = (props: { reportInspection: IReportInspection }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const hasPhotos = props.reportInspection.photos.length > 0
  return (
    <>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Tooltip label='Ver detalle'>
          <IconButton
            onClick={onOpen}
            variant={'ghost'}
            aria-label='Ver detalle'
            size={'sm'}
            icon={<TbListDetails />}
          />
        </Tooltip>
        <Tooltip
          label={
            hasPhotos
              ? 'La inspección tiene fotos'
              : 'La inspección no tiene fotos'
          }
        >
          {hasPhotos ? (
            <IconButton
              variant={'unstyled'}
              aria-label='has-photos'
              size={'sm'}
              icon={<CiImageOn />}
              style={{
                cursor: 'default',
              }}
            />
          ) : (
            <IconButton
              variant={'unstyled'}
              aria-label='no-photos'
              size={'sm'}
              icon={<CiImageOff />}
              style={{
                cursor: 'default',
              }}
            />
          )}
        </Tooltip>
      </Box>
      <Modal size='2xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalle de reporte de inspección</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isOpen && (
              <InspectionDetailBody reportInspection={props.reportInspection} />
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant='ghost'>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const Table = (props: { data: IReportInspection[] }) => {
  const columns = useMemo<Column<IReportInspection>[]>(
    () => [
      {
        id: 'actions',
        //@ts-ignore
        Cell: (cell: Cell<IReportInspection>) => {
          return <InspectionDetail reportInspection={cell.row.original} />
        },
      },
      {
        Header: 'Informe',
        accessor: 'gatewayId',
      },
      {
        Header: 'Nombre',
        accessor: 'customerFirstname',
      },
      {
        Header: 'Apellido',
        accessor: 'customerLastname',
      },
      {
        Header: 'Fecha inspección',
        accessor: (row: IReportInspection) =>
          format(new Date(row.inspectionDate), 'Pp aaa', { locale: es }),
      },
      {
        Header: 'Patente',
        accessor: 'licensePlate',
      },
    ],
    [],
  )

  return (
    <CustomTable
      orientation='left'
      noHover
      columns={columns}
      data={props.data}
    />
  )
}

const AccordionBody = (props: { case: ICaseExtended }) => {
  const { isLoading, data, error } = useQuerySingleton(
    [`case-${props.case.id}-report-inspections`],
    () => CaseClient.listReportInspections(props.case.id),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 180000,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 1,
    },
  )

  if (isLoading) {
    return (
      <Box
        h='full'
        display={'flex'}
        alignItems={'center'}
        justifyContent='center'
      >
        <Spinner />
      </Box>
    )
  }

  if (error || !data?.reportInspections) {
    return <FetchErrorMessage />
  }

  return (
    <Box>
      <Table data={data?.reportInspections} />
    </Box>
  )
}

export const CaseReportInspections = (props: { case: ICaseExtended }) => {
  return (
    <Box>
      <CaseAccordion
        withoutEmptyState
        label={{
          plural: 'Previas',
          singular: 'Previas',
        }}
      >
        {({ isExpanded }) => {
          if (isExpanded) {
            return <AccordionBody case={props.case} />
          }

          return null
        }}
      </CaseAccordion>
    </Box>
  )
}
