import { Box } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineUnorderedList } from 'react-icons/ai';

export const TableHeaderWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box display="flex" alignItems="center" experimental_spaceX={2}>
      {children}
    </Box>
  );
};
