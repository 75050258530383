import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { MailClient } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useError } from '../hooks/useError'
import { useQueryClientSingleton } from '../hooks/useQueryClientSingleton'
import {
  CreateMailAttemptSchema,
  ICreateMailAttemptSchema,
} from '../schemas/create-mail-attempt.schema'
import { buildMailQueryKey } from '../utils/build-mail-query-key'

export const CreateMailAttemptForm = (props: {
  mailId: number
  onClose: () => void
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ICreateMailAttemptSchema>({
    resolver: zodResolver(CreateMailAttemptSchema),
  })

  const queryClient = useQueryClientSingleton()

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const toast = useToast()

  const onSubmit = useCallback((data: Required<ICreateMailAttemptSchema>) => {
    setIsLoading(true)

    toast.promise(
      MailClient.createAttempt(props.mailId, {
        sentAt: data.sentAt,
        ...(data.receivedAt ? { receivedAt: data.receivedAt } : {}),
      }),
      {
        loading: { title: 'Agregando intento...' },
        success: () => {
          queryClient.invalidateQueries([buildMailQueryKey(props.mailId)])
          setIsLoading(false)
          props.onClose()
          return { title: 'Intento agregado correctamente' }
        },
        error: (err) => {
          setIsLoading(false)
          return { title: error.getErrorMessage(err) }
        },
      },
    )
  }, [])

  return (
    <chakra.form
      onSubmit={handleSubmit(onSubmit)}
      experimental_spaceY={4}
      bg='white'
      borderRadius='md'
      p={2}
      noValidate
    >
      <FormControl isInvalid={!!errors['sentAt']} isRequired>
        <FormLabel>Fecha envío</FormLabel>
        <Input {...register('sentAt')} size='sm' type='datetime-local' />
        {!!!errors['sentAt'] ? (
          <FormHelperText></FormHelperText>
        ) : (
          //@ts-ignore
          <FormErrorMessage>{errors['sentAt'].message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={!!errors['receivedAt']}>
        <FormLabel>Fecha acuse</FormLabel>
        <Input {...register('receivedAt')} size='sm' type='datetime-local' />
        {!!!errors['receivedAt'] ? (
          <FormHelperText></FormHelperText>
        ) : (
          //@ts-ignore
          <FormErrorMessage>{errors['receivedAt'].message}</FormErrorMessage>
        )}
      </FormControl>

      <Button
        {...buildTestId('create-mail-attempt-btn')}
        w='full'
        type='submit'
        size='sm'
        colorScheme='brand'
        isLoading={isLoading}
      >
        Crear
      </Button>
    </chakra.form>
  )
}
