import { Avatar, Box, chakra } from '@chakra-ui/react'
import { IUser } from 'kach-clients'
import { buildTestId } from 'kach-commons'
import React from 'react'
import { buildUserName } from '../utils/build-user-name'

export const UserDisplayer: React.FC<
  React.PropsWithChildren<{
    fullWidth?: boolean
    user: Pick<
      IUser,
      'firstName' | 'lastName' | 'email' | 'profilePhoto' | 'cellphone'
    >
    hideEmail?: boolean
    avatarSize?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    textSize?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    title?: string
    withContactCellphone?: boolean
  }>
> = (props) => {
  return (
    <Box
      {...(props.fullWidth ? { width: '100%' } : {})}
      display='flex'
      alignItems='center'
      experimental_spaceX={2}
    >
      <Avatar
        size={props.avatarSize ? props.avatarSize : 'xs'}
        referrerPolicy='no-referrer'
        src={props.user?.profilePhoto}
      />
      <Box
        display='flex'
        flexDir='column'
        fontSize={props.textSize ? props.textSize : 'xs'}
        lineHeight='1.25'
      >
        {props.title ? (
          <chakra.span {...buildTestId(`user-title`)}>
            {props.title}
          </chakra.span>
        ) : null}
        <chakra.span {...buildTestId(`user-name`)} fontWeight='semibold'>
          {buildUserName(props.user)}
        </chakra.span>
        {!props.hideEmail ? (
          <chakra.span {...buildTestId(`user-email`)} color='gray.400'>
            {props.user.email}
          </chakra.span>
        ) : null}

        {props.withContactCellphone && !!props.user.cellphone && (
          <chakra.span
            {...buildTestId(`user-cellphone`)}
            color='gray.400'
            fontSize='xs'
          >
            {props.user.cellphone}
          </chakra.span>
        )}

        {props.children}
      </Box>
    </Box>
  )
}
