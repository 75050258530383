import React, { useMemo } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { AuthorizationClient, IRolMinimized, IUserRole } from 'kach-clients'
import { useQuery } from '@tanstack/react-query'
import { Cell, Column } from 'react-table'
import { CreateRol } from './CreateRol'
import { CustomTable } from './CustomTable'
import HeaderPage from './HeaderPage'
import { PermissionsActions } from './PermissionsVisualizer'
import { ILocalPermission } from './RolePermissions'
import { useQuerySingleton } from '../hooks/useQuerySingleton'

export interface IManageRolesProps {
  initRoles: IRolMinimized[]
  permissionsToRender: ILocalPermission[]
}

export const ManageRoles: React.FC<
  React.PropsWithChildren<IManageRolesProps>
> = (props) => {
  const columns = useMemo<Column<IUserRole>[]>(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Creado por',
        //@ts-ignore
        Cell: (cell: Cell<IUserRole>) => {
          return (
            <Text
              fontStyle={!cell.row.original.createdBy ? 'italic' : 'initial'}
            >
              {cell.row.original.createdBy?.email || 'Kach'}
            </Text>
          )
        },
      },
      {
        id: 'action',
        //@ts-ignore
        Cell: (cell: Cell<IUserRole>) => (
          <PermissionsActions
            role={cell.row.original}
            permissionsToRender={props.permissionsToRender}
          />
        ),
      },
    ],
    [],
  )

  const { data } = useQuerySingleton(
    ['roles'],
    () => AuthorizationClient.listRoles(),
    {
      initialData: props.initRoles,
    },
  )

  return (
    <Box>
      <HeaderPage
        title='Roles'
        subtitle='Lista de roles disponibles para asignar a tus usuarios'
        ActionComponent={
          <CreateRol permissionsToRender={props.permissionsToRender} />
        }
      />

      <Box mt={4}>
        <CustomTable
          orientation='left'
          //@ts-ignore
          columns={columns}
          data={data}
        />
      </Box>
    </Box>
  )
}
