import { Box, Button, Container, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { TbServerOff } from 'react-icons/tb';
import { NavigatorFC } from './NotificationList';

export const UnexpectedError = (props: { Navigator: NavigatorFC }) => {
  return (
    <Container
      justifyContent={'center'}
      display={'flex'}
      alignItems="center"
      centerContent
      h="100vh"
    >
      <Box position="relative">
        <TbServerOff fontSize={'10rem'} />
        <Heading
          textAlign="center"
          fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
        >
          500
        </Heading>
      </Box>
      <Box padding={'1rem'} textAlign={'center'}>
        <Heading
          style={{
            fontWeight: 'bold',
          }}
        >
          Algo salió mal
        </Heading>
        <Text my="5" fontSize="lg">
          El equipo de Kach ya fue notificado del error y lo solucionará lo
          antes posible. Disculpa por las molestias ocasionadas.
        </Text>
      </Box>

      <props.Navigator href="/">
        <Button variant={'primary'}>Ir al inicio</Button>
      </props.Navigator>
    </Container>
  );
};
