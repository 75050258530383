import { ITag, TagClient } from 'kach-clients'
import { useQuerySingleton } from './useQuerySingleton'

export const useTags = (type: ITag['type']) =>
  useQuerySingleton([`tags-${type}`], () => TagClient.list({ type }), {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 1,
  })
