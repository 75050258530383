import { IUser } from './authentication.client';
import { ICompanyEnums, IValidationPattern } from './company.client';
import httpClient from './http-client';
import { IIntegrationConnection } from './integration.client';
import { IJob } from './job.client';
import {
  IArtifact,
  ICaseRequest,
  IInvestigation,
  IInvestigationReport,
  IMessage,
  INews,
} from './notification.client';
import {
  CreateNoteRequestBody,
  ListCaseNotesRequestQuery,
  NoteSchema,
} from './note.client';
import {
  CaseResultsType,
  CaseType,
  StopCaseRequestStatus,
} from 'kach-constants';
import {
  ListActiveCaseInvestigationsByCustomerResponseBody,
  RetentionRequest,
  RetrieveCaseViewsResponseBody,
  Suspect,
} from 'kach-commons';

export interface IEnumOption {
  name: string;
  id: number;
  caseType?: CaseType;
}

export interface IEnumWithDescription extends IEnumOption {
  description: string;
}

export interface ICaseCustomer {
  cuil?: string;
  type?: string;
  cellphone?: string;
  countryISO?: string;
  matricula?: string;
  birthdate?: string;
  birthplace?: string;
  politician?: string;
  maritalStatus?: string;
  sex?: string;
  email?: string;
  landlinePhone?: string;
  city?: string;

  state?: string;

  zipCode?: string;

  address?: string;

  firstName: string;
  lastName: string;
  identification: string;
}

export interface ICaseSsn {
  id: number;
  investigationSummary: string;
  judgment: boolean;
  result: Partial<IEnumOption>;
  resultDescription?: string;
  closeMotive: Partial<IEnumOption>;
  persons: Partial<ISsnPerson>[];
}

export interface IReportInspection {
  request: string;
  report: string;
  authorize: string;
  observations?: string;
  condition: string;
  case_id: number;
  id: number;
  gatewayId: string;
  verificationCode?: string;
  status?: string;
  place?: string;
  inspectionDate: Date;
  validity?: string;
  customerFirstname: string;
  customerLastname: string;
  policyId?: string;
  licensePlate?: string;
  plan?: string;
  motor?: string;
  chasis?: string;
  km?: string;
  tires?: string;
  color?: string;
}

export interface ICaseInspection {
  id: number;
  createdAt: string;
  createdBy: IUser;
  gatewayId: string;
  gatewayTemplateName: string;
  gatewayTemplateType: string;
  gatewayInspectionType: string;
}

export interface ICommercialReport {
  id: number;
  artifact: IArtifact;
}

export interface ICaseCustomerSinister {
  insurer: string;
  occurredAt: Date;
  domain: string;
  chasis: string;
  motor: string;
  coverageAffected: string;
}

export type ICaseCustomerReport = {
  case_id: number;
  identification?: string;
  createdAt: string;
  id: number;
  integrationConnection: IIntegrationConnection;
  requestedBy: IUser;
} & (
  | {
      type: 'commercial';
      commercial: ICommercialReport;
    }
  | {
      type: 'sinister';
      sinisters: ICaseCustomerSinister[];
    }
);

export interface IMailAttempt {
  id: number;
  sentAt: string;
  receivedAt: string;
  expiredAt: string;
  mail: IMail;
}

export interface IMail {
  case: ICase;
  createdBy: IUser;
  id: number;
  createdAt: string;
  artifact: IArtifact;
  externalId: string;
  attempts: IMailAttempt[];
}

export interface IThread {
  case: ICase;
  closedAt?: string;
  id: number;
  createdAt: string;
  openedBy: IUser;
  messages: IMessage[];
  destination: string;
  status: string;
}

export interface IEventDiff {
  column: keyof ICase;
  oldValue: any;
  newValue: any;
}

export type CaseUpdated = {
  event: 'case_updated';
  details: ICase[];
  metadata: {
    diff: IEventDiff[];
  };
};

export interface INewsRequest {
  news: INews[];
  annotation: string;
  artifacts: IArtifactTokenized[];
  id: number;
  createdAt: string;
  updatedAt: string;
  user: IUser;
  destinations?: string[];
}

export interface IInvestigationNewsRequest extends INewsRequest {}

export interface ICaseNewsRequest extends INewsRequest {}

export interface UploadInvestigationInvoice extends IInvestigation {
  name?: string;
}

export interface StopCaseRequest {
  rejectedReason?: string;
  case: {
    id: number;
    caseRequest: {
      sinisterId: string;
    };
  };
  status: StopCaseRequestStatus;
  handler: {
    username: string;
    email: string;
    profilePhoto: string;
    firstName: true;
    lastName: true;
  };
  createdBy: {
    id: number;
    username: string;
    email: string;
    profilePhoto: string;
    firstName: true;
    lastName: true;
  };
  createdById: number;
  id: number;
  reason: string;
  createdAt: Date;
  updatedAt: Date;
}

export type CaseEvents = {
  id: number;
  createdAt: string;
  updatedAt: string;
  triggeredBy: IUser;
} & (
  | {
      event: 'case_created';
    }
  | {
      event: 'case_taken';
    }
  | {
      event: 'stop_case_request_taken';
    }
  | CaseUpdated
  | {
      event: 'collaborator_added_to_case';
      details: IUser[];
    }
  | {
      event: 'case_commercial_report_requested';
      metadata: {
        identification: string;
      };
    }
  | {
      event: 'collaborator_added_to_case_request';
      details: IUser[];
    }
  | {
      event: 'collaborator_removed_from_case_request';
      details: IUser[];
    }
  | {
      event: 'stop_case_request_approved';
      details: StopCaseRequest[];
    }
  | {
      event: 'retention_request_approved';
      details: RetentionRequest[];
    }
  | {
      event: 'stop_case_request_rejected';
      details: StopCaseRequest[];
    }
  | {
      event: 'retention_request_rejected';
      details: RetentionRequest[];
    }
  | {
      event: 'case_assigned';
      details: IUser[];
    }
  | {
      event: 'stop_case_request_assigned';
      details: IUser[];
    }
  | {
      event: 'collaborator_removed_from_case';
      details: IUser[];
    }
  | {
      event: 'case_artifact_uploaded';
      details: IArtifact[];
    }
  | {
      event: 'case_artifact_removed';
      details: IArtifact[];
    }
  | {
      event: 'case_artifact_restored';
      details: IArtifact[];
    }
  | {
      event: 'case_news_created';
      details: INews[];
    }
  | {
      event: 'stop_case_request_news_created';
      details: INews[];
    }
  | {
      event: 'retention_request_news_created';
      details: INews[];
    }
  | {
      event: 'case_thread_created';
      details: IThread[];
    }
  | {
      event: 'case_thread_closed';
      details: IThread[];
    }
  | {
      event: 'case_mail_created';
      details: IMail[];
    }
  | {
      event: 'case_closed';
      details?: ICase[];
    }
  | {
      event: 'case_reopened';
      details?: ICase[];
    }
  | {
      event: 'investigation_report_accepted';
      details: IInvestigationReport[];
    }
  | {
      event: 'investigation_report_rejected';
      details: IInvestigationReport[];
    }
  | {
      event: 'case_responsibility_delegated';
      details: IUser[];
    }
  | {
      event: 'case_processor_updated';
      details: IUser[];
    }
  | {
      event: 'case_locked';
    }
  | {
      event: 'case_unlocked';
    }
  | {
      event: 'investigation_report_created';
      details: IInvestigationReport[];
    }
  | {
      event: 'investigation_report_accepted_dismissed';
      details: IInvestigationReport[];
    }
  | {
      event: 'investigation_report_rejected_dismissed';
      details: IInvestigationReport[];
    }
  | {
      event: 'investigation_reopen';
      details: IInvestigation[];
    }
  | {
      event: 'investigation_updated';
      details: IInvestigation[];
      metadata: {
        reason?: string;
        diff: IEventDiff[];
      };
    }
  | {
      event: 'indicators_added';
      details: IInvestigation[];
    }
  | {
      event: 'case_message_created';
      details: IMessage[];
    }
  | {
      event: 'case_investigation_accepted';
    }
  | {
      event: 'case_investigation_viewed';
    }
  | {
      event: 'case_investigation_requested_news';
      details: IMessage[];
    }
  | {
      event: 'case_investigation_derived';
      details: IInvestigation[];
    }
  | {
      event: 'case_investigation_news_created';
      details: INews[];
    }
  | {
      event: 'case_customer_sinister_history_report_requested';
      details: ICaseCustomerReport[];
    }
  | {
      event: 'case_customer_commercial_report_requested';
      details: ICaseCustomerReport[];
    }
  | {
      event: 'case_commercial_report_removed';
      details: ICaseCustomerReport[];
    }
  | {
      event: 'case_inspection_created';
      details: ICaseInspection[];
    }
  | {
      event: 'case_investigation_rejected';
    }
  | {
      event: 'investigation_closed';
      details: IInvestigation[];
    }
  | {
      event: 'investigation_canceled';
    }
  | {
      event: 'news_requested';
      details: ICaseNewsRequest[];
    }
  | {
      event: 'investigation_news_requested';
      details: IInvestigationNewsRequest[];
    }
  | {
      event: 'stop_case_news_requested';
      details: INewsRequest[];
    }
  | {
      event: 'retention_request_news_requested';
      details: INewsRequest[];
    }
  | {
      event: 'investigation_invoice_uploaded';
      details: UploadInvestigationInvoice[];
    }
  | {
      event: 'stop_case_request_created';
      details: StopCaseRequest[];
    }
  | {
      event: 'retention_request_created';
      details: RetentionRequest[];
    }
);
export interface ICaseUser {
  id: number;
  type: 'responsible' | 'collaborator';
  user: IUser;
}

export type Formulas = 'rivadavia_company' | 'rivadavia_lab';

export interface IFormulaWithResult {
  id: number;
  formula: Formulas;
  type: 'company_saving' | 'lab_saving';
  result: string;
}

export type ReportStatus = 'pending' | 'accepted' | 'rejected';

export interface CaseUserReminder {
  case_id: number;
  user_id: number;
  name: string;
  reminderTime?: Date;
}

export interface ICaseExtended extends ICase {
  appliesEndorsement?: boolean;
  deadline?: {
    daysLeft: number;
    marker: string;
  };
  hidden?: boolean;
  insuranceAgent?: {
    name: string;
    email: string;
    phoneNumbers: string[];
  };
  suspects: Suspect[];
  reminders: CaseUserReminder[];
  highlighted?: boolean;
  jobs: IJob[];
  formulaResults: IFormulaResult[];
  formulas: IFormulaWithResult[];
  inspections: ICaseInspection[];
  customerReports: ICaseCustomerReport[];
  investigations: IInvestigation[];
  mails: IMail[];
  threads: IThread[];
  events: CaseEvents[];
  artifacts: IArtifact[];
  users: ICaseUser[];
  attorneyIncluded?: boolean;
  ssn?: ICaseSsn;
  responsible: IUser;
  sinisterType?: IEnumOption;
  reason?: IEnumWithDescription;
  labOverwrittenFormulaResult?: string;
  companyOverwrittenFormulaResult?: string;
  savingDescription?: string;
  lastNewsDate?: Date;
  lastInvestigationDate?: Date;
  lastInvestigationNewsDate?: Date;
  last_lab_report_date?: Date;
  last_lab_report_conclusion?: string;
  last_lab_report_status?: ReportStatus;
  last_lab_report_accepted_at?: Date;
}

export interface ICaseUser {
  user_id: number;
  company_id: number;
  user: IUser;
}

export interface IFormulaResult {
  createdAt: string;
  id: number;
  variables: any;
  result: string;
  formula: {
    id: number;
    type: 'company_saving' | 'lab_saving';
  };
}

export interface ISsnPerson {
  id: number;
  type: string;
  firstName: string;
  lastName: string;
  documentType?: string;
  documentNumber?: string;
  ein?: string;
  address?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  profession?: string;
  lawyerType?: string;
  enrollment?: string;
}

export interface IInsuredCompany {
  cuit: string;

  societyReason: string;

  agency: string;

  matricula: string;

  route: string;

  address: string;

  type: string;

  landlinePhone: string;

  cellphone: string;

  email: string;

  identification: string;

  iva: string;

  characterGrossIncome: string;
}

export interface ICase {
  locked?: boolean;
  surrenderActor: IEnumOption;
  insuredCompany: IInsuredCompany;
  inabilityCoefficientLab?: string;
  inabilityCoefficientCompany?: string;
  appraisedPropertyDamage?: string;
  insuredAmount?: string;
  injuredCount?: number;
  users: ICaseUser[];
  closeMotive: IEnumWithDescription;
  result: CaseResultsType;
  customer: ICaseCustomer;
  news: INews[];
  id: number;
  status: 'not_assigned' | 'in_progress' | 'closed';
  createdAt: string;
  externalStatus: IEnumOption;
  instance: IEnumOption;
  caseRequest: ICaseRequest;
}

export interface IArtifactTokenized {
  originalName: string;
  fetchURL?: string;
  fields: object;
  presignedURL: string;
  token: string;
  storedName: string;
}

export interface ICaseContext {
  validationPatterns: IValidationPattern[];
  caseInstances: IEnumOption[];
  caseReasons: IEnumOption[];
  sinisterTypes: IEnumOption[];
  company: {
    id: number;
    name: string;
  };
  email: string;
}

export interface ICaseEnums
  extends Omit<
    ICaseContext,
    'company' | 'email' | 'validationPatterns' | 'externalStatus'
  > {}

export interface IMessageUserMention {
  userId: number;
}

export interface UpdateCaseSaving {
  percentageOfInability?: string;
  appraisedPropertyDamage?: string;
  insuredAmount?: string;
  injuredCount?: number;
  labOverwrittenFormulaResult?: string;
  companyOverwrittenFormulaResult?: string;
  savingDescription?: string;
}

export type UpdateCaseType = Partial<
  Map<keyof Omit<ICompanyEnums, 'validationPatterns'>, { name: string }>
> & {
  highlighted?: boolean;
  percentageOfInability?: string;
  appraisedPropertyDamage?: string;
  insuredAmount?: string;
  injuredCount?: number;
  customer?: Partial<ICaseCustomer>;
  ssn?: Partial<ICaseSsn>;
  sinisterType?: IEnumOption;
  reason?: IEnumOption;
  externalStatus?: IEnumOption;
  labOverwrittenFormulaResult?: string;
  companyOverwrittenFormulaResult?: string;
  savingDescription?: string;
  investigationObservations: string;
  investigationLabManagementTasksDescription: string;
  investigationLabManagementTasksResolution: string;
  appliesEndorsement?: boolean;
};

export type UpdateCaseRequestType = Partial<
  Map<
    keyof Pick<ICompanyEnums, 'sinisterTypes' | 'caseReasons'>,
    {
      name: string;
    }
  >
>;

export interface IArtifactAck {
  url: string;
  token: string;
  tags?: {
    id: number;
    label?: string;
    value?: number;
  }[];
}

export interface ICloseCase {
  result: string;
  motive: string;
}

export interface ISinisterDoc {
  originalDate: Date;
  case: ICase;
  case_id: number;
  type: string;
  gatewayRelation: string;
  description: string;
  document: IArtifact;
  document_id: number;
}

export interface IExpertExamination {
  id: number;
  case: ICase;
  case_id: number;
  photos: IArtifact[];
  customerFirstname: string;
  customerLastname: string;
  gatewayId: string;
  licensePlate: string;
}

export interface IClaim {
  case_id: number;
  id: number;
  case: ICase;
  documents: IArtifact[];
  customerFirstname: string;
  customerLastname: string;
  licensePlate: string;
  gatewayId: string;
  request: string;
}

export interface ICreateMessage {
  message: string;
  artifacts: IArtifactAck[];
  mentions: IMessageUserMention[];
}

export interface INewsAndRequest {
  type: 'news' | 'request';
  request?: INewsRequest;
  news?: INews;
}

export class CaseClient {
  static async queryAbilityForCreateCase(query: {
    identity: {
      access_token: string;
      provider: string;
    };
    ein: string;
  }): Promise<ICaseContext> {
    const response = await httpClient.post('/v1/case/ability/query', query);

    return response.data;
  }

  static async tokenizeArtifacts(
    caseId: number,
    artifacts: {
      name: string;
      size: number;
    }[]
  ): Promise<{
    artifactsTokenized: IArtifactTokenized[];
  }> {
    const response = await httpClient.post(
      `/v1/case/batch/artifact/token/${caseId}`,
      {
        artifacts,
      }
    );

    return response.data;
  }

  static async getCompressedCaseInfo(id: string): Promise<ICase> {
    const response = await httpClient.get(
      `/v1/insurance/case/compressed/${id}`
    );

    return response.data;
  }

  static async detail(caseId: number | string): Promise<ICaseExtended> {
    const response = await httpClient.get(`/v1/case/detail/${caseId}`);
    return response.data;
  }

  static async retrieveViews(
    caseId: number | string
  ): Promise<RetrieveCaseViewsResponseBody> {
    const response = await httpClient.get(`/v1/case/${caseId}/views`);
    return response.data;
  }

  static async updateLocked(
    caseId: number | string,
    locked: boolean
  ): Promise<{ success: boolean }> {
    const response = await httpClient.put(`/v1/case/${caseId}/locked`, {
      locked,
    });

    return response.data;
  }

  static async reopen(caseId: number | string): Promise<{ success: boolean }> {
    const response = await httpClient.put(`/v1/case/${caseId}/reopen`);

    return response.data;
  }

  static async defineCaseResponsible(
    caseId: number | string,
    userId: number
  ): Promise<{ success: boolean }> {
    const response = await httpClient.post(`/v1/case/assign/${caseId}`, {
      userId,
    });
    return response.data;
  }

  static async listEnums(): Promise<ICaseEnums> {
    const response = await httpClient.get(`/v1/case/enums`);

    return response.data;
  }

  static async takeCase(caseId: number) {
    const response = await httpClient.post(`/v1/case/take/${caseId}`);

    return response.data;
  }

  static async removeCollaborator(caseId: number, caseUserId: number) {
    const response = await httpClient.delete(
      `/v1/case/${caseId}/collaborator/${caseUserId}`
    );

    return response.data;
  }

  static async addCollaborator(caseId: number, userId: number) {
    const response = await httpClient.post(`/v1/case/collaborator/${caseId}`, {
      userId,
    });

    return response.data;
  }

  static async delegateResponsibility(caseId: number, userId: number) {
    const response = await httpClient.post(
      `/v1/case/${caseId}/delegate/responsibility`,
      {
        userId,
      }
    );

    return response.data;
  }

  static async updateCase(caseId: number, update: UpdateCaseType) {
    const response = await httpClient.put(`/v1/case/${caseId}`, update);
    return response.data;
  }

  static async updateMutableFields(
    caseId: number,
    update: Partial<UpdateCaseType>
  ) {
    const response = await httpClient.put(
      `/v1/case/${caseId}/mutable/fields`,
      update
    );
    return response.data;
  }

  static async setCaseUserReminder(
    caseId: number,
    data: {
      reminderTime?: Date | undefined | null;
    }
  ) {
    const response = await httpClient.post(`/v1/case/${caseId}/reminder`, data);
    return response.data;
  }

  static async unsetCaseUserReminder(caseId: number) {
    const response = await httpClient.delete(`/v1/case/${caseId}/reminder`);
    return response.data;
  }

  static async updateCaseSaving(caseId: number, update: UpdateCaseSaving) {
    const response = await httpClient.put(`/v1/case/${caseId}/saving`, update);
    return response.data;
  }

  static async delegateToLab(caseId: number, companyLabId: number) {
    const response = await httpClient.post(`/v1/case/${caseId}/delegate/lab`, {
      companyLabId,
    });

    return response.data;
  }

  static async addMessage(caseId: number, data: ICreateMessage) {
    const response = await httpClient.post(`/v1/case/message/${caseId}`, data);
    return response.data;
  }

  static async addArtifacts(caseId: number, artifacts: IArtifactAck[]) {
    const response = await httpClient.post(
      `/v1/case/batch/artifact/${caseId}`,
      {
        artifacts,
      }
    );
    return response.data;
  }

  static async close(caseId: number) {
    const response = await httpClient.put(`/v1/case/close/${caseId}`);

    return response.data;
  }

  static async listSinisterDocs(caseId: number): Promise<{
    sinisterDocuments: ISinisterDoc[];
  }> {
    const response = await httpClient.get(
      `/v1/case/${caseId}/sinister-documents`
    );

    return response.data;
  }

  static async listCaseRequestsNewsDocs(caseRequestId: number): Promise<{
    news: INews[];
  }> {
    const response = await httpClient.get(
      `/v1/case/request/${caseRequestId}/news`
    );

    return response.data;
  }

  static async listNewsAndRequests(caseId: number): Promise<{
    news: INewsAndRequest[];
  }> {
    const response = await httpClient.get(
      `/v1/case/${caseId}/news-and-requests`
    );

    return response.data;
  }

  static async listCaseNewsDocs(caseId: number): Promise<{
    news: INews[];
  }> {
    const response = await httpClient.get(`/v1/case/${caseId}/news`);

    return response.data;
  }

  static async fetchNewsRequests(caseId: number): Promise<{
    newsRequests: INewsRequest[];
  }> {
    const response = await httpClient.get(`/v1/case/${caseId}/news/request`);

    return response.data;
  }

  static async listClaimDocs(
    caseId: number,
    claimId: number
  ): Promise<{
    docs: IArtifact[];
  }> {
    const response = await httpClient.get(
      `/v1/case/${caseId}/claims/${claimId}/docs`
    );

    return response.data;
  }

  static async listReportInspections(caseId: number): Promise<{
    reportInspections: IReportInspection[];
  }> {
    const response = await httpClient.get(
      `/v1/case/${caseId}/report-inspections`
    );

    return response.data;
  }

  static async listReportInspectionPhotos(
    caseId: number,
    caseReportInspectionId: number
  ): Promise<{
    photos: IArtifact[];
  }> {
    const response = await httpClient.get(
      `/v1/case/${caseId}/report-inspections/${caseReportInspectionId}/photos`
    );

    return response.data;
  }

  static async listClaims(caseId: number): Promise<{
    claims: IClaim[];
  }> {
    const response = await httpClient.get(`/v1/case/${caseId}/claims`);

    return response.data;
  }

  static async listExpertExaminations(caseId: number): Promise<{
    expertExaminations: IExpertExamination[];
  }> {
    const response = await httpClient.get(
      `/v1/case/${caseId}/expert-examinations`
    );

    return response.data;
  }

  static async listExpertExaminationPhotos(
    caseId: number,
    expertExaminationId: number
  ): Promise<{
    photos: IArtifact[];
  }> {
    const response = await httpClient.get(
      `/v1/case/${caseId}/expert-examinations/${expertExaminationId}/photos`
    );

    return response.data;
  }

  static async updateProcessor(caseId: number, email: string) {
    const response = await httpClient.put(`/v1/case/${caseId}/processor`, {
      email,
    });

    return response.data;
  }

  static async resyncCase(caseId: number): Promise<{ jobId: number }> {
    const response = await httpClient.put(`/v1/case/${caseId}/resync`);

    return response.data;
  }

  static async listCaseNotes(
    caseId: number,
    query: ListCaseNotesRequestQuery
  ): Promise<{
    notes: NoteSchema[];
  }> {
    const response = await httpClient.get(`/v1/case/${caseId}/note/list`, {
      params: query,
    });

    return response.data;
  }

  static async createCaseNote(
    caseId: number,
    body: CreateNoteRequestBody
  ): Promise<{
    success: boolean;
  }> {
    const response = await httpClient.post(`/v1/case/${caseId}/note`, body);

    return response.data;
  }

  static async updateCaseNote(
    caseId: number,
    noteId: number,
    body: CreateNoteRequestBody
  ): Promise<{
    success: boolean;
  }> {
    const response = await httpClient.put(
      `/v1/case/${caseId}/note/${noteId}`,
      body
    );

    return response.data;
  }

  static async deleteCaseNote(
    caseId: number,
    noteId: number
  ): Promise<{
    success: boolean;
  }> {
    const response = await httpClient.delete(
      `/v1/case/${caseId}/note/${noteId}`
    );

    return response.data;
  }

  static async listActiveInvestigationsByCustomer(
    caseId: number
  ): Promise<ListActiveCaseInvestigationsByCustomerResponseBody> {
    const response = await httpClient.get(
      `/v1/case/${caseId}/active/investigation`
    );

    return response.data;
  }

  static async restoreCaseArtifactRemoved(caseId: number, artifactId: number) {
    const response = await httpClient.put(
      `/v1/case/${caseId}/artifact/${artifactId}/restore`
    );

    return response.data;
  }

  static async deleteCaseArtifacts(
    caseId: number,
    artifactId: number[]
  ): Promise<{
    token: string;
    presignedURL: string;
    filename: string;
  }> {
    const response = await httpClient.delete(
      `/v1/case/${caseId}/artifact/batch`,
      {
        params: { artifactId },
      }
    );

    return response.data;
  }
}

export const buildCaseNotesQueryKey = (caseId: number) =>
  `case-${caseId}-notes`;
