import { Box, Heading, Text } from '@chakra-ui/react'
import { IInvestigation } from 'kach-clients'
import React from 'react'
import { useContactPriceColumns } from '../hooks/useContactPriceColumns'
import { CustomTable } from './CustomTable'

export const InvestigationBudget = (props: {
  investigation: IInvestigation
}) => {
  const [columns] = useContactPriceColumns()

  if (!props.investigation?.budget) {
    return null
  }

  return (
    <Box
      borderWidth='1px'
      borderColor={'gray.100'}
      rounded='md'
      p={4}
      boxShadow={'md'}
    >
      <Box>
        <Heading fontWeight={'medium'} fontSize={'lg'}>
          Honorarios
        </Heading>
        <Text mt='1' maxW='2xl' color='gray.500' fontSize={'sm'}>
          Honorarios asignados a esta investigación
        </Text>
      </Box>
      <Box mt='5'>
        <CustomTable
          columns={columns}
          data={[props.investigation.budget]}
          orientation='center'
          noHover
        />
      </Box>
    </Box>
  )
}
